const Full = () => import( '@/container/Full.vue')

const cpIndexRoute = () => import(/* webpackPrefetch: false */ '../views/accountsetting/Index.vue')
const myZoneRoute = () => import(/* webpackPrefetch: false */ '../views/accountsetting/MyZoneDetails.vue')
export default {
   path: '/account_settings',
   component: Full,
   redirect: '/account_settings/:id/:accountId',
   beforeEnter(to, from, next) {
      if (localStorage.getItem('token')) {
         next()
      } else {
         next('/signin')
      }  
   },
   children: [
      {
         path: '/account_settings/:id/:accountId',
         component: cpIndexRoute,
         meta: {
            title: "Account Settings"
         }
      },
      {
         path: '/account_settings/get_all_my_zone_detail',
         component: myZoneRoute,
         meta: {
            title: "My Zone Details"
         }
      }
   ]
}
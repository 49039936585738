<template>
   <div class="jazeMainPanel" style="height: 100%;">
    <div class="jazePanelContent jazePanelContent--scrollable">
      <div class=" unifiSettingsHeader">
        <div class="unifiSettingsHeader__title">Search Result</div>
      </div>
      <div class="unifiSettingsContainer">
        <div  v-if="searchResult !=''">
          <form  ref="generalConfigForm" class="appForm appForm--cozy is-validation-hidden">
              <div class="form-group appRow">
                <div v-for='(search,index) in searchResult' :key='index' class="appInfoBoxContainer appInfoBoxContainer--dark mx-9 my-8"><br>
                  <a  @click="navigate(search.url)">
                    <div class="unifiSettingsHeader__title ml-3">{{search.name}}</div> 
                    <div v-for='(match,index1) in search.matches' :key='index1' class=""><br>
                      <div class="col--sm1"></div>
                      <h4 class="appLabel appLabel--boxAlign my-2"> {{match}}</h4><br>
                    </div>
                  </a>
                </div>
              </div>
          </form>
        </div>
        <div  v-else>
            <h4> No Matching Records Found</h4>
        </div>
      </div>
    </div>
   </div>
</template>
<script>
import { ref,toRefs } from 'vue'
import Nprogress from "nprogress";
import qs from "qs";
import axios from "@/api/index";
import Treeselect from "jaze-vue3-treeselect";
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";

export default {
  setup(props, context){
    const title_for_layout = ref( "search_settings" )
    const { searchResult } = toRefs(props)

    const navigate = (path) => {
      title_for_layout.value = path;
      context.emit("search-title-for-layout", title_for_layout.value);
    }

    return{
      title_for_layout,
      searchResult,
      navigate,
    }
  },
  props: ['searchResult'],
  components: {
    Treeselect,
    SuccessToaster,
    ErrorToaster
  },
}
</script>

<template>
  <header class="jazeHeaderPanel jazeHeaderPanel--global">
    <div class="jazeHeader jazeHeader--global" v-click-outside="hide">
      <router-link :to="{ path: '/dashboard'}">
        <div class="jazeHeader__logo">
          <a @click="dashboardClick" class="jazeLink">
            <div v-if="headerDetails.getLogoUrl!='/img/logologin.png'">
              <img style="max-height: 40px; max-width: 100px; height: auto; width: auto;" :src="headerDetails.getLogoUrl">
            </div>
            <div v-else>
              <img v-if="headerDetails.edition=='isp'" style="max-height: 40px; max-width: 100px; height: auto; width: auto;" :src="config.hostname+'/img/isp-logo1.png'">
              <img v-else-if="headerDetails.edition=='cloud'" style="max-height: 40px; max-width: 100px; height: auto; width: auto;" :src="config.hostname+'/img/dashboard1.png'">
              <img v-else style="max-height: 40px; max-width: 100px; height: auto; width: auto;" :src="config.hostname+'/img/access-manager.png'">
            </div>
          </a>
          <router-link :to="{ path: '/dashboard'}" v-if="headerDetails.Auth != undefined && headerDetails.Auth.Dashboard.isCoBranding!='' && headerDetails.Auth.Dashboard.isCoBranding=='yes'">
            <img class="headlogosize" alt="" position="center" :src="headerDetails.Auth.Dashboard.coBrandingLogo" />
          </router-link>
        </div>
      </router-link>
      <div class="jazeHeader__actions">
        <div class="jazePopoverContainer appOrgSwitcher appOrgSwitcher--withTitle pt-0" v-if="headerDetails.permissions != undefined && headerDetails.permissions.getallusers==true">
          <router-link :to="{ path: countRoundClick()}">
            <div class="appOrgSwitcher__trigger appOrgSwitcher__trigger_hide" @click="countRoundClick()" v-if="headerDetails.edition!='cloud' && headerDetails.Auth != undefined &&  headerDetails.Auth.Dashboard.removeAllUsers=='no'">
              <div class="appOrgSwitcher__title" v-if="headerDetails.permissions != undefined && headerDetails.permissions.getallusers==true">
                <span v-if="headerDetails.edition=='cloud' && headerDetails.elasticenabled==true" style="color: inherit;" @click="$router.push('/vouchers/allusers')"> Vouchers</span>
                <span v-else-if="headerDetails.edition=='cloud' && headerDetails.elasticenabled!=true" style="color: inherit;" @click="$router.push('/vouchers/all')"> Vouchers</span>
                <span v-else-if="headerDetails.edition!='cloud' && headerDetails.elasticenabled==true" style="color: inherit; " @click="$router.push('/users/allusers')"> Users</span>
                <span v-else-if="headerDetails.edition!='cloud' && headerDetails.elasticenabled!=true" style="color: inherit;" @click="$router.push('/users/all')"> Users</span>
              </div>
              <span class='appOrgSwitcher__label'>
                <router-link :to="{ path: countRoundClick()}">
                  <div class="count count--round appCount appCount--static" @click="countRoundClick()">
                    <div class="count__value">
                      <strong>
                        <a v-if="headerDetails.edition=='cloud' && headerDetails.elasticenabled==true" style="color: inherit;" @click="$router.push('/vouchers/allusers')"> {{headerDetails.currentTotalUsers}}</a>
                        <a v-else-if="headerDetails.edition=='cloud' && headerDetails.elasticenabled!=true" style="color: inherit;" @click="$router.push('/vouchers/all')"> {{headerDetails.currentTotalUsers}}</a>
                        <a v-else-if="headerDetails.edition!='cloud' && headerDetails.elasticenabled==true" style="color: inherit; " @click="$router.push('/users/allusers')"> {{headerDetails.currentTotalUsers}}</a>
                        <a v-else-if="headerDetails.edition!='cloud' && headerDetails.elasticenabled!=true" style="color: inherit;" @click="$router.push('/users/all')"> {{headerDetails.currentTotalUsers}}</a>
                      </strong>
                    </div>
                  </div>
                </router-link>
              </span>
            </div>
          </router-link>
        </div>
        <div class="jazePopoverContainer appOrgSwitcher appOrgSwitcher--withTitle pt-0" v-if="headerDetails.permissions != undefined && headerDetails.permissions.active_sessions==true">
          <router-link :to="{ path: '/monitoring/active_sessions'}">
            <div class="appOrgSwitcher__trigger appOrgSwitcher__trigger_hide" @click="$router.push('/monitoring/active_sessions')">
              <div class="appOrgSwitcher__title">
                <span v-if="headerDetails.permissions != undefined && headerDetails.permissions.active_sessions==true" @click="$router.push('/monitoring/active_sessions')">Online</span>
              </div>
              <span class='appOrgSwitcher__label'>
                <div class="count count--round appCount appCount--static">
                  <div class="count__value">
                    <strong>
                      <a style="color: inherit;" @click="$router.push('/monitoring/active_sessions')"> {{headerDetails.currentActiveSessions}} </a>
                    </strong>
                  </div>
                </div>
              </span>
            </div>
          </router-link>
        </div>
        <div class="jazePopoverContainer appOrgSwitcher appOrgSwitcher--withTitle pt-0">
          <div class="appOrgSwitcher__trigger fallIn" @click="viewMenuDatafn('notification');">
            <span class="jaze-icon--bell"></span>
            <span v-if="headerDetails.notificationCount != 0" class="appBadge appBadge--primary"> {{headerDetails.notificationCount}} </span>
          </div>
          <div class="jazePopover jazePopover--bottomLeft fallInMenu" :class='(viewMenuData =="notification")? "is-visible":""' style="width: 250px;margin-top: 2px;">
            <ul class="appOrgSwitcher__list">
              <li class="appOrgSwitcher__item_title">
                <span v-if="headerDetails.notificationCount!=0" class="jazeHeader__title type-jaze-title">
                  You have {{headerDetails.notificationCount}} notification(s)
                </span>
                <span v-else class="jazeHeader__title type-jaze-title">
                  You have 0 notification(s)
                </span>
              </li>
              <li class="appOrgSwitcher__item" v-for="(pendingUser, index) in headerDetails.usersPendingNotify" :key="'PendingUsers'+index">
                <span>
                  <a v-if="headerDetails.permissions != undefined && headerDetails.permissions.pending_users== true" @click="goToThisPage(pendingUser.AdminNotification.type_id, pendingUser.AdminNotification.account_id, 'user', 'no', 'pending_users')">
                  <span v-html="pendingUser.AdminNotification.content"></span> <span class="jaze jaze-icon--info-fill clientUp visible--lgUp unifiUpload">&nbsp;{{dateformat(pendingUser.AdminNotification.created)}}</span>
                    <!-- <strong> {{headerDetails.content}} user(s) </strong> have requested access. -->
                  </a>
                </span>
              </li>
              <li class="appOrgSwitcher__item" v-for="(raisedticket, index) in headerDetails.ticketsCount" :key="'RaisedTicket'+index">
                <span>
                  <a v-if="headerDetails.permissions != undefined && headerDetails.permissions.Helpdesk== true" @click="goToThisPage(raisedticket.AdminNotification.type_id, raisedticket.AdminNotification.account_id, 'ticket', 'no', 'tickets')">
                  <span v-html="raisedticket.AdminNotification.content"></span> <span class="jaze jaze-icon--info-fill clientUp visible--lgUp unifiUpload">&nbsp;{{dateformat(raisedticket.AdminNotification.created)}}</span>
                    <!-- <strong> {{headerDetails.ticketsCount}} ticket(s) </strong> have been raised. -->
                  </a>
                </span>
              </li>
              <li class="appOrgSwitcher__item" v-for="(newlead, index) in headerDetails.createdLeadCount" :key="index" >
                <span>
                    <a v-if="headerDetails.permissions != undefined && headerDetails.permissions.lead_dashboard== true" @click="goToThisPage(newlead.AdminNotification.type_id, newlead.AdminNotification.account_id, 'lead', 'no', 'lead_management')" >
                      <span v-html="newlead.AdminNotification.content"></span> <span class="jaze jaze-icon--info-fill clientUp visible--lgUp unifiUpload">&nbsp;{{dateformat(newlead.AdminNotification.created)}}</span>
                    </a>
                </span>
              </li>
              <li class="appOrgSwitcher__item" v-for="(unassignedtask, index) in headerDetails.unassignedTaskCount" :key="'UnassignedTasks'+index">
                <span>
                  <a v-if="headerDetails.permissions != undefined && headerDetails.permissions.Tasks== true" @click="goToThisPage(unassignedtask.AdminNotification.type_id, unassignedtask.AdminNotification.account_id, 'tasks', 'yes', 'tasks')">
                  <span v-html="unassignedtask.AdminNotification.content"></span> <span class="jaze jaze-icon--info-fill clientUp visible--lgUp unifiUpload">&nbsp;{{dateformat(unassignedtask.AdminNotification.created)}}</span>
                    <!-- <strong> {{headerDetails.AdminNotification.content}} unclaimed task(s) </strong> are pending. -->
                  </a>
                </span>
              </li>
              <li class="appOrgSwitcher__item" v-for="(assignedtask, index) in headerDetails.assignedTaskCount" :key="'AssignedTasks'+index">
                <span>
                  <a v-if="headerDetails.permissions != undefined && headerDetails.permissions.assigned_tasks== true" @click="goToThisPage(assignedtask.AdminNotification.type_id, assignedtask.AdminNotification.account_id, 'tasks', 'yes', 'assigned_tasks')">
                  <span v-html="assignedtask.AdminNotification.content"></span> <span class="jaze jaze-icon--info-fill clientUp visible--lgUp unifiUpload">&nbsp;{{dateformat(assignedtask.AdminNotification.created)}}</span>
                    <!-- <strong> {{headerDetails.assignedTaskCount}} assigned task(s) </strong> are pending. -->
                  </a>
                </span>
              </li>
              <li class="appOrgSwitcher__item" v-for="(assignedlead, index) in headerDetails.assignedLeadCount" :key="'AssignedLeads'+index">
                <span>
                    <a v-if="headerDetails.permissions != undefined && headerDetails.permissions.lead_dashboard== true" @click="goToThisPage(assignedlead.AdminNotification.type_id, assignedlead.AdminNotification.account_id, 'lead', 'no', 'lead_management')">
                      <span v-html="assignedlead.AdminNotification.content"></span> <span class="jaze jaze-icon--info-fill clientUp visible--lgUp unifiUpload">&nbsp;{{dateformat(assignedlead.AdminNotification.created)}}</span>
                    <!-- <strong> {{headerDetails.assignedLeadCount}} lead(s) </strong> are assigned. -->
                    </a>
                </span>
              </li>
              <li class="appOrgSwitcher__item">
                <span>
                  <a v-if="headerDetails.unReadMessageCount != 0 && headerDetails.permissions != undefined" @click="$router.push('/helpdesk/tickets')">
                    <strong> {{headerDetails.unReadMessageCount}}</strong> messages are unread.
                  </a>
                </span>
              </li>
              <li class="appOrgSwitcher__item" v-for="(natLogFailureRouter, index) in headerDetails.natLogFailureRouters" :key="'NatLogFailureRouter'+index">
                <span>
                  <a v-if="headerDetails.permissions != undefined && headerDetails.permissions.AccessPoints== true" @click="goToThisPage(natLogFailureRouter.AdminNotification.type_id, natLogFailureRouter.AdminNotification.account_id, 'natLogFailureRouters', 'no', 'routers',natLogFailureRouter.AdminNotification.created)">
                  <span v-html="natLogFailureRouter.AdminNotification.content"></span> <span class="jaze jaze-icon--info-fill clientUp visible--lgUp unifiUpload">&nbsp;{{dateformat(natLogFailureRouter.AdminNotification.created)}}</span>
                    <!-- Nat Logs have stopped generating for <strong> {{headerDetails.natLogFailureRouters}} </strong> router(s)'. -->
                  </a>
                </span>
              </li>
              <li class="appOrgSwitcher__item" v-for="(natLogFailureMac, index) in headerDetails.natLogFailureMacs" :key="'NatLogFailureMac'+index">
                <span>
                  <a v-if="headerDetails.permissions != undefined && headerDetails.permissions.AccessPoints== true" @click="goToThisPage(natLogFailureMac.AdminNotification.type_id, natLogFailureMac.AdminNotification.account_id, 'natLogFailureMacs', 'no', 'routers',natLogFailureMac.AdminNotification.created)">
                   <span v-html="natLogFailureMac.AdminNotification.content"></span> <span class="jaze jaze-icon--info-fill clientUp visible--lgUp unifiUpload">&nbsp;{{dateformat(natLogFailureMac.AdminNotification.created)}}</span>
                    <!-- Nat Logs have stopped generating for <strong> {{headerDetails.natLogFailureMacs}} </strong> MAC(s)'. -->
                  </a>
                </span>
              </li>
              <li class="appOrgSwitcher__item">
                <span>
                  <a v-if="headerDetails.notificationCount == 0" href="javascript:void(0)">
                    No new notifications.
                  </a>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="jazePopoverContainer appOrgSwitcher appOrgSwitcher--withTitle pt-0">
          <div class="appOrgSwitcher__trigger fallIn" @click="viewMenuDatafn('company')">
            <div class="appOrgSwitcher__title">
              <span v-if="headerDetails.Auth != undefined && headerDetails.Auth.User.Account.company_name!='' && headerDetails.Auth.User.Account.company_name!=undefined" class="appOrgSwitcher__label" type="0">
                {{ headerDetails.Auth.User.Account.company_name }}
              </span>
              <span v-else class="appOrgSwitcher__label" type="0">
                {{ sessionOutFn("Default")}}
              </span>
              <span class="appOrgSwitcher__arrow icon jaze-icon--pointer-down"></span>
            </div>
          </div>
          <div class="jazePopover jazePopover--bottomLeft fallInMenu" :class='(viewMenuData =="company")? "is-visible":""' style="width: 170px;margin-top: 8px;">
            <ul class="appOrgSwitcher__list">
              <li v-if="headerDetails.permissions != undefined && headerDetails.permissions.Settings==true" class="appOrgSwitcher__item">
                <router-link v-if="headerDetails.permissions.general==true" :to="{ path: '/settings/general'}" replace><i class="jaze-icon--settings"></i>&nbsp;<span>Settings</span></router-link>
                <router-link v-else-if="headerDetails.permissions.express==true" :to="{ path: '/settings/express'}" replace><i class="jaze-icon--settings"></i>&nbsp;<span>Settings</span></router-link>
                <router-link v-else-if="headerDetails.permissions.miscellaneous==true" :to="{ path: '/settings/miscellaneous'}" replace><i class="jaze-icon--settings"></i>&nbsp;<span>Settings</span></router-link>
                <router-link v-else-if="headerDetails.permissions.billing_settings==true" :to="{ path: '/settings/billing_settings'}" replace><i class="jaze-icon--settings"></i>&nbsp;<span>Settings</span></router-link>
                <router-link v-else-if="headerDetails.permissions.billing_address==true" :to="{ path: '/settings/billing_address'}" replace><i class="jaze-icon--settings"></i>&nbsp;<span>Settings</span></router-link>
                <router-link v-else-if="headerDetails.permissions.apis==true" :to="{ path: '/settings/apis'}" replace><i class="jaze-icon--settings"></i>&nbsp;<span>Settings</span></router-link>
                <router-link v-else-if="headerDetails.permissions.billing_period==true" :to="{ path: '/settings/billing_period'}" replace><i class="jaze-icon--settings"></i>&nbsp;<span>Settings</span></router-link>
                <router-link v-else-if="headerDetails.permissions.prefix_settings==true" :to="{ path: '/settings/prefix_settings'}" replace><i class="jaze-icon--settings"></i>&nbsp;<span>Settings</span></router-link>
                <router-link v-else-if="headerDetails.permissions.table_header_configuration==true" :to="{ path: '/settings/table_header_configuration'}" replace><i class="jaze-icon--settings"></i>&nbsp;<span>Settings</span></router-link>
                <router-link v-else-if="headerDetails.permissions.report_column_visible_configuration==true" :to="{ path: '/settings/report_column_visible_configuration'}" replace><i class="jaze-icon--settings"></i>&nbsp;<span>Settings</span></router-link>
                <router-link v-else-if="headerDetails.permissions.task_settings==true" :to="{ path: '/settings/task_settings'}" replace><i class="jaze-icon--settings"></i>&nbsp;<span>Settings</span></router-link>
                <router-link v-else-if="headerDetails.permissions.profile_settings==true" :to="{ path: '/settings/profile_settings'}" replace><i class="jaze-icon--settings"></i>&nbsp;<span>Settings</span></router-link>
                <router-link v-else-if="headerDetails.permissions.payasyougo_settings==true" :to="{ path: '/settings/payasyougo_settings'}" replace><i class="jaze-icon--settings"></i>&nbsp;<span>Settings</span></router-link>
                <router-link v-else-if="headerDetails.permissions.lead_settings==true" :to="{ path: '/settings/lead_settings'}" replace><i class="jaze-icon--settings"></i>&nbsp;<span>Settings</span></router-link>
                <router-link v-else-if="headerDetails.permissions.custom_rules==true" :to="{ path: '/settings/custom_rules'}" replace><i class="jaze-icon--settings"></i>&nbsp;<span>Settings</span></router-link>
                <router-link v-else-if="headerDetails.permissions.user_mandatory_fields==true" :to="{ path: '/settings/user_mandatory_fields'}" replace><i class="jaze-icon--settings"></i>&nbsp;<span>Settings</span></router-link>
                <router-link v-else-if="headerDetails.permissions.additional_fields==true" :to="{ path: '/settings/additional_fields'}" replace><i class="jaze-icon--settings"></i>&nbsp;<span>Settings</span></router-link>
                <router-link v-else-if="headerDetails.permissions.dashboard_configuration==true" :to="{ path: '/settings/dashboard_configuration'}" replace><i class="jaze-icon--settings"></i>&nbsp;<span>Settings</span></router-link>
                <router-link v-else-if="headerDetails.permissions.customer_portal_url==true" :to="{ path: '/settings/customer_portal_url'}" replace><i class="jaze-icon--settings"></i>&nbsp;<span>Settings</span></router-link>
                <router-link v-else-if="headerDetails.permissions.sms_management==true" :to="{ path: '/settings/sms_management'}" replace><i class="jaze-icon--settings"></i>&nbsp;<span>Settings</span></router-link>
                <router-link v-else-if="headerDetails.permissions.payment_collection_settings==true" :to="{ path: '/settings/payment_collection_settings'}" replace><i class="jaze-icon--settings"></i>&nbsp;<span>Settings</span></router-link>
                <router-link v-else-if="headerDetails.permissions.tally_informations==true" :to="{ path: '/settings/tally_informations'}" replace><i class="jaze-icon--settings"></i>&nbsp;<span>Settings</span></router-link>
                <router-link v-else-if="headerDetails.permissions.move_accounts==true" :to="{ path: '/settings/move_accounts'}" replace><i class="jaze-icon--settings"></i>&nbsp;<span>Settings</span></router-link>
                <router-link v-else-if="headerDetails.permissions.admins_with_access==true" :to="{ path: '/settings/admins_with_access'}" replace><i class="jaze-icon--settings"></i>&nbsp;<span>Settings</span></router-link>
                <router-link v-else-if="headerDetails.permissions.enable_working_hours==true" :to="{ path: '/settings/enable_working_hours'}" replace><i class="jaze-icon--settings"></i>&nbsp;<span>Settings</span></router-link>
              </li>
              <li v-if="headerDetails.permissions != undefined && headerDetails.permissions.my_zone_detail==true && headerDetails.edition=='isp' && headerDetails.onlyAccountsShow == 'yes'" class="appOrgSwitcher__item">
                <router-link :to="{ path: '/account_settings/get_all_my_zone_detail'}" replace><i class="jaze-icon--forums"></i>&nbsp;<span>My Zone Details</span></router-link>
              </li>
              <li v-if="headerDetails.permissions != undefined && headerDetails.permissions.create_sub_account==true && headerDetails.Auth != undefined && (headerDetails.Auth.Dashboard.parentFranchiseHead == 'no' || headerDetails.Auth.User.Account.franchise_head == 'yes') && headerDetails.onlyAccountsShow == 'yes'" class="appOrgSwitcher__item">
                <router-link :to="{ path: '/dashboard/create_subaccount'}" replace>
                  <i class="jaze-icon--social-network"></i>
                  <span v-if="headerDetails.edition == 'isp'"> Create sub-zone </span>
                  <span v-else> Create sub-account </span>
                </router-link>
              </li>
               <li v-if="headerDetails.platform == 'cloud' && headerDetails.appDetailsEnabled == 'yes'" class="appOrgSwitcher__item">
                <router-link :to="{ path: '/app_creation/app_details'}" replace><i class="jaze-icon--mobile-phone"></i>&nbsp;<span>App Details</span></router-link>
              </li>
              <div v-if="headerDetails.permissions != undefined && headerDetails.permissions.switch_account==true">
                <li v-if="headerDetails.Auth != undefined && (headerDetails.Auth.Dashboard.parentFranchiseHead == 'no' || headerDetails.Auth.User.Account.franchise_head == 'yes') && headerDetails.onlyAccountsShow == 'yes'" class="appOrgSwitcher__item">
                  <a href="#" class='switchAccountModalOpenButton' @click="openSwitchAccModal = true">
                    <i class="jaze-icon--sync"></i>
                    <span v-if="headerDetails.edition == 'isp'"> Switch zone </span>
                    <span v-else> Switch account </span>
                  </a>
                </li>
                <li v-else class="appOrgSwitcher__item appOrgSwitcher__add">
                  <a href="#" class='switchAccountModalOpenButton' @click="openSwitchAccModal = true">
                    <i class="jaze-icon--sync"></i>
                    <span v-if="headerDetails.edition == 'isp'"> Switch zone </span>
                    <span v-else> Switch account </span>
                  </a>
                </li>
              </div>
            </ul>
          </div>
        </div>
        <div class="jazePopoverContainer appOrgSwitcher appOrgSwitcher--withTitle pt-0">
          <div class="appOrgSwitcher__trigger fallIn" @click="viewMenuDatafn('admin')">
            <div class="appOrgSwitcher__label">
              <img v-if="headerDetails.Auth != undefined && headerDetails.Auth.Dashboard.profileImage!=''" :src="headerDetails.Auth.Dashboard.profileImage" style='width:27px;height:27px;border-radius: 100%;' alt="">
              <img v-else :src="config.hostname+'/img/default_photo.jpg'" style='width:27px;height:27px;border-radius: 100%;' alt="">
            </div>
          </div>
          <div class="jazePopover jazePopover--bottomLeft fallInMenu" :class='(viewMenuData =="admin")? "is-visible":""' style="width: 220px;">
            <ul class="appOrgSwitcher__list">
              <li class="appOrgSwitcher__item">
                <div v-if="headerDetails.Auth != undefined && headerDetails.Auth.User.username!=''"> {{headerDetails.Auth.User.username}} </div>
                <div v-else> ADMIN </div>
                <div v-if="headerDetails.Auth != undefined && headerDetails.Auth.User.name!=''"> Administrator {{headerDetails.Auth.User.name}} </div>
                <div v-else> Administrator </div>
              </li>
              <li v-if="headerDetails.permissions != undefined && headerDetails.permissions.AccountSettings==true" class="appOrgSwitcher__item appOrgSwitcher__add">
                <router-link :to="{ path: '/account_settings/'+headerDetails.Auth.User.id+'/'+headerDetails.Auth.User.account_id}"><i class="jaze-icon--client"></i>&nbsp;<span>My Profile</span></router-link>
              </li>
              <li v-if="headerDetails.edition=='isp'" class="appOrgSwitcher__item">
                <a v-if="headerDetails.Auth != undefined && headerDetails.Auth.Dashboard.adminAttendanceStatusValue == 'checkIn'" class="adminAttendanceSwitch" name="adminAttendanceSwitch" value="true" @click="toggleInOut(!checkIn)">
                    <span class="status-pill green online"></span>&nbsp;&nbsp;Online
                </a>
                <a v-if="headerDetails.Auth != undefined && headerDetails.Auth.Dashboard.adminAttendanceStatusValue == 'checkOut'" class="adminAttendanceSwitch" name="adminAttendanceSwitch" value="false" @click="toggleInOut(!checkIn)">
                  <span class="status-pill red offline"></span> &nbsp;Offline
                </a>
              </li>
              <li v-if="headerDetails.permissions != undefined && headerDetails.permissions.NetworkAdmins==true" class="appOrgSwitcher__item">
                <router-link :to="{ path: '/admins'}" replace><i class="jaze-icon--users"></i>&nbsp;<span >Admin Accounts</span></router-link>
              </li>
              <li v-if="headerDetails.permissions != undefined && headerDetails.permissions.AdminRoles==true" class="appOrgSwitcher__item">
                <router-link :to="{ path: '/admins/admin_role'}" replace><i class="jaze-icon--preferences"></i>&nbsp;<span>Admin Roles</span></router-link>
              </li>
              <li v-if="headerDetails.permissions != undefined && headerDetails.permissions.admin_classification==true" class="appOrgSwitcher__item">
                <router-link :to="{ path: '/network_admins/admin_classification'}" replace><i class="jaze-icon--logical"></i>&nbsp;<span>Admin Classifications</span></router-link>
              </li>
              <li v-if="headerDetails.permissions != undefined && headerDetails.permissions.License==true && headerDetails.platform =='hardware'" class="appOrgSwitcher__item">
                <router-link :to="{ path: '/license'}" replace><i class="jaze-icon--creditcard"></i>&nbsp;<span>License</span></router-link>
              </li>
              <li v-if="headerDetails.permissions != undefined && headerDetails.permissions.PaymentCollection==true && headerDetails.Auth != undefined && headerDetails.Auth.Dashboard.MobileOrDesktopActual == 'mobile' && 0" class="appOrgSwitcher__item">
                <a href="/dashboard/switch_to_mobile"><i class="jaze-icon--mobile-phone"></i> &nbsp;Switch To Mobile</a>
              </li>
              <li class="appOrgSwitcher__item">
                <router-link :to="{ path: '/lock_screen'}" replace><i class="jaze-icon--key"></i>&nbsp;<span>Lock Screen</span></router-link>
              </li>
              <li class="appOrgSwitcher__item">
                <a @click='logout'><i class="jaze-icon--login"></i>&nbsp;<span>Log Out</span></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="icon--medium jaze-icon--menu jazeMenuIcon" @click="displayMobileMenuFn('mobileShow')"></div>
      </div>

      <switchZoneModal v-if="openSwitchAccModal" :edition="headerDetails.edition" :authDetails="headerDetails.Auth" @extend-clicked-show-detail="onclickclose"></switchZoneModal>
    </div>
  </header>
</template>

<script>
import { ref,computed,watchEffect,onMounted,onBeforeUnmount } from 'vue'
import vClickOutside from "click-outside-vue3";
import axios from "@/api/index";
import qs from "qs";
import switchZoneModal from "@/components/layout/SwitchZone.vue";
import configa from "@/helpers/config.js";
import moment from "moment";

import { uselayoutStore } from '@/store/layoutstore'
import {storeToRefs} from "pinia";
import { useloginStore } from '@/store/loginstore'
import { useRouter,useRoute } from 'vue-router';
import   '../../../node_modules/js-loading-overlay/dist/js-loading-overlay.min.js'
export default {
  setup(_,{emit}) {
     const router = useRouter();
      const route = useRoute();
    const layoutStore = uselayoutStore()
    const { headerDetails } = storeToRefs(layoutStore)
    const loginStore = useloginStore()
    const viewMenuData=ref("");
    const viewMobileData=ref("no");
      const openSwitchAccModal=ref(false);
      const config=ref(configa);
       const checkIn=ref(false);
     const headerDetailsGettera= computed(() =>headerDetails.value);
     watchEffect(() => {
       headerDetails.value = headerDetailsGettera.value;
       if(headerDetails.value != undefined && headerDetails.value.Auth != undefined && headerDetails.value.Auth.Dashboard != undefined && headerDetails.value.Auth.Dashboard.currentAccountId != undefined && headerDetails.value.Auth.Dashboard.currentAccountId != null && headerDetails.value.Auth.User.Account.id != headerDetails.value.Auth.Dashboard.currentAccountId){
         location.reload();
        }
      }
      );
     onBeforeUnmount(() => {
      layoutStore.clearLayoutState()
    });
    const getHeaderDetails = () => {
       if (Object.keys(headerDetailsGettera.value).length == 0) {
        //this.$store.dispatch("fetchHeaderDetails");
        layoutStore.fetchHeaderDetails()
      } else {
        headerDetails.value = headerDetailsGettera.value;
        if (
          headerDetails.value.Auth.Dashboard.adminAttendanceStatusValue ==
          "checkIn"
        ) {
          checkIn.value = true;
        } else {
          checkIn.value = false;
        }
        if(headerDetails.value != undefined && headerDetails.value.Auth != undefined && headerDetails.value.Auth.Dashboard != undefined && headerDetails.value.Auth.Dashboard.currentAccountId != undefined && headerDetails.value.Auth.Dashboard.currentAccountId != null && headerDetails.value.Auth.User.Account.id != headerDetails.value.Auth.Dashboard.currentAccountId){
         location.reload();
        }
      }
      }
    onMounted(() => {
      getHeaderDetails()
    })
    const sessionOutFn = (value) => {
    if (headerDetailsGettera.value == undefined) {
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("token");
        localStorage.removeItem("adminDetails");
        location.reload();
      }
      return value;
    }
    const dashboardClick = () => {
      if (router.currentRoute.value.path == "/dashboard") {
        location.reload();
      } else {
        router.push("/dashboard");
      }
    }
     const countRoundClick = () => {
     var returnData = "";
      if (
        headerDetails.value.edition == "cloud" &&
        headerDetails.elasticenabled == true
      ) {
        returnData = "/vouchers/allusers";
      } else if (
        headerDetails.value.edition == "cloud" &&
        headerDetails.value.elasticenabled != true
      ) {
        returnData = "/vouchers/all";
      } else if (
        headerDetails.value.edition != "cloud" &&
        headerDetails.value.elasticenabled == true
      ) {
        returnData = "/users/allusers";
      } else if (
        headerDetails.value.edition != "cloud" &&
        headerDetails.value.elasticenabled != true
      ) {
        returnData = "/users/all";
      }
      return returnData;
     }
      const onclickclose = (value) => {
        openSwitchAccModal.value=value;
      }
      const logout = () => {
        loginStore.logout();
      }
       const viewMenuDatafn = (value) => {
         if (viewMenuData.value == value) {
        viewMenuData.value = "";
        if(value=="notification"){
          goToThisPage(null, null, null, null, null,null,'yes');
          layoutStore.fetchHeaderDetails();
        }
      } else {
        viewMenuData.value = value;
      }
      }
      const hide = () => {
        if(viewMenuData.value=="notification"){
        goToThisPage(null, null, null, null, null,null,'yes');
        layoutStore.fetchHeaderDetails();
        }
       viewMenuData.value = "";
      }
       const displayMobileMenuFn = (val) => {
        if (viewMobileData.value == val) {
        viewMobileData.value = "no";
      } else {
        viewMobileData.value = val;
      }
      emit("extend-mobile-show-detail",viewMobileData.value);
       }
       const toggleInOut = (val) => {
        JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
      var postData = {
        adminAttendance: val,
      };
      axios
        .post(
          "/dashboard/admin_attendance",
          qs.stringify(postData, {
            parseArrays: false,
          })
        )
        .then((response) => {
          JsLoadingOverlay.hide();
          if (response.data.status == "checkIn") {
            headerDetails.value.Auth.Dashboard.adminAttendanceStatusValue =
              "checkIn";
            checkIn.value = true;
          } else {
            headerDetails.value.Auth.Dashboard.adminAttendanceStatusValue =
              "checkOut";
            checkIn.value = false;
          }
        })
        .catch((error) => console.log(error));
       }
       const dateformat = (date) => {
         return moment(date).fromNow();
       }
        const goToThisPage = (typeId, accountId, type, fromTasks, path,created=null,allSeen=null) => {
          var postData = {
        type_id: typeId,
        account_id: accountId,
        type: type,
        from_tasks: fromTasks,
        created:created,
        allSeen:allSeen
      };
      axios
        .post(
          "/dashboard/notification_seen",
          qs.stringify(postData, {
            parseArrays: false,
          })
        )
        .then((response) => {
          if(response.data.status == 'success') {
            if(path == 'pending_users') {
              router.push('/users/pending_users/'+typeId).catch((error) => {});
              if(router.currentRoute.value.path === '/users/pending_users/'+typeId) {
                location.reload();
              }
            } else if(path == 'tickets') {
               router.push('/helpdesk/tickets/null/'+typeId).catch((error) => {});
              if(router.currentRoute.value.path === '/helpdesk/tickets/null/'+typeId) {
                location.reload();
              }
            } else if(path == 'lead_management') {
              router.push('/lead_management/all/'+typeId+'/'+accountId).catch((error) => {});
              if(router.currentRoute.value.path === '/lead_management/all/'+typeId+'/'+accountId) {
                location.reload();
              }
            } else if(path == 'tasks') {
              router.push('/tasks/'+typeId).catch((error) => {});
              if(router.currentRoute.value.path === '/tasks/'+typeId) {
                location.reload();
              }
            } else if(path == 'assigned_tasks') {
              router.push('/tasks/assigned_tasks/'+typeId).catch((error) => {});
              if(router.currentRoute.value.path === '/tasks/assigned_tasks/'+typeId) {
                location.reload();
              }
            } else if(path == 'routers') {
              router.push('/routers').catch((error) => {});
            }
          }          
        })
        .catch((error) => console.log(error));
        }
    return {goToThisPage,dateformat,toggleInOut,displayMobileMenuFn,hide,viewMenuDatafn,logout,onclickclose,countRoundClick, dashboardClick,sessionOutFn,layoutStore,checkIn,config,viewMenuData,openSwitchAccModal,headerDetails, headerDetailsGetter: headerDetails, loginStore }
  },
  components: {
    switchZoneModal,
  },
  directives:{
      clickOutside: vClickOutside.directive
  },
};
</script>

const Full = () => import( '@/container/Full.vue')

// dashboard components
const adminRoutes = () => import(/* webpackPrefetch: false */ '@/views/admins/index.vue')
const adminRoleRoutes = () => import(/* webpackPrefetch: false */ '@/views/admins/AdminRoleBar.vue')
const adminClassifyRoutes = () => import(/* webpackPrefetch: false */ '@/views/admins/AdminClassification.vue')
export default {
   path: '/admins',
   component: Full,
   redirect: '/admins',
   beforeEnter(to, from, next) {
      if (localStorage.getItem('token')) {
         next()
      } else {
         next('/signin')
      }
     
   },
   children: [
      {
         path: '/admins',
         component: adminRoutes,
         meta: {
            title: "Admins"
         }
      },
      {
         path: '/admins/admin_role',
         component: adminRoleRoutes,
         meta: {
            title: "Admin Roles"
         }
      },
      {
         path: '/admins/admin_controls',
         component: adminRoleRoutes,
         meta: {
            title: "Admin Roles"
         }
      },
      {
         path: '/network_admins/admin_classification',
         component: adminClassifyRoutes,
         meta: {
            title: "Admin Classifications"
         }
      }
   ]
}

<template>
  <div v-if="getOverviewDetail!= null && getOverviewDetail!= undefined && getOverviewDetail!= ''" class="col--sm12">
    <div class="moduleContent moduleContent--flexible">
      <div class="moduleHeader">
        <div class="modularSubHeading modularSubHeading--clipped moduleHeaderTitle">
          To Be Renewed
        </div>
      </div><br>

      <div class="unifiChannelDistributionChannels ml-5 mr-5">
        <div class="unifiChannelDistributionChannel unifiChannelDistributionChannel--5g">
          <div class="unifiChannelDistributionItem flexFlag flexFlag__body flexFlag--stretched positionRelative">
            <div class="ubntTooltipContainer ubntTooltipContainer--hoverable">
              <div class="unifiChannelDistributionItem__box" style="height:30px; padding: 10px; text-align:center; color:white; background-color: var(--color-500);">
                {{getOverviewDetail.usersToBeRenewed[0].type}}
              </div>
            </div>
            <div class="unifiChannelDistributionItem__label">
              <a @click='navigate("/billing/voucher_based_billing/" + account + "/" + getOverviewDetail.renewalCountDates[0])'>
                {{getOverviewDetail.usersToBeRenewed[0].count}}
              </a>
            </div>
          </div>
        </div>
        <div class="unifiChannelDistributionChannel unifiChannelDistributionChannel--5g">
          <div class="unifiChannelDistributionItem flexFlag flexFlag__body flexFlag--stretched positionRelative">
            <div class="ubntTooltipContainer ubntTooltipContainer--hoverable">
              <div class="unifiChannelDistributionItem__box" style="height:30px; padding: 10px; text-align:center;">
                {{getOverviewDetail.usersToBeRenewed[1].type}}
              </div>
            </div>
            <div class="unifiChannelDistributionItem__label">
              <a @click='navigate("/billing/voucher_based_billing/" + account + "/" + getOverviewDetail.renewalCountDates[1])'>
                {{getOverviewDetail.usersToBeRenewed[1].count}}
              </a>
            </div>
          </div>
        </div>
        <div class="unifiChannelDistributionChannel unifiChannelDistributionChannel--5g">
          <div class="unifiChannelDistributionItem flexFlag flexFlag__body flexFlag--stretched positionRelative">
            <div class="ubntTooltipContainer ubntTooltipContainer--hoverable">
              <div class="unifiChannelDistributionItem__box" style="height:30px; padding: 10px; text-align:center; color:white; background-color: var(--color-500);">
                {{getOverviewDetail.usersToBeRenewed[2].type}}
              </div>
            </div>
            <div class="unifiChannelDistributionItem__label">
              <a @click='navigate("/billing/voucher_based_billing/" + account + "/" + getOverviewDetail.renewalCountDates[2])'>
                {{getOverviewDetail.usersToBeRenewed[2].count}}
              </a>
            </div>
          </div>
        </div>
        <div class="unifiChannelDistributionChannel unifiChannelDistributionChannel--5g">
          <div class="unifiChannelDistributionItem flexFlag flexFlag__body flexFlag--stretched positionRelative">
            <div class="ubntTooltipContainer ubntTooltipContainer--hoverable">
              <div class="unifiChannelDistributionItem__box" style="height:30px; padding: 10px; text-align:center;">
                {{getOverviewDetail.usersToBeRenewed[3].type}}
              </div>
            </div>
            <div class="unifiChannelDistributionItem__label">
              <a @click='navigate("/billing/voucher_based_billing/" + account + "/" + getOverviewDetail.renewalCountDates[3])'>
                {{getOverviewDetail.usersToBeRenewed[3].count}}
              </a>
            </div>
          </div>
        </div>
      </div><br>
      <div class="unifiChannelDistributionChannels ml-5 mr-5">
        <div class="unifiChannelDistributionChannel unifiChannelDistributionChannel--5g">
          <div class="unifiChannelDistributionItem flexFlag flexFlag__body flexFlag--stretched positionRelative">
            <div class="ubntTooltipContainer ubntTooltipContainer--hoverable">
              <div class="unifiChannelDistributionItem__box" style="height:30px; padding: 10px; text-align:center; color:white; background-color: var(--color-500);">
                {{getOverviewDetail.usersToBeRenewed[4].type}}
              </div>
            </div>
            <div class="unifiChannelDistributionItem__label">
              <a @click='navigate("/billing/voucher_based_billing/" + this.account + "/" + getOverviewDetail.renewalCountDates[4])'>
                {{getOverviewDetail.usersToBeRenewed[4].count}}
              </a>
            </div>
          </div>
        </div>
        <div class="unifiChannelDistributionChannel unifiChannelDistributionChannel--5g">
          <div class="unifiChannelDistributionItem flexFlag flexFlag__body flexFlag--stretched positionRelative">
            <div class="ubntTooltipContainer ubntTooltipContainer--hoverable">
              <div class="unifiChannelDistributionItem__box" style="height:30px; padding: 10px; text-align:center;">
                {{getOverviewDetail.usersToBeRenewed[5].type}}
              </div>
            </div>
            <div class="unifiChannelDistributionItem__label">
              <a @click='navigate("/billing/voucher_based_billing/" + this.account + "/" + getOverviewDetail.renewalCountDates[5])'>
                {{getOverviewDetail.usersToBeRenewed[5].count}}
              </a>
            </div>
          </div>
        </div>
        <div class="unifiChannelDistributionChannel unifiChannelDistributionChannel--5g">
          <div class="unifiChannelDistributionItem flexFlag flexFlag__body flexFlag--stretched positionRelative">
            <div class="ubntTooltipContainer ubntTooltipContainer--hoverable">
              <div class="unifiChannelDistributionItem__box" style="height:30px; padding: 10px; text-align:center; color:white; background-color: var(--color-500);">
                {{getOverviewDetail.usersToBeRenewed[6].type}}
              </div>
            </div>
            <div class="unifiChannelDistributionItem__label">
              <a @click='navigate("/billing/voucher_based_billing/" + account + "/" + getOverviewDetail.renewalCountDates[6])'>
                {{getOverviewDetail.usersToBeRenewed[6].count}}
              </a>
            </div>
          </div>
        </div>
        <div class="unifiChannelDistributionChannel unifiChannelDistributionChannel--5g">
          <div class="unifiChannelDistributionItem flexFlag flexFlag__body flexFlag--stretched positionRelative">
            <div class="ubntTooltipContainer ubntTooltipContainer--hoverable">
              <div class="unifiChannelDistributionItem__box" style="height:30px; padding: 10px; text-align:center;">
                {{getOverviewDetail.usersToBeRenewed[7].type}}
              </div>
            </div>
            <div class="unifiChannelDistributionItem__label">
              <a @click='navigate("/billing/voucher_based_billing/" + account + "/" + getOverviewDetail.renewalCountDates[7])'>
                {{getOverviewDetail.usersToBeRenewed[7].count}}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Nprogress from "nprogress";
import axios from "@/api/index";
import { mapState, mapActions,storeToRefs } from "pinia"
import { usedashboardStore } from '@/store/dashboardstore';
import {ref,toRef,computed,watch,onMounted,watchEffect} from 'vue';
import {useRouter} from 'vue-router'
export default {
  props: ["detailType", "duration", "account"],
  setup(props,context) {
    const dashboardStore = usedashboardStore();
    const {overviewList,getOverviewList} = storeToRefs(dashboardStore);
    const overviewDetailsListGetters = computed(()=>overviewList.value);
    const getOverviewDetailsListGetters = computed(()=>getOverviewList.value);

    const overviewDetail = ref([]);
    const getOverviewDetail = ref([]);
    const detailType = toRef(props,'detailType');
    const duration = toRef(props,'duration');
    const account = toRef(props,'account');
    const tasksCounts=ref(null);
    const router= useRouter();
    onMounted(()=>{
      getDetails();
    });

    watch(overviewDetailsListGetters,()=>{
      overviewDetail.value = overviewDetailsListGetters.value.OverviewDetailsList;
    });

    watch(getOverviewDetailsListGetters,()=>{
      var postData = {
        detailType: "to_be_renewed",
        account: account.value,
        duration: duration.value,
      };
      if (Object.keys(getOverviewDetailsListGetters.value).length == 0) {
        // $store.dispatch("fetchGetOverviewDetails", postData);
        dashboardStore.fetchGetOverviewDetails(postData);
      } else {
        var obj = Object.keys(getOverviewDetailsListGetters.value);
        var dataobj = "GetOverviewDetailsList";
        if (obj.includes(dataobj) == true) {
          getOverviewDetail.value = getOverviewDetailsListGetters.value[dataobj];
        } else {
          // $store.dispatch("fetchGetOverviewDetails", postData);
          dashboardStore.fetchGetOverviewDetails(postData);
        }
      }
    });


    const getDetails = function() {
      var postData = {
        detailType: "to_be_renewed",
        account: account.value,
        duration: duration.value,
      };
      if (Object.keys(overviewDetailsListGetters.value).length == 0) {
        // $store.dispatch("fetchOverviewDetails", postData);
        dashboardStore.fetchOverviewDetails(postData);
      } else {
        var obj = Object.keys(overviewDetailsListGetters.value);
        var dataobj = "OverviewDetailsList";
        if (obj.includes(dataobj) == true) {
          overviewDetail.value = overviewDetailsListGetters.value[dataobj];
        } else {
          // $store.dispatch("fetchOverviewDetails", postData);
          dashboardStore.fetchOverviewDetails(postData);
        }
      }

      if (Object.keys(getOverviewDetailsListGetters.value).length == 0) {
        // $store.dispatch("fetchGetOverviewDetails", postData);
        dashboardStore.fetchGetOverviewDetails(postData);
      } else {
        var obj = Object.keys(getOverviewDetailsListGetters.value);
        var dataobj = "GetOverviewDetailsList";
        if (obj.includes(dataobj) == true) {
          getOverviewDetail.value = getOverviewDetailsListGetters.value[dataobj];
        } else {
          // $store.dispatch("fetchGetOverviewDetails", postData);
          dashboardStore.fetchGetOverviewDetails(postData);
        }
      }
    };
    const getTasksCount = function() {
      tasksCounts.value = tasksCountDetail.value.resp.tasksCount;
    };
    const navigate = (path)=> {
      router.push(path);
    };
    return { dashboardStore,overviewDetailsListGetters: overviewList,getOverviewDetailsListGetters: getOverviewList,detailType,duration,account,
    overviewDetail,getOverviewDetail,getDetails,getTasksCount,navigate,tasksCounts, }
  },
};
</script>



const Full = () => import(/* webpackChunkName: "fullcontainer-chunk" */ '@/container/Full.vue')
const TdsPaymentRoutes = () => import(/* webpackPrefetch: false */ '@/views/paymentCollection/TdsPayments.vue')
const ChequePaymentRoutes = () => import(/* webpackPrefetch: false */ '@/views/paymentCollection/ChequePayments.vue')
const CashPaymentRoutes = () => import(/* webpackPrefetch: false */ '@/views/paymentCollection/CashPayments.vue')
const BankDepositRoutes = () => import(/* webpackPrefetch: false */ '@/views/paymentCollection/BankDeposits.vue')
const PaymentCollectionRoutes = () => import(/* webpackPrefetch: false */ '@/views/paymentCollection/PaymentCollectionDetails.vue')
const AdminLedgerRoutes = () => import(/* webpackPrefetch: false */ '@/views/paymentCollection/AdminLedger.vue')
const PaymentCollectionUserRoutes = () => import(/* webpackPrefetch: false */ '@/views/paymentCollection/PaymentCollection.vue')
const PaymentConfigurationUserRoutes = () => import(/* webpackPrefetch: false */ '@/views/paymentCollection/PaymentConfiguration.vue')
const CollectionUserAreaUserRoutes = () => import(/* webpackPrefetch: false */ '@/views/paymentCollection/CollectionUsersAreaWise.vue')
const CollectionApprovalAreaUserRoutes = () => import(/* webpackPrefetch: false */ '@/views/paymentCollection/CollectionApprovalAreaWise.vue')
const CollectionAreaRoutes = () => import(/* webpackPrefetch: false */ '@/views/paymentCollection/CollectionAreas.vue')
const CollectionUserAdminUserRoutes = () => import(/* webpackPrefetch: false */ '@/views/paymentCollection/CollectionUsersAdminWise.vue')
const CollectionApprovalAdminUserRoutes = () => import(/* webpackPrefetch: false */ '@/views/paymentCollection/CollectionApprovalAdminWise.vue')
const BuildingMasterRoutes = () => import(/* webpackPrefetch: false */ '@/views/paymentCollection/BuildingMaster.vue')
const blockedRoutes = () => import(/* webpackPrefetch: false */ '@/components/Payment/Blocked.vue')
const DueForRenewalRoutes = () => import(/* webpackPrefetch: false */ '@/views/paymentCollection/DueForRenewal.vue')
export default {
    path: '/billing/tds_payments',
    component: Full,
    redirect: '/billing/tds_payments',
    beforeEnter(to, from, next) {
        if (localStorage.getItem('token')) {
            next()
        } else {
            next('/signin')
        }
    },
    children: [
        {
            path: '/billing/tds_payments',
            component: TdsPaymentRoutes,
            meta: {
                title: "TDS Payments"
            }
        },
        {
            path: '/billing/cheque_payments',
            component: ChequePaymentRoutes,
            meta: {
                title: "Cheque Payments"
            }
        },
        {
            path: '/billing/cash_payments',
            component: CashPaymentRoutes,
            meta: {
                title: "Cash Payments"
            }
        },
        {
            path: '/billing/bank_deposits',
            component: BankDepositRoutes,
            meta: {
                title: "Bank Deposits"
            }
        },
        {
            path: '/billing/payment_collection_details/:id',
            component: PaymentCollectionRoutes,
            meta: {
                title: "Payment Collection"
            }
        },
        {
            path: '/billing/admin_ledger/:id',
            component: AdminLedgerRoutes,
            meta: {
                title: "Admin Ledger"
            }
        },
        {
            path: '/billing/payment_collection',
            component: PaymentCollectionUserRoutes,
            meta: {
                title: "Payment Collection"
            }
        },
        {
            path: '/payments_collection/configuration',
            component: PaymentConfigurationUserRoutes,
            meta: {
                title: "Configuration"
            }
        },
        {
            path: '/payments_collection/collection_users',
            component: CollectionUserAreaUserRoutes,
            meta: {
                title: "Collection Users"
            }
        },
        {
            path: '/payments_collection/collection_approval',
            component: CollectionApprovalAreaUserRoutes,
            meta: {
                title: "Collection Approval"
            }
        },
        {
            path: '/payments_collection/areas',
            component: CollectionAreaRoutes,
            meta: {
                title: "Collection Areas"
            }
        },
        {
            path: '/payments_collection/collection_admin_users',
            component: CollectionUserAdminUserRoutes,
            meta: {
                title: "Collection Admin Users"
            }
        },
        {
            path: '/payments_collection/collection_admin_approval',
            component: CollectionApprovalAdminUserRoutes,
            meta: {
                title: "Collection Admin Approval"
            }
        },
        {
            path: '/payments_collection/building_master',
            component: BuildingMasterRoutes,
            meta: {
                title: "Building Master"
            }
        },
        {
            path: '/payment/blocked',
            component: blockedRoutes,
            meta: {
                title: "Blocked"
            }
        },
        {
            path: '/payments_collection/due_for_renewal',
            component: DueForRenewalRoutes,
            meta: {
                title: "Due For Renewal"
            }
        },
    ]
}
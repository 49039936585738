const Full = () => import(/* webpackChunkName: "fullcontainer-chunk" */ '@/container/Full.vue')
const ProductRoutes = () => import(/* webpackPrefetch: false */'@/views/billing/Products.vue')
const OrdersRoutes = () => import(/* webpackPrefetch: false */'@/views/billing/Orders.vue')
const InvoicesRoutes = () => import(/* webpackPrefetch: false */'@/views/billing/Invoices.vue')
const ProformaInvoicesRoutes = () => import(/* webpackPrefetch: false */'@/views/billing/ProformaInvoices.vue')
const CreditNotesRoutes = () => import(/* webpackPrefetch: false */'@/views/billing/CreditNotes.vue')
const PaymentsRoutes = () => import(/* webpackPrefetch: false */'@/views/billing/Payments.vue')
const InvoicesPayRoutes = () => import(/* webpackPrefetch: false */'@/views/billing/InvoiceAssociatedWithPayments.vue')
const ScheduledRenewalsRoutes = () => import(/* webpackPrefetch: false */'@/views/billing/ScheduledRenewals.vue')
const PlansRoutes = () => import(/* webpackPrefetch: false */'@/views/billing/Plans.vue')
const RenewalSummaryRoutes = () => import(/* webpackPrefetch: false */'@/views/billing/RenewalSummaryDetails.vue')
const NotificationRoutes = () => import(/* webpackPrefetch: false */'@/views/billing/BillingNotifications.vue')
const VoucherBasedBillingRoutes = () => import(/* webpackPrefetch: false */'@/views/billing/VoucherBasedBilling.vue')
const GracePeriodUsersRoutes = () => import(/* webpackPrefetch: false */'@/views/billing/GracePeriodUsers.vue')
const PayLaterUsersRoutes = () => import(/* webpackPrefetch: false */'@/views/billing/PayLaterUsers.vue')
const BillingConfigRoutes = () => import(/* webpackPrefetch: false */'@/views/billing/BillingConfiguration.vue')
const BillingDiagnRoutes = () => import(/* webpackPrefetch: false */'@/views/billing/BillingDiagnostics.vue')
const InvoiceTemplateRoutes = () => import(/* webpackPrefetch: false */'@/views/billing/InvoiceTemplate.vue')
const ReceiptTemplateRoutes = () => import(/* webpackPrefetch: false */'@/views/billing/ReceiptTemplate.vue')
const StaticIpsBillingRoutes = () => import(/* webpackPrefetch: false */'@/views/billing/StaticIpsBilling.vue')
const CreditNoteTemplateRoutes = () => import(/* webpackPrefetch: false */'@/views/billing/CreditNoteTemplate.vue')
const CafTemplateRoutes = () => import(/* webpackPrefetch: false */'@/views/billing/CAFTemplate.vue')
const FranchiseInvoiceTemplateRoutes = () => import(/* webpackPrefetch: false */'@/views/billing/FranchiseInvoiceTemplate.vue')
const InvoicesDashboard = () => import(/* webpackPrefetch: false */'@/views/billing/InvoicesDashboard.vue')
const PaymentDashboard = () => import(/* webpackPrefetch: false */'@/components/Billing/PaymentDashboard.vue')
const LedgerTemplateRoutes = () => import(/* webpackPrefetch: false */'@/views/billing/LedgerTemplate.vue')
export default {
   path: '/billing',
   component: Full,
   redirect: '/billing',
   beforeEnter(to, from, next) {
      if (localStorage.getItem('token')) {
         next()
      } else {
         next('/signin')
      }
   },
   children: [
      {
         path: '/billing/products',
         component: ProductRoutes,
         meta: {
            title: "Products"
         }
      },
      {
         path: '/billing/products/:socket',
         component: ProductRoutes,
         meta: {
               title: "Products"
         }
      },
         
      {
         path: '/billing/orders',
         component: OrdersRoutes,
         meta: {
            title: "Orders"
         }
      },
      {
         path: '/billing/invoices',
         component: InvoicesRoutes,
         meta: {
            title: "Invoices"
         }
      },
      {
         path: '/billing/proforma_invoices',
         component: ProformaInvoicesRoutes,
         meta: {
            title: "Proforma Invoices"
         }
      },
      {
         path: '/billing/credit_notes',
         component: CreditNotesRoutes,
         meta: {
            title: "Credit Notes"
         }


      },
      {
         path: '/billing/payments/:accountId/:startDate/:endDate/:method',
         component: PaymentsRoutes,
         meta: {
            title: "Payments"
         }
      },
      {
         path: '/billing/payments',
         component: PaymentsRoutes,
         meta: {
            title: "Payments"
         }
      },
      {
         path: '/billing/invoices_associated_with_payments',
         component: InvoicesPayRoutes,
         meta: {
            title: "Inoice Associated With Payments"
         }

      },
      {
         path: '/billing/schedule_renewals',
         component: ScheduledRenewalsRoutes,
         meta: {
            title: "Schedule Renewals"
         }
      },
      {
         path: '/billing/plans',
         component: PlansRoutes,
         meta: {
            title: "Plans"
         }
      },

      {
         path: '/billing/voucher_based_billing',
         component: VoucherBasedBillingRoutes,
         meta: {
            title: "Voucher Based Billing"
         }

      },
      {
         path: '/billing/graceperiod_users',
         component: GracePeriodUsersRoutes,
         meta: {
            title: "Grace Period Users"
         }

      },
      {
         path: '/billing/pay_later_users',
         component: PayLaterUsersRoutes,
         meta: {
            title: "Pay Later Users"
         }

      },
      {
         path: '/billing/notifications',
         component: NotificationRoutes,
         meta: {
            title: "Notifications"
         }
      },

      {
         path: '/billing/renewals_summary_details',
         component: RenewalSummaryRoutes,
         meta: {
            title: "Renewal Summary"
         }
      },
      {
         path: '/billing/billing_configuration',
         component: BillingConfigRoutes,
         meta: {
            title: "Billing Configuration"
         }
      },
      {
         path: '/billing/billing_diagnostics',
         component: BillingDiagnRoutes,
         meta: {
            title: "Billing Diagnostics"
         }
      },
      {
         path: '/billing/static_ips_billing',
         component: StaticIpsBillingRoutes,
         meta: {
            title: "Static IP Billing"
         }
      },
      {
         path: '/billing/invoice_template',
         component: InvoiceTemplateRoutes,
         meta: {
            title: "Invoice Template"
         }
      },
      {
         path: '/billing/receipt_template',
         component: ReceiptTemplateRoutes,
         meta: {
            title: "Receipt Template"
         }
      },
      {
         path: '/billing/credit_note_template',
         component: CreditNoteTemplateRoutes,
         meta: {
            title: "Credit Note Template"
         }
      },
      {
         path: '/billing/caf_template',
         component: CafTemplateRoutes,
         meta: {
            title: "CAF Template"
         }
      },
      {
         path: '/billing/franchise_invoice_template',
         component: FranchiseInvoiceTemplateRoutes,
         meta: {
            title: "Franchise Invoice Template"
         }
      },
      {
         path: '/billing/invoices_dashboard',
         component: InvoicesDashboard,
         meta: {
            title: "Invoice Dashboard"
         }
      },
      {
         path: '/billing/payment_dashboard',
         component: PaymentDashboard,
         meta: {
            title: "Payment Dashboard"
         }
      },
      {
         path: '/billing/ledger_template',
         component: LedgerTemplateRoutes,
         meta: {
            title: "Ledger Template"
         }
      },

   ]
}

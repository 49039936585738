const Full = () => import(/* webpackChunkName: "fullcontainer-chunk" */ '@/container/Full.vue')
const PingRoutes = () => import(/* webpackPrefetch: false */ '@/views/networkdiagnostics/Ping.vue')
const TraceRoutes = () => import(/* webpackPrefetch: false */ '@/views/networkdiagnostics/TraceRoute.vue')
const SysStatusRoutes = () => import(/* webpackPrefetch: false */ '@/views/networkdiagnostics/SystemStatus.vue')

export default {
    path: '/diagnostics',
    component: Full,
    redirect: '/diagnostics',
    beforeEnter(to, from, next) {
        if (localStorage.getItem('token')) {
            next()
        } else {
            next('/signin')
        }
    },
    children: [
        {
            path: '/diagnostics/ping',
            component: PingRoutes,
            meta: {
                title: "Diagnostics"
            }
        },
        {
            path: '/diagnostics/trace_route',
            component: TraceRoutes,
            meta: {
                title: "Diagnostics"
            }
        },
        {
            path: '/diagnostics/system_status',
            component: SysStatusRoutes,
            meta: {
                title: "Diagnostics"
            }
        },
    ]
}
import config from './config';
const getSelectedAccount = (searchQuery, accountData) => {
    if (searchQuery != "") {
        const regExpression = Array.from(searchQuery.toLowerCase()).reduce(
            (a, v, i) => `${a}[${searchQuery.toLowerCase().substr(i)}]?${v}`,
            ""
          );
        const formMatch = RegExp(regExpression);
        var filterResult = accountData.filter((data) =>
            data.label.toLowerCase().match(formMatch)
        );
        var queryResultDatas = [];
        filterResult.forEach((data) => {
            queryResultDatas.push(data.id);
        });
    } else {
        //  queryResultDatas = []
    }
    return queryResultDatas
}
const downloadFiles = (fileUrl, fileName) => {
    var fileUrl = config.hostname + fileUrl
    let anchor = document.createElement("a");
    document.body.appendChild(anchor);

    let headers = new Headers();
    headers.append('Authorization', localStorage.getItem('token'));

    fetch(fileUrl, { headers })
        .then(response => response.blob())
        .then(blobby => {
            let objectUrl = window.URL.createObjectURL(blobby);
            anchor.href = objectUrl;
            anchor.download = fileName;
            anchor.target ="_blank"
            anchor.click();
            window.URL.revokeObjectURL(objectUrl);
        });
}
export default { getSelectedAccount, downloadFiles };
const Full = () => import( '@/container/Full.vue')

// setting components

const settingRoutes = () => import(/* webpackPrefetch: false */ '@/views/settings/Settings.vue')

export default {
   path: '/settings',
   component: Full,
   redirect: '/settings',
   beforeEnter(to, from, next) {
      if (localStorage.getItem('token')) {
         next()
      } else {
         next('/signin')
      }
   },
   children: [
      {
         path: '/settings/general',
         component: settingRoutes,
         meta: {
            title: "General Setting"
         }
      },
      {
         path: '/settings/express',
         component: settingRoutes,
         meta: {
            title: "Express Setting"
         }
      },
      {
         path: '/settings/miscellaneous',
         component: settingRoutes,
         meta: {
            title: "Miscellaneous Setting"
         }
      },
      {
         path: '/settings/billing_settings',
         component: settingRoutes,
         meta: {
            title: "Billing Settings"
         }
      },
      {
         path: '/settings/billing_address',
         component: settingRoutes,
         meta: {
            title: "Billing Address Setting"
         }
      },
      {
         path: '/settings/router_settings',
         component: settingRoutes,
         meta: {
            title: "Router Settings"
         }
      },
      {
         path: '/settings/apis',
         component: settingRoutes,
         meta: {
            title: "APIS"
         }
      },
      {
         path: '/settings/billing_period',
         component: settingRoutes,
         meta: {
            title: "Billing Period"
         }
      },
      {
         path: '/settings/social_settings',
         component: settingRoutes,
         meta: {
            title: "Social Settings"
         }
      },
      {
         path: '/settings/prefix_settings',
         component: settingRoutes,
         meta: {
            title: "Prefix Settings"
         }
      },
      {
         path: '/settings/task_settings',
         component: settingRoutes,
         meta: {
            title: "Task Settings"
         }
      },
      {
         path: '/settings/payasyougo_settings',
         component: settingRoutes,
         meta: {
            title: "PayAsPayYouGo Settings"
         }
      },
      {
         path: '/settings/lead_settings',
         component: settingRoutes,
         meta: {
            title: "Lead Settings"
         }
      },
      {
         path: '/settings/custom_rules',
         component: settingRoutes,
         meta: {
            title: "Custom Rule Settings"
         }
      },
      {
         path: '/settings/admin_settings',
         component: settingRoutes,
         meta: {
            title: "Admin Settings"
         }
      },
      {
         path: '/settings/profile_settings',
         component: settingRoutes,
         meta: {
            title: "Profile Settings"
         }
      },
      {
         path: '/settings/tally_informations',
         component: settingRoutes,
         meta: {
            title: "Tally Information Settings"
         }
      },
      {
         path: '/settings/tag_payment_gateway',
         component: settingRoutes,
         meta: {
            title: "Tag Payment Gateway"
         }
      },
      {
         path: '/settings/dashboard_configuration',
         component: settingRoutes,
         meta: {
            title: "Dashboard Configuration"
         }
      },
      {
         path: '/settings/user_mandatory_fields',
         component: settingRoutes,
         meta: {
            title: "User Mandatory Fields"
         }
      },
      {
         path: '/settings/payment_collection_settings',
         component: settingRoutes,
         meta: {
            title: "Payment Collection Settings"
         }
      },
      {
         path: '/settings/customer_portal_url',
         component: settingRoutes,
         meta: {
            title: "Customer Portal URL"
         }
      },
      {
         path: '/settings/additional_fields',
         component: settingRoutes,
         meta: {
            title: "Additional Fields"
         }
      },
      {
         path: '/settings/franchise_settings',
         component: settingRoutes,
         meta: {
            title: "Franchise Settings"
         }
      },
      {
         path: '/settings/sms_management',
         component: settingRoutes,
         meta: {
            title: "SMS Management"
         }
      },
      {
         path: '/settings/sms_managements',
         component: settingRoutes,
         meta: {
            title: "SMS Management"
         }
      },
      {
         path: '/settings/table_header_configuration',
         component: settingRoutes,
         meta: {
            title: "Table Header Configurtion"
         }
      },
      {
         path: '/settings/report_column_visible_configuration',
         component: settingRoutes,
         meta: {
            title: "Report Column Visible Configurtion"
         }
      },
      {
         path: '/settings/move_accounts',
         component: settingRoutes,
         meta: {
            title: "Move Accounts"
         }
      },
      {
         path: '/settings/pms_settings',
         component: settingRoutes,
         meta: {
            title: "PMS settings"
         }
      },
      {
         path: '/settings/admins_with_access',
         component: settingRoutes,
         meta: {
            title: "Admins With Access"
         }
      },
      {
         path: '/settings/enable_working_hours',
         component: settingRoutes,
         meta: {
            title: "Working Hours"
         }
      },
      {
         path: '/settings/payment_virtual_account',
         component: settingRoutes,
         meta: {
            title: "Payment Virtual Account"
         }
      },
      {
         path: '/settings/app_last_run_time',
         component: settingRoutes,
         meta: {
            title: "App Last Run Time"
         }
      },
      {
         path: '/settings/user_notifications_settings',
         component: settingRoutes,
         meta: {
             title: "User Notifications"
         }
     },
     {
      path: '/settings/search_settings',
      component: settingRoutes,
      meta: {
          title: "Search Result"
      }
  },
   ]
}

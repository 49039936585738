import { defineStore } from 'pinia'

import router from '@/router/index'
import axios from '@/api/index'
import qs from 'qs'
import Nprogress from 'nprogress';
import  '../../node_modules/js-loading-overlay/dist/js-loading-overlay.min.js'
export const useannouncementsStore = defineStore('announcementsstore', {
    state: () => {
        return {
            sendAnnouncementsData: [],
            triggersData: [],
            messageData: []
        }
    },
    getters: {
        sendAnnouncementsDataState: (state) => state.sendAnnouncementsData,
        sendAnnouncementsGetters() {
            return this.sendAnnouncementsDataState;
        },
        triggersDataState: (state) => state.triggersData,
        triggersGetters() {
            return this.triggersDataState;
        },
        messageDataState: (state) => state.messageData,
        messageGetters() {
            return this.messageDataState;
        },
    },
    actions: {
        fetchSendAnnouncements() {
            Nprogress.start();
            return new Promise((resolve, reject) => {
                axios.get('/announcements/send_announcement_list')
                .then(res => {
                    Nprogress.done();                    if (res.data.status == "redirect") {
                        router.push(res.data.message);
                    }else if(res.data.status=="error" && res.data.isDatabaseAccessibleCheck=="yes"){
                        resolve(res.data);
                        
                    } else {
                        this.sendAnnouncementsData = res.data
                    }
                })
                .catch(error => console.log(error))
            });
        },
        sendAnnouncement(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/announcements/submit_announcements', postData)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        scheduleAnnouncement(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/announcements/schedule_announcements', postData)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        this.sendAnnouncementsData.scheduleAnnouncements.unshift(response.data.scheduleAnnouncementsArr);
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        deleteScheduleAnnouncement(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post("/announcements/delete_schedule_announcements", postData)
                   .then(response => {
                    JsLoadingOverlay.hide();
                    if (response.data.status != 'success') {
                        reject(response.data.message);
                        return;
                    }
                    var index = this.sendAnnouncementsData.scheduleAnnouncements.findIndex(schedule => schedule[0].id == postData.scheduleId);
                    if (index != '-1') {
                        this.sendAnnouncementsData.scheduleAnnouncements.splice(index, 1)
                    }
                    resolve(response.data);
                })
                .catch(error => console.log(error));
            });
        },
        addRecipientData(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/announcements/get_group_users', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        restrictToAllSubZoneAn(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/announcements/save_sub_accounts_cannot_change_offers', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        fetchTriggers() {
            Nprogress.start();
            axios.get('/announcements/triggers_list')
                .then(res => {
                    Nprogress.done()
                    this.triggersData = res.data
                })
                .catch(error => console.log(error))
        },
        postTrigger(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/announcements/additional_quota_offers/true', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        this.triggersData = response.data.message
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        fetchMessages() {
            Nprogress.start();
            axios.get('/announcements/messages_list')
                .then(res => {
                    Nprogress.done()
                    if (res.data.status == "redirect") {
                        router.push(res.data.message);
                    } else {
                        this.messageData = res.data
                    }
                })
                .catch(error => console.log(error))
        },
        saveMessagesSub(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/announcements/save_messages', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        applyToAllSubZoneAn(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/announcements/additional_quota_offers_subaccounts', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        getTemplateContent(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/announcements/get_template_content', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        }
    }
})
const Full = () => import('@/container/Full.vue')

// dashboard components
const Status = () => import(/* webpackPrefetch: false */ '@/views/status/status.vue')

export default {
    path: '/statistics',
    component: Full,
    redirect: '/statistics/status',
    beforeEnter(to, from, next) {
        if (localStorage.getItem('token')) {
            next()
        } else {
            next('/signin')
        }
    },
    children: [
        {
            path: '/statistics/status',
            component: Status,
            meta: {
                title: "Status"
            }
        },
    ]
}
const Full = () => import( '@/container/Full.vue')

// dashboard components
const accesspointRoutes = () => import(/* webpackPrefetch: false */ '@/views/accesspoints/Index.vue')
const allApsRoutes = () => import(/* webpackPrefetch: false */ '@/views/accesspoints/AllAps.vue')
const cpeDeviceRoutes = () => import(/* webpackPrefetch: false */ '@/views/accesspoints/OltCpeDevices.vue')
const ipManagementRoutes = () => import(/* webpackPrefetch: false */ '@/views/accesspoints/IpManagement.vue')
const peeringRoutes = () => import(/* webpackPrefetch: false */ '@/views/accesspoints/Peering.vue')
const oltOnuRoutes = () => import(/* webpackPrefetch: false */ '@/views/accesspoints/OltOnu.vue')
const apTimeLineRoutes = () => import(/* webpackPrefetch: false */ '@/views/accesspoints/APTimeLine.vue')
const natTimeLineRoutes = () => import(/* webpackPrefetch: false */ '@/views/accesspoints/NATTimeLine.vue')

export default {
  path: '/routers',
  component: Full,
  redirect: '/routers',
  beforeEnter(to, from, next) {
    if (localStorage.getItem('token')) {
      next()
    } else {
      next('/signin')
    }
  },
  children: [
    {
      path: '/routers',
      component: accesspointRoutes,
      meta: {
        title: "Routers"
      }
    },
    {
      path: '/routers/all_aps/:mac',
      component: allApsRoutes,
      meta: {
        title: "Routers"
      }
    },
    {
      path: '/ip_ranges',
      component: ipManagementRoutes,
      meta: {
        title: "IP Management"
      }
    },
    {
      path: '/cpe_device',
      component: cpeDeviceRoutes,
      meta: {
        title: "Routers"
      }
    },
    {
      path: '/olt_onu/:id/:accountId/',
      component: oltOnuRoutes,
      meta: {
        title: "Routers"
      }
    },
    {
      path: '/peering/:id',
      component: peeringRoutes,
      meta: {
        title: "Routers"
      }
    },
    {
      path: '/routers/ap_time_line/true',
      component: apTimeLineRoutes,
      meta: {
        title: "Routers"
      }
    },
    {
      path: '/routers/nat_time_line/:id',
      component: natTimeLineRoutes,
      meta: {
        title: "Routers"
      }
    }
  ]
}

<template>
  <div class="jazeMainPanel">
    <div class="jazePanelContent jazePanelContent--scrollable">
      <div class="unifiSettingsContainer">
        <form ref="paymentGatewayForm" id="paymentGatewayForm" class="appForm appForm--cozy is-validation-hidden">
          <div class="unifiSettingsHeader">
            <div class="unifiSettingsHeader__left">
              <div class="unifiSettingsHeader__title">Tag Payment Gateway</div>
            </div>
          </div>
          <fieldset class="appFieldset appFieldset--quiet">
            <div class="unifiSettingsSection" v-if="paymentGatewayData.gateways != undefined && paymentGatewayData.gateways.length != 0">
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign" v-if="paymentGatewayData.edition=='isp'"> Select Zone </label>
                  <label class="appLabel appLabel--primary appLabel--boxAlign" v-else> Select Account </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <treeselect :load-options="loadOptions" :options="zoneOptions" v-model="zoneSelected" :searchable="true" :clearable="false" @update:modelValue="displayGateways($event)"></treeselect>
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Apply these tags while making payment from Admin side? </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" :name="'enableForAdminSide_'+zoneSelected" :checked="applyTags[zoneSelected]==true ? true : false">
                </div>
              </div>
              <div v-for="gateway in gatewayArray" :key="gateway.name">
                <div class="appRow" v-if="!gateway.isDisabled">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> {{gateway.name}} </label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy" v-if='gatedis==true'>
                    <treeselect :load-options="loadOptions" :name="'select-'+zoneSelected+'-'+gateway.name1+'-'+gateway.gateway" :options="gateway.accOptions" v-model="gateway.selected" :searchable="true" :clearable="false"></treeselect>
                  </div>
                  <div class="col--sm3" v-if="gateway.mapText != undefined">
                    {{gateway.mapText}}
                  </div>
                </div>
                <input type="hidden" name="gateway" :value="gateway.gateway" v-if="!gateway.isDisabled">
                <input type="hidden" name="fromAccount" :value="gateway.fromAccount" v-if="!gateway.isDisabled">
                <input type="hidden" name="name" :value="gateway.name1" v-if="!gateway.isDisabled">
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> BBPS Account </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy" >
                  <treeselect :name="'bbpsAccount_'+zoneSelected" :options="subAccountsArr" v-model="bbpsSelected[zoneSelected]" :searchable="true" :clearable="false"></treeselect>
                </div>
                <div class="col--sm3" v-if="bbpsAccountMap[zoneSelected] != undefined && bbpsAccountMap[zoneSelected] !=''">
                  {{bbpsAccountMap[zoneSelected]}}
                </div>
              </div>
            </div>
            <div class="unifiSettingsSection" v-else>
              <div class="appRow">
                <div class="col--sm8">
                  There are no Payment Gateways
                </div>
              </div><br>
            </div>
          </fieldset>
        </form>
        <div class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark">
          <div class="appFormFooter__right">
            <button class="appMainButton appMainButton--primary busyToggle" type="submit" @click="saveProfileSettings"> Save</button>
          </div>
        </div>
      </div>
    </div>

    <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
    <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
  </div>
</template>

<script>
import { ref,computed,watch,watchEffect,onMounted,onBeforeUnmount } from 'vue';
import Treeselect from "jaze-vue3-treeselect";
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import { uselayoutStore } from '@/store/layoutstore'

import { storeToRefs } from "pinia";
import { usesettingsStore } from '@/store/settingsstore'
export default {
  setup() {
    // const paymentGatewayData = ref( [] )
    const successtoaster = ref( false )
    const successMessage = ref( "" )
    const errortoaster = ref( false )
    const errorMessage = ref( "" )
    const paymentGatewayArr = ref( {} )
    const zoneOptions = ref( [] )
    const zoneSelected = ref( null )
    const subAccountsArr = ref( [] )
    const firstZone = ref( "" )
    const selectedArr = ref( [] )
    const applyTags = ref( [] )
    const gatewayArray = ref( [] )
    const bbpsSelected = ref( [] )
    const bbpsAccountMap = ref( [] )
    const gatedis=ref(false);
    
    const settingsStore = usesettingsStore()
    const { paymentGatewayData } = storeToRefs(settingsStore)
    const paymentGatewaySettingsGetter = computed(() => paymentGatewayData.value)
    const layoutStore = uselayoutStore()
    const { franchiseBbpsAccountData } = storeToRefs(layoutStore)
    const franchiseBbpsAccountGetter = computed(() => franchiseBbpsAccountData.value);

    onMounted(() => {
      if(franchiseBbpsAccountGetter.value == undefined || (franchiseBbpsAccountGetter.value != undefined && franchiseBbpsAccountGetter.value.length == 0)){
        layoutStore.fetchfranchiseBbpsAccount();
      }
      PaymentGatewayDetails();
    })
    onBeforeUnmount(() => {
      settingsStore.clearSettingsState();
    })

    watchEffect(() => {
      paymentGatewayData.value = paymentGatewaySettingsGetter.value;
      franchiseBbpsAccountData.value = franchiseBbpsAccountGetter.value;
      if (
        franchiseBbpsAccountData.value.subAccountNames != undefined &&
        franchiseBbpsAccountData.value.subAccountNames.length != 0
      ) {
        subAccountsArr.value = [];
        for (
          var i = 0;
          i < franchiseBbpsAccountData.value.subAccountNames.length;
          i++
        ) {
          subAccountsArr.value.push({
            id: franchiseBbpsAccountData.value.subAccountNames[i].id,
            label: franchiseBbpsAccountData.value.subAccountNames[i].name
          });
          if (
            franchiseBbpsAccountData.value.subAccountNames[i].franchiseBbpsAccount !=
              undefined &&
            franchiseBbpsAccountData.value.subAccountNames[i].franchiseBbpsAccount !=
              ""
          ) {
            bbpsSelected.value[
              franchiseBbpsAccountData.value.subAccountNames[i].id
            ] = franchiseBbpsAccountData.value.subAccountNames[i].franchiseBbpsAccount;
            bbpsAccountMap.value[
              franchiseBbpsAccountData.value.subAccountNames[i].id
            ] = franchiseBbpsAccountData.value.subAccountNames[i].mapText;
          }
        }
      }
      if (
        paymentGatewayData.value.gateways != undefined &&
        paymentGatewayData.value.gateways.length != 0
      ) {
        var accountsArr = paymentGatewayData.value.gateways;
        zoneOptions.value = [];
        paymentGatewayArr.value = {};
        var obj = {};
        for (var i = 0; i < accountsArr.length; i++) {
          paymentGatewayArr.value[accountsArr[i].accountId] = [];
          applyTags.value[accountsArr[i].accountId] = "";
          zoneOptions.value.push({
            id: accountsArr[i].accountId,
            label: accountsArr[i].name
          });
          zoneSelected.value = zoneOptions.value[0].id;
          if (i == 0) {
            firstZone.value = accountsArr[i].accountId;
          }
          if (
            accountsArr[i].franchiseTagAdminSidePayment != undefined &&
            accountsArr[i].franchiseTagAdminSidePayment == "yes"
          ) {
            applyTags.value[accountsArr[i].accountId] = true;
          } else {
            applyTags.value[accountsArr[i].accountId] = false;
          }
          var optionsArr = [];
          if (
            accountsArr[i].gateways != undefined &&
            accountsArr[i].gateways.length != 0
          ) {
            var gatewayArr = accountsArr[i].gateways;
            for (var j = 0; j < gatewayArr.length; j++) {
              paymentGatewayArr.value[accountsArr[i].accountId][j] = {};
              paymentGatewayArr.value[accountsArr[i].accountId][j]["name"] =
                gatewayArr[j].name.toUpperCase() +
                "-" +
                gatewayArr[j].operatorValue;
              paymentGatewayArr.value[accountsArr[i].accountId][j]["accOptions"] = 
                subAccountsArr.value;
              paymentGatewayArr.value[accountsArr[i].accountId][j]["gateway"] =
                gatewayArr[j].operatorValue;
              paymentGatewayArr.value[accountsArr[i].accountId][j]["fromAccount"] = 
                accountsArr[i].accountId;
              paymentGatewayArr.value[accountsArr[i].accountId][j]["name1"] =
                gatewayArr[j].name;
              if (gatewayArr[j].operatorValue == "disable") {
                paymentGatewayArr.value[accountsArr[i].accountId][j][
                  "isDisabled"
                ] = true;
              } else {
                paymentGatewayArr.value[accountsArr[i].accountId][j][
                  "isDisabled"
                ] = false;
              }
              var tagPresent = "0";
              if (
                paymentGatewayData.value.taggedPaymentGateways != undefined &&
                paymentGatewayData.value.taggedPaymentGateways != null &&
                Object.keys(paymentGatewayData.value.taggedPaymentGateways)
                  .length != 0
              ) {
                for (const [key, value] of Object.entries(
                  paymentGatewayData.value.taggedPaymentGateways
                )) {
                  if (key == accountsArr[i].accountId) {
                    if (value.length != 0) {
                      for (var k = 0; k < value.length; k++) {
                        var maplist = JSON.parse(value[k]);
                        if (
                          (maplist["gateway"] =
                            gatewayArr[j].operatorValue &&
                            maplist["name"] == gatewayArr[j].name)
                        ) {
                          tagPresent = "1";
                          obj[
                            accountsArr[i].accountId +
                              "-" +
                              gatewayArr[j].name +
                              "-" +
                              gatewayArr[j].operatorValue
                          ] = maplist["toAccount"];
                          paymentGatewayArr.value[accountsArr[i].accountId][j][
                            "selected"
                          ] = maplist["toAccount"];
                        }
                      }
                    }
                  }
                }
              }
              if (tagPresent == "0") {
                obj[
                  accountsArr[i].accountId +
                    "-" +
                    gatewayArr[j].name +
                    "-" +
                    gatewayArr[j].operatorValue
                ] = accountsArr[i].accountId;
                paymentGatewayArr.value[accountsArr[i].accountId][j][
                  "selected"
                ] = accountsArr[i].accountId;
                paymentGatewayArr.value[accountsArr[i].accountId][j]["mapText"] =
                  "(not yet mapped)";
              }
            }
          }
        }
        selectedArr.value = obj;
        gatewayArray.value = paymentGatewayArr.value[firstZone.value];
        gatedis.value=true;
      }
    })

    const PaymentGatewayDetails = () => {
      if (Object.keys(paymentGatewaySettingsGetter.value).length == 0) {
        // $store.dispatch("fetchPaymentGatewaySettings");
        settingsStore.fetchPaymentGatewaySettings();
      } else {
        paymentGatewayData.value = paymentGatewaySettingsGetter.value;
      }
    }
    const displayGateways = (event) => {
      gatedis.value=false;
      gatewayArray.value = paymentGatewayArr.value[zoneSelected.value];
      setTimeout(
            function() {
              gatedis.value = true;
            }.bind(this),
            0.1
      );
      
    }
    const loadOptions = ({ action, searchQuery, callback, instanceId }) => {
      callback(null);
    }
    const saveProfileSettings = (e) => {
      e.preventDefault();
      var form = document.getElementById('paymentGatewayForm');
      const formData = new FormData(form);
      var postData = {};
      postData["gateway"] = [];
      postData["fromAccount"] = [];
      postData["name"] = [];
      postData["toAccount"] = [];
      for (let [key, val] of formData.entries()) {
        if (val != "disable") {
          if (key == "gateway") {
            postData["gateway"].push(val);
          }
          if (key == "fromAccount") {
            postData["fromAccount"].push(val);
          }
          if (key == "name") {
            postData["name"].push(val);
          }
          if (key.startsWith("enableForAdminSide_")) {
            Object.assign(postData, { [key]: val });
          }
          if (key.startsWith("bbpsAccount_")) {
            Object.assign(postData, { [key]: val });
          }
          if (key.startsWith("select-")) {
            postData["toAccount"].push(val);
          }
        }
      }
      // $store.dispatch("paymentGatewaySettingsSubmit", postData).then(
      settingsStore.paymentGatewaySettingsSubmit(postData).then(  
        response => {
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function() {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        error => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function() {
              errortoaster.value = false;
            }.bind(this),
            5000
          );
          console.log(error);
        }
      );
    }

    return { 
      settingsStore,
      paymentGatewaySettingsGetter: paymentGatewayData,
      franchiseBbpsAccountGetter,
      paymentGatewayData,
      successtoaster,
      successMessage,
      errortoaster,
      errorMessage,
      paymentGatewayArr,
      zoneOptions,
      zoneSelected,
      subAccountsArr,
      firstZone,
      selectedArr,
      applyTags,
      gatewayArray,
      bbpsSelected,
      bbpsAccountMap,
      PaymentGatewayDetails,
      displayGateways,
      loadOptions,
      saveProfileSettings,
      gatedis
    }
  },
  components: {
    Treeselect,
    SuccessToaster,
    ErrorToaster
  },
};
</script>

<template>
    <div>
      <div class="jazeSidePanel jazeSidePanel--global jazeSidePanel--shiftable jazeSidePanel--rightPanel rightPanelControl jazeSidePanel--fixedRight is-side-panel-open" style="--side-panel-width: 600px;" v-show='importTemplateModalShow'>
        <div class="jazePanelContainer jazePanelContainer--horizontal">
          <div class="jazeMainPanel jazeMainPanel--globalSidePanel unifiDndItem" draggable="true" style="left: 0px; top: 0px; width: 100%; z-index: 1;">
            <div class="jazeProperties jazeProperties--maximized">
              <div class="jazePanelContainer jazePanelContainer--horizontal gatewayProperties">
                <div class="jazeHeaderPanel jazeHeaderPanel--secondary jazeHeaderPanel--properties pr-0">
                  <div class="jazeHeaderGroup noPointerEvents" style="--header-actions-width:65px;">
                    <div class="jazeHeader jazeHeader--properties">
                      <div class="jazeHeader__title jazeHeader__title--properties">
                        <div class="type-jaze-title type-ellipsis py-1 mr-2"> Import {{templateName}} </div>
                      </div>
                    </div>
                    <div class="jazeHeader flex-none px-4">
                      <div class="jazeHeader__actions allPointerEvents">
                        <div class="jazeIconGroup jazeIconGroup--cozy jazeIconGroup--noSpacer">
                          <div class="unifiAuraIcon">
                            <svg class="jazeIcon jazeIcon--actionable" @click="onclosesubmit">
                              <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                                <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z"></path>
                              </g>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="jazeMainPanel jazeMainPanel--properties">
                  <div class="jazePanelContent jazePanelContent--scrollable">
                    <div class="unifiPanelItemContent">
                      <form ref='importBandwidthData' class="jazeForm unifiAddUserRight is-validation-hidden">
                        <div class="container container--horizontal-cozy container--vertical-comfy">
                          Upload a CSV in the following format -<br />
                          <div class="table-responsive">
                            <table class="jazeTable jazeTable--hoverable mt-1 is-not-responsive">
                              <thead>
                                <tr>
                                  <th>Field Name</th>
                                  <th>Mandatory ?</th>
                                  <th>Comments</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>name</td>
                                  <td>Yes</td>
                                  <td>Name of the rule</td>
                                </tr>
                                <tr>
                                  <td>if</td>
                                  <td>Yes</td>
                                  <td>Description condition</td>
                                </tr>
                                <tr>
                                  <td>action</td>
                                  <td>Yes</td>
                                  <td>Description action</td>
                                </tr>
                                <tr>
                                  <td>value</td>
                                  <td>No</td>
                                  <td>Description action value</td>
                                </tr>
                                <tr>
                                  <td>status</td>
                                  <td>Yes</td>
                                  <td>Active/Disable</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="form-group">
                            <div class="col-12">
                              <div class='row col-12'>
                                <input style="display:none" id='importFile' ref='importFile' class="importFile" type="file" @change="previewImage2" accept="application/pdf,image/*">
                                <button class='jazeButton jazeButton--grey' @click="chooseImage2">
                                  <span v-if='selectFile'>Select file </span>
                                  <span v-else>Change </span>
                                </button>&nbsp;&nbsp; &nbsp; &nbsp;
  
                                <div v-if="image2 != ''">
                                  {{importFileData.name}}
                                </div>
                              </div><br>
                              <div v-if='templatesData != undefined && templatesData.length >0'>
                                <span> Looks like you already have some {{templateName}} .</span><br><br>
                                <input v-model='autoUpdateExistingTemplate' type="checkbox" true-value="on" false-value="off" name="autoUpdateExistingTemplate">&nbsp; Auto-update &nbsp;&nbsp;
                                <a class="unifiNetworkStatusDiagramIconImage jazeTooltipContainer">
                                  <a @mouseover="tootipShow = true" @mouseleave="tootipShow = false" class="popovers" href="javascript:;" style="color: #666666"><i class="jaze-icon jaze-icon--info "></i> </a>
                                  <div class="jazeTooltip jazeTooltip--topRight jazeTooltip--small type-reset" v-show='tootipShow'>
                                    <div class="appDescriptionListRow">
                                      If a {{templateName}} is already present, it can update the {{templateName}} for you. This is particularly useful if you have an existing {{templateName}} that you are trying to keep synced with an external list.
                                    </div>
                                  </div>
                                </a><br><br>
                              </div>
                              <div>
                                The first line of the CSV file should be the header with the field names.<br />
                                <br />
                                See a
                                <a  class='jazeLink' :href='"/backend"+getsampleCustomRuleSampleFilePath' target="_blank">Sample CSV File</a>.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="jazeModal__footer">
                          <div class="jazeButtonGroup jazeButtonGroup--cozy">
                            <button class="jazeButton jazeButton--secondary" @click="onclosesubmit">Cancel</button>
                            <button class="jazeButton jazeButton--primary " @click='extendSubmit'>Confirm</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
  
            </div>
          </div>
        </div>
      </div>
      <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
      <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
    </div>
</template>
  
<script>
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import { ref,toRef,computed,getCurrentInstance } from 'vue'
import { usesettingsStore } from '@/store/settingsstore'
import {storeToRefs} from "pinia";
export default {
    props: ["templateName", "templatesData"],
    setup(props,context) {
      const templateName=toRef(props,'templateName');
      const templatesData=toRef(props,'templatesData');

      const settingsStore = usesettingsStore()
      const { customRuleSampleFilePath } = storeToRefs(settingsStore)
      const getsampleCustomRuleSampleFilePath = computed(() => customRuleSampleFilePath.value)

      const importTemplateModalShow =ref(true);
      const tootipShow =ref(false);
      const importFile =ref("");
      const image2 =ref("");
      const importFileData =ref("");
      const selectFile =ref(true);
      const autoUpdateExistingTemplate =ref(false);
      const successtoaster =ref(false);
      const successMessage =ref("");
      const errortoaster =ref(false);
      const errorMessage =ref("");
      const instance = getCurrentInstance()
      const roles =ref([]);
      const onclosesubmit = () => {
        importTemplateModalShow.value = false;
        context.emit("extend-clicked-import-detail", importTemplateModalShow.value);
      }
      const chooseImage2 = (e) => {
        e.preventDefault();
        instance.refs.importFile.click();
      }
      const previewImage2 = (event) => {
        var input = event.target;
        if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.onload = (e) => {
            image2.value = e.target.result;
            selectFile.value = false;
          };
          reader.readAsDataURL(input.files[0]);
          importFileData.value = input.files[0];
        }
      }
      const extendSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("templateName", templateName.value);
        formData.append("importFile", importFileData.value);
        if(autoUpdateExistingTemplate.value == 'on') {
          autoUpdateExistingTemplate.value = 'true';
        } else {
          autoUpdateExistingTemplate.value = 'false';
        }
        formData.append(
          "autoUpdateExistingTemplate",
          autoUpdateExistingTemplate.value
        );
        settingsStore.importCustomRuleModal(formData).then(  
          response => {
            importTemplateModalShow.value = false;
            successtoaster.value = true;
            successMessage.value = response.message;
            setTimeout(
              function() {
                successtoaster.value = false;
                context.emit("extend-clicked-import-detail", false);
              }.bind(this),
              1500
            );
          },
          error => {
            errortoaster.value = true;
            errorMessage.value = error;
            setTimeout(
              function() {
                errortoaster.value = false;
              }.bind(this),
              1500
            );
            console.log(error);
          }
        );
      }
      
      return { settingsStore, templateName, templatesData, getsampleCustomRuleSampleFilePath, importTemplateModalShow, 
      tootipShow, importFile, image2, importFileData, selectFile, autoUpdateExistingTemplate, successtoaster, successMessage, errortoaster, errorMessage, 
      roles, onclosesubmit, chooseImage2, previewImage2, extendSubmit }
    },
    components: { 
      SuccessToaster, 
      ErrorToaster
    },
}
</script>
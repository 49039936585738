const Full = () => import('@/container/Full.vue')

const Register = () => import(/* webpackPrefetch: false */ '@/views/register/index.vue')

export default {
    path: '/register',
    component: Full,
    redirect: '/register',
    beforeEnter(to, from, next) {
        // if (localStorage.getItem('token')) {
        //     next()
        // } else {
        //     next('/signin')
        // }
    },
    children: [
        {
            path: '/register',
            component: Register,
            meta: {
                title: "Register"
            }
        },
    ]
}
import { defineStore } from 'pinia'
import router from '@/router/index'
import axios from '@/api/index'
import qs from 'qs'
import Nprogress from 'nprogress';
import  '../../node_modules/js-loading-overlay/dist/js-loading-overlay.min.js'
export const usecpdatasStore = defineStore('cpdatasstore', {
    state: () => {
        return {
            userPortalData: [],
            targetedAdsData: [],
            editDataAd: [],
            customizePortal: [],
            wirelessData: [],
            enableCpData: []            
        }
    },
    getters: {
        userPortalDataState: (state) => state.userPortalData,
        userPortalDataGetters() {
            return this.userPortalDataState;
        },
        targetedAdsDataState: (state) => state.targetedAdsData,
        targetedAdsDataGetters() {
            return this.targetedAdsDataState;
        },
        editDataAdState: (state) => state.editDataAd,
        targetedAdsEditGetters() {
            return this.editDataAdState;
        },
        customizePortalState: (state) => state.customizePortal,
        customizePortalGetters() {
            return this.customizePortalState;
        },
        wirelessDataState: (state) => state.wirelessData,
        wirelessInstructionGetters() {
            return this.wirelessDataState;
        },
        enableCpDataState: (state) => state.enableCpData,
        enableCpGetters() {
            return this.enableCpDataState;
        },
    },
    actions: {
        fetchUserPortalDatas() {
            Nprogress.start();
            axios.get('/captive_portal_settings/user_portal_list')
                .then(res => {
                    Nprogress.done();
                    if (res.data.status == "redirect") {
                        router.push("/dashboard");
                      }
                      else{
                        this.userPortalData = res.data
                      }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 403) {
                            window.location = "/dashboard";
                        }
                    }
                })
        },
        restrictToSubZone(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/cpdatas/save_sub_accounts_cannot_change_userportal', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if(response.status=='redirect')
                        {
                            router.push("/dashboard");
                        }
                        else if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        applyToSubZone(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/cpdatas/add_all_userportal_to_subaccounts', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if(response.status=='redirect')
                        {
                            router.push("/dashboard");
                        }
                       else if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        saveUserPortal(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/cpdatas/user_portal_submit', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if(response.data.status=='redirect')
                        {
                            router.push("/dashboard");
                        }
                        else if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        fetchTargetedAdsDatas() {
            Nprogress.start();
            axios.get('/captive_portal_settings/targeted_ads_list')
                .then(res => {
                    Nprogress.done();
                    if(res.data.status=='redirect')
                    {
                        router.push("/dashboard");
                    }
                    else{
                        this.targetedAdsData = res.data
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 403) {
                            window.location = "/dashboard";
                        }
                    }
                })
        },
        getAdEditDatas(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/captive_portal_settings/create_edit_ad/' + postData)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        this.editDataAd = response.data
                        if(response.data.status=='redirect')
                        {
                            router.push("/dashboard");
                        }
                        else if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        saveAdEditDatas(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/cpdatas/add_edit_ad', postData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if(response.data.status=='redirect')
                        {
                            router.push("/dashboard");
                        }
                        else if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }    
                        var index = this.targetedAdsData.addatas.targetedAds.findIndex(element => element.Ad.id == response.data.lastAdId[0].Ad.id);
                        if (index == -1) {
                            this.targetedAdsData.addatas.targetedAds.push(response.data.lastAdId[0]);
                        }                        
                        if (this.targetedAdsData.adDetails != null) {
                            this.targetedAdsData.adDetails.push(response.data.adNewDetails[0]);
                        }                        
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        deleteAd(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/cpdatas/delete_ad.json', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if(response.data.status=='redirect')
                        {
                            router.push("/dashboard");
                        }
                        else if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var index = this.targetedAdsData.addatas.targetedAds.findIndex(element => element.Ad.id == postData.id);
                        if (index != -1) {
                            this.targetedAdsData.addatas.targetedAds.splice(index, 1);
                        }                        
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        fetchCustomizeCaptivePortal() {
            Nprogress.start();
            axios.get('/captive_portal_settings/captive_portal_setting_list')
                .then(res => {
                    Nprogress.done()
                    if(res.data.status=='redirect')
                        {
                            router.push("/dashboard");
                        }
                        else{
                            this.customizePortal = res.data
                        }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 403) {
                            window.location = "/dashboard";
                        }
                    }
                })
        },
        uploadPictureCp(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios
                    .post('/captive_portal_settings/captive_portal_setting_list', postData)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if(response.data.status=='redirect')
                        {
                            router.push("/dashboard");
                        }
                       else if (response.status != 200) {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response);
    
                    })
                    .catch(error => console.log(error));
            });
        },
        saveTextEditorDatas(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/cpdatas/edit_simul', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if(response.data.status=='redirect')
                        {
                            router.push("/dashboard");
                        }
                        else if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        editCustomizeData(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/captive_portal_settings/edit_cpdatas.json', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        fetchWirelessInstruction() {
            Nprogress.start();
            axios.get('/captive_portal_settings/wireless_instruction/true')
                .then(res => {
                    Nprogress.done();
                    var tData = {};
                    var parseData = res.data.message;
                    if (Object.keys(this.wirelessData).length == 0) {
                        tData[res.data.accountId + '-wirelessData'] = parseData;
                        this.wirelessData = tData;
                    } else {
                        this.wirelessData[res.data.accountId + '-wirelessData']= parseData;
                    }                    
                })
                .catch(error => console.log(error))
        },   
        saveWirelessInstructions(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/captive_portal_settings/edit_wireless_instruction', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },   
        uploadWireSubmit(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios
                    .post("/captive_portal_settings/import_file", postData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var fileD = response.data.fileD;
                        var accountId = postData.accountId;
                        if (Object.keys(this.wirelessData).length != 0 && this.wirelessData != 'noData') {
                            this.wirelessData[accountId + "-wirelessData"].files.push(fileD);
                        } else {
                            var dataSet = {}
                            dataSet[accountId + "-wirelessData"].files = fileD;
                            this.wirelessData = dataSet;
                        }                        
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },  
        fetchEnableCpData() {
            Nprogress.start();
            axios.get('/captive_portal_settings/enable_captive_portal/true')
                .then(res => {
                    Nprogress.done();
                    var tData = {};
                    var parseData = res.data.message;
                    if (Object.keys(this.enableCpData).length == 0) {
                        tData[res.data.accountId + '-enableCpData'] = parseData;
                        this.enableCpData = tData;
                    } else {
                        this.enableCpData[res.data.accountId + '-enableCpData']= parseData;
                    }                    
                })
                .catch(error => console.log(error))
        },
        enableCaptiveSubmit(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/captive_portal_settings/save_enable_captive_portal', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        resetDefault(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/cpdatas/action_todo.json', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if(response.data.status=='redirect')
                        {
                            router.push("/dashboard");
                        }
                        else if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        savetosubzone(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/cpdatas/add_captive_portal_settings_to_all_accounts', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if(response.data.status=='redirect')
                        {
                            router.push("/dashboard");
                        }
                        else if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
    }
})
const Full = () => import('@/container/Full.vue')
const franchiseIndexRoutes = () => import(/* webpackPrefetch: false */ '@/views/franchiseManagement/Index.vue')
const franchiseStatementIndexRoutes = () => import(/* webpackPrefetch: false */ '@/views/franchiseManagement/FranchiseStatement.vue')
const viewUserRevenueIndexRoutes = () => import(/* webpackPrefetch: false */ '@/views/franchiseManagement/ViewUserRevenue.vue')
const franchiseLogsIndexRoutes = () => import(/* webpackPrefetch: false */ '@/views/franchiseManagement/FranchiseLogs.vue')
const FranchisePaymentStatementRoutes = () => import(/* webpackPrefetch: false */ '@/views/franchiseManagement/FranchisePaymentStatement.vue')
const FranchisePaymentsRoutes = () => import(/* webpackPrefetch: false */ '@/views/franchiseManagement/FranchisePayments.vue')
const ConfigureFranchiseRoutes = () => import(/* webpackPrefetch: false */ '@/views/franchiseManagement/ConfigureFranchise.vue')
const FranchiseConfigRoutes = () => import(/* webpackPrefetch: false */ '@/views/franchiseManagement/FranchiseConfig.vue')
const AddLimitFlowRoutes = () => import(/* webpackPrefetch: false */ '@/components/Franchise/AddLimitFlow3.vue')
const FranchisePaymentStatusRoutes = () => import(/* webpackPrefetch: false */ '@/views/franchiseManagement/FranchisePaymentStatusModal.vue')
const ResellerPaymentRoutes = () => import(/* webpackPrefetch: false */ '@/views/franchiseManagement/ResellerPayment.vue')
const ResellerBlockedRoutes = () => import(/* webpackPrefetch: false */ '@/views/franchiseManagement/ResellerBlocked.vue')
const ResellerAccountDetails = () => import(/* webpackPrefetch: false */ '@/views/Payment/ResellersAccounts.vue')
const FranchisePackageDetailsRoutes = () => import(/* webpackPrefetch: false */ '@/views/franchiseManagement/FranchisePackageDetails.vue')
const PaysharpRoutes = () => import(/* webpackPrefetch: false */ '@/views/franchiseManagement/Paysharp.vue')
const RecurringBillingDetails=() =>import(/* webpackPrefetch: false */ '@/views/franchiseManagement/FranchiseRecurringBilling.vue')
export default {
    path: '/franchise_management',
    component: Full,
    redirect: '/franchise_management',
    beforeEnter(to, from, next) {
        if (localStorage.getItem('token')) {
            next()
        } else {
            next('/signin')
        }
    },
    children: [
        {
            path: '/franchise_management',
            component: franchiseIndexRoutes,
            meta: {
                title: "Franchise Management"
            }
        },
        {
            path: '/franchise_management/reseller_account_detail',
            component: RecurringBillingDetails,
            meta: {
                title: "Recurring Billing"
            }
        },
        {
            path: '/franchise_management/franchise_logs',
            component: franchiseLogsIndexRoutes,
            meta: {
                title: "Franchise Logs"
            }
        },
        {
            path: '/franchise_management/franchise_statement',
            component: franchiseStatementIndexRoutes,
            meta: {
                title: "Franchise Statement"
            }
        },
        {
            path: '/franchise_management/view_user_revenue',
            component: viewUserRevenueIndexRoutes,
            meta: {
                title: "Franchise Management"
            }
        },
        {
            path: '/franchise_management/franchise_payment_statement',
            component: FranchisePaymentStatementRoutes,
            meta: {
                title: "Franchise Payment Statement"
            }
        },
        {
            path: '/franchise_management/franchise_payments',
            component: FranchisePaymentsRoutes,
            meta: {
                title: "Franchise Payments"
            }
        },
        {
            path: '/franchise_management/configure_franchise',
            component: ConfigureFranchiseRoutes,
            meta: {
                title: "Franchise Configuration"
            }
        },
        {
            path: '/franchise_management/franchise_config',
            component: FranchiseConfigRoutes,
            meta: {
                title: "Franchise Configuration"
            }
        },
        {
            path: '/franchise_management/franchise_config/:accountId',
            component: FranchiseConfigRoutes,
            meta: {
                title: "Franchise Configuration"
            }
        },
        {
            path: '/franchise_management/franchise_add_limit',
            component: AddLimitFlowRoutes,
            meta: {
                title: "Franchise"
            }
        },
        {
            path: '/franchise_management/franchise_add_limit_success/:actualAmount/:status/:txnid/:isFlutter',
            component: FranchisePaymentStatusRoutes,
            meta: {
                title: "Franchise"
            }
        },
        {
            path: '/franchise_management/reseller_payment_index_list',
            component: ResellerPaymentRoutes,
            meta: {
                title: "Reseller Payment"
            }
        },
        {
            path: '/franchise_management/resellerBlocked',
            component: ResellerBlockedRoutes,
            meta: {
                title: "Reseller Blocked"
            }
        },
        
        {
            path: '/franchise_management/recurring_billing_details',
            component: ResellerAccountDetails,
            meta: {
                title: "Reseller Accounts"
            }
        },
        {
            path: '/franchise_management/franchise_package_details',
            component: FranchisePackageDetailsRoutes,
            meta: {
                title: "Subzone Package Configuration"
            }
        },
        {
            path: '/franchise_management/paysharp',
            component: PaysharpRoutes,
            meta: {
                title: "Paysharp"
            }
        },
        {
            path: '/franchise_management/paysharp/:isFlutter',
            component: PaysharpRoutes,
            meta: {
                title: "Paysharp"
            }
        },
    ]
}
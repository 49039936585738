const Full = () => import(/* webpackPrefetch: false */ '@/container/Full.vue')

// dashboard components
const DashboardRoutes = () => import(/* webpackPrefetch: false */ '../views/dashboard/index.vue')
const createSubzoneRoutes = () => import(/* webpackPrefetch: false */ '../views/dashboard/CreateSubzone.vue')
const applicationDetailsRoutes = () => import('../views/application/index.vue')
const VoucherBasedBillingRoutes = () => import(/* webpackPrefetch: false */ '@/views/billing/VoucherBasedBilling.vue')
const StaticIpsBillingRoutes = () => import(/* webpackPrefetch: false */ '@/views/billing/StaticIpsBilling.vue')
export default {
    path: '/dashboard',
    component: Full,
    redirect: '/dashboard',
    beforeEnter(to, from, next) {
        if (localStorage.getItem('token')) {
            next()
        } else {
            next('/signin')
        }
    },
    children: [
        {
            path: '/dashboard',
            component: DashboardRoutes,
            meta: {
                title: "Dashboard"
            }
        },
        {
            path: '/app_creation/app_details',
            component: applicationDetailsRoutes,
            meta: {
                title: "Application Details"
            }
        },
        {
            path: '/dashboard/create_subaccount',
            component: createSubzoneRoutes,
            meta: {
                title: "Create Sub Zone"
            }
        },
        {
            path: '/dashboard/create_subaccount/:accountId',
            component: createSubzoneRoutes,
            meta: {
                title: "Create Sub Zone"
            }
        },
        {
            path: '/billing/voucher_based_billing/:account/:date',
            component: VoucherBasedBillingRoutes,
            meta: {
                title: "Voucher Based Billing"
            }
        },
        {
            path: '/billing/static_ips_billing/:account/:date',
            component: StaticIpsBillingRoutes,
            meta: {
                title: "Static Ips Billing"
            }
        },
        
    ]
}
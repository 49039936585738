<template>
  <div class="jazeMainPanel">
    <div class>
      <div class="unifiSettingsContainer">
        <form ref="pmsSettingsForm" id="pmsSettingsForm" class="appForm appForm--cozy is-validation-hidden">
          <div class="unifiSettingsHeader">
            <div class="unifiSettingsHeader__title">PMS Settings</div>
          </div>
          <fieldset class="appFieldset appFieldset--quiet">
            <div class="unifiSettingsSection">
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">PMS user groups can be used only for voucher login</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="usePmsVoucherCodeFlow" :checked="pmsSettingsDetails!=undefined && pmsSettingsDetails.usePmsVoucherCodeFlow!=undefined && pmsSettingsDetails.usePmsVoucherCodeFlow =='yes' ? true : false" /> &emsp;
                </div>
              </div>
            </div>
          </fieldset>
        </form>
        <div class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark">
          <div class="appFormFooter__right">
            <button class="appMainButton appMainButton--primary busyToggle" type="submit" @click="savePMSSettings">Save</button>
          </div>
        </div>
      </div>
    </div>
    <SuccessToaster :successMessage="successMessage" :sucesstoasterShow="successtoaster" v-if="successtoaster"></SuccessToaster>
    <ErrorToaster :errorMessage="errorMessage" :errortoasterShow="errortoaster" v-if="errortoaster"></ErrorToaster>
  </div>
</template>

<script>
import { ref,computed,watch,watchEffect,onMounted,onBeforeUnmount } from 'vue'
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";

import { storeToRefs } from "pinia";
import { usesettingsStore } from '@/store/settingsstore'
export default {
  setup() {
    const errortoaster = ref( false )
    const errorMessage = ref( "" )
    const successtoaster = ref( false )
    const successMessage = ref( "" )
    // const pmsSettingsDetails = ref( [] )

    const settingsStore = usesettingsStore()
    const { pmsSettingsDetails } = storeToRefs(settingsStore)
    const pmsSettingsGetter = computed(() => pmsSettingsDetails.value);

    onMounted(() => {
      getConfigDetails();
    })
    onBeforeUnmount(() => {
      settingsStore.clearSettingsState();
    })

    watchEffect(() => {
      pmsSettingsDetails.value = pmsSettingsGetter.value;
    })

    const getConfigDetails = () => {
      if (Object.keys(pmsSettingsGetter.value).length == 0) {
        // $store.dispatch("fetchPMSSettingsDetails");
        settingsStore.fetchPMSSettingsDetails();
      } else {
        pmsSettingsDetails.value = pmsSettingsGetter.value;
      }
    }
    const savePMSSettings = (e) => {
      successtoaster.value = false;
      errortoaster.value = false;
      e.preventDefault();
      var form = document.getElementById('pmsSettingsForm');
      const formData = new FormData(form);
      const postData = {};
      for (let [key, val] of formData.entries()) {
        Object.assign(postData, { [key]: val });
      }
      // $store.dispatch("pmsSettingsSubmit", postData).then(
      settingsStore.pmsSettingsSubmit(postData).then(  
        (response) => {
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
    }

    return {
      settingsStore,
      pmsSettingsGetter: pmsSettingsDetails,
      errortoaster,
      errorMessage,
      successtoaster,
      successMessage,
      pmsSettingsDetails,
      getConfigDetails,
      savePMSSettings,
    }
  },
  components: {
    SuccessToaster,
    ErrorToaster,
  },
};
</script>
const Full = () => import('@/container/Full.vue')

const LicenseRoutes = () => import(/* webpackPrefetch: false */ '@/views/license/License.vue')
export default {
    path: '/license',
    component: Full,
    redirect: '/license/index',
    beforeEnter(to, from, next) {
       if (localStorage.getItem('token')) {
          next()
       } else {
          next('/signin')
       }
    },
    children: [
       {
          path: '/license/index',
          component: LicenseRoutes,
          meta: {
            title: "License"
        }
       },
    ]
 }
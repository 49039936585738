<template>
  <div class="jazeToastContainer jazeToastContainer--topRight"  v-show='errortoasterShow'>
    <div class="jazeToast">
      <div class="jazeToast__image">
        <div class="jazeIconContainer jazeIconContainer--round jazeIconContainer--danger">
          <div class="unifiAuraIcon">
            <svg viewBox="0 0 14 14" class="jazeIcon">
              <g transform="translate(-1 0)" fill="currentColor">
                <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                  <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z"></path>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
      <div class="jazeToast__close">
        <div class="jazeIcon jazeIcon--actionable jazeIcon--transparent iconText__icon jaze-icon--x-circle" onclick="document.getElementById('successToaster').style.display = 'none'"></div>
      </div>
      <div class="jazeToast__body">
        <div class="jazeToast__details" v-html="errorMessage"> </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['errortoasterShow',"errorMessage"],
};
</script>
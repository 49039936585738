const Full = () => import( '@/container/Full.vue')

// dashboard components
const headerTasks = () => import(/* webpackPrefetch: false */ '@/views/tasks/HeaderTaskBar.vue')
const AssignedTasks = () => import(/* webpackPrefetch: false */ '@/components/Tasks/AssignedTasks.vue')
const ApprovedTasks = () => import(/* webpackPrefetch: false */ '@/components/Tasks/ApprovedTasks.vue')
const CompletedTasks = () => import(/* webpackPrefetch: false */ '@/components/Tasks/CompletedTasks.vue')
const PendingTasks = () => import(/* webpackPrefetch: false */ '@/components/Tasks/PendingTasks.vue')
const RejectedTasks = () => import(/* webpackPrefetch: false */ '@/components/Tasks/RejectedTasks.vue')
const UnclaimedTasks = () => import(/* webpackPrefetch: false */ '@/components/Tasks/UnclaimedTasks.vue')

export default {
   path: '/tasks',
   component: Full,
   redirect: '/tasks',
   beforeEnter(to, from, next) {
      if (localStorage.getItem('token')) {
         next()
      } else {
         next('/signin')
      }
   },
   children: [
      {
         path: '/tasks',
         component: UnclaimedTasks,
         meta: {
            title: "Tasks"
         }
      },
      {
         path: '/tasks/:task_id',
         component: UnclaimedTasks,
         meta: {
            title: "Tasks"
         }
      },
      {
         path: '/tasks/assigned_tasks',
         component: AssignedTasks,
         meta: {
            title: "Assigned Tasks"
         }
      },
      {
         path: '/tasks/assigned_tasks/:task_id',
         component: AssignedTasks,
         meta: {
            title: "Assigned Tasks"
         }
      },
      {
         path: '/tasks/completed_tasks',
         component: CompletedTasks,
         meta: {
            title: "Completed Tasks"
         }
      },
      {
         path: '/tasks/rejected_tasks',
         component: RejectedTasks,
         meta: {
            title: "Rejected Tasks"
         }
      },
      {
         path: '/tasks/approved_tasks',
         component: ApprovedTasks,
         meta: {
            title: "Approved Tasks"
         }
      },
      {
         path: '/tasks/pending_tasks',
         component: PendingTasks,
         meta: {
            title: "Pending Tasks"
         }
      }
   ]
}
const Full = () => import( '@/container/Full.vue')

const LockScreenRoutes = () => import(/* webpackPrefetch: false */ '@/views/lockscreen/LockScreen.vue')
export default {
    path: '/lock_screen',
    component: Full,
    redirect: '/lock_screen/index',
    beforeEnter(to, from, next) {
       if (localStorage.getItem('token')) {
          next()
       } else {
          next('/signin')
       }
    },
    children: [
       {
          path: '/lock_screen/index',
          component: LockScreenRoutes,
          meta: {
            title: "Lock Screen"
        }
       },
    ]
 }
const Full = () => import('@/container/Full.vue')

// dashboard components
const discountIndexRoute = () => import(/* webpackChunkName: "discount-chunk" */ '@/views/discount/Index.vue')
const ViewAllDiscountRoute = () => import(/* webpackChunkName: "discount-chunk" */ '@/views/discount/ViewAllDiscount.vue')
const DiscountConfiguration = () => import(/* webpackChunkName: "discount-chunk" */ '@/views/discount/DiscountConfiguration.vue')

export default {
    path: '/discount',
    component: Full,
    redirect: '/discount',
    beforeEnter(to, from, next) {
        if (localStorage.getItem('token')) {
            next()
        } else {
            next('/signin')
        }
    },
    children: [
        {
            path: '/discount',
            component: discountIndexRoute,
            meta: {
                title: "Discount"
            }
        },
        {
            path: '/discount/viewAllDiscount/:bunchId/:accountID',
            component: ViewAllDiscountRoute,
            meta: {
                title: "Discount"
            }
        },
        {
            path: '/discount/discount_configuration',
            component: DiscountConfiguration,
            meta: {
                title: "Discount"
            }
        },
    ]
}
const Full = () => import( '@/container/Full.vue')

// dashboard components
const Index = () => import(/* webpackPrefetch: false */ '@/views/analytics/Index.vue')
export default {
   path: '/analytics',
   component: Full,
   redirect: '/analytics',
   beforeEnter(to, from, next) {
      if (localStorage.getItem('token')) {
         next()
      } else {
         next('/signin')
      }
   },
   children: [
      {
         path: '/analytics',
         component: Index,
         meta: {
            title: "Analytics"
         }
      },
   ]
}

<template>
  <div class="jazeMainPanel" style="height: 100%;">
    <div class="jazePanelContent jazePanelContent--scrollable">
      <div class="unifiSettingsContainer">
        <form ref="taskSettingsForm" id="taskSettingsForm" class="appForm appForm--cozy is-validation-hidden" :class="formClass">
          <div class="unifiSettingsHeader">
            <div class="unifiSettingsHeader__title">Approval Tasks</div>
          </div>
          <fieldset class="appFieldset appFieldset--quiet">
            <div class="jazeAlert jazeAlert--danger row col-12" v-show='errorShow'>
              <div class="col-6">
                <strong>{{errorMessage}}</strong>
              </div>
            </div>
            <div class="unifiSettingsSection">
                <div class="appRow">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> User Approval </label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="userApprovalEnable" :checked="taskSettingsData.userApproval!=undefined && taskSettingsData.userApproval == 'yes' ? true : false" @click="toggleAccountsDiv($event)">
                  </div>
                </div>
                <div class="appRow" v-if="showUserApproveDiv==true">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Select the accounts for user approval </label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <treeselect name="userApprovalIds" v-model="selectedAccounts" :multiple="true" :options="subAccOptions" :searchable="true" :clearable="false" :defaultExpandLevel="1" :disable-branch-nodes="true"></treeselect>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Create Order Approval </label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="createOrderApprovalEnable" :checked="taskSettingsData.orderApproval!=undefined && taskSettingsData.orderApproval == 'yes' ? true : false" @click="toggleOrdersDiv($event)">
                  </div>
                </div>
                <div class="appRow" v-if="showCreateOrderDiv==true">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Select the accounts for order approval </label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <treeselect name="orderApprovalIds" v-model="selectedOrderAccounts" :multiple="true" :options="subAccOptions" :searchable="true" :clearable="false" :defaultExpandLevel="1" :disable-branch-nodes="true"></treeselect>
                  </div>
                </div>

                <div class="appRow">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Discount/Penalty Approval </label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="createDiscountApprovalEnable" :checked="taskSettingsData.dicountApproval!=undefined && taskSettingsData.dicountApproval == 'yes' ? true : false" @click="toggleDicountDiv($event)">
                  </div>
                </div>
                <div class="appRow" v-if="showCreateDiscountDiv==true">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Select the accounts for discount/penalty approval </label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <treeselect name="discountApprovalIds" v-model="selectedDiscountAccounts" :multiple="true" :options="subAccOptions" :searchable="true" :clearable="false" :defaultExpandLevel="1" :disable-branch-nodes="true"></treeselect>
                  </div>
                </div>

            </div>
          </fieldset>
        </form>
        <div class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark">
          <div class="appFormFooter__right">
            <button class="appMainButton appMainButton--primary busyToggle" type="submit" @click="saveApprovalSettings"> Save</button>
          </div>
        </div>
      </div>
    </div>
    <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
    <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
  </div>
</template>

<script>
import { ref,computed,watch,watchEffect,onMounted,onBeforeUnmount } from 'vue'
import Treeselect from "jaze-vue3-treeselect";
import { overrideIsSelected } from "@/overrides/vue3-treeselect";
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import { uselayoutStore } from '@/store/layoutstore'
import { usesettingsStore } from '@/store/settingsstore'
import { storeToRefs } from "pinia";
export default {
  mixins: [overrideIsSelected()],
  setup() {
    const taskSettingsData = ref( [] )
    const formClass = ref( "" )
    const errorShow = ref( false )
    const errorMessage = ref( "" )
    const successtoaster = ref( false )
    const successMessage = ref( "" )
    const errortoaster = ref( false )
    const showUserApproveDiv = ref( false )
    const showCreateOrderDiv = ref( false )
    const showCreateDiscountDiv = ref( false )
    const selectedAccounts = ref( new Array() )
    const selectedOrderAccounts = ref( new Array() )
    const selectedDiscountAccounts = ref( new Array() )
    const subAccOptions = ref( [{
              id: 'selectAll',
              label: 'Select All',
              children: []
          }] )

    const layoutStore = uselayoutStore()
    const { subaccDetails } = storeToRefs(layoutStore)
    const subaccDetailsGetter= computed(() => subaccDetails.value);
    const settingsStore = usesettingsStore()
    const { approvalTaskData } = storeToRefs(settingsStore)
    const approvalTasksGetter = computed(() => approvalTaskData.value);

    onMounted(() => {
      getTaskDetails();
    })
    onBeforeUnmount(() => {
      settingsStore.clearSettingsState();
    })
      watch(subaccDetailsGetter, () => {
       if (
        subaccDetailsGetter.value != undefined &&
        subaccDetailsGetter.value.length != 0
      ) {
      taskSettingsData.value = approvalTasksGetter.value;
      if(subaccDetailsGetter.value!=undefined && subaccDetailsGetter.value.length !=0) {
          for(let i=0; i<subaccDetailsGetter.value.length-1; i++) {
             subAccOptions.value[0].children.push({id:subaccDetailsGetter.value[i].id, label:subaccDetailsGetter.value[i].name})
          }
      }
      if(taskSettingsData.value.userApprovalAccounts!=undefined && taskSettingsData.value.userApprovalAccounts.length !=0) {
          for(let i=0; i<taskSettingsData.value.userApprovalAccounts.length; i++) {
              selectedAccounts.value.push(taskSettingsData.value.userApprovalAccounts[i])
          }
      }
      if(taskSettingsData.value.orderApprovalAccounts!=undefined && taskSettingsData.value.orderApprovalAccounts.length !=0) {
          for(let i=0; i<taskSettingsData.value.orderApprovalAccounts.length; i++) {
              selectedOrderAccounts.value.push(taskSettingsData.value.orderApprovalAccounts[i])
          }
      }
      if(taskSettingsData.value.discountApprovalAccounts!=undefined && taskSettingsData.value.discountApprovalAccounts.length !=0) {
          for(let i=0; i<taskSettingsData.value.discountApprovalAccounts.length; i++) {
              selectedDiscountAccounts.value.push(taskSettingsData.value.discountApprovalAccounts[i])
          }
      }
      if(taskSettingsData.value.userApproval!=undefined && taskSettingsData.value.userApproval == 'yes') {
          showUserApproveDiv.value = true;
      }      
      if(taskSettingsData.value.orderApproval!=undefined && taskSettingsData.value.orderApproval == 'yes') {
          showCreateOrderDiv.value = true;
      }
      if(taskSettingsData.value.dicountApproval!=undefined && taskSettingsData.value.dicountApproval == 'yes') {
          showCreateDiscountDiv.value = true;
      }
      }
    })

    const getTaskDetails = () => {
        // $store.dispatch("fetchapprovalTasks"); 
        settingsStore.fetchapprovalTasks();      
    }
    const saveApprovalSettings = () => {
      successtoaster.value = false;
      errortoaster.value = false;
      errorShow.value = false;
      var form = document.getElementById('taskSettingsForm');
      const formData = new FormData(form);
      const postData = {};
      for (let [key, val] of formData.entries()) {
        Object.assign(postData, { [key]: val });
      }
        var accounts = [];
        if(selectedAccounts.value.length==1 && selectedAccounts.value[0] == 'selectAll' &&subAccOptions.value[0].children.length!=0) {
            for(let i =0;i<subAccOptions.value[0].children.length;i++) {
                accounts.push(subAccOptions.value[0].children[i].id)
            }
        } else {
            accounts = selectedAccounts.value;
        }
        var orderAccounts = [];
        if(selectedOrderAccounts.value.length==1 && selectedOrderAccounts.value[0] == 'selectAll' &&subAccOptions.value[0].children.length!=0) {
            for(let i =0;i<subAccOptions.value[0].children.length;i++) {
                orderAccounts.push(subAccOptions.value[0].children[i].id)
            }
        } else {
            orderAccounts = selectedOrderAccounts.value;
        }
        var discAccounts = [];
        if(selectedDiscountAccounts.value.length==1 && selectedDiscountAccounts.value[0] == 'selectAll' &&subAccOptions.value[0].children.length!=0) {
            for(let i =0;i<subAccOptions.value[0].children.length;i++) {
                discAccounts.push(subAccOptions.value[0].children[i].id)
            }
        } else {
            discAccounts = selectedDiscountAccounts.value;
        }
        postData.userApprovalIds = accounts;
        postData.orderApprovalIds = orderAccounts;
        postData.discountApprovalIds = discAccounts;
      // $store.dispatch("taskSettingsSubmit", postData).then(
      settingsStore.taskSettingsSubmit(postData).then(  
        response => {
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function() {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        error => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function() {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
    }
    const toggleAccountsDiv = (event) => {
         if(event.target.checked == true) {
            showUserApproveDiv.value = true;
        } else {
            showUserApproveDiv.value = false;
        }
    }
    const toggleOrdersDiv = (event) => {
         if(event.target.checked == true) {
            showCreateOrderDiv.value = true;
        } else {
            showCreateOrderDiv.value = false;
        }
    }
    const toggleDicountDiv = (event) => {
         if(event.target.checked == true) {
            showCreateDiscountDiv.value = true;
        } else {
            showCreateDiscountDiv.value = false;
        }
    }

    return {
      settingsStore,
      approvalTasksGetter: approvalTaskData,
      subaccDetailsGetter,
      taskSettingsData,
      formClass,
      errorShow,
      errorMessage,
      successtoaster,
      successMessage,
      errortoaster,
      showUserApproveDiv,
      showCreateOrderDiv,
      showCreateDiscountDiv,
      selectedAccounts,
      selectedOrderAccounts,
      selectedDiscountAccounts,
      subAccOptions,
      getTaskDetails,
      saveApprovalSettings,
      toggleAccountsDiv,
      toggleOrdersDiv,
      toggleDicountDiv,
    }
  },
  components: {
    Treeselect,
    SuccessToaster,
    ErrorToaster
  },
};
</script>



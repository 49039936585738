<template>
  <div class="jazeMainPanel" style="height: 100%;">
    <div class="unifiSettingsContainer">
      <div class="unifiSettingsHeader unifiSettingsHeader--withTabs noBorder" tabs="settingsSubMenuRoutingAndFirewallCtrl.tabs">
        <ul class="appTabs appTabs--cozy appTabs--flexible" pageutils-nav-active-class="is-tab-selected">
          <li @click="selectedTab='api'" class="tabs__item" :class="{'is-tab-selected':selectedTab == 'api'}">
            <span>API</span>
          </li>
          <li @click="selectedTab='keys'" class="tabs__item" :class="{'is-tab-selected':selectedTab == 'keys'}">
            <span>Upload Keys</span>
          </li>
        </ul>
      </div>
      <div v-if="selectedTab == 'api'">
        <div class="appRow">
          <div class="col--sm3"> </div>
          <div class="col--sm8 appFormGroup appFormGroup--cozy">

          </div>
        </div>
        <div class="appRow">
          <div class="col--sm3">
            <label class="appLabel appLabel--primary">API Token</label>
          </div>
          <div class="col--sm5 appFormGroup appFormGroup--cozy">
            <label class="appLabel"> {{apiDetails.apiToken}}</label>
          </div>
        </div>
        <div class="appRow">
          <div class="col--sm3">
            <label class="appLabel appLabel--primary">API Key</label>
          </div>
          <div class="col--sm5 appFormGroup appFormGroup--cozy">
            <label class="appLabel"> {{apiDetails.apikey}}</label>
          </div>
        </div>
        <div class="appRow" v-if="apiDetails.showDbDetails != undefined && apiDetails.showDbDetails=='yes'">
          <div class="col--sm3">
            <label class="appLabel appLabel--primary">Database Name</label>
          </div>
          <div class="col--sm5 appFormGroup appFormGroup--cozy">
            <label class="appLabel"> {{apiDetails.dBName}}</label>
          </div>
        </div>
        <div class="appRow">
          <div class="col--sm3">
            <label class="appLabel appLabel--primary">API Example To Get User Balance</label>
          </div>
          <div class="col--sm5 appFormGroup appFormGroup--cozy">
            <a class="appMainButton appMainButton--primary appMainButton--cozy busyToggle" type="button" @click="showCode=true">
              <div class="busyToggle__body">Code</div>
            </a>
          </div>
        </div>
        <div class="appRow">
          <div class="col--sm3">
            <label class="appLabel appLabel--primary">Postman Collection</label>
          </div>
          <div class="col--sm5 appFormGroup appFormGroup--cozy">
            <a class="appMainButton appMainButton--primary appMainButton--cozy busyToggle" type="button" :href="postmanCollectionLink" target="blank">
              <div class="busyToggle__body">Open</div>
            </a>
          </div>
        </div>
      </div>
      <div v-if="selectedTab == 'keys'">
        <form class="appForm appForm--cozy is-validation-hidden">
          <fieldset class="appFieldset appFieldset--quiet">
            <div class="form-group appRow">
              <div class="col--sm2">
                  <label class="appLabel appLabel--primary">Encryption Type</label>
              </div>
              <div class="col--sm3 appFormGroup appFormGroup--cozy">
                  <select class="width-full" name="encryptionType" v-model="encryptionType" @change="encryptionTypeChange">
                      <option value="asymmetric">Asymmetric</option>
                      <option value="hybrid">Hybrid</option>
                  </select>
              </div>
            </div>
          </fieldset>
        </form>
        <label class="appLabel appLabel--primary">Uploaded Certificates and Keys</label><br><br>
        <div class="appRow" v-if="apiDetails.certificates != undefined && apiDetails.certificates.length!=0" v-for='(certificate,index) in apiDetails.certificates' :key="certificate">
          <div class="col--sm3">
            <label class="appLabel appLabel--primary" v-if="index==0"> Certificates </label>
          </div>
          <div class="col--sm5 appFormGroup appFormGroup--cozy">
            <label class="appLabel"> {{certificate}} </label>
          </div>
        </div> 
        <div class="appRow" v-if="apiDetails.certificates=='' || apiDetails.certificates.length == 0">
          There are no Uploaded Certificates.
          <br><br>
        </div>       
      </div>
      <div class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark" v-if="selectedTab == 'api'">
        <!-- <div class="appFormFooter__left">
          <a class="appMainButton busyToggle" type="button" href="/backend/pdf/API-Document.pdf" target="blank">
            <div class="busyToggle__body">Open API document</div>
          </a>
          <a class="appMainButton busyToggle" type="button" href="/backend/pdf/API-Document.pdf" download="API-Document.pdf">
            <div class="busyToggle__body">Download API document</div>
          </a>
        </div> -->
        <div class="appFormFooter__right">
          <button class="appMainButton appMainButton--primary" @click="changeApiShow=true"><span>Change API Key</span></button>
        </div>
      </div>
      <div class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark" v-if="selectedTab == 'keys'">
        <form ref="certificateForm" id="certificateForm" class="row col--sm12" role="form" enctype="multipart/form-data">
            <div class="col--sm2">
                  <input style="display:none" ref="changeCertFile" type="file" @change="previewImageCert" name="crtFile">
                  <button class='appMainButton busyToggle' @click="chooseImageCert">
                    <span v-if='selectCerFile'> Select cer file </span>
                    <span v-else>Change </span>
                  </button>&emsp;
                  <div v-if="certImage != ''">
                    {{cerFileData.name}}
                  </div>
            </div>
            <div class="col--sm2">
                  <input style="display:none" ref="changeKeyFile" type="file" @change="previewImageKey" name="keyFile">
                  <button class='appMainButton busyToggle' @click="chooseImageKey">
                    <span v-if='selectKeyFile'> Select key file </span>
                    <span v-else>Change </span>
                  </button>&emsp;
                  <div v-if="keyImage != ''">
                    {{keyFileData.name}}
                  </div>
            </div>
            <div class="col--sm6">
            </div>

            <div class="col--sm2">
                  <button class='appMainButton appMainButton--primary' type="button" @click="uploadFiles">
                    <span> Upload Files </span>
                  </button>&emsp;
              <!-- <button class="appMainButton appMainButton--primary" @click="changeApiShow=true"><span>Change API Key</span></button> -->
            </div>
        </form>
      </div> <br><br>
    </div>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="changeApiShow" style="display:flex">
      <div class="pos-relative" dialog-window>
        <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
          <form class="jazeModal jazeModal--small unifiAddUser">
            <div class="jazeModal__header pb-0">
              <div class="jazeHeader jazeHeader--centered">
                <div class="jazeHeader__title"> Change API Key </div>
                <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="changeApiShow=false">
                  <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                    <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                  </g>
                </svg>
              </div>
            </div>
            <div class="jazeModal__main">
              <div class="jazeModalContent pb-1">
                <div class="container container--horizontal-cozy container--vertical-comfy">
                  <div class="row">
                    <div class="col jazeFormGroup jazeFormGroup--flex">
                      <label class="jazeLabel jazeLabel--primary">
                        Are you sure want to change the <b>API Key</b> ?
                      </label>
                    </div>
                  </div>
                  <div class="appType--micro appType--quiet italicized">
                    Note: Changing API key will affect all the places where it is configured.
                  </div><br>
                  <form ref="apiForm" id="apiForm" class="appForm appForm--cozy is-validation-hidden">
                    <div class="appRow" v-if="authenticateBtnShow">
                      <div class="col--sm4">
                        <label class="appLabel appLabel--boxInline">Admin Username</label>
                      </div>
                      <div class="col--sm8 appFormGroup appFormGroup--cozy">
                        <input type="text" class="input--full" name="adminUsername" />
                      </div>
                    </div>
                    <div class="appRow" v-if="authenticateBtnShow">
                      <div class="col--sm4">
                        <label class="appLabel appLabel--boxInline">Admin Password</label>
                      </div>
                      <div class="col--sm8 appFormGroup appFormGroup--cozy">
                        <input type="password" class="input--full" name="adminPassword" />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="jazeModal__footer">
              <div class="jazeButtonGroup jazeButtonGroup--cozy">
                <button class="jazeButton jazeButton--secondary" type="button" @click="changeApiShow=false">Cancel</button>
                <button class="jazeButton jazeButton--primary submitForm" type="button" @click="changeApiKey('ok')" v-if="authenticateBtnShow==false">Yes</button>
                <button class="jazeButton jazeButton--primary submitForm" type="button" @click="changeApiKey('authenticate')" v-else>Authenticate and Change</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="showCode" style="display:flex">
      <div class="pos-relative" dialog-window>
        <div class="jazeModal jazeModal--medium unifiSpeedTestModal unifiSpeedTestModal--inProgress">
          <form class="jazeForm jazeModal jazeModal--medium unifiAddUser">
            <div class="jazeModal__header pb-0">
              <div class="jazeHeader jazeHeader--centered">
                <div class="jazeHeader__title"> Code snippets </div>
                <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="showCode=false">
                  <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                    <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                  </g>
                </svg>
              </div>
            </div>
            <div class="jazeModal__main">
              <div class="jazeModalContent pb-1">
                <div class="container container--horizontal-cozy container--vertical-comfy">
                  <div class="row">
                    <div class="col-9">
                      <select class="lineHeight--normal without-standard-validation" @change="triggerContent" v-model="codeType">
                        <option value="httpOption" selected="selected">HTTP</option>
                        <option value="cLibCurlOption">C (LibCurl)</option>
                        <option value="curlOption">Curl</option>
                        <option value="cRestOption">C# (RestSharp)</option>
                        <option value="goOption">Go</option>
                        <option value="javaHttpOption">Java OK HTTP</option>
                        <option value="javaUniOption">Java Unirest</option>
                        <option value="jsAjaxOption">JavaScript Jquery AJAX</option>
                        <option value="jsXhrOption">JavaScript XHR</option>
                        <option value="nodeRequestOption">NodeJS Request</option>
                        <option value="nodeUnirestOption">NodeJS Unirest</option>
                        <option value="objectiveOption">Objective-C (NSURL)</option>
                        <option value="ocamlOption">OCaml (Cohttp)</option>
                        <option value="phpHttpOption">PHP HttpRequest</option>
                        <option value="phpPeclOption">PHP pecl_http</option>
                        <option value="phpCurlOption">PHP cURL</option>
                        <option value="pythonOption">Python Requests</option>
                        <option value="rubyOption">Ruby (NET::Http)</option>
                        <option value="shellWgetOption">Shell wget</option>
                        <option value="shellHttpOption">Shell Httpie</option>
                        <option value="shellCurlOption">Shell cURL</option>
                        <option value="swiftOption">Swift (NSURL)</option>
                      </select>
                      <button class="appMainButton--transparent" type="button" style="color:inherit">
                        <div class="">Where userId is the id of the user</div>
                      </button>
                    </div>
                    <div class="col-3">
                      <button class="floatRight appMainButton appMainButton--primary appMainButton--cozy" type="button" @click="copyToClipboard">
                        <span>Copy to Clipboard</span>
                      </button>
                    </div>
                  </div>
                  <div class="appInfoBoxContainer appInfoBoxContainer--dark mx-0">
                    <div class="appInfoBox appInfoBox--warning appInfoBox--cozy appInfoBox--noBorder">
                      <div class="appInfoBox__content">
                        <div class="appInfoBox__body" v-if="httpOption">
                          <textarea rows="20" cols="85" ref="httpLanguageTextArea">GET /api/v1/get_balance/userId HTTP/1.1
Host:{{apiDetails.apiToken}}:{{apiDetails.apikey}}@{{apiDetails.httpHost}}
                          </textarea>
                        </div>
                        <div class="appInfoBox__body" v-if="cLibCurlOption">
                          <textarea rows="20" cols="85" ref="cLibCurlLanguageTextArea">CURL *hnd = curl_easy_init();

curl_easy_setopt(hnd, CURLOPT_CUSTOMREQUEST, "GET");
curl_easy_setopt(hnd, CURLOPT_URL, "https://{{apiDetails.apiToken}}:{{apiDetails.apikey}}@{{apiDetails.httpHost}}/api/v1/get_balance/userId");

CURLcode ret = curl_easy_perform(hnd);
                          </textarea>
                        </div>
                        <div class="appInfoBox__body" v-if="curlOption">
                          <textarea rows="20" cols="85" ref="curlLanguageTextArea">
curl -X GET \
https://{{apiDetails.apiToken}}:{{apiDetails.apikey}}@{{apiDetails.httpHost}}/api/v1/get_balance/userId \
                          </textarea>
                        </div>
                        <div class="appInfoBox__body" v-if="cRestOption">
                          <textarea rows="20" cols="85" ref="cRestSharpLanguageTextArea">
var client = new RestClient("https://{{apiDetails.apiToken}}::{{apiDetails.apikey}}@{{apiDetails.httpHost}}/api/v1/get_balance/userId");
var request = new RestRequest(Method.GET);
IRestResponse response = client.Execute(request);
                          </textarea>
                        </div>
                        <div class="appInfoBox__body" v-if="goOption">
                          <textarea rows="20" cols="85" ref="goLanguageTextArea">
package main

import (
	"fmt"
	"net/http"
	"io/ioutil"
)

func main() {

	url := "https://{{apiDetails.apiToken}}:{{apiDetails.apikey}}@{{apiDetails.httpHost}}/api/v1/get_balance/userId"

	req, _ := http.NewRequest("GET", url, nil)

	res, _ := http.DefaultClient.Do(req)

	defer res.Body.Close()
	body, _ := ioutil.ReadAll(res.Body)

	fmt.Println(res)
	fmt.Println(string(body))

}
                          </textarea>
                        </div>
                        <div class="appInfoBox__body" v-if="javaHttpOption">
                          <textarea rows="20" cols="85" ref="javaOkHttpLanguageTextArea">
OkHttpClient client = new OkHttpClient();

Request request = new Request.Builder()
  .url("https://{{apiDetails.apiToken}}:{{apiDetails.apikey}}@{{apiDetails.httpHost}}/api/v1/get_balance/userId")
  .get()
  .build();

Response response = client.newCall(request).execute();
                          </textarea>
                        </div>
                        <div class="appInfoBox__body" v-if="javaUniOption">
                          <textarea rows="20" cols="85" ref="javaUnirestLanguageTextArea">
<String>HttpResponse</String> response = Unirest.get("https://{{apiDetails.apiToken}}:{{apiDetails.apikey}}@{{apiDetails.httpHost}}/api/v1/get_balance/userId")
  .asString();
                          </textarea>
                        </div>
                        <div class="appInfoBox__body" v-if="jsAjaxOption">
                          <textarea rows="20" cols="85" ref="javaScriptJqueryAjaxLanguageTextArea">
var settings = {
  "async": true,
  "crossDomain": true,
  "url": "https://{{apiDetails.apiToken}}:{{apiDetails.apikey}}@{{apiDetails.httpHost}}/api/v1/get_balance/userId",
  "method": "GET"
}

$.ajax(settings).done(function (response) {
  console.log(response);
});
                          </textarea>
                        </div>
                        <div class="appInfoBox__body" v-if="jsXhrOption">
                          <textarea rows="20" cols="85" ref="javaScriptXhrLanguageTextArea">
var data = null;

var xhr = new XMLHttpRequest();
xhr.withCredentials = true;

xhr.addEventListener("readystatechange", function () {
  if (this.readyState === 4) {
    console.log(this.responseText);
  }
});

xhr.open("GET", "https://{{apiDetails.apiToken}}:{{apiDetails.apikey}}@{{apiDetails.httpHost}}/api/v1/get_balance/userId");

xhr.send(data);
                          </textarea>
                        </div>
                        <div class="appInfoBox__body" v-if="nodeRequestOption">
                          <textarea rows="20" cols="85" ref="nodeJsRequestLanguageTextArea">
var request = require("request");

var options = { method: 'GET',
  url: 'https://{{apiDetails.apiToken}}:{{apiDetails.apikey}}@{{apiDetails.httpHost}}/api/v1/get_balance/userId'
};

request(options, function (error, response, body) {
  if (error) throw new Error(error);

  console.log(body);
});
                          </textarea>
                        </div>
                        <div class="appInfoBox__body" v-if="nodeUnirestOption">
                          <textarea rows="20" cols="85" ref="nodeJsUnirestLanguageTextArea">
var unirest = require("unirest");

var req = unirest("GET", "https://{{apiDetails.apiToken}}:{{apiDetails.apikey}}@{{apiDetails.httpHost}}/api/v1/get_balance/userId");

req.end(function (res) {
  if (res.error) throw new Error(res.error);

  console.log(res.body);
});
                          </textarea>
                        </div>
                        <div class="appInfoBox__body" v-if="objectiveOption">
                          <textarea rows="20" cols="85" ref="objectiveCNsurlLanguageTextArea">
#import "Foundation/Foundation.h"

NSMutableURLRequest *request = [NSMutableURLRequest requestWithURL:[NSURL URLWithString:@"https://{{apiDetails.apiToken}}:{{apiDetails.apikey}}@{{apiDetails.httpHost}}/api/v1/get_balance/userId"]
                                                       cachePolicy:NSURLRequestUseProtocolCachePolicy
                                                   timeoutInterval:10.0];
[request setHTTPMethod:@"GET"];
[request setAllHTTPHeaderFields:headers];

NSURLSession *session = [NSURLSession sharedSession];
NSURLSessionDataTask *dataTask = [session dataTaskWithRequest:request
                                            completionHandler:^(NSData *data, NSURLResponse *response, NSError *error) {
                                                if (error) {
                                                    NSLog(@"%@", error);
                                                } else {
                                                    NSHTTPURLResponse *httpResponse = (NSHTTPURLResponse *) response;
                                                    NSLog(@"%@", httpResponse);
                                                }
                                            }];
[dataTask resume];
                          </textarea>
                        </div>
                        <div class="appInfoBox__body" v-if="ocamlOption">
                          <textarea rows="20" cols="85" ref="ocamlCohttpLanguageTextArea">
open Cohttp_lwt_unix
open Cohttp
open Lwt

let uri = Uri.of_string "https://{{apiDetails.apiToken}}:{{apiDetails.apikey}}@{{apiDetails.httpHost}}/api/v1/get_balance/userId" in
let headers = Header.init ()
  
in

Client.call ~headers `GET uri
>>= fun (res, body_stream) ->
  (* Do stuff with the result *)
                          </textarea>
                        </div>
                        <div class="appInfoBox__body" v-if="phpHttpOption">
                          <textarea rows="20" cols="85" ref="phpHttpRequestLanguageTextArea">
<!-- <?php
{{apiDetails.phpEol}}
$request = new HttpRequest(); {{apiDetails.phpEol}}
('https://{{apiDetails.apiToken}}:{{apiDetails.apikey}}@{{apiDetails.httpHost}}/api/v1/get_balance/userId'); {{apiDetails.phpEol}}
$request->setMethod(HTTP_METH_GET); {{apiDetails.phpEol}}
try { {{apiDetails.phpEol}}
$response = $request->send(); {{apiDetails.phpEol}}
echo $response->getBody(); {{apiDetails.phpEol}}
} catch (HttpException $ex) { {{apiDetails.phpEol}}
echo $ex; {{apiDetails.phpEol}}
};
?>   -->
                          </textarea>
                        </div>
                        <div class="appInfoBox__body" v-if="phpPeclOption">
                          <textarea rows="20" cols="85" ref="phpPeclHttpLanguageTextArea">
<!-- <?php
{{apiDetails.phpEol}}
$client = new http\Client; {{apiDetails.phpEol}}
$request = new http\Client\Request; {{apiDetails.phpEol}}
('https://{{apiDetails.apiToken}}:{{apiDetails.apikey}}@{{apiDetails.httpHost}}//api/v1/get_balance/userId'); {{apiDetails.phpEol}}
('GET'); {{apiDetails.phpEol}}
$client->enqueue($request)->send(); {{apiDetails.phpEol}}
$response = $client->getResponse(); {{apiDetails.phpEol}}
echo $response->getBody(); {{apiDetails.phpEol}}
?>  -->
                          </textarea>
                        </div>
                        <div class="appInfoBox__body" v-if="phpCurlOption">
                          <textarea rows="20" cols="85" ref="phpCurlLanguageTextArea">
<!-- <?php
$port = explode(":", $_SERVER['HTTP_HOST']);
{{apiDetails.phpEol}}
$curl = curl_init(); {{apiDetails.phpEol}}
curl_setopt_array($curl, array( {{apiDetails.phpEol}}
if (!empty($port[1])) {
  CURLOPT_PORT => "8000", {{apiDetails.phpEol}}
}
CURLOPT_URL => "https://{{apiDetails.apiToken}}:{{apiDetails.apikey}}@{{apiDetails.httpHost}}/api/v1/get_balance/userId" {{apiDetails.phpEol}}
CURLOPT_RETURNTRANSFER => true, {{apiDetails.phpEol}}
CURLOPT_ENCODING => "", {{apiDetails.phpEol}}
CURLOPT_MAXREDIRS => 10, {{apiDetails.phpEol}}
CURLOPT_TIMEOUT => 30, {{apiDetails.phpEol}}
CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1, {{apiDetails.phpEol}}
CURLOPT_CUSTOMREQUEST => "GET", {{apiDetails.phpEol}}
CURLOPT_POSTFIELDS => "" {{apiDetails.phpEol}}
)); {{apiDetails.phpEol}}
$response = curl_exec($curl); {{apiDetails.phpEol}}
$err = curl_error($curl); {{apiDetails.phpEol}}
curl_close($curl); {{apiDetails.phpEol}}
if ($err) { {{apiDetails.phpEol}}
echo "cURL Error #:" . $err; {{apiDetails.phpEol}}
} else { {{apiDetails.phpEol}}
echo $response; {{apiDetails.phpEol}}
} {{apiDetails.phpEol}}
?>  -->
                          </textarea>
                        </div>
                        <div class="appInfoBox__body" v-if="pythonOption">
                          <textarea rows="20" cols="85" ref="pythonRequestsTextArea">
import requests

url = "https://{{apiDetails.apiToken}}:{{apiDetails.apikey}}@{{apiDetails.httpHost}}/api/v1/get_balance/userId"

payload = ""
headers = {
    
    }

response = requests.request("GET", url, data=payload, headers=headers)

print(response.text)
                          </textarea>
                        </div>
                        <div class="appInfoBox__body" v-if="rubyOption">
                          <textarea rows="20" cols="85" ref="rubyNetHttpLanguageTextArea">
require 'uri'
require 'net/http'

url = URI("https://{{apiDetails.apiToken}}:{{apiDetails.apikey}}@{{apiDetails.httpHost}}/api/v1/get_balance/userId")

http = Net::HTTP.new(url.host, url.port)

request = Net::HTTP::Get.new(url)

response = http.request(request)
puts response.read_body
                          </textarea>
                        </div>
                        <div class="appInfoBox__body" v-if="shellWgetOption">
                          <textarea rows="20" cols="85" ref="shellWgetLanguageTextArea">
wget --quiet \
  --method GET \
  --output-document \
  - https://{{apiDetails.apiToken}}:{{apiDetails.apikey}}@{{apiDetails.httpHost}}/api/v1/get_balance/userId
                          </textarea>
                        </div>
                        <div class="appInfoBox__body" v-if="shellHttpOption">
                          <textarea rows="20" cols="85" ref="shellHttpieLanguageTextArea">
http GET https://{{apiDetails.apiToken}}:{{apiDetails.apikey}}@{{apiDetails.httpHost}}/api/v1/get_balance/userId \
                          </textarea>
                        </div>
                        <div class="appInfoBox__body" v-if="shellCurlOption">
                          <textarea rows="20" cols="85" ref="shellCurlLanguageTextArea">
curl --request GET \
  --url https://{{apiDetails.apiToken}}:{{apiDetails.apikey}}@{{apiDetails.httpHost}}/api/v1/get_balance/userId \
                          </textarea>
                        </div>
                        <div class="appInfoBox__body" v-if="swiftOption">
                          <textarea rows="20" cols="85" ref="swiftNsurlLanguageTextArea">
import Foundation

let headers = [
  
]

let request = NSMutableURLRequest(url: NSURL(string: "https://{{apiDetails.apiToken}}:{{apiDetails.apikey}}@{{apiDetails.httpHost}}/api/v1/get_balance/userId")! as URL,
                                        cachePolicy: .useProtocolCachePolicy,
                                    timeoutInterval: 10.0)
request.httpMethod = "GET"
request.allHTTPHeaderFields = headers

let session = URLSession.shared
let dataTask = session.dataTask(with: request as URLRequest, completionHandler: { (data, response, error) -> Void in
  if (error != nil) {
    print(error)
  } else {
    let httpResponse = response as? HTTPURLResponse
    print(httpResponse)
  }
})

dataTask.resume()
                          </textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="jazeModal__footer">
              <div class="jazeButtonGroup jazeButtonGroup--cozy">
                <button class="jazeButton jazeButton--secondary" type="button" @click="showCode=false">Close</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
    <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
  </div>
</template>

<script>
import { ref,computed,watch,watchEffect,onMounted,onBeforeUnmount } from 'vue';
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import axios from "@/api/index";
import qs from "qs";

import { storeToRefs } from "pinia";
import { usesettingsStore } from '@/store/settingsstore'
export default {
  setup() {
    const apiDetails = ref( [] )
    const formClass = ref( "" )
    const errorShow = ref( false )
    const errorMessage = ref( "" )
    const successtoaster = ref( false )
    const successMessage = ref( "" )
    const errortoaster = ref( false )
    const selectedTab = ref( "api" )
    const showCode = ref( false )
    const changeApiShow = ref( false )
    const authenticateBtnShow = ref( false )
    const httpOption = ref( true )
    const cLibCurlOption = ref( false )
    const curlOption = ref( false )
    const cRestOption = ref( false )
    const goOption = ref( false )
    const javaHttpOption = ref( false )
    const javaUniOption = ref( false )
    const jsAjaxOption = ref( false )
    const jsXhrOption = ref( false )
    const nodeRequestOption = ref( false )
    const nodeUnirestOption = ref( false )
    const objectiveOption = ref( false )
    const ocamlOption = ref( false )
    const phpHttpOption = ref( false )
    const phpPeclOption = ref( false )
    const phpCurlOption = ref( false )
    const pythonOption = ref( false )
    const rubyOption = ref( false )
    const shellWgetOption = ref( false )
    const shellHttpOption = ref( false )
    const shellCurlOption = ref( false )
    const swiftOption = ref( false )
    const codeType = ref( "httpOption" )
    const cerFileData = ref( "" )
    const keyFileData = ref( "" )
    const selectCerFile = ref( true )
    const selectKeyFile = ref( true )
    const certImage = ref( "" )
    const keyImage = ref( "" )
    const encryptionType  = ref( 'asymmetric' )
    const httpLanguageTextArea = ref( null )
    const cLibCurlLanguageTextArea = ref( null )
    const curlLanguageTextArea = ref( null )
    const cRestSharpLanguageTextArea = ref( null )
    const goLanguageTextArea = ref( null )
    const javaOkHttpLanguageTextArea = ref( null )
    const javaUnirestLanguageTextArea = ref( null )
    const javaScriptJqueryAjaxLanguageTextArea = ref( null )
    const javaScriptXhrLanguageTextArea = ref( null )
    const nodeJsRequestLanguageTextArea = ref( null )
    const nodeJsUnirestLanguageTextArea = ref( null )
    const objectiveCNsurlLanguageTextArea = ref( null )
    const ocamlCohttpLanguageTextArea = ref( null )
    const phpHttpRequestLanguageTextArea = ref( null )
    const phpPeclHttpLanguageTextArea = ref( null )
    const phpCurlLanguageTextArea = ref( null )
    const pythonRequestsTextArea = ref( null )
    const rubyNetHttpLanguageTextArea = ref( null )
    const shellWgetLanguageTextArea = ref( null )
    const shellHttpieLanguageTextArea = ref( null )
    const shellCurlLanguageTextArea = ref( null )
    const swiftNsurlLanguageTextArea = ref( null )
    const changeCertFile = ref( null )
    const changeKeyFile = ref( null )
    const postmanCollectionLink = ref( "" )


    const settingsStore = usesettingsStore()
    const apiData = storeToRefs(settingsStore)
    const apiDataGetter = computed(() => apiData.apiData.value);

    onMounted(() => {
      getApiDetails();
    })
    onBeforeUnmount(() => {
      settingsStore.clearSettingsState();
    })

    watch(apiDataGetter,() => {
      apiDetails.value = apiDataGetter.value;
      if(apiDetails.value.encryptionType != null && apiDetails.value.encryptionType != undefined && apiDetails.value.encryptionType != '' ){
        if(apiDetails.value.encryptionType['Setting']['value'] == 'asymmetric'){
          encryptionType.value = 'asymmetric';
        }
        if(apiDetails.value.encryptionType['Setting']['value'] == 'hybrid'){
          encryptionType.value = 'hybrid';
        }
      }
      if(apiDetails.value.postmanCollectionLink != null && apiDetails.value.postmanCollectionLink != undefined && apiDetails.value.postmanCollectionLink != '' ) {
        postmanCollectionLink.value = apiDetails.value.postmanCollectionLink;
      }
    })

    const getApiDetails = () => {
      if(apiDataGetter.value == null || apiDataGetter.value == undefined || apiDataGetter.value == ''){
        // if (Object.keys(apiDataGetter.value).length == 0) {
          // $store.dispatch("fetchApiDetails");
          settingsStore.fetchApiDetails();
        // }
      }
       else {
        apiDetails.value = apiDataGetter.value;
        if(apiDetails.value.encryptionType != null && apiDetails.value.encryptionType != undefined && apiDetails.value.encryptionType != '' ){
          if(apiDetails.value.encryptionType['Setting']['value'] == 'asymmetric'){
            encryptionType.value = 'asymmetric';
          }
          if(apiDetails.value.encryptionType['Setting']['value'] == 'hybrid'){
            encryptionType.value = 'hybrid';
          }
        }
        if(apiDetails.value.postmanCollectionLink != null && apiDetails.value.postmanCollectionLink != undefined && apiDetails.value.postmanCollectionLink != '' ) {
          postmanCollectionLink.value = apiDetails.value.postmanCollectionLink;
        }
      }
    }
    const changeApiKey = (btnType) => {
      if (btnType == "ok") {
        authenticateBtnShow.value = true;
      } else {
        var form = document.getElementById('apiForm');
        const formData = new FormData(form);
        const postData = {};
        for (let [key, val] of formData.entries()) {
          Object.assign(postData, { [key]: val });
        }
        axios
          .post(
            "/settings/change_api_key",
            qs.stringify(postData, {
              parseArrays: false
            })
          )
          .then(response => {
            if (response.data.status != "success") {
              errortoaster.value = true;
              errorMessage.value = response.data.message;
              setTimeout(
                function() {
                  errortoaster.value = false;
                }.bind(this),
                5000
              );
              return;
            }
            changeApiShow.value = false;
            successtoaster.value = true;
            successMessage.value = response.data.message;
            setTimeout(
              function() {
                successtoaster.value = false;
              }.bind(this),
              2000
            );
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
    const triggerContent = (event) => {
      httpOption.value = false;
      cLibCurlOption.value = false;
      curlOption.value = false;
      cRestOption.value = false;
      goOption.value = false;
      javaHttpOption.value = false;
      javaUniOption.value = false;
      jsAjaxOption.value = false;
      jsXhrOption.value = false;
      nodeRequestOption.value = false;
      nodeUnirestOption.value = false;
      objectiveOption.value = false;
      ocamlOption.value = false;
      phpHttpOption.value = false;
      phpPeclOption.value = false;
      phpCurlOption.value = false;
      pythonOption.value = false;
      rubyOption.value = false;
      shellWgetOption.value = false;
      shellHttpOption.value = false;
      shellCurlOption.value = false;
      swiftOption.value = false;
      var type = event.target.value;
      if (type == "httpOption") {
        httpOption.value = true;
      } else if (type == "cLibCurlOption") {
        cLibCurlOption.value = true;
      } else if (type == "curlOption") {
        curlOption.value = true;
      } else if (type == "cRestOption") {
        cRestOption.value = true;
      } else if (type == "goOption") {
        goOption.value = true;
      } else if (type == "javaHttpOption") {
        javaHttpOption.value = true;
      } else if (type == "javaUniOption") {
        javaUniOption.value = true;
      } else if (type == "jsAjaxOption") {
        jsAjaxOption.value = true;
      } else if (type == "jsXhrOption") {
        jsXhrOption.value = true;
      } else if (type == "nodeRequestOption") {
        nodeRequestOption.value = true;
      } else if (type == "nodeUnirestOption") {
        nodeUnirestOption.value = true;
      } else if (type == "objectiveOption") {
        objectiveOption.value = true;
      } else if (type == "ocamlOption") {
        ocamlOption.value = true;
      } else if (type == "phpHttpOption") {
        phpHttpOption.value = true;
      } else if (type == "phpPeclOption") {
        phpPeclOption.value = true;
      } else if (type == "phpCurlOption") {
        phpCurlOption.value = true;
      } else if (type == "pythonOption") {
        pythonOption.value = true;
      } else if (type == "rubyOption") {
        rubyOption.value = true;
      } else if (type == "shellWgetOption") {
        shellWgetOption.value = true;
      } else if (type == "shellHttpOption") {
        shellHttpOption.value = true;
      } else if (type == "shellCurlOption") {
        shellCurlOption.value = true;
      } else if (type == "swiftOption") {
        swiftOption.value = true;
      }
    }
    const copyToClipboard = () => {
      getText(codeType.value).then((data) => {
          data.select();
          document.execCommand("copy");
      });
    }
    const getText = () => {
      return new Promise((resolve) => {    
        var textarea = "";      
        if (codeType.value == "httpOption") {
          textarea = httpLanguageTextArea.value;
        } else if (codeType.value == "cLibCurlOption") {
          textarea = cLibCurlLanguageTextArea.value;
        } else if (codeType.value == "curlOption") {
          textarea = curlLanguageTextArea.value;
        } else if (codeType.value == "cRestOption") {
          textarea = cRestSharpLanguageTextArea.value;
        } else if (codeType.value == "goOption") {
          textarea = goLanguageTextArea.value;
        } else if (codeType.value == "javaHttpOption") {
          textarea = javaOkHttpLanguageTextArea.value;
        } else if (codeType.value == "javaUniOption") {
          textarea = javaUnirestLanguageTextArea.value;
        } else if (codeType.value == "jsAjaxOption") {
          textarea = javaScriptJqueryAjaxLanguageTextArea.value;
        } else if (codeType.value == "jsXhrOption") {
          textarea = javaScriptXhrLanguageTextArea.value;
        } else if (codeType.value == "nodeRequestOption") {
          textarea = nodeJsRequestLanguageTextArea.value;
        } else if (codeType.value == "nodeUnirestOption") {
          textarea = nodeJsUnirestLanguageTextArea.value;
        } else if (codeType.value == "objectiveOption") {
          textarea = objectiveCNsurlLanguageTextArea.value;
        } else if (codeType.value == "ocamlOption") {
          textarea = ocamlCohttpLanguageTextArea.value;
        } else if (codeType.value == "phpHttpOption") {
          textarea = phpHttpRequestLanguageTextArea.value;
        } else if (codeType.value == "phpPeclOption") {
          textarea = phpPeclHttpLanguageTextArea.value;
        } else if (codeType.value == "phpCurlOption") {
          textarea = phpCurlLanguageTextArea.value;
        } else if (codeType.value == "pythonOption") {
          textarea = pythonRequestsTextArea.value;
        } else if (codeType.value == "rubyOption") {
          textarea = rubyNetHttpLanguageTextArea.value;
        } else if (codeType.value == "shellWgetOption") {
          textarea = shellWgetLanguageTextArea.value;
        } else if (codeType.value == "shellHttpOption") {
          textarea = shellHttpieLanguageTextArea.value;
        } else if (codeType.value == "shellCurlOption") {
          textarea = shellCurlLanguageTextArea.value;
        } else if (codeType.value == "swiftOption") {
          textarea = swiftNsurlLanguageTextArea.value;
        }
        resolve(textarea);
      });
    }
    const chooseImageCert = (e) => {
      e.preventDefault();
      changeCertFile.value.click();
    }
    const chooseImageKey = (e) => {
      e.preventDefault();
      changeKeyFile.value.click();
    }
    const previewImageCert = (event) => {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = e => {
          certImage.value = e.target.result;
          selectCerFile.value = false;
        };
        reader.readAsDataURL(input.files[0]);
        cerFileData.value = input.files[0];
      }
    }
    const previewImageKey = (event) => {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = e => {
          keyImage.value = e.target.result;
          selectKeyFile.value = false;
        };
        reader.readAsDataURL(input.files[0]);
        keyFileData.value = input.files[0];
      }
    }
    const encryptionTypeChange = () =>{
      var data = {
          encryptionType: encryptionType.value
      };
      axios
          .post(
            "/settings/save_encryption_type",qs.stringify(data, {
              parseArrays: false
            }))
          .then(response => {
            if (response.data.status != "success") {
              errortoaster.value = true;
              errorMessage.value = response.data.message;
              setTimeout(
                function() {
                  errortoaster.value = false;
                }.bind(this),
                3000
              );
              return;
            }
            successtoaster.value = true;
            successMessage.value = response.data.message;
            setTimeout(
              function() {
                successtoaster.value = false;
              }.bind(this),
              1500
            );
          })
          .catch(error => {
            console.log(error);
          });
    }
    const uploadFiles = () => {
        var form = document.getElementById('certificateForm');
        const formData = new FormData(form);
        var formArr = new Object();
        formArr.crtFile = cerFileData.value;
        formArr.keyFile = keyFileData.value;
        formData.append("form",formArr);
        axios
          .post(
            "/settings/upload_pub_and_pri_certificates",formData)
          .then(response => {
            if (response.data.status != "success") {
              errortoaster.value = true;
              errorMessage.value = response.data.message;
              setTimeout(
                function() {
                  errortoaster.value = false;
                }.bind(this),
                5000
              );
              return;
            }
            successtoaster.value = true;
            successMessage.value = response.data.message;
            setTimeout(
              function() {
                successtoaster.value = false;
              }.bind(this),
              2000
            );
            cerFileData.value = "";
            keyFileData.value = "";
            selectCerFile.value = false;
            selectKeyFile.value = false;
          })
          .catch(error => {
            console.log(error);
          });
    }

    return {
      settingsStore,
      apiDataGetter: apiData,
      apiDetails,
      formClass,
      errorShow,
      errorMessage,
      successtoaster,
      successMessage,
      errortoaster,
      errorMessage,
      selectedTab,
      showCode,
      changeApiShow,
      authenticateBtnShow,
      httpOption,
      cLibCurlOption,
      curlOption,
      cRestOption,
      goOption,
      javaHttpOption,
      javaUniOption,
      jsAjaxOption,
      jsXhrOption,
      nodeRequestOption,
      nodeUnirestOption,
      objectiveOption,
      ocamlOption,
      phpHttpOption,
      phpPeclOption,
      phpCurlOption,
      pythonOption,
      rubyOption,
      shellWgetOption,
      shellHttpOption,
      shellCurlOption,
      swiftOption,
      codeType,
      cerFileData,
      keyFileData,
      selectCerFile,
      selectKeyFile,
      certImage,
      keyImage,
      encryptionType,
      httpLanguageTextArea,
      cLibCurlLanguageTextArea,
      curlLanguageTextArea,
      cRestSharpLanguageTextArea,
      goLanguageTextArea,
      javaOkHttpLanguageTextArea,
      javaUnirestLanguageTextArea,
      javaScriptJqueryAjaxLanguageTextArea,
      javaScriptXhrLanguageTextArea,
      nodeJsRequestLanguageTextArea,
      nodeJsUnirestLanguageTextArea,
      objectiveCNsurlLanguageTextArea,
      ocamlCohttpLanguageTextArea,
      phpHttpRequestLanguageTextArea,
      phpPeclHttpLanguageTextArea,
      phpCurlLanguageTextArea,
      pythonRequestsTextArea,
      rubyNetHttpLanguageTextArea,
      shellWgetLanguageTextArea,
      shellHttpieLanguageTextArea,
      shellCurlLanguageTextArea,
      swiftNsurlLanguageTextArea,
      changeCertFile,
      changeKeyFile,
      postmanCollectionLink,
      getApiDetails,
      changeApiKey,
      triggerContent,
      copyToClipboard,
      getText,
      chooseImageCert,
      chooseImageKey,
      previewImageCert,
      previewImageKey,
      encryptionTypeChange,
      uploadFiles,
    }
  },
  components: {
    SuccessToaster,
    ErrorToaster
  },
};
</script>

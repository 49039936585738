<template>
    <div>
        <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="allSubAccountsModalShow">
            <div class="pos-relative" dialog-window>
                <div dialog-content>
                <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
                    <form class="jazeForm jazeModal jazeModal--small unifiAddUser">
                    <div class="jazeModal__header pb-0">
                        <div class="jazeHeader jazeHeader--centered">
                        <div class="jazeHeader__title"> Save </div>
                        <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="onClose">
                            <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                            <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                            </g>
                        </svg>
                        </div>
                    </div>
                    <div class="jazeModal__main" style="height:270px;overflow:auto;">
                        <div class="jazeModalContent pb-1">
                        <div class="container container--horizontal-cozy container--vertical-comfy">
                            <div class="">
                            <label class="jazeLabel jazeLabel--primary" v-if="edition =='isp'">Are You sure want to use this {{prefixPageData.title_for_layout}} to all sub zones? </label>
                            <label class="jazeLabel jazeLabel--primary" v-else>Are You sure want to use this {{prefixPageData.title_for_layout}} to all sub accounts? </label>
                            <br><br>
                            <div class="appType--micro appType--quiet italicized" v-if="edition =='isp'">
                                Note: Once you use these settings in all sub-zones, you cannot undo it. If you want to undo it then it has to be done manually .
                            </div>
                            <div class="appType--micro appType--quiet italicized" v-else>
                                Note: Once you use these settings in all sub-accounts, you cannot undo it. If you want to undo it then it has to be done manually .
                            </div><br>
                            <treeselect :multiple="true" :always-open="true" v-model="selectedAccVal" :options="subAccOptions" :searchable="true" :clearable="false" :default-expand-level="1"></treeselect>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="jazeModal__footer">
                        <div class="jazeButtonGroup jazeButtonGroup--cozy">
                        <button class="jazeButton jazeButton--secondary" type="button" @click="onClose">Cancel</button>
                        <button class="jazeButton jazeButton--primary submitForm" type="button" @click="addToAllSubAccounts">Yes</button>
                        </div>
                    </div>
                    </form>
                </div>
                </div>
            </div>
        </div>
        <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
        <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
    </div>
</template>
<script>
import { ref,toRefs } from 'vue'
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import Treeselect from "jaze-vue3-treeselect";
import { usesettingsStore } from '@/store/settingsstore'
export default {
    setup(props,context) {
        const { prefixTab, prefixPageData, subAccOptions, edition } = toRefs(props)
        const formClass = ref( "" )
        const errorMessage = ref( "" )
        const successtoaster = ref( false )
        const successMessage = ref( "" )
        const errortoaster = ref( false )
        const errorShow = ref( false )
        const selectedAccVal = ref( null )
        const allSubAccountsModalShow = ref( true )

        const settingsStore = usesettingsStore()

        const onClose = () => {
            allSubAccountsModalShow.value = false;
            context.emit("clicked-prefix-push-to-subzone-detail", allSubAccountsModalShow.value);
        }
        const addToAllSubAccounts = () => {
            successtoaster.value = false;
            errortoaster.value = false;
            var accounts = [];
            var prefix = null;
            if(selectedAccVal.value.length==1 && selectedAccVal.value[0] == 'selectAll' && subAccOptions.value[0].children.length!=0) {
                for(let i =0;i<subAccOptions.value[0].children.length;i++) {
                    accounts.push(subAccOptions.value[0].children[i].id)
                }
            } else {
                accounts = selectedAccVal.value;
            }
            var data = {
                value: accounts,
                prefix: prefixTab.value
            };
            // $store.dispatch("addPrefixConfigToAllSubZones", data).then(
            settingsStore.addPrefixConfigToAllSubZones(data).then(    
            response => {
                allSubAccountsModalShow.value = false;
                successtoaster.value = true;
                successMessage.value = response;
                setTimeout(
                function() {
                    successtoaster.value = false;
                    context.emit("clicked-prefix-push-to-subzone-detail", false);
                }.bind(this),
                4000
                );
            },
            error => {
                errortoaster.value = true;
                errorMessage.value = error;
                setTimeout(
                function() {
                    errortoaster.value = false;
                    context.emit("clicked-prefix-push-to-subzone-detail", false);
                }.bind(this),
                15000
                );
                console.log(error);
            }
            );
        }

        return {
            settingsStore,
            prefixTab,
            prefixPageData,
            subAccOptions,
            edition,
            formClass,
            errorMessage,
            successtoaster,
            successMessage,
            errortoaster,
            errorShow,
            selectedAccVal,
            allSubAccountsModalShow,
            onClose,
            addToAllSubAccounts,
        }
    },    
    props: ['prefixTab', 'prefixPageData', 'subAccOptions', 'edition'],
    components: {
        Treeselect,
        SuccessToaster,
        ErrorToaster
    },
};
</script>
const Full = () => import( '@/container/Full.vue')

// dashboard components
const overviewIndexRoute = () => import(/* webpackPrefetch: false */ '@/views/overview/Index.vue')
const overviewDataBaseRoute = () => import(/* webpackPrefetch: false */ '@/views/overview/Databases.vue')
const overviewRadiusRoute = () => import(/* webpackPrefetch: false */ '@/views/overview/Radius.vue')
const overviewRadiusAcctStatsRoute = () => import(/* webpackPrefetch: false */ '@/views/overview/RadiusAcct.vue')
export default {
   path: '/overview',
   component: Full,
   redirect: '/overview',
   beforeEnter(to, from, next) {
      if (localStorage.getItem('token')) {
         next()
      } else {
         next('/signin')
      }
   },
   children: [
      {
         path: '/overview',
         component: overviewIndexRoute,
         meta: {
            title: "Overview"
         }
      },
      {
         path: '/overview/databases',
         component: overviewDataBaseRoute,
         meta: {
            title: "Overview"
         }
      },
      {
         path: '/overview/radius',
         component: overviewRadiusRoute,
         meta: {
            title: "Overview"
         }
      },
      {
         path: '/overview/radiusacct',
         component: overviewRadiusAcctStatsRoute,
         meta: {
            title: "Overview"
         }
      },
   ]
}
const Full = () => import( '@/container/Full.vue')

// dashboard components
const LeadManagement = () => import(/* webpackPrefetch: false */ '@/views/leads/Index.vue')
const LeadClassification = () => import(/* webpackPrefetch: false */ '@/views/leads/LeadClassification.vue')
const CompletedLeads = () => import(/* webpackPrefetch: false */ '@/views/leads/CompletedLeads.vue')
const TATLeads = () => import(/* webpackPrefetch: false */ '@/views/leads/TATLeads.vue')
const DroppedLeads = () => import(/* webpackPrefetch: false */ '@/views/leads/DroppedLeads.vue')
const AssignLeads = () => import(/* webpackPrefetch: false */ '@/views/leads/AssignLeads.vue')
const LeadSla = () => import(/* webpackPrefetch: false */ '@/views/leads/LeadSla.vue')
const addleadRoutes = () => import(/* webpackPrefetch: false */ '@/views/leads/AddLead.vue')
const LeadDashboard = () => import(/* webpackPrefetch: false */ '@/components/Leads/Dashboard.vue')

export default {
    path: '/lead_management',
    component: Full,
    redirect: '/lead_management',
    beforeEnter(to, from, next) {
        if (localStorage.getItem('token')) {
            next()
        } else {
            next('/signin')
        }
    },
    children: [
        {
            path: '/lead_management/assign_leads',
            component: AssignLeads,
            meta: {
                title: "Assign Leads"
            }
        },
        {
            path: '/lead_management/add_lead/:leadId/:accountId',
            component: addleadRoutes,
            meta: {
                title: "Add Lead"
            }
        },
        {
            path: '/lead_management/:stage',
            component: LeadManagement,
            meta: {
               title: "Lead Management"
            }
         },
         {
            path: '/lead_management/:stage/:lead_id/:account_id',
            component: LeadManagement,
            meta: {
               title: "Lead Management"
            }
         },
        {
            path: '/lead_management/lead_classification',
            component: LeadClassification,
            meta: {
                title: "Lead Classification"
            }
        },
        {
            path: '/lead_management/completed_leads',
            component: CompletedLeads,
            meta: {
                title: "Completed Leads"
            }
        },
        {
            path: '/lead_management/tat_leads',
            component: TATLeads,
            meta: {
                title: "TAT Leads"
            }
        },
        {
            path: '/lead_management/dropped_leads',
            component: DroppedLeads,
            meta: {
                title: "Dropped Leads"
            }
        },
        {
            path: '/lead_management/lead_sla',
            component: LeadSla,
            meta: {
                title: "Lead SLA"
            }
        },
        {
            path: '/lead_management/lead_dashboard',
            component: LeadDashboard,
            meta: {
                title: "Lead Dashboard"
            }
        },
        {
            path: '/lead_management',
            component: LeadManagement,
            meta: {
                title: "Lead Management"
            }
        },
        
        
    ]
}

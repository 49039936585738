import * as Sentry from "@sentry/vue";
var urlArr = window.location.href.split("/");
var url = urlArr[0] + "//" + urlArr[2];

import axios from "axios";
import router from "@/router/index";
import config from "@/helpers/config.js";
const https = require("https");
const agent = new https.Agent({
  rejectUnauthorized: false,
});
const instance = axios.create({
  baseURL: config.hostname,
  httpsAgent: agent,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    Accept: "application/json,",
    Authorization: `${localStorage.getItem("token")}`,
  },
  crossdomain: true,
});

instance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    if (!token) {
      return config;
    }
    var expirationDate = localStorage.getItem("expirationDate");
    var now = new Date();
    var currentTime = now.getTime();

    if (currentTime >= parseInt(expirationDate)) {
      Sentry.captureException("session expire");
      sessionExpired();
    } else {
      return config;
    }
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    if (response.data.unauthorized == "unauthorized") {
      Sentry.captureException("unauthorized");
      sessionExpired();
    }
    return response;

  },
  function (error) {
    // Do something with response error
    const status = error.response ? error.response.status : null;
    const value = getValueWithTimeout("logoutCounter");
    //console.log(value);
    //console.log(localStorage.getItem("token"));
    if (value == undefined) {
      setValueWithTimestamp("logoutCounter", 1, new Date().getTime());
    }
    if (status === 401 || status === 403 || localStorage.getItem("token") == "logout") {
      if (
        (localStorage.getItem("token") != null &&
          localStorage.getItem("token") != "null") &&
        localStorage.getItem("token") != "logout"
      ) {
        if (value != undefined && value > 5) {
          //11times
          console.log(localStorage.getItem("token"));
          console.log(value);
          Sentry.captureException(
            "Do something with response error more than 5"
          );
          setValueWithTimestamp("logoutCounter", 0, new Date().getTime());
          sessionExpired();
        }
      } else {
        if (localStorage.getItem("token") != "logout") {
          setValueWithTimestamp("logoutCounter", 0, new Date().getTime());
          Sentry.captureException("Do something with response error");
        }
        //console.log("value---",value);
        //console.log(localStorage.getItem("token"));
        sessionExpired();
      }
    }
    return Promise.reject(error);
  }
);

function sessionExpired() {
  if (
    localStorage.getItem("token") != null &&
    localStorage.getItem("token") != "null"
  ) {
    localStorage.removeItem("expirationDate");
    localStorage.removeItem("token");
    localStorage.removeItem("adminDetails");
    window.location.href = "/signin";
  } else {
    localStorage.removeItem("expirationDate");
    localStorage.removeItem("token");
    localStorage.removeItem("adminDetails");
    router.push("/signin");
  }
}

function setValueWithTimestamp(key, value, timestamp) {
  const data = {
    value: value,
    timestamp: timestamp,
  };
  localStorage.setItem(key, JSON.stringify(data));
}
function getValueWithTimeout(key) {
  const data = JSON.parse(localStorage.getItem(key));

  if (data) {
    const now = new Date().getTime();
    // Check if more than 1 minutes (60000 milliseconds) have
    if (now - data.timestamp > 60000) {
      // More than 5 minutes have passed, reset value
      setValueWithTimestamp(key, 0, new Date().getTime());
      return 0;
    } else {
      data.value += 1;
      setValueWithTimestamp(key, data.value, data.timestamp);
      return data.value;
    }
  }
  return undefined;
}

export default instance;

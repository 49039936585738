<template>
  <div class="jazeMainPanel " style="height: 100%;">
    <div class="jazePanelContent jazePanelContent--scrollable">
      <div class="unifiSettingsContainer">
        <form ref="workingHoursForm" class="appForm appForm--cozy is-validation-hidden ">
          <div class="unifiSettingsHeader ">
            <div class="unifiSettingsHeader__title">Customize Working Hours</div>
          </div>
          <fieldset class="appFieldset appFieldset--quiet">
            <CompWork :adminId='"null"' :action='"settings"'></CompWork>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import CompWork from "@/components/Settings/CompWorkHour.vue";
export default {
  components: {
    CompWork,
  },
};
</script>
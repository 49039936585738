const Full = () => import(/* webpackPrefetch: false */ '@/container/Full.vue')

const cpIndexRoute = () => import(/* webpackPrefetch: false */ '@/views/CpConfiguration/Index.vue')

export default {
   path: '/captive_portal_configuration',
   component: Full,
   redirect: '/captive_portal_configuration',
   beforeEnter(to, from, next) {
      if (localStorage.getItem('token')) {
         next()
      } else {
         next('/signin')
      }
   },
   children: [
      {
         path: '/captive_portal_configuration',
         component: cpIndexRoute,
         meta: {
            title: "Captive Portal Settings"
         }
      },

   ]
}
const Full = () => import( '@/container/Full.vue')

// dashboard components
const headerHelpDesk = () => import(/* webpackPrefetch: false */ '@/views/helpdesk/HelpDeskHeaderBar.vue')
const TicketDetails = () => import(/* webpackPrefetch: false */ '@/components/Helpdesk/TicketDetails.vue')
const Dashboard = () => import(/* webpackPrefetch: false */ '@/components/Helpdesk/Dashboard.vue')
const CompletedTickets = () => import(/* webpackPrefetch: false */ '@/components/Helpdesk/CompletedTickets.vue')
const TatTickets = () => import(/* webpackPrefetch: false */ '@/components/Helpdesk/TatTickets.vue')
const Tickets = () => import(/* webpackPrefetch: false */ '@/components/Helpdesk/Tickets.vue')
const TicketRules = () => import(/* webpackPrefetch: false */ '@/components/Helpdesk/TicketRules.vue')
const TicketSLA = () => import(/* webpackPrefetch: false */ '@/components/Helpdesk/TicketSLA.vue')
const TicketConfig = () => import(/* webpackPrefetch: false */ '@/components/Helpdesk/TicketConfig.vue')

export default {
    path: '/helpdesk',
    component: Full,
    redirect: '/helpdesk',
    beforeEnter(to, from, next) {
        if (localStorage.getItem('token')) {
            next()
        } else {
            next('/signin')
        }
    },
    children: [
        {
            path: '/helpdesk',
            component: Dashboard,
            meta: {
                title: "HelpDesk"
            }
        },
        {
            path: '/helpdesk/tickets',
            component: Tickets,
            meta: {
                title: "Tickets"
            }
        },
        {
            path: '/helpdesk/tickets/null/:ticket_id',
            component: Tickets,
            meta: {
                title: "Tickets"
            }
        },
        {
            path: '/helpdesk/ticket_config',
            component: TicketConfig,
            meta: {
                title: "Ticket Configuration"
            }
        },
        {
            path: '/helpdesk/completed_tickets',
            component: CompletedTickets,
            meta: {
                title: "Completed Tickets"
            }
        },
        {
            path: '/helpdesk/tat_tickets',
            component: TatTickets,
            meta: {
                title: "TAT Tickets"
            }
        },
        {
            path: '/helpdesk/ticket_slas',
            component: TicketSLA,
            meta: {
                title: "Ticket SLA"
            }

        },
        {
            path: '/helpdesk/ticket_rules',
            component: TicketRules,
            meta: {
                title: "Ticket Rules"
            }
        },
        {
            path: '/helpdesk/ticket_details/:userid',
            component: TicketDetails,
            meta: {
                title: "Ticket Details"
            }
        },
    ]
}
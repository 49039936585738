<template>
  <div class="jazeMainPanel" style="height: 100%;" v-if="isDatabaseAccessible">
    <div class="jazePanelContent jazePanelContent--scrollable">
      <div class="unifiSettingsContainer">
        <form ref="profileSettingsForm" class="appForm appForm--cozy is-validation-hidden">
          <div class="unifiSettingsHeader">
            <div class="unifiSettingsHeader__left">
              <div class="unifiSettingsHeader__title">Package Settings</div>
            </div>
            <div class="unifiSettingsHeader__right" v-if="packageSettingsData.accountsArr!= undefined && packageSettingsData.accountsArr.length!=0">
              <div class="appActionBarFilters py-1">
                <div class="appActionBarFilters" v-click-outside="hide">
                  <div class="jazeDropdownContainer px-3 jazeButton jazeButton--secondary" :class='(viewMenuData =="action")? "is-child-dropdown-expanded":""' @click="viewMenuDatafn('action')">
                    <div>
                      <span class="noTextTransform">Action</span>
                      <span class="button__icon button__icon--right icon jaze-icon--pointer-down"></span>
                    </div>
                    <div class="jazeDropdown jazeDropdown--bottomRight">
                      <div class="jazeDropdown__item clickable">
                        <a class="dropdown-item" tabindex="-1" role="menuitem" v-if="packageSettingsData.edition=='isp'" @click="addPackage"> Add packages </a>
                        <a class="dropdown-item" tabindex="-1" role="menuitem" v-else @click="addPackage"> Add profiles </a>
                      </div>
                      <div class="jazeDropdown__item clickable">
                        <a class="dropdown-item" tabindex="-1" role="menuitem" v-if="packageSettingsData.edition=='isp'" @click="deletePackage"> Remove packages </a>
                        <a class="dropdown-item" tabindex="-1" role="menuitem" v-else @click="deletePackage"> Remove profiles </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ul class="appTabs appTabs--cozy appTabs--flexible" pageutils-nav-active-class="is-tab-selected">
            <li @click="selectedTab = 'packageSettings'" class="tabs__item" :class="{'is-tab-selected':selectedTab == 'packageSettings'}">
              <span>Package Settings</span>
            </li>
            <li @click="selectedTab = 'defaultPackageSettings'" class="tabs__item" :class="{'is-tab-selected':selectedTab == 'defaultPackageSettings'}">
              <span>Default Package Settings</span>
            </li>
          </ul>
          <div v-show="selectedTab == 'packageSettings'">
            <fieldset class="appFieldset appFieldset--quiet">
              <div class="unifiSettingsSection" v-if="Object.keys(packageSettingsData).length != 0">
                <div class="appRow">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign" v-if="packageSettingsData.edition == 'isp'"> Enforce Package Policies </label>
                    <label class="appLabel appLabel--primary appLabel--boxAlign" v-else> Enforce Profile Policies </label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="enforceProfilePolicyForFranchise" v-model="enforceProfilePolicyForFranchise" :checked='packageSettingsData.enforceProfilePolicy!=undefined && packageSettingsData.enforceProfilePolicy == "yes" ? true:false'>
                  </div>
                </div>
                <div class="appRow" v-if="enforceProfilePolicyForFranchise">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">By Default </label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <div class="appInputGroup verticalRhythmTop--condensed">
                      <div class="appInputGroup appInputGroup--cozy inlineBlock">
                        <input type="radio" name="enforceProfilesForFranchiseVisible" v-model="enforceProfilesForFranchiseVisible" value="showAllPackages" :checked="packageSettingsData!=undefined && packageSettingsData.enforceProfilesForFranchiseVisible !=undefined && packageSettingsData.enforceProfilesForFranchiseVisible == 'showAllPackages' ? true : false" />
                        <label for="ShowAllPackages"> Show all packages</label>
                      </div>
                      <div class="appInputGroup appInputGroup--cozy inlineBlock">
                        <input type="radio" name="enforceProfilesForFranchiseVisible" v-model="enforceProfilesForFranchiseVisible" value="dontShowAllPackages" :checked="packageSettingsData!=undefined && packageSettingsData.enforceProfilesForFranchiseVisible!=undefined && packageSettingsData.enforceProfilesForFranchiseVisible == 'dontShowAllPackages' ? true : false" />
                        <label for="DontShowAllPackages">Don't show all packages</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="packageSettingsData.accountsArr!= undefined && packageSettingsData.accountsArr.length!=0 && accountsArr.length!=0">
                  <div v-for="(account,index) in accountsArr" :key=account.Account.id>
                    <div class="appRow">
                      <div class="col--sm3">
                        <label class="appLabel appLabel--primary appLabel--boxAlign"> {{account.Account.company_name}} </label>
                      </div>
                      <div class="col--sm5 appFormGroup appFormGroup--cozy">
                        <treeselect @open='opendiv(index)' :load-options="loadOptions" :options="accountOptions" v-model="selectedGroupsArr['profiles_'+account.Account.id]" :multiple="true" :searchable="true" :clearable="false" :placeholder="packageSettingsData.edition=='isp' ? 'Select the packages' : 'Select the profiles' " :disable-branch-nodes="true"></treeselect>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Do not allow different User group and Package names </label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="franchiseDifferentUserGroupandPackageName" v-model="franchiseDifferentUserGroupandPackageName" :checked="packageSettingsData.franchiseDifferentUserGroupandPackageName!=undefined && packageSettingsData.franchiseDifferentUserGroupandPackageName == 'yes' ? true : false">
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <div v-show="selectedTab == 'defaultPackageSettings'">
            <DefaultPackageSettings v-if="selectedTab == 'defaultPackageSettings'" :edition = packageSettingsData.edition :selectedTab = selectedTab></DefaultPackageSettings>
          </div>
        </form>
        <div v-show="selectedTab == 'packageSettings'" class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark">
          <div class="appFormFooter__right">
            <button class="appMainButton appMainButton--primary busyToggle" type="submit" @click="saveProfileSettings"> Save</button>
          </div>
        </div>
      </div>
    </div>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="addRemovePackageModal">
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form class="jazeForm jazeModal jazeModal--small is-validation-hidden" :class="formClass">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title" v-if="packageSettingsData.edition=='isp'"> Select the package to {{action}} </div>
                  <div class="jazeHeader__title" v-else> Select the profile to {{action}} </div>
                  <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="addRemovePackageModal=false">
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeAlert jazeAlert--danger row col-12" v-show='errorShow'>
                <div class="col-6">
                  <strong>{{errorMessage}}</strong>
                </div>
              </div>
              <div class="jazeModal__main">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                    <div class="appRow">
                      <div class="col--sm4">
                        <label class="appLabel appLabel--boxInline" v-if="packageSettingsData.edition=='isp'">Select the package</label>
                        <label class="appLabel appLabel--boxInline" v-else>Select the profile</label>
                      </div>
                      <div class="col--sm8 appFormGroup appFormGroup--cozy">
                        <treeselect :options="profileOptions" v-model="selectedPackage" :searchable="true" :clearable="false" :placeholder="packageSettingsData.edition=='isp' ? 'Select the package' : 'Select the profile' " :disable-branch-nodes="true"></treeselect>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--secondary" type="button" @click="addRemovePackageModal=false">Cancel</button>
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="addRemovePackage">Ok</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="errorMsgModal">
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form class="jazeForm jazeModal jazeModal--small unifiAddUser">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title"> Error </div>
                </div>
              </div>
              <div class="jazeModal__main">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                    <div class="row">
                      <div class="col jazeFormGroup jazeFormGroup--flex">
                        <label class="jazeLabel jazeLabel--primary">
                          <span v-html="script"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="errorMsgModal=false">Ok</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
    <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
  </div>
  <div class="jazeMainPanel" style="height: 100%;" v-else>
    <div class="jazePanelContent jazePanelContent--scrollable">
      <div class="unifiSettingsContainer">
          <div class="unifiSettingsHeader">
            <div class="unifiSettingsHeader__left">
              <div class="unifiSettingsHeader__title">Package Settings</div>
            </div>
          </div>
      </div>
    </div>
    <ErrorPage></ErrorPage>
  </div>

</template>

<script>
import { ref,computed,watch,watchEffect,onMounted,onBeforeUnmount } from 'vue'
import Treeselect from "jaze-vue3-treeselect";
import { overrideIsSelected } from "@/overrides/vue3-treeselect";
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import DefaultPackageSettings from "@/components/Settings/DefaultPackageSettings.vue";
import ErrorPage from "@/components/Status/ErrorPage.vue";
import { storeToRefs } from "pinia";
import { uselayoutStore } from '@/store/layoutstore';
;
import { usesettingsStore } from '@/store/settingsstore'
export default {
  mixins: [overrideIsSelected()],
  setup() {
    const viewMenuData = ref( "" )
    const packageSettingsData = ref( [] )
    const successtoaster = ref( false )
    const successMessage = ref( "" )
    const errortoaster = ref( false )
    const errorMessage = ref( "" )
    const errorShow = ref( false )
    const accountOptions = ref( null )
    const packageArrOptions = ref( [] )
    const selectedGroupsArr = ref( [] )
    const enforceProfilePolicyForFranchise = ref( false )
    const enforceProfilesForFranchiseVisible = ref( "showAllPackages" )
    const franchiseDifferentUserGroupandPackageName = ref( false )
    const script = ref( "" )
    const errorMsgModal = ref( false )
    const addRemovePackageModal = ref( false )
    const action = ref( "" )
    const profileOptions = ref( new Array() )
    const selectedPackage = ref( null )
    const formClass = ref( "" )
    const selectedTab = ref( 'packageSettings' )

    const layoutStore = uselayoutStore()
    const { headerDetails } = storeToRefs(layoutStore)
    const headerDetailsGetter = computed(() => headerDetails.value);
    const settingsStore = usesettingsStore()
    const { profileSettingsData } = storeToRefs(settingsStore)
    const profileSettingsGetter = computed(() => profileSettingsData.value)

    const isDatabaseAccessible = computed(() => {
      var isDatabaseAccessible = true;
      if (Object.keys(headerDetailsGetter.value).length > 0) {
        return headerDetailsGetter.value.isDatabaseAccessible;
      }else{
        layoutStore.fetchHeaderDetails();
        isDatabaseAccessible = headerDetailsGetter.value.isDatabaseAccessible;
      }
      return isDatabaseAccessible
    })
    const accountsArr = computed(() => {
      if (
        packageSettingsData.value.accountsArr != undefined &&
        packageSettingsData.value.accountsArr.length != 0
      ) {
        var accountsArr = packageSettingsData.value.accountsArr;
        var packageArr = packageSettingsData.value.profiles;
        for (var i = 0; i < accountsArr.length; i++) {
          var packageOptions = [];
          var selectedOptions = [];
          for (var j = 0; j < packageArr.length; j++) {
            packageOptions.push({ id: packageArr[j], label: packageArr[j] });
          }
          packageArrOptions.value[i] = packageOptions;
          if (
            accountsArr[i].Setting != undefined &&
            accountsArr[i].Setting.profiles != undefined &&
            accountsArr[i].Setting.profiles != null
          ) {
            if (Array.isArray(accountsArr[i].Setting.profiles)) {
              var profilesArr = accountsArr[i].Setting.profiles;
              for (var k = 0; k < profilesArr.length; k++) {
                selectedOptions.push(profilesArr[k]);
              }
            } else if (
              Object.keys(accountsArr[i].Setting.profiles).length > 0
            ) {
              for (const [key, value] of Object.entries(
                accountsArr[i].Setting.profiles
              )) {
                selectedOptions.push(value);
              }
            }
          }
          selectedGroupsArr.value[
            "profiles_" + accountsArr[i].Account.id
          ] = selectedOptions;
          opendiv(i);
        }
      }
      return packageSettingsData.value.accountsArr;
    })
    
    onMounted(() => {
      getProfileSettings();
    })
    onBeforeUnmount(() => {
      settingsStore.clearSettingsState();
    })

    watch(profileSettingsGetter,() => {
      packageSettingsData.value = profileSettingsGetter.value;
      if (
        packageSettingsData.value.enforceProfilePolicy != undefined &&
        packageSettingsData.value.enforceProfilePolicy == "yes"
      ) {
        enforceProfilePolicyForFranchise.value = true;
      }
      if (
        packageSettingsData.value.enforceProfilesForFranchiseVisible != undefined
      ) {
        enforceProfilesForFranchiseVisible.value = packageSettingsData.value.enforceProfilesForFranchiseVisible;
      }
      if (
        packageSettingsData.value.franchiseDifferentUserGroupandPackageName !=
          undefined &&
        packageSettingsData.value.franchiseDifferentUserGroupandPackageName ==
          "yes"
      ) {
        franchiseDifferentUserGroupandPackageName.value = true;
      }
      if (Array.isArray(packageSettingsData.value.profiles)) {
        for (var i = 0; i < packageSettingsData.value.profiles.length; i++) {
          profileOptions.value.push({
            id: packageSettingsData.value.profiles[i],
            label: packageSettingsData.value.profiles[i]
          });
        }
      }
    })

    const getProfileSettings = () => {
      if(!isDatabaseAccessible.value){
        return;
      }
      if (Object.keys(profileSettingsGetter.value).length == 0) {
        // $store.dispatch("fetchProfileSettings");
        settingsStore.fetchProfileSettings();
      } else {
        packageSettingsData.value = profileSettingsGetter.value;
      }
    }
    const opendiv = (index) => {
      var options = packageArrOptions.value[index];
      accountOptions.value = options;
    }
    const loadOptions = ({ action, searchQuery, callback, instanceId }) => {
      callback(null);
    }
    const saveProfileSettings = (e) => {
      e.preventDefault();
      var postData = {};
      if (enforceProfilePolicyForFranchise.value == true) {
        postData.enforceProfilePolicyForFranchise = "yes";
      }
      postData.enforceProfilesForFranchiseVisible = enforceProfilesForFranchiseVisible.value;

      if (franchiseDifferentUserGroupandPackageName.value == true) {
        postData.franchiseDifferentUserGroupandPackageName = "on";
      }
      for (const [key, value] of Object.entries(selectedGroupsArr.value)) {
        if (value.length == 0) {
          delete selectedGroupsArr.value[key];
        }
      }
      // postData = selectedGroupsArr.value;
      Object.assign(postData,selectedGroupsArr.value);
      // $store.dispatch("profileSettingsSubmit", postData).then(
      settingsStore.profileSettingsSubmit(postData).then(        
        response => {
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function() {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        error => {
          if (error.type == "profileNameError") {
            script.value = "";
            var groupsArr = error.profileGroups;
            if (Array.isArray(groupsArr)) {
              for (var i = 0; i < groupsArr.length; i++) {
                if (packageSettingsData.value.edition == "isp") {
                  script.value += " <div>Zone <b> ";
                } else {
                  script.value += " <div>Account <b> ";
                }
                script.value += groupsArr[i].accountName + "</b> is having";
                if (packageSettingsData.value.edition == "isp") {
                  script.value += " Package ";
                } else {
                  script.value += " Profiles ";
                }
                script.value +=
                  "<b>" + groupsArr[i].profileName + " </b>associated with  ";
                for (var j = 0; j < groupsArr[i].groups.length; j++) {
                  script.value += "<b>" + groupsArr[i].groups[j] + " </b> , ";
                }
                script.value += " Groups</div><br>";
              }
            }
            errorMsgModal.value = true;
          } else {
            errortoaster.value = true;
            errorMessage.value = error.profileGroups;
          }
          setTimeout(
            function() {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
    }
    const addPackage = () => {
      action.value = "add";
      errorShow.value = false;
      addRemovePackageModal.value = true;
    }
    const deletePackage = () => {
      action.value = "remove";
      errorShow.value = false;
      addRemovePackageModal.value = true;
    }
    const viewMenuDatafn = (value) => {
      if (viewMenuData.value == value) {
        viewMenuData.value = "";
      } else {
        viewMenuData.value = value;
      }
    }
    const hide = () => {
      viewMenuData.value = "";
    }
    const addRemovePackage = () => {
      if (selectedPackage.value == "" || selectedPackage.value == null) {
        errorMessage.value = "Value cannot be empty";
        errorShow.value = true;
        formClass.value = "is-form-validation-visible";
        return;
      }
      if (action.value == "add") {
        var data = {
          deactivated: selectedPackage.value,
          funcName: 'addPackageSubmit'
        };
        // var funcName = "addPackageSubmit";
      } else {
        var data = {
          removal: selectedPackage.value,
          funcName: 'removePackageSubmit'
        };
        // var funcName = "removePackageSubmit";
      }
      errorShow.value = false;
      // $store.dispatch(funcName, data).then(
      settingsStore.addRemovePackage(data).then(  
        response => {
          addRemovePackageModal.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function() {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        error => {
          addRemovePackageModal.value = false;
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function() {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
    }

    return {
      layoutStore,
      headerDetailsGetter: headerDetails,
      settingsStore,
      profileSettingsGetter:profileSettingsData,
      isDatabaseAccessible,
      accountsArr,
      viewMenuData,
      packageSettingsData,
      successtoaster,
      successMessage,
      errortoaster,
      errorMessage,
      errorShow,
      accountOptions,
      packageArrOptions,
      selectedGroupsArr,
      enforceProfilePolicyForFranchise,
      enforceProfilesForFranchiseVisible,
      franchiseDifferentUserGroupandPackageName,
      script,
      errorMsgModal,
      addRemovePackageModal,
      action,
      profileOptions,
      selectedPackage,
      formClass,
      selectedTab,
      getProfileSettings,
      opendiv,
      loadOptions,
      saveProfileSettings,
      addPackage,
      deletePackage,
      viewMenuDatafn,
      hide,
      addRemovePackage,
    }
  },
  components: {
    Treeselect,
    SuccessToaster,
    ErrorToaster,
    DefaultPackageSettings,
    ErrorPage,
  },
};
</script>

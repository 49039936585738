const Full = () => import( '@/container/Full.vue')

const IPPRoutes = () => import(/* webpackPrefetch: false */ '@/views/voicePhone/VoicePhone.vue')

export default {
   path: '/ipphone',
   component: Full,
   redirect: '/ipphone/set_top_phone',
   beforeEnter(to, from, next) {
      if (localStorage.getItem('token')) {
         next()
      } else {
         next('/signin')
      }
   },
   children: [
      {
         path: '/ipphone/set_top_phone',
         component: IPPRoutes,
         meta: {
            title: "Set Top Phone"
        }
      },
   ]
}
import { defineStore } from 'pinia'

import router from '@/router/index'
import axios from '@/api/index'
import qs from 'qs'
import Nprogress from 'nprogress';
import  '../../node_modules/js-loading-overlay/dist/js-loading-overlay.min.js'
export const useexternalintegrationStore = defineStore('externalintegrationstore', {
    state: () => {
        return {
            smsList: [],
            smsRoles: [],
            emailRoles: [],
            whatsappRoles: [],
            smsContentList: [],
            ottList: [],
            enachList: [],
            aadhaarkycList: [],
            ipPhoneList: [],
            ipPhoneContentList: [],
            iptvList: [],
            iptvContentList: [],
            quickBookList: [],
            webHooksList: [],
            oneSignalList: [],
            amazonS3List: [],
            googleDriveList: [],
            ftpList: [],
            whatsappList: [],
            whatsappContentList: [],
            payGateList: [],
            emailList: [],
            pmsList: [],
            socialNetworkList: [],
            emailContentData: [],
            xpressConnectList: [],
            userStoreList: [],
            ivrList: [],
            bandwidthSampleFilePath: [],
            emailSampleFilePath:'',
            whatsappSampleFilePath:[],
            acsList: [],
        }
    },
    getters: {
        bandwidthSampleFilePathState: (state) => state.bandwidthSampleFilePath,
        getsampleSmsSampleFilePath() {
            return this.bandwidthSampleFilePathState;
        },
        smsRolesState: (state) => state.smsRoles,
        getSmsRolesGetters() {
            return this.smsRolesState;
        },
        emailSampleFilePathState: (state) => state.emailSampleFilePath,
        getsampleEmailSampleFilePath() {
            return this.emailSampleFilePathState;
        },
        emailRolesState: (state) => state.emailRoles,
        getEmailRolesGetters() {
            return this.emailRolesState;
        },
        whatsappSampleFilePathState: (state) => state.whatsappSampleFilePath,
        getsampleWhatsappSampleFilePath() {
            return this.whatsappSampleFilePathState;
        },
        whatsappRolesState: (state) => state.whatsappRoles,
        getWhatsappRolesGetters() {
            return this.whatsappRolesState;
        },
        userStoreListState: (state) => state.userStoreList,
        userStoreGetters() {
            return this.userStoreListState;
        },
        xpressConnectListState: (state) => state.xpressConnectList,
        xpressConnectGetters() {
            return this.xpressConnectListState;
        },
        smsListState: (state) => state.smsList,
        getSmsDetailsGetters() {
            return this.smsListState;
        },
        smsContentListState: (state) => state.smsContentList,
        smsContentGetters() {
            return this.smsContentListState;
        },
        ottListState: (state) => state.ottList,
        getOttDetailsGetters() {
            return this.ottListState;
        },
        enachListState: (state) => state.enachList,
        getEnachDetailsGetters() {
            return this.enachListState;
        },
        aadhaarkycListState: (state) => state.aadhaarkycList,
        getAadhaarkycDetailsGetters() {
            return this.aadhaarkycListState;
        },
        ipPhoneListState: (state) => state.ipPhoneList,
        getIpPhoneDetailsGetters() {
            return this.ipPhoneListState;
        },
        ipPhoneContentListState: (state) => state.ipPhoneContentList,
        ipPhoneContentGetters() {
            return this.ipPhoneContentListState;
        },
        iptvListState: (state) => state.iptvList,
        getIptvDetailsGetters() {
            return this.iptvListState;
        },
        iptvContentListState: (state) => state.iptvContentList,
        iptvContentGetters() {
            return this.iptvContentListState;
        },
        quickBookListState: (state) => state.quickBookList,
        getQuickBookDetailsGetters() {
            return this.quickBookListState;
        },
        webHooksListState: (state) => state.webHooksList,
        getWebHooksDetailsGetters() {
            return this.webHooksListState;
        },
        oneSignalListState: (state) => state.oneSignalList,
        getOneSignalDetailsGetters() {
            return this.oneSignalListState;
        },
        amazonS3ListState: (state) => state.amazonS3List,
        getAmazonS3DetailsGetters() {
            return this.amazonS3ListState;
        },
        googleDriveListState: (state) => state.googleDriveList,
        getGoogleDriveDetailsGetters() {
            return this.googleDriveListState;
        },
        ftpListState: (state) => state.ftpList,
        getFtpDetailsGetters() {
            return this.ftpListState;
        },
        whatsappListState: (state) => state.whatsappList,
        getWhatsappDetailsGetters() {
            return this.whatsappListState;
        },
        whatsappContentListState: (state) => state.whatsappContentList,
        whatsappContentGetters() {
            return this.whatsappContentListState;
        },
        payGateListState: (state) => state.payGateList,
        getPayGateDetailsGetters() {
            return this.payGateListState;
        },
        emailListState: (state) => state.emailList,
        getEmailDetailsGetters() {
            return this.emailListState;
        },
        pmsListState: (state) => state.pmsList,
        getPmsDetailsGetters() {
            return this.pmsListState;
        },
        socialNetworkListState: (state) => state.socialNetworkList,
        getSocialNetworksDetailsGetters() {
            return this.socialNetworkListState;
        },
        emailContentDataState: (state) => state.emailContentData,
        emailContentGetters() {
            return this.emailContentDataState;
        },
        ivrListState: (state) => state.ivrList,
        getIvrDetailsGetters() {
            return this.ivrListState;
        },
        acsListState: (state) => state.acsList,
        getAcsDetailsGetters() {
            return this.acsListState;
        },
    },
    actions: {
        clearSmsState() {
            this.smsList = [];
            this.smsContentList = [];
            this.smsRoles = [];
            this.emailRoles = [];
            this.whatsappRoles = [];
            this.bandwidthSampleFilePath = '';
            this.emailSampleFilePath = '';
            this.whatsappSampleFilePath = '';
        },
        clearOttState() {
            this.ottList = [];
        },
        clearEnachState() {
            this.enachList = [];
        },
        clearAadhaarkycState() {
            this.aadhaarkycList = [];
        },
        clearIvrState() {
            this.ivrList = [];
        },
        clearIpPhoneState() {
            this.ipPhoneList = [];
            this.smsContentList = [];
        },
        clearIptvState() {
            this.iptvList = [];
            this.iptvContentList = [];
        },
        clearQuickBookState() {
            this.quickBookList = [];
        },
        clearWebHooksState() {
            this.webHooksList = [];
        },
        clearOneSignalState() {
            this.oneSignalList = [];
        },
        clearAmazonS3State() {
            this.amazonS3List = [];
        },
        clearGoogleDriveState() {
            this.googleDriveList = [];
        },
        clearFtpState() {
            this.ftpList = [];
        },
        clearWhatsappState() {
            this.whatsappList = [];
            this.whatsappContentList = [];
        },
        clearPayGateState() {
            this.payGateList = [];
        },
        clearEmailState() {
            this.emailList = [];
        },
        clearPmsState() {
            this.pmsList = [];
        },
        clearSocialNetworkState() {
            this.socialNetworkList = [];
        },
        clearemailContent() {
            this.emailContentData = []
        },
        fetchUserStoreDetails() {
            Nprogress.start()
            return new Promise((resolve, reject) => {
                axios.get('/external_integration/user_store/true')
                    .then(res => {
                        Nprogress.done()
                        var tData = {};
                        var parseData = res.data.message;
                        if (Object.keys(this.userStoreList).length == 0) {
                            tData[res.data.accountId + '-userStoreList'] = parseData;
                            this.userStoreList = tData;
                        } else {
                            this.userStoreList[res.data.accountId + '-userStoreList'] = parseData;
                        }                        
                        resolve(res.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        fetchXpressConnectDetails() {
            Nprogress.start()
            return new Promise((resolve, reject) => {
                axios.get('/external_integration/xpress_connect/true')
                    .then(res => {
                        Nprogress.done()
                        var tData = {};
                        var parseData = res.data.message;
                        if (Object.keys(this.xpressConnectList).length == 0) {
                            tData[res.data.accountId + '-xpressConnectList'] = parseData;
                            this.xpressConnectList = tData;
                        } else {
                            this.xpressConnectList[res.data.accountId + '-xpressConnectList'] = parseData;
                        }                        
                        resolve(res.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        fetchSmsDetails() {
            Nprogress.start()
            axios.get("/customize_user_request_access/customize_sms_settings/true")
                .then(response => {
                    Nprogress.done()
                    if (response.data.status == "redirect") {
                        router.push(response.data.message);
                    } else {
                        var tData = {};
                        var parseData = response.data.message;
                        if (Object.keys(this.smsList).length == 0) {
                            tData[response.data.accountId + '-smsList'] = parseData;
                            this.smsList = tData;
                        } else {
                            this.smsList[response.data.accountId + '-smsList'] = parseData;
                        }                        
                    }
                })
                .catch(error => console.log(error))
        },
        fetchSmsContent() {
            Nprogress.start()
            axios.get("/captive_portal/customize_sms_content/true")
                .then(response => {
                    Nprogress.done()
                    var tData = {};
                    var parseData = response.data.message;
                    this.smsRoles = parseData.pageSpecificRoles;
                    this.bandwidthSampleFilePath = response.data.samplePath;
                    if (Object.keys(this.smsContentList).length == 0) {
                        tData[response.data.accountId + '-smsContentList'] = parseData;
                        this.smsContentList = tData;
                    } else {
                        this.smsContentList[response.data.accountId + '-smsContentList'] = parseData;
                    }                    
                })
                .catch(error => console.log(error))
        },
        fetchEmailContent() {
            Nprogress.start()
            axios.get("/captive_portal/customize_email_content/true")
                .then(response => {
                    Nprogress.done()
                    var tData = {};
                    var parseData = response.data.message;
                    tData[response.data.accountId + '-emailContentList'] = parseData;                   
                    this.emailContentData = tData;      
                    this.emailSampleFilePath= response.data.samplePath;            
                })
                .catch(error => console.log(error))
        },
        xpressConnectSubmit(formData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/xpress_connect_post', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
    
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
    
                    })
                    .catch(error => console.log(error));
            });
        },
        addEditLdapFormSubmit(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/add_edit_ldap_save', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        deleteUserStoreFormSubmit(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/delete_user_store', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        createSmsModalSubmit(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/captive_portal/save_default_operator', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var smsData = response.data.smsData;
                        var accountId = response.data.accountId;
                        if (Object.keys(this.smsList).length != 0 && this.smsList != 'noData') {
                            if (this.smsList != 'noData') {
                                var index = this.smsList[accountId + "-smsList"].gateways.findIndex(sms => sms.name == smsData.oldName);
                                if (index != '-1') {
                                    this.smsList[accountId + "-smsList"].gateways.splice(index, 1)
                                }
                                this.smsList[accountId + "-smsList"].gateways.push(smsData);
                            }
                        } else {
                            var dataSet = {}
                            dataSet[accountId + "-smsList"].gateways = smsData;
                            this.smsList = dataSet;
                        }                        
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        deleteSmsGatewaySubmit(authData) {
            return new Promise((resolve, reject) => {    
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.get('/customize_user_request_access/delete_sms_gateway/' + authData.smsIndex)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var smsIds = response.data.smsGatewayName;
                        var accountId = response.data.accountId;
                        if (Object.keys(this.smsList).length != 0) {
                            var index = this.smsList[accountId + "-smsList"].gateways.findIndex(sms => sms.name == smsIds);
                            if (index != '-1') {
                                this.smsList[accountId + "-smsList"].gateways.splice(index, 1)
                            }
                        }                        
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        makeDefaultSmsGatewaySubmit(authData) {
            return new Promise((resolve, reject) => {    
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.get('/customize_user_request_access/make_default_sms_gateway/' + authData.smsIndex)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        addSmsToAllSubZones(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/captive_portal/add_all_accounts', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data.genericBody);
                    })
                    .catch(error => console.log(error));
            });
        },
        saveSmsContent(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/captive_portal/save_sms_contents/true', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var dataList = {
                            allData: postData
                        }
                        this.updateSmsContent(dataList)
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        addCustomSmsTemplate(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/captive_portal/save_custom_sms_contents', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var dataList = {
                            smsContentData: response.data,
                            accountId: response.data.accountId
                        }
                        this.updateCustomSmsContentList(dataList)
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        exportSmsCsv(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/captive_portal/exportSmsCsv', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        deleteCustomSmsTemplate(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/captive_portal/delete_custom_sms_template', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        importTemplateModal(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/captive_portal/import_template_file', postData)
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          importTemplateCopy(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/captive_portal/import_template_file_copy', postData)
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
        fetchOttDetails() {
            Nprogress.start()
            axios.get("/external_integration/customize_ott/true")
                .then(response => {
                    Nprogress.done()
                    if (response.data.status == "redirect") {
                        router.push(response.data.message);
                    } else {
                        var tData = {};
                        var parseData = response.data.message;
                        if (Object.keys(this.ottList).length == 0) {
                            tData[response.data.accountId + '-ottList'] = parseData;
                            this.ottList = tData;
                        } else {
                            this.ottList[response.data.accountId + '-ottList'] = parseData;
                        }                        
                    }
                })
                .catch(error => console.log(error))
        },
        createEnachModalSubmit(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/save_enach', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var enachData = response.data.enachData;
                        var accountId = response.data.accountId;
                        if (Object.keys(this.enachList).length != 0 && this.enachList != 'noData') {
                            if (this.enachList != 'noData') {
                                var index = this.enachList[accountId + "-enachList"].gateways.findIndex(enach => enach.name == enachData.oldName);
                                if (index != '-1') {
                                    this.enachList[accountId + "-enachList"].gateways.splice(index, 1);
                                }
                                this.enachList[accountId + "-enachList"].gateways.push(enachData);
                
                            }
                        } else {
                            var dataSet = {}
                            dataSet[accountId + "-enachList"].gateways = ottData;
                            this.enachList = dataSet;
                        }                            
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        createAadhaarkycModalSubmit(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/save_aadhaarkyc', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var aadhaarkycData = response.data.aadhaarkycData;
                        var accountId = response.data.accountId;
                        if (Object.keys(this.aadhaarkycList).length != 0 && this.aadhaarkycList != 'noData') {
                            if (this.aadhaarkycList != 'noData') {
                                var index = this.aadhaarkycList[accountId + "-aadhaarkycList"].gateways.findIndex(aadhaarkyc => aadhaarkyc.name == aadhaarkycData.oldName);
                                if (index != '-1') {
                                    this.aadhaarkycList[accountId + "-aadhaarkycList"].gateways.splice(index, 1);
                                }
                                this.aadhaarkycList[accountId + "-aadhaarkycList"].gateways.push(aadhaarkycData);
                
                            }
                        } else {
                            var dataSet = {}
                            dataSet[accountId + "-aadhaarkycList"].gateways = ottData;
                            this.aadhaarkycList = dataSet;
                        }                            
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        fetchEnachDetails() {
            Nprogress.start()
            axios.get("/external_integration/customize_enach/true")
                .then(response => {
                    Nprogress.done()
                    if (response.data.status == "redirect") {
                        router.push(response.data.message);
                    } else {
                        var tData = {};
                        var parseData = response.data.message;
                        if (Object.keys(this.enachList).length == 0) {
                            tData[response.data.accountId + '-enachList'] = parseData;
                            this.enachList = tData;
                        } else {
                            this.enachList[response.data.accountId + '-enachList'] = parseData;
                        }                        
                    }
                })
                .catch(error => console.log(error))
        },
        fetchAadhaarkycDetails() {
            Nprogress.start()
            axios.get("/external_integration/customize_aadhaarkyc/true")
                .then(response => {
                    Nprogress.done()
                    if (response.data.status == "redirect") {
                        router.push(response.data.message);
                    } else {
                        var tData = {};
                        var parseData = response.data.message;
                        if (Object.keys(this.aadhaarkycList).length == 0) {
                            tData[response.data.accountId + '-aadhaarkycList'] = parseData;
                            this.aadhaarkycList = tData;
                        } else {
                            this.aadhaarkycList[response.data.accountId + '-aadhaarkycList'] = parseData;
                        }                        
                    }
                })
                .catch(error => console.log(error))
        },
        createOttModalSubmit(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/save_ott', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var ottData = response.data.ottData;
                        var accountId = response.data.accountId;
                        if (Object.keys(this.ottList).length != 0 && this.ottList != 'noData') {
                            if (this.ottList != 'noData') {
                                var index = this.ottList[accountId + "-ottList"].gateways.findIndex(ott => ott.name == ottData.oldName);
                                if (index != '-1') {
                                    this.ottList[accountId + "-ottList"].gateways.splice(index, 1);
                                }
                                this.ottList[accountId + "-ottList"].gateways.push(ottData);
                
                            }
                        } else {
                            var dataSet = {}
                            dataSet[accountId + "-ottList"].gateways = ottData;
                            this.ottList = dataSet;
                        }                            
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        makeDefaultEnachSubmit(authData) {
            return new Promise((resolve, reject) => {    
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.get('/external_integration/make_default_enach/' + authData.enachIndex)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        makeDefaultAadhaarkycSubmit(authData) {
            return new Promise((resolve, reject) => {    
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.get('/external_integration/make_default_aadhaarkyc/' + authData.aadhaarkycIndex)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        makeDefaultOttSubmit(authData) {
            return new Promise((resolve, reject) => {    
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.get('/external_integration/make_default_ott/' + authData.ottIndex)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        addOttToAllSubZones(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/add_all_ott_sub_accounts', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data.genericBody);
                    })
                    .catch(error => console.log(error));
            });
        },
        saveSubAccSettings(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/save_sub_accounts_cannot_change_ott_settings', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data.genericBody);
                    })
                    .catch(error => console.log(error));
            });
        },
        deleteOttSubmit(authData) {
            return new Promise((resolve, reject) => {    
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.get('/external_integration/delete_ott/' + authData.ottIndex)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var ottIds = response.data.ottGatewayName;
                        var accountId = response.data.accountId;                
                        if (Object.keys(this.ottList).length != 0) {
                            var index = this.ottList[accountId + "-ottList"].gateways.findIndex(ott => ott.name == ottIds);
                            if (index != '-1') {
                                this.ottList[accountId + "-ottList"].gateways.splice(index, 1)
                            }
                        }                        
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        addEnachToAllSubZones(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/add_all_enach_sub_accounts', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data.genericBody);
                    })
                    .catch(error => console.log(error));
            });
        },
        addAadhaarkycToAllSubZones(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/add_all_aadhaarkyc_sub_accounts', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data.genericBody);
                    })
                    .catch(error => console.log(error));
            });
        },
        saveSubAccEnachSettings(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/save_sub_accounts_cannot_change_enach_settings', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data.genericBody);
                    })
                    .catch(error => console.log(error));
            });
        },
        saveSubAccAadhaarkycSettings(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/save_sub_accounts_cannot_change_aadhaarkyc_settings', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data.genericBody);
                    })
                    .catch(error => console.log(error));
            });
        },
        deleteEnachSubmit(authData) {
            return new Promise((resolve, reject) => {
    
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.get('/external_integration/delete_enach/' + authData.enachIndex)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var enachIds = response.data.enachGatewayName;
                        var accountId = response.data.accountId;                
                        if (Object.keys(this.enachList).length != 0) {
                            var index = this.enachList[accountId + "-enachList"].gateways.findIndex(enach => enach.name == enachIds);
                            if (index != '-1') {
                                this.enachList[accountId + "-enachList"].gateways.splice(index, 1)
                            }
                        }                        
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        deleteAadhaarkycSubmit(authData) {
            return new Promise((resolve, reject) => {    
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.get('/external_integration/delete_aadhaarkyc/' + authData.aadhaarkycIndex)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var aadhaarkycIds = response.data.aadhaarkycGatewayName;
                        var accountId = response.data.accountId;                
                        if (Object.keys(this.aadhaarkycList).length != 0) {
                            var index = this.aadhaarkycList[accountId + "-aadhaarkycList"].gateways.findIndex(aadhaarkyc => aadhaarkyc.name == aadhaarkycIds);
                            if (index != '-1') {
                                this.aadhaarkycList[accountId + "-aadhaarkycList"].gateways.splice(index, 1)
                            }
                        }                        
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        fetchIpPhoneDetails() {
            Nprogress.start()
            axios.get("/external_integration/customize_ipphone/true")
                .then(response => {
                    Nprogress.done()
                    if (response.data.status == "redirect") {
                        router.push(response.data.message);
                    } else {
                        var tData = {};
                        var parseData = response.data.message;
                        if (Object.keys(this.ipPhoneList).length == 0) {
                            tData[response.data.accountId + '-ipPhoneList'] = parseData;
                            this.ipPhoneList = tData;
                        } else {
                            this.ipPhoneList[response.data.accountId + '-ipPhoneList'] = parseData;
                        }                        
                    }
                })
                .catch(error => console.log(error))
        },
        createIpPhoneModalSubmit(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/save_ipphone', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var ipPhoneData = response.data.ipPhoneData;
                        var accountId = response.data.accountId;
                        if (Object.keys(this.ipPhoneList).length != 0 && this.ipPhoneList != 'noData') {
                            if (this.ipPhoneList != 'noData') {
                                var index = this.ipPhoneList[accountId + "-ipPhoneList"].gateways.findIndex(ipp => ipp.name == ipPhoneData.oldName);
                                if (index != '-1') {
                                    this.ipPhoneList[accountId + "-ipPhoneList"].gateways.splice(index, 1);
                                }
                                this.ipPhoneList[accountId + "-ipPhoneList"].gateways.push(ipPhoneData);
                
                            }
                        } else {
                            var dataSet = {}
                            dataSet[accountId + "-ipPhoneList"].gateways = ipPhoneData;
                            this.ipPhoneList = dataSet;
                        }                            
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        makeDefaultIpPhoneSubmit(authData) {
            return new Promise((resolve, reject) => {    
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.get('/external_integration/make_default_ipphone/' + authData.ippIndex)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        syncIpPhoneToUsers(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/sync_users_nexge', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        saveIpPhoneSubAccSettings(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/save_sub_accounts_cannot_change_ipphone_settings', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data.genericBody);
                    })
                    .catch(error => console.log(error));
            });
        },
        addIpPhoneToAllSubZones(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/add_all_ipphone_sub_accounts', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data.genericBody);
                    })
                    .catch(error => console.log(error));
            });
        },
        deleteIpPhoneSubmit(authData) {
            return new Promise((resolve, reject) => {    
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.get('/external_integration/delete_ipphone/' + authData.ippIndex)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var ipPhoneIds = response.data.ipPhoneGatewayName;
                        var accountId = response.data.accountId;                
                        if (Object.keys(this.ipPhoneList).length != 0) {
                            var index = this.ipPhoneList[accountId + "-ipPhoneList"].gateways.findIndex(ipp => ipp.name == ipPhoneIds);
                            if (index != '-1') {
                                this.ipPhoneList[accountId + "-ipPhoneList"].gateways.splice(index, 1)
                            }
                        }                        
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        saveIpPhoneContent(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/captive_portal/save_ipphone_contents/true', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var dataList = {
                            allData: postData
                        }
                        this.updateIpphoneContent(dataList)
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        fetchIpPhoneContent() {
            Nprogress.start()
            axios.get("/captive_portal/customize_ipphone_content/true")
                .then(response => {
                    Nprogress.done()
                    var tData = {};
                    var parseData = response.data.message;
                    if (Object.keys(this.ipPhoneContentList).length == 0) {
                        tData[response.data.accountId + '-ipPhoneContentList'] = parseData;
                        this.ipPhoneContentList = tData;
                    } else {
                        this.ipPhoneContentList[response.data.accountId + '-ipPhoneContentList'] = parseData;
                    }                    
                })
                .catch(error => console.log(error))
        },
        fetchIptvDetails() {
            Nprogress.start()
            axios.get("/external_integration/customize_iptv/true")
                .then(response => {
                    Nprogress.done()
                    if (response.data.status == "redirect") {
                        router.push(response.data.message);
                    } else {
                        var tData = {};
                        var parseData = response.data.message;
                        if (Object.keys(this.iptvList).length == 0) {
                            tData[response.data.accountId + '-iptvList'] = parseData;
                            this.iptvList = tData;
                        } else {
                            this.iptvList[response.data.accountId + '-iptvList'] = parseData;
                        }                        
                    }
                })
                .catch(error => console.log(error))
        },
        makeDefaultIptvSubmit(authData) {
            return new Promise((resolve, reject) => {    
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.get('/external_integration/make_default_iptv/' + authData.iptvIndex)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        saveIptvSubAccountSettings(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/save_sub_accounts_cannot_change_iptv_settings', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data.genericBody);
                    })
                    .catch(error => console.log(error));
            });
        },
        addIptvToAllSubZones(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/add_all_iptv_sub_accounts', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data.genericBody);
                    })
                    .catch(error => console.log(error));
            });
        },
        deleteIptvSubmit(authData) {
            return new Promise((resolve, reject) => {    
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.get('/external_integration/delete_iptv/' + authData.iptvIndex)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var iptvIds = response.data.iptvName;
                        var accountId = response.data.accountId;                
                        if (Object.keys(this.iptvList).length != 0) {
                            var index = this.iptvList[accountId + "-iptvList"].gateways.findIndex(iptv => iptv.name == iptvIds);
                            if (index != '-1') {
                                this.iptvList[accountId + "-iptvList"].gateways.splice(index, 1)
                            }
                        }                        
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        createIptvModalSubmit(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/save_iptv', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var iptvData = response.data.iptvData;
                        var accountId = response.data.accountId;
                        if (Object.keys(this.iptvList).length != 0 && this.iptvList != 'noData') {
                            if (this.iptvList != 'noData') {
                                var index = this.iptvList[accountId + "-iptvList"].gateways.findIndex(iptv => iptv.name == iptvData.oldName);
                                if (index != '-1') {
                                    this.iptvList[accountId + "-iptvList"].gateways.splice(index, 1);
                                }
                                this.iptvList[accountId + "-iptvList"].gateways.push(iptvData);
                
                            }
                        } else {
                            var dataSet = {}
                            dataSet[accountId + "-iptvList"].gateways = iptvData;
                            this.iptvList = dataSet;
                        }                            
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        fetchIptvContent() {
            Nprogress.start()
            axios.get("/captive_portal/customize_iptv_content/true")
                .then(response => {
                    Nprogress.done()
                    var tData = {};
                    var parseData = response.data.message;
                    if (Object.keys(this.iptvContentList).length == 0) {
                        tData[response.data.accountId + '-iptvContentList'] = parseData;
                        this.iptvContentList = tData;
                    } else {
                        this.iptvContentList[response.data.accountId + '-iptvContentList'] = parseData;
                    }                    
                })
                .catch(error => console.log(error))
        },
        saveIptvContent(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/captive_portal/save_iptv_contents/true', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var dataList = {
                            allData: postData
                        }
                        this.updateIptvContent(dataList)
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
    
        fetchQuickBookDetails() {
            Nprogress.start()
            axios.get("/external_integration/quickbooks/true")
                .then(response => {
                    Nprogress.done()
                    if (response.data.status == "redirect") {
                        router.push(response.data.message);
                    } else {
                        var tData = {};
                        var parseData = response.data.message;
                        if (Object.keys(this.quickBookList).length == 0) {
                            tData[response.data.accountId + '-quickBookList'] = parseData;
                            this.quickBookList = tData;
                        } else {
                            this.quickBookList[response.data.accountId + '-quickBookList'] = parseData;
                        }                        
                    }
                })
                .catch(error => console.log(error))
        },
        fetchAddEditQuickBookDetails(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/getquickbookDetails', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response);
                    })
                    .catch(error => console.log(error));
            });
        },
        addQuickBookToAllSubZones(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/add_quickbooks_settings_subzones', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data.genericBody);
                    })
                    .catch(error => console.log(error));
            });
        },
        saveQuickBookSubAccSettings(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/subacc_cannotchange_quickbookssettings', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data.genericBody);
                    })
                    .catch(error => console.log(error));
            });
        },
        deleteQuickBookSubmit(authData) {
            return new Promise((resolve, reject) => {    
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.get('/external_integration/delete_quickbook/' + authData.quickBookIndex)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var quickbookIds = response.data.quickBookName;
                        var accountId = response.data.accountId;                
                        if (Object.keys(this.quickBookList).length != 0) {
                            var index = this.quickBookList[accountId + "-quickBookList"].quickbooks.findIndex(quickBook => quickBook.quickBookName == quickbookIds);
                            if (index != '-1') {
                                this.quickBookList[accountId + "-quickBookList"].quickbooks.splice(index, 1)
                            }
                        }                        
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        createQuickBookModalSubmit(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/add_quickbooks', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status == "AuthRedirect") {
                            window.location.href = response.data.authUrl;
                        }
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        fetchWebHooksDetails() {
            Nprogress.start()
            axios.get("/external_integration/web_hooks/true")
                .then(response => {
                    Nprogress.done()
                    if (response.data.status == "redirect") {
                        router.push(response.data.message);
                    } else {
                        var tData = {};
                        var parseData = response.data.message;
                        if (Object.keys(this.webHooksList).length == 0) {
                            tData[response.data.accountId + '-webHooksList'] = parseData;
                            this.webHooksList = tData;
                        } else {
                            this.webHooksList[response.data.accountId + '-webHooksList'] = parseData;
                        }                        
                    }
                })
                .catch(error => console.log(error))
        },
        addWebHooksToAllSubZones(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/save_web_hooks_all_accounts', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data.genericBody);
                    })
                    .catch(error => console.log(error));
            });
        },
        createWebHooksModalSubmit(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/web_hooks/true', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        fetchOneSignalDetails() {
            Nprogress.start()
            axios.get("/external_integration/one_signal/true")
                .then(response => {
                    Nprogress.done()
                    if (response.data.status == "redirect") {
                        router.push(response.data.message);
                    } else {
                        var tData = {};
                        var parseData = response.data.message;
                        if (Object.keys(this.oneSignalList).length == 0) {
                            tData[response.data.accountId + '-oneSignalList'] = parseData;
                            this.oneSignalList = tData;
                        } else {
                            this.oneSignalList[response.data.accountId + '-oneSignalList'] = parseData;
                        }                        
                    }
                })
                .catch(error => console.log(error))
        },
        saveOneSignalSubAccSettings(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/subAccCannotChangeOneSignalAppSettings', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.alertGoogleText);
                            return;
                        }
                        resolve(response.data.genericBody);
                    })
                    .catch(error => console.log(error));
            });
        },
        addOneSignalToAllSubZones(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/addOneSignalAppsSettingsSubZones', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data.genericBody);
                    })
                    .catch(error => console.log(error));
            });
        },
        deleteOneSignalSubmit(authData) {
            return new Promise((resolve, reject) => {    
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.get('/external_integration/delete_one_signal_app/' + authData.oneSignalIndex)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var oneSignalIds = response.data.oneSignalName;
                        var accountId = response.data.accountId;                
                        if (Object.keys(this.oneSignalList).length != 0) {
                            var index = this.oneSignalList[accountId + "-oneSignalList"].oneSignalApps.findIndex(oneSignal => oneSignal.appName == oneSignalIds);
                            if (index != '-1') {
                                this.oneSignalList[accountId + "-oneSignalList"].oneSignalApps.splice(index, 1)
                            }
                        }                        
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        fetchAddEditOneSignalDetails(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/getOneSignalAppDetails', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response);
                    })
                    .catch(error => console.log(error));
            });
        },
        createOneSignalModalSubmit(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/add_edit_OneSignalApp', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var oneSignalData = response.data.oneSignalData;
                        var accountId = response.data.accountId;
                        if (Object.keys(this.oneSignalList).length != 0 && this.oneSignalList != 'noData') {
                            if (this.oneSignalList != 'noData') {
                                var index = this.oneSignalList[accountId + "-oneSignalList"].oneSignalApps.findIndex(oneSignal => oneSignal.appName == oneSignalData.oldName);
                                if (index != '-1') {
                                    this.oneSignalList[accountId + "-oneSignalList"].oneSignalApps.splice(index, 1);
                                }
                                this.oneSignalList[accountId + "-oneSignalList"].oneSignalApps.push(oneSignalData);
                
                            }
                        } else {
                            this.oneSignalList[accountId + "-oneSignalList"].oneSignalApps.push(oneSignalData);
                            // var dataSet = {}
                            // dataSet[accountId + "-oneSignalList"].oneSignalApps = oneSignalData;
                            // this.oneSignalList = dataSet;
                        }                            
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        fetchAmazonS3Details() {
            Nprogress.start()
            axios.get("/external_integration/amazon_s3/true")
                .then(response => {
                    Nprogress.done()
                    if (response.data.status == "redirect") {
                        router.push(response.data.message);
                    } else {
                        var tData = {};
                        var parseData = response.data.message;
                        if (Object.keys(this.amazonS3List).length == 0) {
                            tData[response.data.accountId + '-amazonS3List'] = parseData;
                            this.amazonS3List = tData;
                        } else {
                            this.amazonS3List[response.data.accountId + '-amazonS3List'] = parseData;
                        }                        
                    }
                })
                .catch(error => console.log(error))
        },
        saveAmazonS3SubAccSettings(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/subacc_cannotchange_amazons3settings', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data.genericBody);
                    })
                    .catch(error => console.log(error));
            });
        },
        addAmazonS3ToAllSubZones(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/add_amazonS3_settings_subzones', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data.genericBody);
                    })
                    .catch(error => console.log(error));
            });
        },
        deleteAmazonS3Submit(authData) {
            return new Promise((resolve, reject) => {    
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.get('/external_integration/delete_amazon_s3/' + authData.amazonS3Index)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var amazonS3Ids = response.data.amazonS3Name;
                        var accountId = response.data.accountId;                
                        if (Object.keys(this.amazonS3List).length != 0) {
                            var index = this.amazonS3List[accountId + "-amazonS3List"].amazons3s.findIndex(amazonS3 => amazonS3.servername == amazonS3Ids);
                            if (index != '-1') {
                                this.amazonS3List[accountId + "-amazonS3List"].amazons3s.splice(index, 1)
                            }
                        }                        
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        fetchAddEditAmazonS3Details(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/get_amazonS3_details', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response);
                    })
                    .catch(error => console.log(error));
            });
        },
        createAmazonS3ModalSubmit(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/add_edit_amazon_s3', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var amazonS3Data = response.data.amazonS3Data;
                        var accountId = response.data.accountId;
                        if (Object.keys(this.amazonS3List).length != 0 && this.amazonS3List != 'noData') {
                            if (this.amazonS3List != 'noData') {
                                var index = this.amazonS3List[accountId + "-amazonS3List"].amazons3s.findIndex(amazonS3 => amazonS3.servername == amazonS3Data.oldName);
                                if (index != '-1') {
                                    this.amazonS3List[accountId + "-amazonS3List"].amazons3s.splice(index, 1);
                                }
                                this.amazonS3List[accountId + "-amazonS3List"].amazons3s.push(amazonS3Data);
                
                            }
                        } else {
                            this.amazonS3List[accountId + "-amazonS3List"].amazons3s.push(amazonS3Data);
                            // var dataSet = {}
                            // dataSet[accountId + "-amazonS3List"].amazons3s = amazonS3Data;
                            // this.amazonS3List = dataSet;
                        }                            
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        fetchGoogleDriveDetails() {
            Nprogress.start()
            axios.get("/external_integration/googledrive/true")
                .then(response => {
                    Nprogress.done()
                    if (response.data.status == "redirect") {
                        router.push(response.data.message);
                    } else {
                        var tData = {};
                        var parseData = response.data.message;
                        if (Object.keys(this.googleDriveList).length == 0) {
                            tData[response.data.accountId + '-googleDriveList'] = parseData;
                            this.googleDriveList = tData;
                        } else {
                            this.googleDriveList[response.data.accountId + '-googleDriveList'] = parseData;
                        }                        
                    }
                })
                .catch(error => console.log(error))
        },
        fetchAddEditGoogleDriveDetails(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/getgoogledriveDetails', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response);
                    })
                    .catch(error => console.log(error));
            });
        },
        addGoogleDriveToAllSubZones(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/addDriveSettingsSubZones', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data.genericBody);
                    })
                    .catch(error => console.log(error));
            });
        },
        saveGoogleDriveSubAccSettings(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/subAccCannotChangeGoogleDriveSettings', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data.genericBody);
                    })
                    .catch(error => console.log(error));
            });
        },
        deleteGoogleDriveSubmit(authData) {
            return new Promise((resolve, reject) => {    
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.get('/external_integration/deletegoogledrive/' + authData.googleDriveIndex)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var googleDriveIds = response.data.googleDriveName;
                        var accountId = response.data.accountId;                
                        if (Object.keys(this.googleDriveList).length != 0) {
                            var index = this.googleDriveList[accountId + "-googleDriveList"].googledrives.findIndex(googleDrive => googleDrive.googleDriveName == googleDriveIds);
                            if (index != '-1') {
                                this.googleDriveList[accountId + "-googleDriveList"].googledrives.splice(index, 1)
                            }
                        }                        
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        createGoogleDriveModalSubmit(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/addGoogleDrive', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status == "AuthRedirect") {
                            window.location.href = response.data.authUrl;
                        }
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        fetchFtpDetails() {
            Nprogress.start()
            axios.get("/external_integration/ftp/true")
                .then(response => {
                    Nprogress.done()
                    if (response.data.status == "redirect") {
                        router.push(response.data.message);
                    } else {
                        var tData = {};
                        var parseData = response.data.message;
                        if (Object.keys(this.ftpList).length == 0) {
                            tData[response.data.accountId + '-ftpList'] = parseData;
                            this.ftpList = tData;
                        } else {
                            this.ftpList[response.data.accountId + '-ftpList'] = parseData;
                        }                        
                    }
                })
                .catch(error => console.log(error))
        },
        saveFtpSubAccSettings(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/subacc_cannotchange_ftpserversettings', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data.genericBody);
                    })
                    .catch(error => console.log(error));
            });
        },
        addFtpToAllSubZones(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/add_ftp_settings_subzones', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data.genericBody);
                    })
                    .catch(error => console.log(error));
            });
        },
        deleteFtpSubmit(authData) {
            return new Promise((resolve, reject) => {    
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.get('/external_integration/delete_ftp/' + authData.ftpIndex)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var ftpIds = response.data.ftpName;
                        var accountId = response.data.accountId;                
                        if (Object.keys(this.ftpList).length != 0) {
                            var index = this.ftpList[accountId + "-ftpList"].ftps.findIndex(ftp => ftp.servername == ftpIds);
                            if (index != '-1') {
                                this.ftpList[accountId + "-ftpList"].ftps.splice(index, 1)
                            }
                        }                        
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        fetchAddEditFtpDetails(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/getFtpDetails', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response);
                    })
                    .catch(error => console.log(error));
            });
        },
        createFtpModalSubmit(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/add_edit_ftp', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var ftpData = response.data.ftpData;
                        var accountId = response.data.accountId;
                        if (Object.keys(this.ftpList).length != 0 && this.ftpList != 'noData') {
                            if (this.ftpList != 'noData') {
                                var index = this.ftpList[accountId + "-ftpList"].ftps.findIndex(ftp => ftp.servername == ftpData.oldName);
                                if (index != '-1') {
                                    this.ftpList[accountId + "-ftpList"].ftps.splice(index, 1);
                                }
                                this.ftpList[accountId + "-ftpList"].ftps.push(ftpData);
                
                            }
                        } else {
                            this.ftpList[accountId + "-ftpList"].ftps.push(ftpData);
                            // var dataSet = {}
                            // dataSet[accountId + "-ftpList"].ftps = ftpData;
                            // this.ftpList = dataSet;
                        }                            
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        fetchWhatsappDetails() {
            Nprogress.start()
            axios.get("/customize_user_request_access/customize_whatsapp_settings/true")
                .then(response => {
                    Nprogress.done()
                    if (response.data.status == "redirect") {
                        router.push(response.data.message);
                    } else {
                        var tData = {};
                        var parseData = response.data.message;
                        if (Object.keys(this.whatsappList).length == 0) {
                            tData[response.data.accountId + '-whatsappList'] = parseData;
                            this.whatsappList = tData;
                        } else {
                            this.whatsappList[response.data.accountId + '-whatsappList'] = parseData;
                            this.whatsappRoles[response.data.accountId + '-whatsappList'] = parseData.pageSpecificRoles;
                
                        }                        
                    }
                })
                .catch(error => console.log(error))
        },
        fetchWhatsappContent() {
            Nprogress.start()
            axios.get("/captive_portal/customize_whatsapp_content/true")
                .then(response => {
                    Nprogress.done()
                    var tData = {};
                    var parseData = response.data.message;
                    this.whatsappSampleFilePath = response.data.samplePath;
                    if (Object.keys(this.whatsappContentList).length == 0) {
                        tData[response.data.accountId + '-whatsappContentList'] = parseData;
                        this.whatsappContentList = tData;
                    } else {
                        this.whatsappContentList[response.data.accountId + '-whatsappContentList'] = parseData;
                        this.whatsappRoles[response.data.accountId + '-whatsappContentList'] = parseData.pageSpecificRoles;
                    }                    
                })
                .catch(error => console.log(error))
        },
        createWhatsappModalSubmit(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/captive_portal/save_default_operator_whatsapp', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var whatsappData = response.data.whatsappData;
                        var accountId = response.data.accountId;
                        if (Object.keys(this.whatsappList).length != 0 && this.whatsappList != 'noData') {
                            if (this.whatsappList != 'noData') {
                                var index = this.whatsappList[accountId + "-whatsappList"].gateways.findIndex(whatsapp => whatsapp.name == whatsappData.oldName);
                                if (index != '-1') {
                                    this.whatsappList[accountId + "-whatsappList"].gateways.splice(index, 1)
                                }
                                this.whatsappList[accountId + "-whatsappList"].gateways.push(whatsappData);
                            }
                        } else {
                            var dataSet = {}
                            dataSet[accountId + "-whatsappList"].gateways = whatsappData;
                            this.whatsappList = dataSet;
                        }                        
    
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        deleteWhatsappGatewaySubmit(authData) {
            return new Promise((resolve, reject) => {    
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.get('/customize_user_request_access/delete_whatsapp_gateway/' + authData.whatsappIndex)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var whatsappIds = response.data.whatsappGatewayName;
                        var accountId = response.data.accountId;
                        if (Object.keys(this.whatsappList).length != 0) {
                            var index = this.whatsappList[accountId + "-whatsappList"].gateways.findIndex(whatsapp => whatsapp.name == whatsappIds);
                            if (index != '-1') {
                                this.whatsappList[accountId + "-whatsappList"].gateways.splice(index, 1)
                            }
                        }                        
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        saveWhatsappSubAccSettings(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/captive_portal/save_sub_accounts_cannot_change_captiveportal_fields', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data.genericBody);
                    })
                    .catch(error => console.log(error));
            });
        },
        makeDefaultWhatsappGatewaySubmit(authData) {
            return new Promise((resolve, reject) => {    
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.get('/customize_user_request_access/make_default_whatsapp_gateway/' + authData.whatsappIndex)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        addWhatsappToAllSubZones(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/captive_portal/add_all_whatsapp_accounts', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data.genericBody);
                    })
                    .catch(error => console.log(error));
            });
        },
        saveWhatsappContent(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/captive_portal/save_whatsapp_contents/true', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var dataList = {
                            allData: postData
                        }
                        this.updateWhatsappContent(dataList)
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        addCustomWhatsappTemplate(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/captive_portal/save_custom_whatsapp_contents', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var dataList = {
                            whatsappContentData: response.data,
                            accountId: response.data.accountId
                        }
                        this.updateCustomWhatsappContentList(dataList)                        
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        exportWhatsappCsv(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/captive_portal/exportWhatsAppCsv', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        deleteCustomWhatsappTemplate(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/captive_portal/delete_custom_whatsapp_template', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }    
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        fetchPayGateDetails() {
            Nprogress.start()
            axios.get("/external_integration/payment_gateway_settings/true")
                .then(response => {
                    Nprogress.done()
                    if (response.data.status == "redirect") {
                        router.push(response.data.message);
                    } else {
                        var tData = {};
                        var parseData = response.data.message;
                        if (Object.keys(this.payGateList).length == 0) {
                            tData[response.data.accountId + '-payGateList'] = parseData;
                            this.payGateList = tData;
                        } else {
                            this.payGateList[response.data.accountId + '-payGateList'] = parseData;
                        }                        
                    }
                })
                .catch(error => console.log(error))
        },
        createPayGateModalSubmit(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/save_default_payment_gateway_operator', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var payGateData = response.data.payGateData;
                        var accountId = response.data.accountId;
                        if (Object.keys(this.payGateList).length != 0 && this.payGateList != 'noData') {
                            if (this.payGateList != 'noData') {
                                var index = this.payGateList[accountId + "-payGateList"].gateways.findIndex(payGate => payGate.name == payGateData.oldName);
                                if (index != '-1') {
                                    this.payGateList[accountId + "-payGateList"].gateways.splice(index, 1);
                                }
                                this.payGateList[accountId + "-payGateList"].gateways.push(payGateData);
                
                            }
                        } else {
                            var dataSet = {}
                            dataSet[accountId + "-payGateList"].gateways = payGateData;
                            this.payGateList = dataSet;
                        }                            
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        makeDefaultPayGateSubmit(authData) {
            return new Promise((resolve, reject) => {    
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.get('/external_integration/make_default_gateway/' + authData.payGateIndex)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        addPayGateToAllSubZones(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/add_all_payment_accounts', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data.genericBody);
                    })
                    .catch(error => console.log(error));
            });
        },
        savePayGateSubAccSettings(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/save_sub_accounts_cannot_change_paymentgateway_settings', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data.genericBody);
                    })
                    .catch(error => console.log(error));
            });
        },
        deletePayGateSubmit(authData) {
            return new Promise((resolve, reject) => {    
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.get('/external_integration/delete_payment_gateway/' + authData.payGateIndex)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var payGateIds = response.data.payGatewayName;
                        var accountId = response.data.accountId;                
                        if (Object.keys(this.payGateList).length != 0) {
                            var index = this.payGateList[accountId + "-payGateList"].gateways.findIndex(payGate => payGate.name == payGateIds);
                            if (index != '-1') {
                                this.payGateList[accountId + "-payGateList"].gateways.splice(index, 1)
                            }
                        }                        
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        fetchEmailDetails() {
            Nprogress.start()
            axios.get("/customize_user_request_access/customize_email_settings/true")
                .then(response => {
                    Nprogress.done()
                    if (response.data.status == "redirect") {
                        router.push(response.data.message);
                    } else {
                        var tData = {};
                        var parseData = response.data.message;
                        if (Object.keys(this.emailList).length == 0) {
                            tData[response.data.accountId + '-emailList'] = parseData;
                            this.emailList = tData;
                        } else {
                            this.emailList[response.data.accountId + '-emailList'] = parseData;
                        }                        
                    }
                })
                .catch(error => console.log(error))
        },
        addEmailToAllSubZones(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/captive_portal/add_all_email_accounts', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data.genericBody);
                    })
                    .catch(error => console.log(error));
            });
        },
        addInEmailToAllSubZones(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/captive_portal/add_inbound_email_accounts', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data.genericBody);
                    })
                    .catch(error => console.log(error));
            });
        },
        saveOutEmailSubAccSettings(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/captive_portal/save_sub_accounts_cannot_change_captiveportal_fields', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data.genericBody);
                    })
                    .catch(error => console.log(error));
            });
        },
        saveInEmailSubAccSettings(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/captive_portal/save_sub_accounts_cannot_change_captiveportal_fields', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data.genericBody);
                    })
                    .catch(error => console.log(error));
            });
        },
        deleteOutEmailSubmit(authData) {
            return new Promise((resolve, reject) => {    
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.get('/customize_user_request_access/delete_email_gateway/' + authData.outEmailIndex)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var outEmailIds = response.data.outEmailName;
                        var accountId = response.data.accountId;                
                        if (Object.keys(this.emailList).length != 0) {
                            var index = this.emailList[accountId + "-emailList"].gateways.findIndex(outEmail => outEmail.name == outEmailIds);
                            if (index != '-1') {
                                this.emailList[accountId + "-emailList"].gateways.splice(index, 1)
                            }
                        }                        
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        deleteInEmailSubmit(authData) {
            return new Promise((resolve, reject) => {    
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.get('/customize_user_request_access/delete_inbound_email_gateway/' + authData.inEmailIndex)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var inEmailIds = response.data.inEmailName;
                        var accountId = response.data.accountId;                
                        if (Object.keys(this.emailList).length != 0) {
                            var index = this.emailList[accountId + "-emailList"].inboundEmailGateways.findIndex(inEmail => inEmail.name == inEmailIds);
                            if (index != '-1') {
                                this.emailList[accountId + "-emailList"].inboundEmailGateways.splice(index, 1)
                            }
                        }                        
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        makeDefaultOutEmailSubmit(authData) {
            return new Promise((resolve, reject) => {    
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.get('/customize_user_request_access/make_default_email_gateway/' + authData.outEmailIndex)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                        var tData = {};
                        var parseData = response.data.emailContent.message;
                        tData[response.data.emailContent.accountId + '-emailContentList'] = parseData;
                        this.emailContentData = tData;                        
                    })
                    .catch(error => console.log(error));
            });
        },
        makeDefaultInEmailSubmit(authData) {
            return new Promise((resolve, reject) => {    
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.get('/customize_user_request_access/make_default_inbound_email_gateway/' + authData.inEmailIndex)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        createInEmailModalSubmit(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/captive_portal/save_inbound_email_operator', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var inEmailData = response.data.inEmailData;
                        var accountId = response.data.accountId;
                        if (Object.keys(this.emailList).length != 0 && this.emailList != 'noData') {
                            if (this.emailList != 'noData' ) {
                                if(this.emailList[accountId + "-emailList"].inboundEmailGateways.length != undefined){
                                var index = this.emailList[accountId + "-emailList"].inboundEmailGateways.findIndex(inEmail => inEmail.name == inEmailData.oldName);
                                if (index != '-1') {
                                    this.emailList[accountId + "-emailList"].inboundEmailGateways.splice(index, 1);
                                }
                                }
                                this.emailList[accountId + "-emailList"].inboundEmailGateways.push(inEmailData);
                
                            }
                        } else {
                            var dataSet = {}
                            dataSet[accountId + "-emailList"].inboundEmailGateways = inEmailData;
                            this.emailList = dataSet;
                        }                            
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        createOutEmailModalSubmit(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/captive_portal/save_default_email_operator', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var outEmailData = response.data.outEmailData;
                        var accountId = response.data.accountId;
                        if (Object.keys(this.emailList).length != 0 && this.emailList != 'noData') {
                            if (this.emailList != 'noData') {
                                var index = this.emailList[accountId + "-emailList"].gateways.findIndex(outEmail => outEmail.name == outEmailData.oldName);
                                if (index != '-1') {
                                    this.emailList[accountId + "-emailList"].gateways.splice(index, 1);
                                }
                                this.emailList[accountId + "-emailList"].gateways.push(outEmailData);
                
                            }
                        } else {
                            var dataSet = {}
                            dataSet[accountId + "-emailList"].gateways = outEmailData;
                            this.emailList = dataSet;
                        }                            
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        saveEmailContent(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/captive_portal/save_email_contents/true', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var dataList = {
                            allData: postData,
                            accountId: postData.accountId,
                            custom: postData.custom
                        }
                        this.updateEmailContent(dataList)
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        exportEmailCsv(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/captive_portal/exportemailCsv', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        addCustomEmailTemplate(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/captive_portal/save_custom_email_contents', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        fetchPmsDetails() {
            Nprogress.start()
            axios.get("/external_integration/pms/true")
                .then(response => {
                    Nprogress.done()
                    var tData = {};
                    var parseData = response.data.message;
                    if (Object.keys(this.pmsList).length == 0) {
                        tData['pmsList'] = parseData;
                        this.pmsList = tData;
                    } else {
                        this.pmsList['pmsList'] = parseData;
                    }                    
                })
                .catch(error => console.log(error))
        },
        createPmsModalSubmit(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/pms/true', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        testPmsModalSubmit(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/pms_test/true', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        fetchSocialNetworkDetails() {
            Nprogress.start()
            axios.get("/external_integration/index/true")
                .then(response => {
                    Nprogress.done()
                    var tData = {};
                    var parseData = response.data.message;
                    if (Object.keys(this.socialNetworkList).length == 0) {
                        tData[response.data.accountId + '-socialNetworkList'] = parseData;
                        this.socialNetworkList = tData;
                    } else {
                        this.socialNetworkList[response.data.accountId + '-socialNetworkList'] = parseData;
                    }                    
                })
                .catch(error => console.log(error))
        },
    
        createSocialNetworkModalSubmit(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/social_networks/true', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }    
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        fetchIvrDetails() {
            Nprogress.start()
            axios.get("/external_integration/customize_ivr")
                .then(response => {
                    Nprogress.done()
                    if (response.data.status == "redirect") {
                        router.push(response.data.message);
                    } else {
                        var tData = {};
                        var parseData = response.data.message;
                        if (Object.keys(this.ivrList).length == 0) {
                            tData[response.data.accountId + '-ivrList'] = parseData;
                            this.ivrList = tData;
                        } else {
                            this.ivrList[response.data.accountId + '-ivrList'] = parseData;
                        }                        
                    }
                })
                .catch(error => console.log(error))
        },
        createIvrModalSubmit(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/save_ivr', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var ivrData = response.data.ivrData;
                        var accountId = response.data.accountId;
                        if (Object.keys(this.ivrList).length != 0 && this.ivrList != 'noData') {
                            if (this.ivrList != 'noData') {
                                var index = this.ivrList[accountId + "-ivrList"].gateways.findIndex(ivr => ivr.name == ivrData.oldName);
                                if (index != '-1') {
                                    this.ivrList[accountId + "-ivrList"].gateways.splice(index, 1);
                                }
                                this.ivrList[accountId + "-ivrList"].gateways.push(ivrData);
                
                            }
                        } else {
                            var dataSet = {}
                            dataSet[accountId + "-ivrList"].gateways = ivrData;
                            this.ivrList = dataSet;
                        }                            
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        addIvrToAllSubZones(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/add_all_ivr_sub_accounts', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data.genericBody);
                    })
                    .catch(error => console.log(error));
            });
        },
        deleteIvrSubmit(authData) {
            return new Promise((resolve, reject) => {    
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.get('/external_integration/delete_ivr/' + authData.ivrIndex)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var ivrIds = response.data.ivrGatewayName;
                        var accountId = response.data.accountId;                
                        if (Object.keys(this.ivrList).length != 0) {
                            var index = this.ivrList[accountId + "-ivrList"].gateways.findIndex(ivr => ivr.name == ivrIds);
                            if (index != '-1') {
                                this.ivrList[accountId + "-ivrList"].gateways.splice(index, 1)
                            }
                        }                        
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        makeDefaultIvrSubmit(authData) {
            return new Promise((resolve, reject) => {    
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.get('/external_integration/make_default_ivr/' + authData.ivrIndex)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        saveSubAccIvrSettings(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/external_integration/save_sub_accounts_cannot_change_ivr_settings', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data.genericBody);
                    })
                    .catch(error => console.log(error));
            });
        },
        updateEmailContent(emailData) {
            var allData = emailData.allData;
            var accountId = emailData.accountId;
            var tempName = allData.templateName;
            var emailName = allData.emailName;
            var custTemp = allData.customDataobj;
            if (Object.keys(this.emailContentData).length != 0) {
                if (emailData.custom == 'no') {
                    if (this.emailContentData[accountId + "-emailContentList"].contentKeys[tempName].name == emailName) {
                        this.emailContentData[accountId + "-emailContentList"][tempName + "Values"].content = allData.content;
                        this.emailContentData[accountId + "-emailContentList"][tempName + "Values"].footer = allData.footer;
                        this.emailContentData[accountId + "-emailContentList"][tempName + "Values"].header = allData.header;
                        this.emailContentData[accountId + "-emailContentList"][tempName + "ContentDisable"] = !allData.disable;
                        if (allData.type == true) {
                            var type = 'html';
                        } else {
                            var type = 'text';
                        }
                        this.emailContentData[accountId + "-emailContentList"][tempName + "Values"].type = type;
                    }
                } else {
                    tempName = custTemp.name;
                    var index = this.emailContentData[accountId + "-emailContentList"].customTemplates.findIndex(data => data.name == tempName);
                    if (index != '-1') {
                        this.emailContentData[accountId + "-emailContentList"].customTemplates.splice(index, 1)
                    }
                    var custData = {};
                    custData.content = custTemp.summernoteContent;
                    if (custTemp.templateChecked == 1) {
                        var disable = true;
                    } else {
                        var disable = false;
                    }
                    custData.disable = disable;
                    custData.name = custTemp.name;
                    custData.templateName = allData.custName;
                    if (custTemp.htmlTemplatechecked == 1) {
                        var type = 'html';
                    } else {
                        var type = 'text';
                    }
                    custData.type = type;
                    this.emailContentData[accountId + "-emailContentList"].customTemplates.push(custData);
                    if (this.emailContentData[accountId + "-emailContentList"].customContentKeys[tempName].name == allData.custName) {
                        this.emailContentData[accountId + "-emailContentList"]["customTemplateDetails"][tempName + "Values"].content = custData.content;
                        this.emailContentData[accountId + "-emailContentList"]["customTemplateDetails"][tempName + "Values"].type = type;
                        this.emailContentData[accountId + "-emailContentList"]["customTemplateDetails"][tempName + "ContentDisable"] = !disable;
                    }
                }
            } else {
                var dataSet = {}
                dataSet[accountId + "-emailContentList"][tempName + "Values"].content = allData.content;
                dataSet[accountId + "-emailContentList"][tempName + "ContentDisable"] = allData.disable;
                if (allData.type == true) {
                    var type = 'html';
                } else {
                    var type = 'text';
                }
                dataSet[accountId + "-emailContentList"][tempName + "Values"].type = type;
                this.emailContentData = dataSet;
            }            
        },
        updateCustomWhatsappContentList(data) {
            var whatsappContentData = data.whatsappContentData.customTemplateDetails;
            var whatsappContentKeyData = data.whatsappContentData.customContentKeys;
            var accountId = data.accountId;
            var obj = {};
            var custData = {};
            if (Object.keys(this.whatsappContentList).length != 0 && this.whatsappContentList != 'noData') {
                if (this.whatsappContentList != 'noData') {
                    var accId = accountId + "-whatsappContentList";
                    this.whatsappContentList[accId]["customContentKeys"] = whatsappContentKeyData;
                    this.whatsappContentList[accId]["customTemplateDetails"] = whatsappContentData;
                    obj.id = data.whatsappContentData.whatsappContentData.name
                    obj.name = data.whatsappContentData.whatsappContentData.templateName;
                    this.whatsappContentList[accId].whatsappTemplates.push(obj);
                    custData.content = data.whatsappContentData.whatsappContentData.content.content;
                    custData.disable = data.whatsappContentData.whatsappContentData.disable;
                    custData.name = data.whatsappContentData.whatsappContentData.name
                    custData.templateName = data.whatsappContentData.whatsappContentData.templateName
                    this.whatsappContentList[accId].customTemplates.push(custData);
                }
            } else {
                var dataSet = {}
                dataSet[accountId + "-whatsappContentList"].customTemplateDetails = whatsappContentData;
                dataSet[accountId + "-whatsappContentList"].customContentKeys = whatsappContentKeyData;
                this.whatsappContentList = dataSet;
            }
        },
        updateWhatsappContent(whatsappData) {
            var allData = whatsappData.allData;
            var accountId = allData.accountId;
            var tempName = allData.templateName;
            var whatsappName = allData.whatsappName;
            if (Object.keys(this.whatsappContentList).length != 0) {
                if (tempName.substr(0, 6) == "custom") {
                    if (this.whatsappContentList[accountId + "-whatsappContentList"].customContentKeys[tempName].name == whatsappName) {
                        this.whatsappContentList[accountId + "-whatsappContentList"]["customTemplateDetails"][tempName + "Values"].content = allData.content;
                        this.whatsappContentList[accountId + "-whatsappContentList"]["customTemplateDetails"][tempName + "Values"].footer = allData.footer;
                        this.whatsappContentList[accountId + "-whatsappContentList"]["customTemplateDetails"][tempName + "Values"].bussinessName = allData.bussinessName;
                        this.whatsappContentList[accountId + "-whatsappContentList"]["customTemplateDetails"][tempName + "ContentDisable"] = allData.tempDis;
                    }
                } else {
                    if (this.whatsappContentList[accountId + "-whatsappContentList"].contentKeys[tempName].name == whatsappName) {
                        this.whatsappContentList[accountId + "-whatsappContentList"][tempName + "Values"].content = allData.content;
                        this.whatsappContentList[accountId + "-whatsappContentList"][tempName + "Values"].footer = allData.footer;
                        this.whatsappContentList[accountId + "-whatsappContentList"][tempName + "Values"].bussinessName = allData.bussinessName;
                        this.whatsappContentList[accountId + "-whatsappContentList"][tempName + "ContentDisable"] = allData.tempDis;
                    }
                }
            } else {
                var dataSet = {}
                dataSet[accountId + "-whatsappContentList"][tempName + "Values"].content = allData.content;
                dataSet[accountId + "-whatsappContentList"][tempName + "ContentDisable"] = allData.tempDis;
                this.whatsappContentList = dataSet;
            }
        },
        updateIptvContent(iptvData) {
            var allData = iptvData.allData;
            var accountId = allData.accountId;
            var tempName = allData.templateName;
            var iptvName = allData.iptvName;
            if (Object.keys(this.iptvContentList).length != 0) {
                if (this.iptvContentList[accountId + "-iptvContentList"].contentKeys[tempName].name == iptvName) {
                    this.iptvContentList[accountId + "-iptvContentList"][tempName + "Values"].content = allData.content;
                    this.iptvContentList[accountId + "-iptvContentList"][tempName + "Values"].footer = allData.footer;
                    this.iptvContentList[accountId + "-iptvContentList"][tempName + "ContentDisable"] = allData.tempDis;
                }
            } else {
                var dataSet = {}
                dataSet[accountId + "-iptvContentList"][tempName + "Values"].content = allData.content;
                dataSet[accountId + "-iptvContentList"][tempName + "ContentDisable"] = allData.tempDis;
                this.iptvContentList = dataSet;
            }            
        },
        updateIpphoneContent(ipPhoneData) {
            var allData = ipPhoneData.allData;
            var accountId = allData.accountId;
            var tempName = allData.templateName;
            var ipPhoneName = allData.ipPhoneName;
            if (Object.keys(this.ipPhoneContentList).length != 0) {
                if (this.ipPhoneContentList[accountId + "-ipPhoneContentList"].contentKeys[tempName].name == ipPhoneName) {
                    this.ipPhoneContentList[accountId + "-ipPhoneContentList"][tempName + "Values"].content = allData.content;
                    this.ipPhoneContentList[accountId + "-ipPhoneContentList"][tempName + "Values"].footer = allData.footer;
                    this.ipPhoneContentList[accountId + "-ipPhoneContentList"][tempName + "ContentDisable"] = allData.tempDis;
                }
            } else {
                var dataSet = {}
                dataSet[accountId + "-ipPhoneContentList"][tempName + "Values"].content = allData.content;
                dataSet[accountId + "-ipPhoneContentList"][tempName + "ContentDisable"] = allData.tempDis;
                this.ipPhoneContentList = dataSet;
            }            
        },
        updateCustomSmsContentList(data) {
            var smsContentData = data.smsContentData.customTemplateDetails;
            var smsContentKeyData = data.smsContentData.customContentKeys;
            var accountId = data.accountId;
            var obj = {};
            var custData = {};
            if (Object.keys(this.smsContentList).length != 0 && this.smsContentList != 'noData') {
                if (this.smsContentList != 'noData') {
                    var accId = accountId + "-smsContentList";
                    this.smsContentList[accId]["customContentKeys"] = smsContentKeyData;
                    this.smsContentList[accId]["customTemplateDetails"] = smsContentData;
                    obj.id = data.smsContentData.smsContentData.name
                    obj.name = data.smsContentData.smsContentData.templateName;
                    this.smsContentList[accId].smsTemplates.push(obj);
                    custData.content = data.smsContentData.smsContentData.content.content;
                    custData.disable = data.smsContentData.smsContentData.disable;
                    custData.name = data.smsContentData.smsContentData.name
                    custData.templateName = data.smsContentData.smsContentData.templateName
                    this.smsContentList[accId].customTemplates.push(custData);
                }
            } else {
                var dataSet = {}
                dataSet[accountId + "-smsContentList"].customTemplateDetails = smsContentData;
                dataSet[accountId + "-smsContentList"].customContentKeys = smsContentKeyData;
                this.smsContentList = dataSet;
            }            
        },
        updateSmsContent(smsData) {
            var allData = smsData.allData;
            var accountId = allData.accountId;
            var tempName = allData.templateName;
            var smsName = allData.smsName;
            if (Object.keys(this.smsContentList).length != 0) {
                if (tempName.substr(0, 6) == "custom") {
                    if (this.smsContentList[accountId + "-smsContentList"].customContentKeys[tempName].name == smsName) {
                        this.smsContentList[accountId + "-smsContentList"]["customTemplateDetails"][tempName + "Values"].content = allData.content;
                        this.smsContentList[accountId + "-smsContentList"]["customTemplateDetails"][tempName + "Values"].footer = allData.footer;
                        this.smsContentList[accountId + "-smsContentList"]["customTemplateDetails"][tempName + "Values"].templateRegistrationNumber = allData.templateRegistrationNumber;
                        this.smsContentList[accountId + "-smsContentList"]["customTemplateDetails"][tempName + "ContentDisable"] = allData.tempDis;
                    }
                } else {
                    if (this.smsContentList[accountId + "-smsContentList"].contentKeys[tempName].name == smsName) {
                        this.smsContentList[accountId + "-smsContentList"][tempName + "Values"].content = allData.content;
                        this.smsContentList[accountId + "-smsContentList"][tempName + "Values"].footer = allData.footer;
                        this.smsContentList[accountId + "-smsContentList"][tempName + "Values"].templateRegistrationNumber = allData.templateRegistrationNumber;
                        this.smsContentList[accountId + "-smsContentList"][tempName + "ContentDisable"] = allData.tempDis;
                    }
                }
            } else {
                var dataSet = {}
                dataSet[accountId + "-smsContentList"][tempName + "Values"].content = allData.content;
                dataSet[accountId + "-smsContentList"][tempName + "ContentDisable"] = allData.tempDis;
                this.smsContentList = dataSet;
            }            
        },
        fetchAcsDetails() {
            Nprogress.start()
            axios.get("/customize_user_request_access/customize_acs_gateway/true")
                .then(response => {
                    Nprogress.done()
                    if (response.data.status == "redirect") {

                        router.push(response.data.message);
                    } else {

                        var tData = {};
                        var parseData = response.data.message;
                        if (Object.keys(this.acsList).length == 0) {
                            tData[response.data.accountId + '-acsList'] = parseData;
                            this.acsList = tData;
                        } else {
                            Vue.set(this.acsList, response.data.accountId + '-acsList', parseData);                
                        }                        
                    }
                })
                .catch(error => console.log(error))
        },
        createAcsModalSubmit(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/captive_portal/save_default_operator_acs_gateway', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var acsData = response.data.acsData;
                        var accountId = response.data.accountId;
                        if (Object.keys(this.acsList).length != 0 && this.acsList != 'noData') {
                            if (this.acsList != 'noData') {
                                var index = this.acsList[accountId + "-acsList"].gateways.findIndex(acs => acs.name == acsData.oldName);
                                if (index != '-1') {
                                    this.acsList[accountId + "-acsList"].gateways.splice(index, 1)
                                }
                                this.acsList[accountId + "-acsList"].gateways.push(acsData);
                            }
                        } else {
                            var dataSet = {}
                            dataSet[accountId + "-acsList"].gateways = acsData;
                            this.acsList = dataSet;
                        }                        
    
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        deleteAcsGatewaySubmit(authData) {
            return new Promise((resolve, reject) => {    
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.get('/customize_user_request_access/delete_acs_gateway/' + authData.acsIndex)
                    .then(response => {
                        JsLoadingOverlay.hide()
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        var acsIds = response.data.acsGatewayName;
                        var accountId = response.data.accountId;
                        if (Object.keys(this.acsList).length != 0) {
                            var index = this.acsList[accountId + "-acsList"].gateways.findIndex(acs => acs.name == acsIds);
                            if (index != '-1') {
                                this.acsList[accountId + "-acsList"].gateways.splice(index, 1)
                            }
                        }                        
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        makeDefaultAcsGatewaySubmit(authData) {
            return new Promise((resolve, reject) => {    
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.get('/customize_user_request_access/make_default_acs_gateway/' + authData.acsIndex)
                    .then(response => {
                        JsLoadingOverlay.hide()
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        saveAcsSubAccSettings(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/captive_portal/save_acs_sub_accounts_cannot_change_captiveportal_fields', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide()
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data.genericBody);
                    })
                    .catch(error => console.log(error));
            });
        },
        addAcsToAllSubZones(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/captive_portal/add_all_acs_gateway', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide()
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data.genericBody);
                    })
                    .catch(error => console.log(error));
            });
        },
    }
})
<template>
  <div class="jazeMainPanel" style="height: 100%;">
    <div class="jazePanelContent jazePanelContent--scrollable">
      <div class="unifiSettingsContainer">
        <form ref="adminWithAccessForm" class="appForm appForm--cozy is-validation-hidden">
          <div class="unifiSettingsHeader">
            <div class="unifiSettingsHeader__title">Admins with Access</div>
          </div>
          <fieldset class="appFieldset appFieldset--quiet">
            <div class="unifiSettingsSection">
              <div v>
                <table class="jazeTable jazeTable--hoverable mt-1 is-not-responsive">
                  <thead>
                    <tr>
                      <th>
                        <span>Admin name</span>
                      </th>
                      <th>
                        <span>Full name</span>
                      </th>
                      <th>
                        <span>Type</span>
                      </th>
                      <th>
                        <span>Email</span>
                      </th>
                      <th>
                        <span>Phone</span>
                      </th>
                      <th>
                        <span>Zone</span>
                      </th>
                      <th></th>
                      <th class="actions jazeTableCell--withActions jazeTableCell--smash"></th>
                    </tr>
                  </thead>
                  <tbody class="jazeTableBody">
                    <tr v-if="adminWithAccessDetails!=undefined && adminWithAccessDetails.accessibleAdmins!=undefined" v-for="(accessibleAdmin,index) in adminWithAccessDetails.accessibleAdmins">
                       <td>
                         <span class='jazeLink'>
                         <u>
                         <a v-bind:href="adminUrl[index]">
                          {{accessibleAdmin.Admin.username}}
                         </a>
                         </u>
                         </span>
                      </td>
                      <td>
                        <span>{{accessibleAdmin.Admin.name}}</span>
                      </td>
                      <td>
                        <span>{{accessibleAdmin.AdminGroup.name}}</span>
                      </td>
                      <td>
                        <span>{{accessibleAdmin.Admin.email}}</span>
                      </td>
                      <td>
                        <span>{{accessibleAdmin.Admin.phone}}</span>
                      </td>
                      <td>
                        <span>{{accessibleAdmin.Account.company_name}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref,computed,watchEffect,onMounted,onBeforeUnmount } from 'vue'

import { storeToRefs } from "pinia";
import { usesettingsStore } from '@/store/settingsstore'
export default {
  setup() {
    const adminUrl = ref( [] )
    // const adminWithAccessDetails = ref( [] )

    const settingsStore = usesettingsStore()
    const { adminWithAccessDetails } = storeToRefs(settingsStore)
    const adminWithAccessGetter = computed(() => adminWithAccessDetails.value);

    onMounted(() => {
      getAdminWithAccessData();
    })
    onBeforeUnmount(() => {
      settingsStore.clearSettingsState();
    })

    watchEffect(() => {
      adminWithAccessDetails.value = adminWithAccessGetter.value;
      adminUrl.value=[];
      if(adminWithAccessDetails.value.accessibleAdmins!= undefined&& adminWithAccessDetails.value.accessibleAdmins.length>0){
      for(var i =0;i<adminWithAccessDetails.value.accessibleAdmins.length;i++){
        var link = "/account_settings/"+adminWithAccessDetails.value.accessibleAdmins[i].Admin.id+"/"+adminWithAccessDetails.value.accessibleAdmins[i].Admin.account_id;
        adminUrl.value.push(link);
      }
      }
    })

    const getAdminWithAccessData = () => {
      if (Object.keys(adminWithAccessGetter.value).length == 0) {
        // $store.dispatch("fetchAdminWithAccessData");
        settingsStore.fetchAdminWithAccessData();
      } else {
        adminWithAccessDetails.value = adminWithAccessGetter.value;
      }
    }

    return { 
      adminUrl,
      adminWithAccessDetails,
      settingsStore,
      adminWithAccessGetter: adminWithAccessDetails,
      getAdminWithAccessData,
    }
  },
}
</script>

<template>
  <div class="jazeToastContainer jazeToastContainer--topRight"  v-show='sucesstoasterShow'>
    <div class="jazeToast">
      <div class="jazeToast__image">
        <div class="jazeIconContainer jazeIconContainer--round jazeIconContainer--primary">
          <div class="unifiAuraIcon">
            <svg viewBox="0 0 16 11" class="jazeIcon">
              <g transform="translate(-23.000000, -23.000000)" fill="currentColor">
                <g transform="translate(15.000000, 12.000000)">
                  <path d="M9.77817459,16.7781746 L22.7781746,16.7781746 L22.7781746,17.7781746 L9.77817459,17.7781746 L8.77817459,17.7781746 L8.77817459,9.77817459 L9.77817459,9.77817459 L9.77817459,16.7781746 Z" transform="translate(15.778175, 13.778175) rotate(-45.000000) translate(-15.778175, -13.778175) "></path>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
      <div class="jazeToast__close">
        <div class="jazeIcon jazeIcon--actionable jazeIcon--transparent iconText__icon jaze-icon--x-circle" onclick="document.getElementById('successToaster').style.display = 'none'"></div>
      </div>
      <div class="jazeToast__body">
        <div v-if="htmlContent == true" class="jazeToast__details" v-html="successMessage"></div>
        <div v-else class="jazeToast__details" v-html="successMessage"> </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['sucesstoasterShow',"successMessage","htmlContent"],
}
</script>
<template>
  <div class="jazeMainPanel" style="height: 100%;">
    <div class="jazePanelContent jazePanelContent--scrollable">
      <div class="unifiSettingsContainer">
        <form ref="leadSettingsForm" id="leadSettingsForm" class="appForm appForm--cozy is-validation-hidden" :class="formClass">
          <div class="unifiSettingsHeader">
            <div class="unifiSettingsHeader__title">Lead Settings</div>
          </div>
          <fieldset class="appFieldset appFieldset--quiet">
            <div class="jazeAlert jazeAlert--danger row col-12" v-show='errorShow'>
              <div class="col-6">
                <strong>{{errorMessage}}</strong>
              </div>
            </div>
            <div class="unifiSettingsSection">
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Allow initial payment through link? </label>
                </div>
                <div class="col--sm3 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="allowLeadInitialPayment" :checked="leadSettingsData!=undefined&&leadSettingsData.allowLeadInitialPayment == 'yes' ? true : false" @click="toggleAllDivs($event)">
                </div>
              </div>
              <div v-if="initialPaymentShow">
                <div class="appRow">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Security Deposit </label>
                  </div>
                  <div class="col--sm3 appFormGroup appFormGroup--cozy">
                    <input type="text" class="input--full" name="leadSecurityDeposit" v-model="leadSecurityDeposit">
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Enable Plan Amount </label>
                  </div>
                  <div class="col--sm3 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="enableInstallationCharges" :checked="leadSettingsData.enableInstallationCharges == 'yes' ? true : false" @click="toggleinstallation($event)">
                  </div>
                </div>

                <div v-if="installChargesShow">
                    <div class="appRow">
                      <div class="col--sm3">
                        <label class="appLabel appLabel--primary appLabel--boxAlign"> Plan Rate </label>
                      </div>
                      <div class="col--sm3 appFormGroup appFormGroup--cozy">
                        <input type="text" class="input--full" name="leadPlanAndInstallationAmount" v-model="leadPlanAndInstallationAmount">
                      </div>
                    </div>
                </div>
                <div class="appRow">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Enable IPTV Plan Amount </label>
                  </div>
                  <div class="col--sm3 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="enableIptvPlanAmount" :checked="leadSettingsData.enableIptvPlanAmount == 'yes' ? true : false" @click="toggleIptvPlanAmount($event)">
                  </div>
                </div>

                <div v-if="iptvPlanDivShow">
                    <div class="appRow">
                      <div class="col--sm3">
                        <label class="appLabel appLabel--primary appLabel--boxAlign"> IPTV Plan Rate </label>
                      </div>
                      <div class="col--sm3 appFormGroup appFormGroup--cozy">
                        <input type="text" class="input--full" name="leadIptvPlanRate" v-model="leadIptvPlanRate">
                      </div>
                    </div>
                </div>
                <div class="appRow">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Enable Voice Plan Amount </label>
                  </div>
                  <div class="col--sm3 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="enableVoicePlanAmount" :checked="leadSettingsData.enableVoicePlanAmount == 'yes' ? true : false" @click="toggleVoicePlanAmount($event)">
                  </div>
                </div>

                <div v-if="voicePlanDivShow">
                    <div class="appRow">
                      <div class="col--sm3">
                        <label class="appLabel appLabel--primary appLabel--boxAlign"> Voice Plan Rate </label>
                      </div>
                      <div class="col--sm3 appFormGroup appFormGroup--cozy">
                        <input type="text" class="input--full" name="leadVoicePlanRate" v-model="leadVoicePlanRate">
                      </div>
                    </div>
                </div>
                <div class="appRow">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Enable OTT Plan Amount </label>
                  </div>
                  <div class="col--sm3 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="enableOttPlanAmount" :checked="leadSettingsData.enableOttPlanAmount == 'yes' ? true : false" @click="toggleOttPlanAmount($event)">
                  </div>
                </div>

                <div v-if="ottPlanDivShow">
                    <div class="appRow">
                      <div class="col--sm3">
                        <label class="appLabel appLabel--primary appLabel--boxAlign"> OTT Plan Rate </label>
                      </div>
                      <div class="col--sm3 appFormGroup appFormGroup--cozy">
                        <input type="text" class="input--full" name="leadOttPlanRate" v-model="leadOttPlanRate">
                      </div>
                    </div>
                </div>

                  <div class="appRow">
                    <div class="col--sm3">
                      <label class="appLabel appLabel--primary appLabel--boxAlign">Enable Installation Charges </label>
                    </div>
                    <div class="col--sm3 appFormGroup appFormGroup--cozy">
                      <input type="checkbox" name="enableInstallation" :checked="leadSettingsData.enableInstallation == 'yes' ? true : false" @click="toggleinstallation1($event)">
                    </div>
                  </div>

                  <div v-if="installChargesShow1">
                    <div class="appRow">
                      <div class="col--sm3">
                        <label class="appLabel appLabel--primary appLabel--boxAlign"> Installation Charges </label>
                      </div>
                      <div class="col--sm3 appFormGroup appFormGroup--cozy">
                        <input type="text" class="input--full" name="installationAmount" v-model="installationAmount">
                      </div>
                    </div>
                  </div>

                  <div class="appRow">
                    <div class="col--sm3">
                      <label class="appLabel appLabel--primary appLabel--boxAlign">Enable Router Charges </label>
                    </div>
                    <div class="col--sm3 appFormGroup appFormGroup--cozy">
                      <input type="checkbox" name="enableRouterCharges" :checked="leadSettingsData.enableRouterCharges == 'yes' ? true : false" @click="toggleinstallation2($event)">
                    </div>
                  </div>

                  <div v-if="installChargesShow2">
                    <div class="appRow">
                      <div class="col--sm3">
                        <label class="appLabel appLabel--primary appLabel--boxAlign"> Router Charges </label>
                      </div>
                      <div class="col--sm3 appFormGroup appFormGroup--cozy">
                        <input type="text" class="input--full" name="routerAmount" v-model="routerAmount">
                      </div>
                    </div>
                  </div>

                <div class="appRow">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Force to pay full amount </label>
                  </div>
                  <div class="col--sm3 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="forceToPayFullAmount" :checked="leadSettingsData.forceToPayFullAmount == 'yes' ? true : false">
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Enable initial payment mandatory? </label>
                  </div>
                  <div class="col--sm3 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="leadInitialPaymentMandatory" :checked="leadSettingsData.leadInitialPaymentMandatory == 'yes' ? true : false">
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Enable transfer in CAF Approval</label>
                  </div>
                  <div class="col--sm3 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="enableTransferINCAFCollection" :checked="leadSettingsData.enableTransferINCAFCollection == 'yes' ? true : false">
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Allow stage selection for broadband page</label>
                  </div>
                  <div class="col--sm3 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" @click="toggleCustomButton($event)" name="allowStageSelectionInBroadband" :checked="leadSettingsData.allowStageSelectionInBroadband == 'yes' ? true : false">
                  </div>
                </div>
                <div class="appRow" v-if="showBroadband==true">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Select stage </label>
                  </div>
                  <div class="col--sm3 appFormGroup appFormGroup--cozy">
                    <select name="selectStageForBroadband" v-model="broadbandStage">
                      <option value="addLead"> Add Lead </option>
                      <option value="feasibilityCheck"> Feasibility Check</option>
                      <option value="transferLead"> Transfer Lead </option>
                      <option value="CAFCollection"> CAF Collection </option>
                      <option value="CAFApproval"> CAF Approval </option>
                      <option value="cablingAndCommissioning"> Cabling And Commissioning </option>
                      <option value="splicing"> Splicing </option>
                      <option value="activation"> Activation </option>
                    </select>
                  </div>
                </div>
                 <div class="appRow">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Allow stage selection for Enquiry page</label>
                  </div>
                  <div class="col--sm3 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" @click="toggleCustomButtonEnquiry($event)" name="allowStageSelectionInEnquiry" :checked="leadSettingsData.allowStageSelectionInEnquiry == 'yes' ? true : false">
                  </div>
                </div>
                <div class="appRow" v-if="showEnquiry==true">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Select stage </label>
                  </div>
                  <div class="col--sm3 appFormGroup appFormGroup--cozy">
                    <select name="selectStageForEnquiry" v-model="enquiryStage">
                      <option value="addLead"> Add Lead </option>
                      <option value="feasibilityCheck"> Feasibility Check</option>
                      <option value="transferLead"> Transfer Lead </option>
                      <option value="CAFCollection"> CAF Collection </option>
                      <option value="CAFApproval"> CAF Approval </option>
                      <option value="cablingAndCommissioning"> Cabling And Commissioning </option>
                      <option value="splicing"> Splicing </option>
                      <option value="activation"> Activation </option>
                    </select>
                  </div>
                </div>
                <div class="appRow" v-if="leadSettingsData.underFranchise != undefined && leadSettingsData.underFranchise != 'true'">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Enable Override Price</label>
                  </div>
                  <div class="col--sm3 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="enableOverridePriceOnLead" :checked="leadSettingsData.enableOverridePriceOnLead != undefined && leadSettingsData.enableOverridePriceOnLead == 'yes' ? true : false">
                  </div>
                </div>
                <div class="appRow" v-if="leadSettingsData.underFranchise != undefined && leadSettingsData.underFranchise != 'true'">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Enable IPTV Override Price</label>
                  </div>
                  <div class="col--sm3 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="enableIptvOverridePriceOnLead" :checked="leadSettingsData.enableIptvOverridePriceOnLead != undefined && leadSettingsData.enableIptvOverridePriceOnLead == 'yes' ? true : false">
                  </div>
                </div>
                <div class="appRow" v-if="leadSettingsData.underFranchise != undefined && leadSettingsData.underFranchise != 'true'">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Enable Voice Override Price</label>
                  </div>
                  <div class="col--sm3 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="enableVoiceOverridePriceOnLead" :checked="leadSettingsData.enableVoiceOverridePriceOnLead != undefined && leadSettingsData.enableVoiceOverridePriceOnLead == 'yes' ? true : false">
                  </div>
                </div>
                <div class="appRow" v-if="leadSettingsData.underFranchise != undefined && leadSettingsData.underFranchise != 'true'">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Enable OTT Override Price</label>
                  </div>
                  <div class="col--sm3 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="enableOttOverridePriceOnLead" :checked="leadSettingsData.enableOttOverridePriceOnLead != undefined && leadSettingsData.enableOttOverridePriceOnLead == 'yes' ? true : false">
                  </div>
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Enable OTP verification on Enquiry Form </label>
                </div>
                <div class="col--sm3 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="enableOTPWhileEnquiry" :checked="leadSettingsData.enableOTPWhileEnquiry!=undefined && leadSettingsData.enableOTPWhileEnquiry == 'yes' ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Enable OTP verification in Broadband Operator login </label>
                </div>
                <div class="col--sm3 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="enableOTPWhileBroadbandOperator" :checked="leadSettingsData.enableOTPWhileBroadbandOperator!=undefined && leadSettingsData.enableOTPWhileBroadbandOperator == 'yes' ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Apply Discount ? </label>
                </div>
                <div class="col--sm3 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="enableDiscountOnLead" :checked="leadSettingsData.enableDiscountOnLead!=undefined && leadSettingsData.enableDiscountOnLead == 'yes' ? true : false">
                </div>
              </div> 
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Do not change group/plan after payment </label>
                </div>
                <div class="col--sm3 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="doNotChangePlanAfterPayment" :checked="leadSettingsData.doNotChangePlanAfterPayment!=undefined && leadSettingsData.doNotChangePlanAfterPayment == 'yes' ? true : false">
                </div>
              </div>  
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Edit Security Deposit on initial payment details (in broadband) ?</label>
                </div>
                <div class="col--sm3 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="broadbandSecurityDepositEditable" :checked="leadSettingsData.broadbandSecurityDepositEditable!=undefined && leadSettingsData.broadbandSecurityDepositEditable == 'yes' ? true : false">
                </div>
              </div>  
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Edit Installation Charges on initial payment details (in broadband) ?</label>
                </div>
                <div class="col--sm3 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="broadbandInstallationChargesEditable" :checked="leadSettingsData.broadbandInstallationChargesEditable!=undefined && leadSettingsData.broadbandInstallationChargesEditable == 'yes' ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Edit Router Charges on initial payment details (in broadband) ?</label>
                </div>
                <div class="col--sm3 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="broadbandRouterChargesEditable" :checked="leadSettingsData.broadbandRouterChargesEditable!=undefined && leadSettingsData.broadbandRouterChargesEditable == 'yes' ? true : false">
                </div>
              </div>
              <div class="appRow">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Exclude Mobile Number Uniqueness</label>
                  </div>
                  <div class="col--sm3 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="excludeMobileNumberUniq" :checked="leadSettingsData.excludeMobileNumberUniq == 'yes' ? true : false">
                  </div>
                </div>  
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Source Field Mandatory ?</label>
                </div>
                <div class="col--sm3 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="leadSourceMandatory" :checked="leadSettingsData.leadSourceMandatory == 'yes' ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Inventory Allocation Mandatory ?</label>
                </div>
                <div class="col--sm3 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="leadInventoryMandatory" :checked="leadSettingsData.leadInventoryMandatory == 'yes' ? true : false">
                </div>
              </div>           
            </div>
          </fieldset>
        </form>
        <div class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark">
          <div class="appFormFooter__right" v-if="leadSettingsData.visible == 'visible'">
            <button class="appMainButton busyToggle" type="button" @click="subZoneModalShow=true" v-if="leadSettingsData.edition == 'isp'"> SubZones Restrictions</button>
            <button class="appMainButton busyToggle" type="button" @click="subZoneModalShow=true" v-else> SubAccounts Restrictions</button>

            <button class="appMainButton busyToggle" type="button" @click="pushZonefn()" v-if="leadSettingsData.edition == 'isp'"> Add to All SubZones</button>
            <button class="appMainButton busyToggle" type="button" @click="pushZonefn()" v-else> Add to All SubAccounts</button>

            <button class="appMainButton appMainButton--primary busyToggle" type="submit" @click="saveLeadSettings($event)"> Save</button>
          </div>
        </div>
      </div>
    </div>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="subZoneModalShow">
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form class="jazeForm jazeModal jazeModal--small unifiAddUser">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title"> Save </div>
                  <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="subZoneModalShow=false">
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeModal__main">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                    <div class="row">
                      <div class="col jazeFormGroup jazeFormGroup--flex">
                        <label class="jazeLabel jazeLabel--primary" v-if="leadSettingsData.edition =='isp'">Allow/Deny sub-zones to change Lead settings</label>
                        <label class="jazeLabel jazeLabel--primary" v-else>Allow/Deny sub-accounts to change Lead settings</label> &emsp;
                        <div class="toggle appToggle appToggle--light" data-on="On" data-off="Off">
                          <input type="checkbox" v-model="switchcheckboxLead" id="switchcheckboxLead" :checked="leadSettingsData.subAccountsCanNotChangeLeadSettings == 'Yes' ? true : false">
                          <label for="switchcheckboxLead"></label>
                        </div>
                      </div>
                    </div>
                    <div class="appType--micro appType--quiet italicized" v-if="leadSettingsData.edition =='isp'">
                      Note: If it is 'ON', sub-zones can not change Lead settings.
                    </div>
                    <div class="appType--micro appType--quiet italicized" v-else>
                      Note: If it is 'ON', sub-accounts can not change Lead settings.
                    </div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--secondary" type="button" @click="subZoneModalShow=false">Cancel</button>
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="allowSubZones">Yes</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
    <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
    <PushToSubZone v-if="pushModal" :selected='selected' :edition="leadSettingsData.edition" @extend-clicked-push-detail="onclickclose" :subAccounts="subaccDetailsGetter" :from="'leadSetting'" :modalType="'selectedType'" :dataSetting="dataSetting"></PushToSubZone>

  </div>
</template>

<script>
import { ref,computed,watch,watchEffect,onMounted,onBeforeUnmount } from 'vue'
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import PushToSubZone from "@/components/Profiles/PushToSubZoneModal.vue";
import Treeselect from "jaze-vue3-treeselect";

import { storeToRefs } from "pinia";
import { usesettingsStore } from '@/store/settingsstore'
import { uselayoutStore } from '@/store/layoutstore'
export default {
  setup() {
    const layoutStore = uselayoutStore()
    const { subaccDetails } = storeToRefs(layoutStore)
    const subaccDetailsGetter= computed(() => subaccDetails.value);
    const enquiryStage = ref("addLead")
    const showEnquiry = ref(false)
    const broadbandStage = ref("addLead")
    const optionaAllSelect = ref("all")
    const showBroadband = ref(false)
    // const leadSettingsData = ref([])
    const formClass = ref("")
    const errorShow = ref(false)
    const errorMessage = ref("")
    const successtoaster = ref(false)
    const successMessage = ref("")
    const errortoaster = ref(false)
    const initialPaymentShow = ref(false)
    const leadSecurityDeposit = ref("")
    const installChargesShow = ref(false)
    const installChargesShow1 = ref(false)
    const installChargesShow2 = ref(false)
    const installChargesShow3 = ref(false)
    const leadPlanAndInstallationAmount = ref("")
    const installationAmount = ref("")
    const routerAmount = ref("")
    const subZoneModalShow = ref(false)
    const selectedAccVal = ref(null)
    const subAccOptions = ref([
            {
              id: "selectAll",
              label: "Select All",
              children: [],
            },
          ])
    const switchcheckboxLead = ref(false)
    const dataSetting = ref([{
              id: "selectAll",
              label: "Select All",
              children: []
            }])
    const iptvPlanDivShow = ref(false)
    const voicePlanDivShow = ref(false)
    const ottPlanDivShow = ref(false)
    const leadIptvPlanRate = ref(0)
    const leadVoicePlanRate = ref(0)
    const leadOttPlanRate = ref(0)
    const pushModal = ref(false)
    const selected = ref([])
    // const subAccounts = ref([])

    const settingsStore = usesettingsStore()
    const { leadSettingsData } = storeToRefs(settingsStore)
    const leadSettingsGetter = computed(() => leadSettingsData.value)

    onMounted(() => {
      getLeadDetails();
    })
    onBeforeUnmount(() => {
      settingsStore.clearSettingsState();
    })

    watchEffect(() => {
      leadSettingsData.value = leadSettingsGetter.value;
      if (leadSettingsData.value.allowStageSelectionInBroadband == "yes") {
        showBroadband.value = true;
      }
      if (leadSettingsData.value.allowStageSelectionInEnquiry == "yes") {
        showEnquiry.value = true;
      }
      broadbandStage.value = leadSettingsData.value.selectStageForBroadband;
      enquiryStage.value = leadSettingsData.value.selectStageForEnquiry;
      if (leadSettingsData.value.allowLeadInitialPayment == "yes") {
        initialPaymentShow.value = true;
      }
      if (leadSettingsData.value.enableInstallationCharges == "yes") {
        installChargesShow.value = true;
      }
      if (leadSettingsData.value.enableInstallation == "yes") {
        installChargesShow1.value = true;
      }
      if (leadSettingsData.value.enableRouterCharges == "yes") {
        installChargesShow2.value = true;
      }
      if (leadSettingsData.value.enableIptvPlanAmount == "yes") {
        iptvPlanDivShow.value = true;
      }
      if (leadSettingsData.value.enableVoicePlanAmount == "yes") {
        voicePlanDivShow.value = true;
      }
      if (leadSettingsData.value.enableOttPlanAmount == "yes") {
        ottPlanDivShow.value = true;
      }
      leadSecurityDeposit.value = leadSettingsData.value.leadSecurityDeposit;
      leadPlanAndInstallationAmount.value =
        leadSettingsData.value.leadPlanAndInstallationAmount;
      leadIptvPlanRate.value =
        leadSettingsData.value.leadIptvPlanRate;
      leadVoicePlanRate.value =
        leadSettingsData.value.leadVoicePlanRate;
      leadOttPlanRate.value =
        leadSettingsData.value.leadOttPlanRate;
      installationAmount.value = leadSettingsData.value.installationAmount;
      routerAmount.value = leadSettingsData.value.routerAmount;
      if (
        leadSettingsData.value.dataSetting != undefined &&
        leadSettingsData.value.dataSetting.length != 0
      ) {
        for (let i = 0; i < leadSettingsData.value.dataSetting.length; i++) {
          // var deactivated = leadSettingsData.value.dataSetting[i].deactivated == 0 ? " (Deactivated)" : "";
          dataSetting.value[0].children.push({
            id: leadSettingsData.value.dataSetting[i].id,
            label: leadSettingsData.value.dataSetting[i].label,
          });
        }
      }
      if (
        leadSettingsData.value.accountsArr != undefined &&
        leadSettingsData.value.accountsArr.length != 0
      ) {
        for (let i = 0; i < leadSettingsData.value.accountsArr.length; i++) {
          var deactivated = leadSettingsData.value.accountsArr[i].deactivated == 0 ? " (Deactivated)" : "";
          subAccOptions.value[0].children.push({
            id: leadSettingsData.value.accountsArr[i].id,
            label: leadSettingsData.value.accountsArr[i].name + deactivated,
          });
        }
      }
      if (leadSettingsData.value.subAccountsCanNotChangeLeadSettings == "Yes") {
        switchcheckboxLead.value = true;
      }
    })

    const getLeadDetails = () => {
      // $store.dispatch("fetchLeadSettingDetails");
      settingsStore.fetchLeadSettingDetails();
      console.log("leadSettingsData.value.selectStageForEnquiry:", leadSettingsData.value.selectStageForEnquiry);
    }
    const toggleAllDivs = (event) => {
      if (event.target.checked == true) {
        initialPaymentShow.value = true;
      } else {
        initialPaymentShow.value = false;
      }
    }
    const toggleinstallation = (event) => {
      if (event.target.checked == true) {
        installChargesShow.value = true;
      } else {
        installChargesShow.value = false;
      }
    }
    const toggleinstallation1 = (event) => {
      if (event.target.checked == true) {
        installChargesShow1.value = true;
      } else {
        installChargesShow1.value = false;
      }
    }
    const toggleinstallation2 = (event) => {
      if (event.target.checked == true) {
        installChargesShow2.value = true;
      } else {
        installChargesShow2.value= false;
      }
    }
    const saveLeadSettings = (e) => {
      e.preventDefault();
      successtoaster.value = false;
      errortoaster.value = false;
      var form = document.getElementById('leadSettingsForm');
      const formData = new FormData(form);
      const postData = {};
      for (let [key, val] of formData.entries()) {
        Object.assign(postData, { [key]: val });
      }
      // $store.dispatch("leadSettingsSubmit", postData).then(
      settingsStore.leadSettingsSubmit(postData).then(  
        (response) => {
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
    }
    const allowSubZones = () => {
      successtoaster.value = false;
      errortoaster.value = false;
      if (switchcheckboxLead.value == true) {
        var check = "on";
      } else {
        var check = "off";
      }
      var data = {
        switchCheckBoxValue: check,
        attribute: "subAccountsCanNotChangeLeadSettings",
        type: "lead",
      };
      // $store.dispatch("saveSubAccountPermissionSettings", data).then(
      settingsStore.saveSubAccountPermissionSettings(data).then(  
        (response) => {
          subZoneModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
    }
    const toggleCustomButton = (event) => {
      if (event.target.checked == true) {
        showBroadband.value = true;
      } else {
        showBroadband.value = false;
      }
    }
    const toggleCustomButtonEnquiry = (event) => {
      if (event.target.checked == true) {
        showEnquiry.value = true;
      } else {
        showEnquiry.value = false;
      }
    }
    const toggleIptvPlanAmount = (event) => {
      if (event.target.checked == true) {
        iptvPlanDivShow.value = true;
      } else {
        iptvPlanDivShow.value = false;
      }
    }
    const toggleVoicePlanAmount = (event) => {
      if (event.target.checked == true) {
        voicePlanDivShow.value = true;
      } else {
        voicePlanDivShow.value = false;
      }
    }
    const toggleOttPlanAmount = (event) => {
      if (event.target.checked == true) {
        ottPlanDivShow.value = true;
      } else {
        ottPlanDivShow.value = false;
      }
    }
    const onclickclose = (value) => {
      pushModal.value = value;
    }
    const pushZonefn = () => {
      pushModal.value = !pushModal.value;
    }

    return {
      settingsStore,
      leadSettingsGetter: leadSettingsData,
      subaccDetailsGetter,
      enquiryStage,
      showEnquiry,
      broadbandStage,
      optionaAllSelect,
      showBroadband,
      leadSettingsData,
      formClass,
      errorShow,
      errorMessage,
      successtoaster,
      successMessage,
      errortoaster,
      initialPaymentShow,
      leadSecurityDeposit,
      installChargesShow,
      installChargesShow1,
      installChargesShow2,
      installChargesShow3,
      leadPlanAndInstallationAmount,
      installationAmount,
      routerAmount,
      subZoneModalShow,
      selectedAccVal,
      subAccOptions,
      switchcheckboxLead,
      dataSetting,
      iptvPlanDivShow,
      voicePlanDivShow,
      ottPlanDivShow,
      leadIptvPlanRate,
      leadVoicePlanRate,
      leadOttPlanRate,
      pushModal,
      selected,
      // subAccounts,
      getLeadDetails,
      toggleAllDivs,
      toggleinstallation,
      toggleinstallation1,
      toggleinstallation2,
      saveLeadSettings,
      allowSubZones,
      toggleCustomButton,
      toggleCustomButtonEnquiry,
      toggleIptvPlanAmount,
      toggleVoicePlanAmount,
      toggleOttPlanAmount,
      onclickclose,
      pushZonefn,
    }
  },
  components: {
    SuccessToaster,
    ErrorToaster,
    PushToSubZone,
    Treeselect,
  },
};
</script>

<template>
  <div class="jazeMainPanel" style="height: 100%;">
    <div class="jazePanelContent jazePanelContent--scrollable">
      <div class="unifiSettingsContainer">
        <form ref="moveZoneForm" class="appForm appForm--cozy is-validation-hidden" :class="formClass">
          <div class="unifiSettingsHeader">
            <div class="unifiSettingsHeader__title" v-if="moveZoneData.edition=='isp'"> Move Zone </div>
            <div class="unifiSettingsHeader__title" v-else> Move Account </div>
          </div>
          <fieldset class="appFieldset appFieldset--quiet">
            <div class="jazeAlert jazeAlert--danger row col-12" v-show='errorShow'>
              <div class="col-6">
                <strong>{{errorMessage}}</strong>
              </div>
            </div>
            <div class="unifiSettingsSection">
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign" v-if="moveZoneData.edition=='isp'"> Select the zone to move </label>
                    <label class="appLabel appLabel--primary appLabel--boxAlign" v-else> Select the account to move </label>
                  </div>
                  <div class="col--sm6 appFormGroup appFormGroup--cozy">
                    <treeselect v-model="selectedChildAcc" :options="zoneOptions" :searchable="true" :clearable="false" :defaultExpandLevel="Infinity"></treeselect>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign" v-if="moveZoneData.edition=='isp'"> Select the zone under which the selected zone is to be moved </label>
                    <label class="appLabel appLabel--primary appLabel--boxAlign" v-else> Select the account under which the selected account is to be moved </label>
                  </div>
                  <div class="col--sm6 appFormGroup appFormGroup--cozy">
                    <treeselect v-model="selectedParentAcc" :options="zoneOptions" :searchable="true" :clearable="false" :defaultExpandLevel="Infinity"></treeselect>
                  </div>
                </div>
            </div>
          </fieldset>
        </form>
        <div class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark">
          <div class="appFormFooter__right">
            <button class="appMainButton appMainButton--primary busyToggle" type="submit" @click="moveZoneSubmit"> 
                <span v-if="moveZoneData.edition=='isp'"> Move Zone </span>   
                <span v-else> Move Account </span> 
            </button>
          </div>
        </div>
      </div>
    </div>
    <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
    <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
  </div>
</template>

<script>
import { ref,computed,watch,watchEffect,onMounted,onBeforeUnmount } from 'vue'
import axios from "@/api/index";
import Treeselect from "jaze-vue3-treeselect";
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";

import { storeToRefs } from "pinia";
import { usesettingsStore } from '@/store/settingsstore'
import { uselayoutStore } from '@/store/layoutstore'
export default {
  setup() {
    const moveZoneData = ref( [] )
    const formClass = ref( "" )
    const successtoaster = ref( false )
    const successMessage = ref( "" )
    const errortoaster = ref( false )
    const errorMessage = ref( "" )
    const errorShow = ref( false )
    const zoneOptions = ref( [] )
    const selectedChildAcc = ref( null )
    const selectedParentAcc = ref( null )

    const settingsStore = usesettingsStore()
    const layoutStore = uselayoutStore() 
    const { moveAccountsData } = storeToRefs(settingsStore)
    const moveAccountsGetter = computed(() => moveAccountsData.value)
    const { generateSubAccountsTreeVue } = storeToRefs(layoutStore)
    const generateSubAccountsTreeVueGetter= computed(() => generateSubAccountsTreeVue.value);

    onMounted(() => {
      getTaskDetails();
    })
    onBeforeUnmount(() => {
      settingsStore.clearSettingsState();
    })

    watchEffect(() => {
      moveZoneData.value = moveAccountsGetter.value;
      // zoneOptions.value = moveZoneData.value.subAccountsNestedListVue;
    })
    watch(generateSubAccountsTreeVueGetter,()=>{
        if (generateSubAccountsTreeVueGetter.value != undefined && generateSubAccountsTreeVueGetter.value.length > 0 && zoneOptions.value != undefined && zoneOptions.value.length == 0) {
          zoneOptions.value = generateSubAccountsTreeVueGetter.value;
        }
      });
    const getTaskDetails = () => {
      if (Object.keys(moveAccountsGetter.value).length == 0) {
        // $store.dispatch("fetchmoveAccountsData");
        settingsStore.fetchmoveAccountsData();
      } else {
        moveZoneData.value = moveAccountsGetter.value;
      } 
      if(generateSubAccountsTreeVueGetter.value != undefined && generateSubAccountsTreeVueGetter.value.length > 0) {
        zoneOptions.value = generateSubAccountsTreeVueGetter.value;
      }else{
        layoutStore.fetchGenerateSubAccountsTreeVue();
      }   
    }
    const checkForm = (postData) => {
      if (postData.childAcctId == null || postData.childAcctId == "") {
        errorMessage.value = moveZoneData.value.edition=="isp" ? "Select the zone to move" : "Select the account to move";
        errorShow.value = true;
        return "error";
      }
      if (postData.parentAcctId == null || postData.parentAcctId == "") {
        errorMessage.value = moveZoneData.value.edition=="isp" ? "Select the parent zone under which the selected zone is to be moved" : "Select the parent account under which the selected account is to be moved";
        errorShow.value = true;
        return "error";
      }
    }
    const moveZoneSubmit = () => {
      successtoaster.value = false;
      errortoaster.value = false;
      errorShow.value = false;
      var postData = {
          childAcctId : selectedChildAcc.value,
          parentAcctId : selectedParentAcc.value
      }
      var status = checkForm(postData);
      if (status == "error") {
        formClass.value = "is-form-validation-visible";
        return;
      }
      // $store.dispatch("moveAccountsSubmit", postData).then(
      settingsStore.moveAccountsSubmit(postData).then(  
        response => {
          successtoaster.value = true;
          successMessage.value = response;
          window.location.reload();
          setTimeout(
            function() {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        error => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function() {
              errortoaster.value = false;
            }.bind(this),
            5000
          );
          console.log(error);
        }
      );
    }

    return {
      settingsStore,
      moveAccountsGetter: moveAccountsData,
      moveZoneData,
      formClass,
      successtoaster,
      successMessage,
      errortoaster,
      errorMessage,
      errorShow,
      zoneOptions,
      selectedChildAcc,
      selectedParentAcc,
      getTaskDetails,
      checkForm,
      moveZoneSubmit,
      generateSubAccountsTreeVueGetter
    }
  },
  components: {
    Treeselect,
    SuccessToaster,
    ErrorToaster
  },
}
</script>



const Full = () => import( '@/container/Full.vue')

// dashboard components
const ActiveSessionsRoutes = () => import(/* webpackPrefetch: false */ '../views/monitoring/ActiveSessions.vue')
const NetworkRoutes = () => import(/* webpackPrefetch: false */ '../views/monitoring/Network.vue')
const CompletedSessionsRoutes = () => import(/* webpackPrefetch: false */ '../views/monitoring/CompletedSessions.vue')
const TrafficGraphsRoutes = () => import(/* webpackPrefetch: false */ '../views/monitoring/TrafficGraphs.vue')
const TrafficUsageRoutes = () => import(/* webpackPrefetch: false */ '../views/monitoring/TrafficUsage.vue')
export default {
    path: '/monitoring',
    component: Full,
    redirect: '/monitoring/active_sessions',
    beforeEnter(to, from, next) {
        if (localStorage.getItem('token')) {
            next()
        } else {
            next('/signin')
        }
    },
    children: [
        {
            path: '/monitoring/active_sessions',
            component: ActiveSessionsRoutes,
            meta: {
                title: "Active Sessions"
            }
        },
        {
            path: '/monitoring/network',
            component: NetworkRoutes,
            meta: {
                title: "Active Sessions"
            }
        },
        {
            path: '/monitoring/completed_sessions',
            component: CompletedSessionsRoutes,
            meta: {
                title: "Completed Sessions"
            }
        },
        {
            path: '/monitoring/traffic_graphs',
            component: TrafficGraphsRoutes,
            meta: {
                title: "Traffic Graphs"
            }
        },
        {
            path: '/monitoring/traffic_usage/:userId',
            component: TrafficUsageRoutes,
            meta: {
                title: "Traffic Usage"
            }
        },
        {
            path: '/monitoring/traffic_usage',
            component: TrafficUsageRoutes,
            meta: {
                title: "Traffic Usage"
            }
        }
    ]
}
import { defineStore } from 'pinia'
import router from '@/router/index'
import axios from '@/api/index'
import qs from 'qs'
import  '../../node_modules/js-loading-overlay/dist/js-loading-overlay.min.js'
import Nprogress from 'nprogress';
export const useprofilesStore = defineStore('profilesstore', {
    state: () => {
      return {
        bandwidthList: [],
        bandwidthRoles: [],
        bandwidthSampleFilePath:'',
        profileSampleFilePath:[],
        ottprofileSampleFilePath:[],
        iptvprofileSampleFilePath:[],
        voiceprofileSampleFilePath:[],
        profileList:[],
        profileListDetails:[],
        profileName:[],
        accountId:[],
        ottprofileList:[],
        ottprofileListDetails:[],
        ottprofilePermission:[],
        ottProfileName:[],
        iptvprofileList:[],
        iptvprofileListDetails:[],
        iptvprofilePermission:[],
        iptvProfileName:[],
        voiceprofileList:[],
        voiceprofileListDetails:[],
        voiceprofileName:[],
        voiceprofilePermission:[],
        fupTemplateList:[],
        createFupDatas:[],
        dynamicVendorAttributeData:[],
        dynamicVendorAttributeDataAccountId:[],
        voucherAccessTemplateList: [],
        dynamicVendorPoolName: [],
        profileData: [],
        ottProfileData:[],
        iptvProfileData:[],
        voiceProfileData:[],
        operatorValue:"",
        profilePermission: [],
      }
    },
    getters: {
        getBandwidthListData: (state) => state.bandwidthList,
        getBandwidthListGetters(){
            return this.getBandwidthListData
        },
        getBandwidthRolesData: (state) => state.bandwidthRoles,
        getBandwidthRolesGetters() {
            return this.getBandwidthRolesData;
          },
        allsampleprofileSampleFilePath: (state) => state.profileSampleFilePath,
        getsampleprofileSampleFilePath() {
            return this.allsampleprofileSampleFilePath;
          },
        allsampleottprofileSampleFilePath: (state) => state.ottprofileSampleFilePath,
        getsampleottprofileSampleFilePath() {
            return this.allsampleottprofileSampleFilePath;
          },
        allsampleiptvprofileSampleFilePath: (state) => state.iptvprofileSampleFilePath,
        getsampleiptvprofileSampleFilePath() {
            return this.allsampleiptvprofileSampleFilePath;
          },
        allsamplevoiceprofileSampleFilePath: (state) => state.voiceprofileSampleFilePath,
        getsamplevoiceprofileSampleFilePath() {
            return this.allsamplevoiceprofileSampleFilePath;
          },
          
        allgetProfileList: (state) => state.profileList,
        getProfileListGetters() {
            return this.allgetProfileList;
        },
        allgetProfileListDetails: (state) => state.profileListDetails,
        getProfileListDetailsGetters() {
            return this.allgetProfileListDetails;
        },
        allgetProfileNames: (state) => state.profileName,
        getProfileNamesGetters() {
            return this.allgetProfileNames;
        },
        allgetAccountId: (state) => state.accountId,
        getAccountIdGetters() {
            return this.allgetAccountId;
        },
        allgeOttProfileList: (state) => state.ottprofileList,
        geOttProfileListGetters() {
            return this.allgeOttProfileList;
        },
        allgetOttProfileListDetails: (state) => state.ottprofileListDetails,
        getOttProfileListDetailsGetters() {
            return this.allgetOttProfileListDetails;
        },
        allgetOttProfilePermission: (state) => state.ottprofilePermission,
        getOttProfilePermissionGetters() {
            return this.allgetOttProfilePermission;
        },
        allgetOttProfileNames: (state) => state.ottProfileName,
        getOttProfileNamesGetters() {
            return this.allgetOttProfileNames;
        },
        allgeIptvProfileList: (state) => state.iptvprofileList,
        geIptvProfileListGetters() {
            return this.allgeIptvProfileList;
        },
        allgetIptvProfileListDetails: (state) => state.iptvprofileListDetails,
        getIptvProfileListDetailsGetters() {
            return this.allgetIptvProfileListDetails;
        },
        allgetIptvProfilePermission: (state) => state.iptvprofilePermission,
        getIptvProfilePermissionGetters() {
            return this.allgetIptvProfilePermission;
        },
        allgetIptvProfileNames: (state) => state.iptvProfileName,
        getIptvProfileNamesGetters() {
            return this.allgetIptvProfileNames;
        },
        allgetVoiceProfileList: (state) => state.voiceprofileList,
        getVoiceProfileListGetters() {
            return this.allgetVoiceProfileList;
        },
        allgetVoiceProfileListDetails: (state) => state.voiceprofileListDetails,
        getVoiceProfileListDetailsGetters() {
            return this.allgetVoiceProfileListDetails;
        },
        allgetVoiceProfileNames: (state) => state.voiceprofileName,
        getVoiceProfileNamesGetters() {
            return this.allgetVoiceProfileNames;
        },
        allgetVoiceProfilePermission: (state) => state.voiceprofilePermission,
        getVoiceProfilePermissionGetters() {
            return this.allgetVoiceProfilePermission;
        },
        allfupTemplateList: (state) => state.fupTemplateList,
        fupTemplateListGetters() {
            return this.allfupTemplateList;
        },
        allcreateFupDatas: (state) => state.createFupDatas,
        createFupDatasGetters() {
            return this.allcreateFupDatas;
        },
        alldynamicVendorAttributes: (state) => state.dynamicVendorAttributeData,
        dynamicVendorAttributesGetter() {
            return this.alldynamicVendorAttributes;
        },
        alldynamicVendorAttributesAccountId: (state) => state.dynamicVendorAttributeDataAccountId,
        dynamicVendorAttributesAccountIdGetter() {
            return this.alldynamicVendorAttributesAccountId;
        },
        dynamicVendorPoolNameState: (state) => state.dynamicVendorPoolName,
        dynamicVendorPoolNameGetter() {
          return this.dynamicVendorPoolNameState
        },
        voucherAccessTemplateListState: (state) => state.voucherAccessTemplateList,
        getVoucherAccessTemplateListGetters() {
          return this.voucherAccessTemplateListState;
        },
        profileDataState: (state) => state.profileData,
        profileDataGetter() {
          return this.profileDataState;
        },
        iptvProfileDataState: (state) => state.iptvProfileData,
        iptvProfileDataGetter() {
          return this.iptvProfileDataState;
        },
        operatorValueState: (state) => state.operatorValue,
        getOperatorValueGetters(){
          return this.operatorValueState;
        },
        ottProfileDataState: (state) => state.ottProfileData,
        ottProfileDataGetter() {
          return this.ottProfileDataState;
        },
        voiceProfileDataState: (state) => state.voiceProfileData,
        voiceProfileDataGetter() {
          return this.voiceProfileDataState;
        },
        bandwidthSampleFilePathState: (state) => state.bandwidthSampleFilePath,
        getsamplebandwidthSampleFilePath() {
          return this.bandwidthSampleFilePathState;
        },
    },
    actions: {  
        clearProfilesState() {
            this.bandwidthList=[];
            this.bandwidthRoles = [];
            this.bandwidthSampleFilePath='';
        },
        fetchBandwidthList(authData) {
          Nprogress.start()
            return new Promise((resolve, reject) => {
              axios.get('/profiles/bandwidth_list')
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push(res.data.message);
                }else if(res.data.status=="error" && res.data.isDatabaseAccessibleCheck=="yes"){
                  resolve(res.data);
                } else {
                    var bandwidthList=res.data;
                    var tData = {};
                    var parseData = JSON.parse(bandwidthList.message);
                    this.bandwidthRoles = parseData.pageSpecificRoles;
                    this.bandwidthSampleFilePath = bandwidthList.samplePath;
                    if (Object.keys(this.bandwidthList).length == 0) {
                      tData[bandwidthList.accountId + '-bandwidthList'] = parseData.bandwidth;
                      this.bandwidthList = tData;
                    } else {
                      this.bandwidthList[bandwidthList.accountId + '-bandwidthList'] = parseData.bandwidth;
                    }
                }
              })
              .catch(error => console.log(error))
            });
          },
          addEditBandwidthModalSubmit(postData) {
            return new Promise((resolve, reject) => {
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/profiles/bandwidth', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var data = {
                    banddata: response.data.bandwidthId,
                    accountId: postData.accountId
                  }
                  if (!postData.fromProfile) {
                    var bandwidth = JSON.parse(data.banddata);
                    if (bandwidth.length > 0) {
                      for (var i = 0; i < bandwidth.length; i++) {
                        var index = this.bandwidthList[data.accountId + "-bandwidthList"].findIndex(bandwidthd => bandwidthd.id == bandwidth[i].id);
                        if (index != '-1') {
                          this.bandwidthList[data.accountId + "-bandwidthList"].splice(index, 1)
                        }
                        this.bandwidthList[data.accountId + "-bandwidthList"].push(bandwidth[i]);
                      }
                    }
                  }
        
        
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          deleteBandwidthModalSubmit(postData) {
            return new Promise((resolve, reject) => {
        
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/profiles/delete_bandwidth.json', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = {
                    ids: response.data.successIds,
                    accountId: postData.accountId
                  }
                  var data=successData;
                  if (Object.keys(this.bandwidthList).length != 0) {
                    for (var i = 0; i < data.ids.length; i++) {
                      var index = this.bandwidthList[data.accountId + "-bandwidthList"].findIndex(bandwidth => bandwidth.id == data.ids[i]);
                      if (index != '-1') {
                        this.bandwidthList[data.accountId + "-bandwidthList"].splice(index, 1);
                      }
                    }
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          importBandwidthModal( postData) {
            return new Promise((resolve, reject) => {
        
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/profiles/import_bandwidth_file', postData)
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var accountId = '';
                  for (let [key, val] of postData.entries()) {
                    if (key == 'accountId') {
                      accountId = val;
                      break;
                    }
                  }
                  var data = {
                    banddata: response.data.bandwidthData,
                    accountId: accountId
                  }
                  var bandwidth = JSON.parse(data.banddata);
                  if (bandwidth.length > 0) {
                    for (var i = 0; i < bandwidth.length; i++) {
                      var index = this.bandwidthList[data.accountId + "-bandwidthList"].findIndex(bandwidthd => bandwidthd.id == bandwidth[i].id);
                      if (index != '-1') {
                        this.bandwidthList[data.accountId + "-bandwidthList"].splice(index, 1)
                      }
                      this.bandwidthList[data.accountId + "-bandwidthList"].push(bandwidth[i]);
                    }
                  }
        
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          }, importBandwidthCopy(postData) {
            return new Promise((resolve, reject) => {
        
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/profiles/import_bandwidth_file_copy', postData)
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var accountId = '';
                  for (let [key, val] of postData.entries()) {
                    if (key == 'accountId') {
                      accountId = val;
                      break;
                    }
                  }
                  var data = {
                    banddata: response.data.bandwidthData,
                    accountId: accountId
                  }
                  var bandwidth = JSON.parse(data.banddata);
                  if (bandwidth.length > 0) {
                    for (var i = 0; i < bandwidth.length; i++) {
                      var index = this.bandwidthList[data.accountId + "-bandwidthList"].findIndex(bandwidthd => bandwidthd.id == bandwidth[i].id);
                      if (index != '-1') {
                        this.bandwidthList[data.accountId + "-bandwidthList"].splice(index, 1)
                      }
                      this.bandwidthList[data.accountId + "-bandwidthList"].push(bandwidth[i]);
                    }
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          quickCreateModalSubmit(postData) {
            return new Promise((resolve, reject) => {
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/profiles/bandwidth', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var data = {
                    banddata: response.data.bandwidthId,
                    accountId: postData.accountId
                  }
                  var bandwidth = JSON.parse(data.banddata);
                  if (bandwidth.length > 0) {
                    for (var i = 0; i < bandwidth.length; i++) {
                      var index = this.bandwidthList[data.accountId + "-bandwidthList"].findIndex(bandwidthd => bandwidthd.id == bandwidth[i].id);
                      if (index != '-1') {
                        this.bandwidthList[data.accountId + "-bandwidthList"].splice(index, 1)
                      }
                      this.bandwidthList[data.accountId + "-bandwidthList"].push(bandwidth[i]);
                    }
                  }
        
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          deleteProfileModalSubmit(postData) {
            return new Promise((resolve, reject) => {
        
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/profiles/delete_all_profiles.json', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  console.log(response);
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = {
                    ids: response.data.successIds,
                    accountId: postData.accountId
                  }
                  //commit('DELETE_PROFILE_LIST', successData);
                  if (Object.keys(this.profileList).length != 0) {
                    for (var i = 0; i < successData.ids.length; i++) {
                      var index = this.profileList[successData.accountId + "-profileList"].profileName.findIndex(profile => profile.id == successData.ids[i]);
                      var index1 = this.profileName.findIndex(profile => profile.id == successData.ids[i]);
                      this.profileList[successData.accountId + "-profileList"].profileName.splice(index, 1)
                      this.profileName.splice(index1, 1);
                    }
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          disconnectUsersFromPackage(postData) {
            return new Promise((resolve, reject) => {
        
              JsLoadingOverlay.show({
                'spinnerIcon': 'ball-clip-rotate',
                "overlayOpacity": "0.6",
                "spinnerSize": "2x",
                "lockScroll": true,
              });
              axios.post('/profiles/disconnect_all_users.json', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  console.log(response);
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          deactivateProfileModalSubmit(postData) {
            return new Promise((resolve, reject) => {
        
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/profiles/package_deactivate', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    //commit('SET_FAILURE', response.data.message);
                    reject(response.data.message);
                    return;
                  }
                  //commit('SET_SUCCESS', response.data.message);
                  
                  //commit('SET_PROFILE_DETAIL', response.data.profileList.profileDetail)
                  this.profileListDetails = response.data.profileList.profileDetail;

                  //commit('SET_PROFILE_LIST', response.data.profileList)
                  this.accountId = JSON.parse(response.data.profileList.message).accountID
                  this.profilePermission = JSON.parse(response.data.profileList.message);
                  this.profileSampleFilePath = JSON.parse(response.data.profileList.message).samplePath;
                  var tData = {};
                  if (Object.keys(this.profileList).length == 0) {
                    tData[response.data.profileList.accountId + '-profileList'] = JSON.parse(response.data.profileList.message);
                    this.profileList = tData;
                  } else {
                    this.profileList[JSON.parse(response.data.profileList.message).accountID + '-profileList'] = JSON.parse(response.data.profileList.message);
                  }
                  this.profileName = JSON.parse(response.data.profileList.message).profileName;
                  // var successData = {
                  //   accountId: postData.accountId
                  // }
                 // commit('DEACTIVATE_PROFILE_LIST', successData);
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          importOttProfileModal(postData) {
            return new Promise((resolve, reject) => {
        
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/profiles/import_ott_profiles_file', postData)
                .then(response => {
                  console.log("import Response", response);
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    //commit('SET_FAILURE', response.data.message);
                    reject(response.data.message);
                    return;
                  }
                  var accountId = '';
                  for (let [key, val] of postData.entries()) {
                    if (key == 'accountId') {
                      accountId = val;
                      break;
                    }
                  }
                  var dataList = {
                    profiledata: response.data.profileData,
                    accountId: accountId,
                    type: "import"
                  }
                  //commit('ADD_OTT_PROFILE_LIST', dataList)
                  if (dataList.type != "import") {
                    var profile = JSON.parse(dataList.profiledata);
                  } else {
                    var profile = dataList.profiledata;
                  }
                  if (profile.length > 0) {
                    for (var i = 0; i < profile.length; i++) {
                      var index = this.ottprofileList[dataList.accountId + "-ottProfileList"].profileName.findIndex(element => element.id == profile[i].id);
                      if (index != '-1') {
                        this.ottprofileList[dataList.accountId + "-ottProfileList"].profileName.splice(index, 1)
                      }
                      this.ottprofileList[dataList.accountId + "-ottProfileList"].profileName.push(profile[i]);
                      this.ottProfileName.push(profile[i]);
                    }
                  }
        
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          importIptvProfileModal(postData) {
            return new Promise((resolve, reject) => {
        
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/profiles/import_iptv_profiles_file', postData)
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    //commit('SET_FAILURE', response.data.message);
                    reject(response.data.message);
                    return;
                  }
                  var accountId = '';
                  for (let [key, val] of postData.entries()) {
                    if (key == 'accountId') {
                      accountId = val;
                      break;
                    }
                  }
                  var dataList = {
                    profiledata: response.data.profileData,
                    accountId: accountId,
                    type: "import"
                  }
                  //commit('ADD_IPTV_PROFILE_LIST', dataList)
                  if (dataList.type != "import") {
                    var profile = JSON.parse(dataList.profiledata);
                  } else {
                    var profile = dataList.profiledata;
                  }
                  if (profile.length > 0) {
                    for (var i = 0; i < profile.length; i++) {
                      var index = this.iptvprofileList[dataList.accountId + "-iptvProfileList"].profileName.findIndex(element => element.id == profile[i].id);
                      if (index != '-1') {
                        this.iptvprofileList[dataList.accountId + "-iptvProfileList"].profileName.splice(index, 1)
                      }
                      this.iptvprofileList[dataList.accountId + "-iptvProfileList"].profileName.push(profile[i]);
                      this.iptvProfileName.push(profile[i]);
                    }
                  }
        
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          importVoiceProfileModal(postData) {
            return new Promise((resolve, reject) => {
        
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/profiles/import_voice_profiles_file', postData)
                .then(response => {
                  console.log("import Response", response);
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    //commit('SET_FAILURE', response.data.message);
                    reject(response.data.message);
                    return;
                  }
                  var accountId = '';
                  for (let [key, val] of postData.entries()) {
                    if (key == 'accountId') {
                      accountId = val;
                      break;
                    }
                  }
                  var dataList = {
                    profiledata: response.data.profileData,
                    accountId: accountId,
                    type: "import"
                  }
                  //commit('ADD_VOICE_PROFILE_LIST', dataList)
                  if (dataList.type != "import") {
                    var profile = JSON.parse(dataList.profiledata);
                  } else {
                    var profile = dataList.profiledata;
                  }
                  if (profile.length > 0) {
                    for (var i = 0; i < profile.length; i++) {
                      var index = this.voiceprofileList[dataList.accountId + "-voiceprofileList"].profileName.findIndex(element => element.id == profile[i].id);
                      if (index != '-1') {
                        this.voiceprofileList[dataList.accountId + "-voiceprofileList"].profileName.splice(index, 1)
                      }
                      this.voiceprofileList[dataList.accountId + "-voiceprofileList"].profileName.push(profile[i]);
                      this.voiceprofileName.push(profile[i]);
                    }
                  }
        
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          importProfileModal(postData) {
            return new Promise((resolve, reject) => {
        
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/profiles/import_profiles_file', postData)
                .then(response => {
                  console.log("inport Response", response);
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    //commit('SET_FAILURE', response.data.message);
                    reject(response.data.message);
                    return;
                  }
                  var accountId = '';
                  for (let [key, val] of postData.entries()) {
                    if (key == 'accountId') {
                      accountId = val;
                      break;
                    }
                  }
                  var dataList = {
                    profiledata: response.data.profileData,
                    accountId: accountId,
                    type: "import"
                  }
                  //commit('ADD_PROFILE_LIST', dataList)
                  if (dataList.type != "import") {
                    var profile = JSON.parse(dataList.profiledata);
                  } else {
                    var profile = dataList.profiledata;
                  }
                  if (profile.length > 0) {
                    for (var i = 0; i < profile.length; i++) {
                      var index = this.profileList[dataList.accountId + "-profileList"].profileName.findIndex(element => element.id == profile[i].id);
                      if (index != '-1') {
                        this.profileList[dataList.accountId + "-profileList"].profileName.splice(index, 1)
                      }
                      this.profileList[dataList.accountId + "-profileList"].profileName.push(profile[i]);
                      this.profileName.push(profile[i]);
                    }
                  }
        
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          importOttProfileCopy(postData) {
            return new Promise((resolve, reject) => {
        
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/profiles/import_ott_profiles_file_copy', postData)
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    //commit('SET_FAILURE', response.data.message);
                    reject(response.data.message);
                    return;
                  }
                  var accountId = '';
                  for (let [key, val] of postData.entries()) {
                    if (key == 'accountId') {
                      accountId = val;
                      break;
                    }
                  }
                  var dataList = {
                    profiledata: response.data.profileData,
                    accountId: accountId,
                    type: "import"
                  }
                  //commit('ADD_OTT_PROFILE_LIST', dataList)
                  if (dataList.type != "import") {
                    var profile = JSON.parse(dataList.profiledata);
                  } else {
                    var profile = dataList.profiledata;
                  }
                  if (profile.length > 0) {
                    for (var i = 0; i < profile.length; i++) {
                      var index = this.ottprofileList[dataList.accountId + "-ottProfileList"].profileName.findIndex(element => element.id == profile[i].id);
                      if (index != '-1') {
                        this.ottprofileList[dataList.accountId + "-ottProfileList"].profileName.splice(index, 1)
                      }
                      this.ottprofileList[dataList.accountId + "-ottProfileList"].profileName.push(profile[i]);
                      this.ottProfileName.push(profile[i]);
                    }
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          importIptvProfileCopy(postData) {
            return new Promise((resolve, reject) => {
        
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/profiles/import_iptv_profiles_file_copy', postData)
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    //commit('SET_FAILURE', response.data.message);
                    reject(response.data.message);
                    return;
                  }
                  var accountId = '';
                  for (let [key, val] of postData.entries()) {
                    if (key == 'accountId') {
                      accountId = val;
                      break;
                    }
                  }
                  var dataList = {
                    profiledata: response.data.profileData,
                    accountId: accountId,
                    type: "import"
                  }
                  //commit('ADD_IPTV_PROFILE_LIST', dataList)
                  if (dataList.type != "import") {
                    var profile = JSON.parse(dataList.profiledata);
                  } else {
                    var profile = dataList.profiledata;
                  }
                  if (profile.length > 0) {
                    for (var i = 0; i < profile.length; i++) {
                      var index = this.iptvprofileList[dataList.accountId + "-iptvProfileList"].profileName.findIndex(element => element.id == profile[i].id);
                      if (index != '-1') {
                        this.iptvprofileList[dataList.accountId + "-iptvProfileList"].profileName.splice(index, 1)
                      }
                      this.iptvprofileList[dataList.accountId + "-iptvProfileList"].profileName.push(profile[i]);
                      this.iptvProfileName.push(profile[i]);
                    }
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          importVoiceProfileCopy(postData) {
            return new Promise((resolve, reject) => {
        
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/profiles/import_voice_profiles_file_copy', postData)
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    //commit('SET_FAILURE', response.data.message);
                    reject(response.data.message);
                    return;
                  }
                  var accountId = '';
                  for (let [key, val] of postData.entries()) {
                    if (key == 'accountId') {
                      accountId = val;
                      break;
                    }
                  }
                  var dataList = {
                    profiledata: response.data.profileData,
                    accountId: accountId,
                    type: "import"
                  }
                  //commit('ADD_VOICE_PROFILE_LIST', dataList)
                  if (dataList.type != "import") {
                    var profile = JSON.parse(dataList.profiledata);
                  } else {
                    var profile = dataList.profiledata;
                  }
                  if (profile.length > 0) {
                    for (var i = 0; i < profile.length; i++) {
                      var index = this.voiceprofileList[dataList.accountId + "-voiceprofileList"].profileName.findIndex(element => element.id == profile[i].id);
                      if (index != '-1') {
                        this.voiceprofileList[dataList.accountId + "-voiceprofileList"].profileName.splice(index, 1)
                      }
                      this.voiceprofileList[dataList.accountId + "-voiceprofileList"].profileName.push(profile[i]);
                      this.voiceprofileName.push(profile[i]);
                    }
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          importProfileCopy(postData) {
            return new Promise((resolve, reject) => {
        
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/profiles/import_profiles_file_copy', postData)
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    //commit('SET_FAILURE', response.data.message);
                    reject(response.data.message);
                    return;
                  }
                  var accountId = '';
                  for (let [key, val] of postData.entries()) {
                    if (key == 'accountId') {
                      accountId = val;
                      break;
                    }
                  }
                  var dataList = {
                    profiledata: response.data.profileData,
                    accountId: accountId,
                    type: "import"
                  }
                  //commit('ADD_PROFILE_LIST', dataList)
                  if (dataList.type != "import") {
                    var profile = JSON.parse(dataList.profiledata);
                  } else {
                    var profile = dataList.profiledata;
                  }
                  if (profile.length > 0) {
                    for (var i = 0; i < profile.length; i++) {
                      var index = this.profileList[dataList.accountId + "-profileList"].profileName.findIndex(element => element.id == profile[i].id);
                      if (index != '-1') {
                        this.profileList[dataList.accountId + "-profileList"].profileName.splice(index, 1)
                      }
                      this.profileList[dataList.accountId + "-profileList"].profileName.push(profile[i]);
                      this.profileName.push(profile[i]);
                    }
                  }
        
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          pushToSubZone(postData) {
            return new Promise((resolve, reject) => {
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/profiles/push_all_profiles', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          iptvPushToSubZone(postData) {
            return new Promise((resolve, reject) => {
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/profiles/push_all_iptv_profiles', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          ottPushToSubZone(postData) {
            return new Promise((resolve, reject) => {
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/profiles/push_all_ott_profiles', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          fetchProfileList(authData) {
            return new Promise((resolve, reject) => {
              Nprogress.start()
              axios.get('/profiles/profile_list')
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push(res.data.message);
                }else if(res.data.status=="error" && res.data.isDatabaseAccessibleCheck=="yes"){
                  resolve(res.data);
                } else {
                  //commit('SET_PROFILE_DETAIL', res.data.profileDetail)
                  this.profileListDetails = res.data.profileDetail;
                  //commit('SET_PROFILE_LIST', res.data)
                  this.accountId = JSON.parse(res.data.message).accountID
                  this.profilePermission = JSON.parse(res.data.message);
                  this.profileSampleFilePath = JSON.parse(res.data.message).samplePath;
                  var tData = {};
                  if (Object.keys(this.profileList).length == 0) {
                    tData[res.data.accountId + '-profileList'] = JSON.parse(res.data.message);
                    this.profileList = tData;
                  } else {
                    this.profileList[JSON.parse(res.data.message).accountID + '-profileList'] = JSON.parse(res.data.message);
                  }
                  this.profileName = JSON.parse(res.data.message).profileName;
                }
              })
              .catch(error => console.log(error))
            });
          },
          deleteOttModalSubmit(postData) {
            return new Promise((resolve, reject) => {
        
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/profiles/delete_all_ott_profiles.json', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  console.log(response);
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = {
                    ids: response.data.successIds,
                    accountId: postData.accountId
                  }
                  //commit('DELETE_OTT_LIST', successData);
                  if (Object.keys(this.ottprofileList).length != 0) {
                    for (var i = 0; i < successData.ids.length; i++) {
                      var index = this.ottprofileList[successData.accountId + "-ottProfileList"].profileName.findIndex(profile => profile.id == successData.ids[i]);
                      var index1 = this.ottProfileName.findIndex(profile => profile.id == successData.ids[i]);
                      this.ottprofileList[successData.accountId + "-ottProfileList"].profileName.splice(index, 1)
                      this.ottProfileName.splice(index1, 1);
                    }
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          deactivateIptvModalSubmit(postData) {
            return new Promise((resolve, reject) => {
        
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/profiles/iptv_deactivate', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  
                  this.iptvprofileListDetails = response.data.profileList.profileDetail;
                  this.accountId = JSON.parse(response.data.profileList.message).accountID
                  this.iptvprofilePermission = JSON.parse(response.data.profileList.message);
                  this.iptvprofileSampleFilePath = JSON.parse(response.data.profileList.message).samplePath;
                  var tData = {};
                  if (Object.keys(this.iptvprofileList).length == 0) {
                    tData[response.data.profileList.accountId + '-iptvProfileList'] = JSON.parse(response.data.profileList.message);
                    this.iptvprofileList = tData;
                  } else {
                    this.iptvprofileList[JSON.parse(response.data.profileList.message).accountID + '-iptvProfileList'] = JSON.parse(response.data.profileList.message);
                  }
                  this.iptvProfileName = JSON.parse(response.data.profileList.message).profileName;
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          deactivateOttModalSubmit(postData) {
            return new Promise((resolve, reject) => {
        
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/profiles/ott_deactivate', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  
                  //commit('SET_OTT_PROFILE_DETAIL', response.data.profileList.profileDetail)
                  this.ottprofileListDetails = response.data.profileList.profileDetail;
                  //commit('SET_OTT_PROFILE_LIST', response.data.profileList)
                  this.accountId = JSON.parse(response.data.profileList.message).accountID
                  this.ottprofilePermission = JSON.parse(response.data.profileList.message);
                  this.ottprofileSampleFilePath = JSON.parse(response.data.profileList.message).samplePath;
                  var tData = {};
                  if (Object.keys(this.ottprofileList).length == 0) {
                    tData[response.data.profileList.accountId + '-ottProfileList'] = JSON.parse(response.data.profileList.message);
                    this.ottprofileList = tData;
                  } else {
                    this.ottprofileList[JSON.parse(response.data.profileList.message).accountID + '-ottProfileList'] = JSON.parse(response.data.profileList.message);
                  }
                  this.ottProfileName = JSON.parse(response.data.profileList.message).profileName;
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          fetchOttList(authData) {
            return new Promise((resolve, reject) => {
              Nprogress.start()
            axios.get('/profiles/ott_profile_list')
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push(res.data.message);
                }else if(res.data.status=="error" && res.data.isDatabaseAccessibleCheck=="yes"){
                  resolve(res.data);
                } else {
                  //commit('SET_OTT_PROFILE_DETAIL', res.data.profileDetail)
                  this.ottprofileListDetails = res.data.profileDetail;
                  //commit('SET_OTT_PROFILE_LIST', res.data)
                  this.accountId = JSON.parse(res.data.message).accountID
                  this.ottprofilePermission = JSON.parse(res.data.message);
                  this.ottprofileSampleFilePath = JSON.parse(res.data.message).samplePath;
                  var tData = {};
                  if (Object.keys(this.ottprofileList).length == 0) {
                    tData[res.data.accountId + '-ottProfileList'] = JSON.parse(res.data.message);
                    this.ottprofileList = tData;
                  } else {
                    this.ottprofileList[JSON.parse(res.data.message).accountID + '-ottProfileList'] = JSON.parse(res.data.message);
                  }
                  this.ottProfileName = JSON.parse(res.data.message).profileName;
                }
              })
              .catch(error => console.log(error))
            });
          },
          deleteIptvModalSubmit(postData) {
            return new Promise((resolve, reject) => {
        
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/profiles/delete_all_iptv_profiles.json', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  console.log(response);
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = {
                    ids: response.data.successIds,
                    accountId: postData.accountId
                  }
                  //commit('DELETE_IPTV_LIST', successData);
                  if (Object.keys(this.iptvprofileList).length != 0) {
                    for (var i = 0; i < successData.ids.length; i++) {
                      var index = this.iptvprofileList[successData.accountId + "-iptvProfileList"].profileName.findIndex(profile => profile.id == successData.ids[i]);
                      var index1 = this.iptvProfileName.findIndex(profile => profile.id == successData.ids[i]);
                      this.iptvprofileList[successData.accountId + "-iptvProfileList"].profileName.splice(index, 1)
                      this.iptvProfileName.splice(index1, 1);
                    }
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          fetchIptvProfilesList(authData) {
            return new Promise((resolve, reject) => {
              Nprogress.start()
              axios.get('/profiles/iptv_profiles')
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push(res.data.message);
                }else if(res.data.status=="error" && res.data.isDatabaseAccessibleCheck=="yes"){
                  resolve(res.data);
                } else {
                  //commit('SET_IPTV_PROFILE_DETAIL', res.data.profileDetail)
                  this.iptvprofileListDetails = res.data.profileDetail;
                  //commit('SET_IPTV_PROFILE_LIST', res.data)
                  this.accountId = JSON.parse(res.data.message).accountID
                  this.iptvprofilePermission = JSON.parse(res.data.message);
                  this.iptvprofileSampleFilePath = JSON.parse(res.data.message).samplePath;
                  var tData = {};
                  if (Object.keys(this.iptvprofileList).length == 0) {
                    tData[res.data.accountId + '-iptvProfileList'] = JSON.parse(res.data.message);
                    this.iptvprofileList = tData;
                  } else {
                    this.iptvprofileList[JSON.parse(res.data.message).accountID + '-iptvProfileList'] = JSON.parse(res.data.message);
                  }
                  this.ottProfileName = JSON.parse(res.data.message).profileName;
                }
              })
              .catch(error => console.log(error))
            });
          },
          deleteVoiceProfileModalSubmit(postData) {
            return new Promise((resolve, reject) => {
        
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/profiles/delete_all_voice_profiles.json', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  console.log(response);
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = {
                    ids: response.data.successIds,
                    accountId: postData.accountId
                  }
                  //commit('DELETE_VOICE_PROFILE_LIST', successData);
                  if (Object.keys(this.profileList).length != 0) {
                    for (var i = 0; i < successData.ids.length; i++) {
                      var index = this.profileList[successData.accountId + "-voiceprofileList"].voiceprofileName.findIndex(profile => profile.id == successData.ids[i]);
                      var index1 = this.voiceprofileName.findIndex(profile => profile.id == successData.ids[i]);
                      this.profileList[successData.accountId + "-voiceprofileList"].voiceprofileName.splice(index, 1)
                      this.voiceprofileName.splice(index1, 1);
                    }
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          deactivateVoiceProfileModalSubmit(postData) {
            return new Promise((resolve, reject) => {
        
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/profiles/voice_package_deactivate', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    //commit('SET_FAILURE', response.data.message);
                    reject(response.data.message);
                    return;
                  }
                  //commit('SET_SUCCESS', response.data.message);
                  
                  //commit('SET_VOICE_PROFILE_DETAIL', response.data.profileList.profileDetail)
                  this.voiceprofileListDetails = response.data.profileList.profileDetail;
                  //commit('SET_VOICE_PROFILE_LIST', response.data.profileList)
                  this.accountId = JSON.parse(response.data.profileList.message).accountID
                  this.voiceprofilePermission = JSON.parse(response.data.profileList.message);
                  this.voiceprofileSampleFilePath = JSON.parse(response.data.profileList.message).samplePath;
                  var tData = {};
                  if (Object.keys(this.voiceprofileList).length == 0) {
                    tData[response.data.profileList.accountId + '-voiceprofileList'] = JSON.parse(response.data.profileList.message);
                    this.voiceprofileList = tData;
                  } else {
                    this.voiceprofileList[JSON.parse(response.data.profileList.message).accountID + '-voiceprofileList'] = JSON.parse(response.data.profileList.message);
                  }
                  this.voiceprofileName = JSON.parse(response.data.profileList.message).profileName;
                  // var successData = {
                  //   accountId: postData.accountId
                  // }
                 // commit('DEACTIVATE_PROFILE_LIST', successData);
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          voicepushToSubZone(postData) {
            return new Promise((resolve, reject) => {
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/profiles/push_all_voice_profiles', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          fetchVoiceProfileList(authData) {
            return new Promise((resolve, reject) => {
              Nprogress.start()
              axios.get('/profiles/voice_profile_list')
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push(res.data.message);
                }else if(res.data.status=="error" && res.data.isDatabaseAccessibleCheck=="yes"){
                  resolve(res.data);
                } else {
                  //commit('SET_VOICE_PROFILE_DETAIL', res.data.profileDetail)
                  this.voiceprofileListDetails = res.data.profileDetail;
                  //commit('SET_VOICE_PROFILE_LIST', res.data)
                  this.accountId = JSON.parse(res.data.message).accountID
                  this.voiceprofilePermission = JSON.parse(res.data.message);
                  this.voiceprofileSampleFilePath = JSON.parse(res.data.message).samplePath;
                  var tData = {};
                  if (Object.keys(this.voiceprofileList).length == 0) {
                    tData[res.data.accountId + '-voiceprofileList'] = JSON.parse(res.data.message);
                    this.voiceprofileList = tData;
                  } else {
                    this.voiceprofileList[JSON.parse(res.data.message).accountID + '-voiceprofileList'] = JSON.parse(res.data.message);
                  }
                  this.voiceprofileName = JSON.parse(res.data.message).profileName;
                  
                }
              })
              .catch(error => console.log(error))
            });
          },
          addEditFupModalSubmit(postData) {
            return new Promise((resolve, reject) => {
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/profiles/fup_template/' + postData.fupAccountId, qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
        
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var dataList = {
                    fupdata: response.data.fupTempid,
                    accountId: postData.fupAccountId,
                    type: postData.type
                  }
                  //commit('ADD_FUP_LIST', dataList)
                  var fupData = dataList.fupdata;
                  if (Object.keys(this.fupTemplateList).length != 0 && this.fupTemplateList != 'noData') {
                    if (this.fupTemplateList != 'noData' || dataList.type == "edit" || dataList.type == "add") {
                      for (var i = 0; i < fupData.length; i++) {
                        var index = this.fupTemplateList[dataList.accountId + "-fupTemplateList"].findIndex(fupd => fupd.id == fupData[i].id);
                        if (index != '-1') {
                          this.fupTemplateList[dataList.accountId + "-fupTemplateList"].splice(index, 1)
                        }
                        this.fupTemplateList[dataList.accountId + "-fupTemplateList"].push(fupData[i]);
              
                      }
                    }
                  } else {
                    var dataSet = {}
                    dataSet[dataList.accountId + "-fupTemplateList"] = fupData;
                    this.fupTemplateList = dataSet;
                  }
        
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          importFupCopy(postData) {
            return new Promise((resolve, reject) => {
        
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/profiles/import_fup_file_copy', postData)
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
        
                  var accountId = '';
                  for (let [key, val] of postData.entries()) {
                    if (key == 'accountId') {
                      accountId = val;
                      break;
                    }
                  }
                  var dataList = {
                    fupdata: response.data.fupData,
                    accountId: response.data.fupData[0].account_id
                  }
        
                  //commit('ADD_FUP_LIST', dataList)
                  var fupData = dataList.fupdata;
                  if (Object.keys(this.fupTemplateList).length != 0 && this.fupTemplateList != 'noData') {
                    if (this.fupTemplateList != 'noData' || dataList.type == "edit" || dataList.type == "add") {
                      for (var i = 0; i < fupData.length; i++) {
                        var index = this.fupTemplateList[dataList.accountId + "-fupTemplateList"].findIndex(fupd => fupd.id == fupData[i].id);
                        if (index != '-1') {
                          this.fupTemplateList[dataList.accountId + "-fupTemplateList"].splice(index, 1)
                        }
                        this.fupTemplateList[dataList.accountId + "-fupTemplateList"].push(fupData[i]);
              
                      }
                    }
                  } else {
                    var dataSet = {}
                    dataSet[dataList.accountId + "-fupTemplateList"] = fupData;
                    this.fupTemplateList = dataSet;
                  }
        
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          importFupModal(postData) {
            return new Promise((resolve, reject) => {
        
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/profiles/import_fup_file', postData)
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
        
                  var accountId = '';
                  for (let [key, val] of postData.entries()) {
                    if (key == 'accountId') {
                      accountId = val;
                      break;
                    }
                  }
                  var dataList = {
                    fupdata: response.data.fupData,
                    accountId: response.data.fupData[0].account_id
                  }
        
                  //commit('ADD_FUP_LIST', dataList)
                  var fupData = dataList.fupdata;
                  if (Object.keys(this.fupTemplateList).length != 0 && this.fupTemplateList != 'noData') {
                    if (this.fupTemplateList != 'noData' || dataList.type == "edit" || dataList.type == "add") {
                      for (var i = 0; i < fupData.length; i++) {
                        var index = this.fupTemplateList[dataList.accountId + "-fupTemplateList"].findIndex(fupd => fupd.id == fupData[i].id);
                        if (index != '-1') {
                          this.fupTemplateList[dataList.accountId + "-fupTemplateList"].splice(index, 1)
                        }
                        this.fupTemplateList[dataList.accountId + "-fupTemplateList"].push(fupData[i]);
              
                      }
                    }
                  } else {
                    var dataSet = {}
                    dataSet[dataList.accountId + "-fupTemplateList"] = fupData;
                    this.fupTemplateList = dataSet;
                  }
        
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          deleteFupTemplateModalSubmit(postData) {
            return new Promise((resolve, reject) => {
        
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/profiles/delete_fuptemplate.json', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  var successData = {
                    ids: response.data.successIds,
                    accountId: postData.accountId
                  }
                  //commit('DELETE_FUP_LIST', successData);
                  if (Object.keys(this.fupTemplateList).length != 0) {
                    for (var i = 0; i < successData.ids.length; i++) {
                      var index = this.fupTemplateList[successData.accountId + "-fupTemplateList"].findIndex(fupd => fupd.id == successData.ids[i]);
                      this.fupTemplateList[successData.accountId + "-fupTemplateList"].splice(index, 1)
                    }
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          fetchFupTemplateList(authData) {
            return new Promise((resolve, reject) => {
              Nprogress.start()
              axios.get('/profiles/fup_template_list')
                .then(res => {
                  Nprogress.done()
                  if (res.data.status == "redirect") {
                    router.push(res.data.message);
                  }else if(res.data.status=="error" && res.data.isDatabaseAccessibleCheck=="yes"){
                    resolve(res.data);
                    
                  } else {
                    //commit('SET_FUPTEMPLATE_LIST', res.data)
                    var tData = {};
                    tData.accountId = res.data.accountId;
                    var parseData = JSON.parse(res.data.message);
                    if (parseData != null) {
                      if (Object.keys(this.fupTemplateList).length == 0) {
                        tData[res.data.accountId + '-fupTemplateList'] = parseData;
                        this.fupTemplateList = tData;
                      } else {
                        this.fupTemplateList[res.data.accountId + '-fupTemplateList'] = parseData;
                      };
                    }
                    //commit('SET_FUP_CREATE_DATAS', res.data);
                    if (res.data.createData != undefined) {
                      this.createFupDatas = res.data.createData;
                    } else {
                      this.createFupDatas = res.data;
                    }
                  }
                  resolve(res.data);
        
                })
                .catch(error => console.log(error))
            });
          },
          fupZoneChange(postData) {
            return new Promise((resolve, reject) => {
        
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/profiles/fup_template/' + postData)
                .then(response => {
                  JsLoadingOverlay.hide();
                 // commit('SET_FUP_CREATE_DATAS', response.data.createData);
                  if (response.data.createData.createData != undefined) {
                    this.createFupDatas = response.data.createData.createData;
                  } else {
                    this.createFupDatas = response.data.createData;
                  }
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  if (response.data.fupTempid == null) {
                    this.fupTemplateList = [];
                    return
                  }
                  //commit('SET_FUP_ZONE_CHANGE', postData);
                  this.accountId = postData;
                  var dataSet = {}
                  dataSet[postData + "-fupTemplateList"] = response.data.fupTempid;
                  //commit("UPDATE_FUPZONE", dataSet);
                  this.fupTemplateList = dataSet;
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
          },
          addFupToAllSubZones(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/profiles/add_fup_template_to_all_accounts', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data.genericBody);
              })
              .catch(error => console.log(error));
          });
        },
        addEditDynamicVendor(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/profiles/create_dynamic_vendor', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var dataList = {
                  dynamicdata: response.data.newData,
                  accountId: response.data.accountId,
                  type: postData.type
                }
                //commit('ADD_DYNAMIC_VENDOR_DATA', dataList)
                var dynamicData = dataList.dynamicdata;
                if (dynamicData.length > 0) {
                  for (var i = 0; i < dynamicData.length; i++) {
                    var index = this.dynamicVendorAttributeData[dataList.accountId + "-dynamicVendorList"].dynamicVendors.findIndex(dynamicd => dynamicd.DynamicVendorAttributes.id == dynamicData[i].DynamicVendorAttributes.id);
                    if (index != '-1') {
                      this.dynamicVendorAttributeData[dataList.accountId + "-dynamicVendorList"].dynamicVendors.splice(index, 1)
                    }
                    this.dynamicVendorAttributeData[dataList.accountId + "-dynamicVendorList"].dynamicVendors.push(dynamicData[i]);
                  }
                }
      
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        deleteDynamicVendor(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/profiles/delete_dynamic_vendor', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                //commit("DELETE_DYNAMIC_VENDOR_ATTRIBUTE", response.data.deletedData)
                var dynamicData = response.data.deletedData;
                var index = this.dynamicVendorAttributeData[response.data.deletedData.DynamicVendorAttributes.account_id + "-dynamicVendorList"].dynamicVendors.findIndex(dynamicd => dynamicd.DynamicVendorAttributes.id == dynamicData.DynamicVendorAttributes.id);
                this.dynamicVendorAttributeData[response.data.deletedData.DynamicVendorAttributes.account_id + "-dynamicVendorList"].dynamicVendors.splice(index, 1)
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        fetchDynamicVendorAttribute(authData) {
          return new Promise((resolve, reject) => {
            Nprogress.start()
          axios.get('/profiles/dynamic_vendor_attributes_list')
            .then(res => {
              Nprogress.done()
              if (res.data.status == "redirect") {
                router.push(res.data.message);
              }else if(res.data.status=="error" && res.data.isDatabaseAccessibleCheck=="yes"){
                resolve(res.data);
              } else {
                //commit('SET_DYNAMIC_VENDOR_LIST', res.data)
                this.dynamicVendorAttributeDataAccountId = res.data.datas
                var tData = {};
                if (Object.keys(this.dynamicVendorAttributeData).length == 0) {
                  tData[res.data.accountId + '-dynamicVendorList'] = res.data.datas;
                  this.dynamicVendorAttributeData = tData;
                } else {
                  this.dynamicVendorAttributeData[res.data.accountId + '-dynamicVendorList'] = res.data.datas;
                }
              }
      
            })
            .catch(error => console.log(error))
          });
        },
        getVoucherAccessTemplateList(postData) {
          return new Promise((resolve, reject) => {
            Nprogress.start();
            axios.get('/profiles/voucher_access_template/true')
              .then(response => {
                Nprogress.done();
                if (response.data.status != 'success') {
                  reject(response.data);
                  return;
                }
                var tData = {};
                var parseData = response.data.message;
                this.voucherAccessTemplateList = [];
                if (parseData != null) {
                  tData[response.data.accountId + '-voucherAccessTemplateList'] = parseData;
                  this.voucherAccessTemplateList = tData;
                }                
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        createDynamicPoolNames(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/profiles/create_dynamic_pool_names', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        fetchDynamicVendorPoolName(authData) {
          Nprogress.start()
          var id = authData.userId;
          var accountId = authData.accountId;
          axios.get('/profiles/dynamic_vendor_vlan_pool_names_list/' + id + '/' + accountId)
            .then(res => {
              Nprogress.done()
              this.dynamicVendorPoolName = res.data.datas;      
            })
            .catch(error => console.log(error))
        }, 
        fetchAditEditProfile(authData) {
          Nprogress.start()
          axios.get(authData.path)
            .then(res => {
              Nprogress.done()
              if (res.data.status == "redirect") {
                router.push(res.data.message);
              } else {
                this.profileData = res.data.message;
              }
            })
            .catch(error => console.log(error))
        }, 
        applyTestPackage(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/profiles/apply_testPack', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }      
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },  
        billingRangeFormSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/profiles/profile_billing_model', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
                var data = response.data;
                if (postData.profileType == "IPTV" || postData.profileType == "IPPhone") {
                  this.profileData['jbillPlansIP'] = data.planName;
                } else {
                  this.profileData['jbillPlans'] = data.planName;
                  this.ottProfileData['jbillPlans'] = data.planName;
                }                
              })
              .catch(error => console.log(error));
          });
        },
        deleteVoucherTemplate(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/profiles/delete_voucher_template', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var data = {
                  id: response.data.id,
                  accountId: postData.accountId
                }
                if (Object.keys(this.voucherAccessTemplateList).length != 0) {
                  var index = this.voucherAccessTemplateList[data.accountId + "-voucherAccessTemplateList"].templates.findIndex(voucher => voucher.VoucherAccessTemplate.id == data.id);
                  if (index != -1)
                    this.voucherAccessTemplateList[data.accountId + "-voucherAccessTemplateList"].templates.splice(index, 1)
                }                
      
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        deleteIspSubPlan(postData) {
          return new Promise((resolve, reject) => {      
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/profiles/delete_isp_sub_plan', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }         
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        addEditVoucherAccessTemplateSubmit(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/profiles/voucher_access_template/true', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                var data = {
                  voucherDetails: response.data.voucherDetails,
                  accountId: postData.accountId,
                  type: postData.type
                }
                console.log('Data : ', data);
                var accountId = data.accountId;
                if (Object.keys(this.voucherAccessTemplateList).length != 0) {
                  if (data.type == 'add') {
                    this.voucherAccessTemplateList[accountId + "-voucherAccessTemplateList"].templates.push(data.voucherDetails);
                  } else {
                    var index = this.voucherAccessTemplateList[accountId + "-voucherAccessTemplateList"].templates.findIndex(voucher => voucher.VoucherAccessTemplate.id == data.voucherDetails.VoucherAccessTemplate.id);
                    if (index != -1)
                      this.voucherAccessTemplateList[accountId + "-voucherAccessTemplateList"].templates[index].VoucherAccessTemplate.price = data.voucherDetails.VoucherAccessTemplate.price;
                    this.voucherAccessTemplateList[accountId + "-voucherAccessTemplateList"].templates[index].VoucherAccessTemplate.name = data.voucherDetails.VoucherAccessTemplate.name;
                  }
            
                }                      
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        clearProfilesState() {
          //commit('CLEAR_PROFILES_STATE')
          this.bandwidthList = [];
          this.bandwidthSampleFilePath = '';
          this.profileSampleFilePath = '';
          this.ottprofileSampleFilePath = '';
          this.bandwidthRoles = [];
          this.fupTemplateList = [];
          this.accountId = '';
          this.operatorValue='';
          this.createFupDatas = [];
          this.dynamicVendorAttributeData = [];
          this.profileList = [];
          this.profileListDetails = [];
          this.ottprofileList = [];
          this.ottprofileListDetails = [];
          this.iptvprofileList = [];
          this.iptvprofileListDetails = [];
          this.profileName = [];
          this.ottProfileName = [];
          this.iptvProfileName = [];
          this.profilePermission = [];
          this.ottprofilePermission = [];
          this.iptvprofilePermission = [];
          this.profileData = [];
          this.ottProfileData=[];
          this.iptvProfileData=[];
          this.voiceprofileSampleFilePath = '';
          this.voiceprofileName = [];
          this.voiceProfileData=[];
          this.voiceprofileList = [];
          this.voiceprofileListDetails = [];
          this.voiceprofilePermission = [];
        },
        fetchAditEditIptvProfile(authData) {
          Nprogress.start()
          axios.get(authData.path)
            .then(res => {
              Nprogress.done()
              if (res.data.status == "redirect") {
                router.push(res.data.message);
              } else {
                // commit('SET_ADD_EDIT_IPTV_PROFILE', res.data);
                this.iptvProfileData = res.data.message;
              }
            })
            .catch(error => console.log(error))
        },
        addEditIptvProfileSubmit(data) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            var postData = data.postData;
            var url = data.url;
            axios.post(url, qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        getusergroup(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/profiles/get_ott_plans', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
                if(postData.from == "ott_profile_page") {
                  response.data.from = "ott_profile_page";
                  // commit('UPDATE_OTT_PLAN', response.data);
                } else {
                  response.data.from = "profile_page";
                  // commit('UPDATE_OTT_PLAN', response.data);
                }
                var data = response.data;
                this.operatorValue=data.operatorValue;
                if(data.from == "ott_profile_page") {
                  this.ottProfileData['ottPlans'] = data.message;
                } else {
                  // this.profileData = data.message;
                  this.profileData['ottPlans']=data.message;
                  
                }
              })
              .catch(error => console.log(error));
          });
        },
        fetchAditEditOttProfile(authData) {
          Nprogress.start()
          axios.get(authData.path)
            .then(res => {
              Nprogress.done()
              if (res.data.status == "redirect") {
                router.push(res.data.message);
              } else {
                // commit('SET_ADD_EDIT_OTT_PROFILE', res.data);
                this.ottProfileData = res.data.message;
              }
            })
            .catch(error => console.log(error))
        },
        addEditOttProfileSubmit(data) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            var postData = data.postData;
            var url = data.url;
            axios.post(url, qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        addEditProfileSubmit(data) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            var postData = data.postData;
            var url = data.url;
            axios.post(url, qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
        getVoicePhonePlans(postData) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            axios.post('/profiles/get_nexge_plans', qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
                // commit('UPDATE_VOICEPHONE_PLAN', response.data);
                this.voiceProfileData['voicePhonePlans'] = response.data.message;
              })
              .catch(error => console.log(error));
          });
        },
        fetchAditEditVoiceProfile(authData) {
          Nprogress.start()
          axios.get(authData.path)
            .then(res => {
              Nprogress.done()
              if (res.data.status == "redirect") {
                router.push(res.data.message);
              } else {
                // commit('SET_ADD_EDIT_VOICE_PROFILE', res.data);
                this.voiceProfileData = res.data.message;
              }
            })
            .catch(error => console.log(error))
        },
        addEditVoiceProfileSubmit(data) {
          return new Promise((resolve, reject) => {
            JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
            var postData = data.postData;
            var url = data.url;
            axios.post(url, qs.stringify(postData, {
              parseArrays: false
            }))
              .then(response => {
                JsLoadingOverlay.hide();
                if (response.data.status != 'success') {
                  reject(response.data.message);
                  return;
                }
                resolve(response.data);
              })
              .catch(error => console.log(error));
          });
        },
    }
  })
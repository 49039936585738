<template>
  <div class="jazeMainPanel" style="height: 100%;">
    <div class="jazePanelContent jazePanelContent--scrollable">
      <div class="unifiSettingsContainer">
        <form ref="routerForm" class="appForm appForm--cozy is-validation-hidden">
          <div class="unifiSettingsHeader">
            <div class="unifiSettingsHeader__title">Router Visibility</div>
          </div>
          <fieldset class="appFieldset appFieldset--quiet">
            <div class="unifiSettingsSection" v-if="Object.keys(routerSettingsData).length != 0">
              <div v-if="subAccountMergeExtraArrGetter!= undefined && subAccountMergeExtraArrGetter.length!=0 && subAccountMergeExtraArrData != undefined && subAccountMergeExtraArrData.length!=0">
                <div v-for="(routerInterface,index) in subAccountMergeExtraArrData" :key=routerInterface.id>
                  <div class="appRow">
                    <div class="col--sm6">
                      <label class="appLabel appLabel--primary appLabel--boxAlign" v-if="routerSettingsData.edition=='isp'"> Zone Name: {{routerInterface.name}}</label>
                      <label class="appLabel appLabel--primary appLabel--boxAlign" v-else> Account Name: {{routerInterface.name}}</label>
                    </div>
                  </div>
                  <div class="appRow">
                    <div class="col--sm3">
                      <label class="appLabel appLabel--primary appLabel--boxAlign"> Visible Interface : </label>
                    </div>
                    <div class="col--sm5 appFormGroup appFormGroup--cozy">
                      <treeselect @open='opendiv(index)' :load-options="loadOptions" :options="routerInterfaceOptions" v-model="selectedInterfaceValues['visibleMikrotikRouter-' + routerInterface.id]" :multiple="true" :searchable="true" :clearable="false" placeholder="Select the Interface"></treeselect>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="appRow col--sm10">
                  <label class="appLabel appLabel--primary appLabel--boxAlign" v-if="routerSettingsData.edition=='isp'">There are no sub zones!</label>
                  <label class="appLabel appLabel--primary appLabel--boxAlign" v-else>There are no sub accounts!</label>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
        <div class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark">
          <div class="appFormFooter__right" v-if="subAccountMergeExtraArrGetter!= undefined && subAccountMergeExtraArrGetter.length!=0">
            <button class="appMainButton appMainButton--primary busyToggle" type="submit" @click="saveRouterSettings"> Save</button>
          </div>
        </div>
      </div>
    </div>

    <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
    <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
  </div>
</template>

<script>
import { ref,computed,watch,watchEffect,onMounted,onBeforeUnmount } from 'vue'
import Treeselect from "jaze-vue3-treeselect";
import { overrideIsSelected } from "@/overrides/vue3-treeselect";
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import { uselayoutStore } from '@/store/layoutstore'
import { storeToRefs } from "pinia";
import { usesettingsStore } from '@/store/settingsstore'
export default {
  mixins: [overrideIsSelected()],
  setup() {
    const routerSettingsData = ref( [] )
    const successtoaster = ref( false )
    const successMessage = ref( "" )
    const errortoaster = ref( false )
    const errorMessage = ref( "" )
    const routerInterfaces = ref( [] )
    const visibleInterface = ref( new Array() )
    const selectedInterfaceValues = ref( [] )
    const routerOptions = ref( [] )
    const routerInterfaceOptions = ref( null )
    const interfaceOptions = ref( [] )

    const settingsStore = usesettingsStore()
    const { routerData } = storeToRefs(settingsStore)
    const routerSettingsGetter = computed(() => routerData.value)
    const layoutStore = uselayoutStore()
    const { subAccountMergeExtraArr } = storeToRefs(layoutStore)
    const subAccountMergeExtraArrGetter= computed(() => subAccountMergeExtraArr.value);

    const subAccountMergeExtraArrData = computed(() => {
      var ret=new Array();
      if (
        Object.keys(routerSettingsData.value).length != 0 &&
        subAccountMergeExtraArrGetter.value != undefined &&
        subAccountMergeExtraArrGetter.value.length != 0
      ) {
        var subAccArr = subAccountMergeExtraArrGetter.value;
        subAccArr.splice(-1,1);
        var apArr = routerSettingsData.value.aps;
        var superAccArr = routerSettingsData.value.superAccountapsArr;
        var interfaceArr = [];
        var visibleInterfaceArr = [];
        var tree = new Array();
        var vueTree = [];
        var selected = [];
        routerInterfaces.value = [];
        for (let i = 0; i < subAccArr.length; i++) {
          routerInterfaces.value.push({
            zoneId: subAccArr[i]["id"],
            zonename: subAccArr[i]["name"],
            interfaceTree: [],
            selectedInterface: []
          });
          for (let j = 0; j < apArr.length; j++) {
            if (
              Object.values(superAccArr).includes(apArr[j]["AccessPoint"]["ip"])
            ) {
              apArr.splice(j, 1);
            }
            if (
              apArr[j] != undefined &&
              apArr[j]["AccessPoint"]["interfaces"] != ""
            ) {
              interfaceArr = JSON.parse(apArr[j]["AccessPoint"]["interfaces"]);
              if (interfaceArr.length != 0) {
                var idDisable =
                  apArr[j]["AccessPoint"]["ip"] +
                  "_" +
                  apArr[j]["AccessPoint"]["name"];
                vueTree.push({
                  id: idDisable,
                  label: apArr[j]["AccessPoint"]["name"],
                  isDisabled: true
                });
                for (const [key, value] of Object.entries(interfaceArr)) {
                  var id = apArr[j]["AccessPoint"]["ip"] + "_" + value[0];
                  vueTree.push({ id: id, label: value[0], isDisabled: false });
                  if (
                    subAccArr[i]["visibleInterface"] != "" &&
                    subAccArr[i]["visibleInterface"] != "null"
                  ) {
                    visibleInterfaceArr = JSON.parse(
                      subAccArr[i]["visibleInterface"]
                    );
                    if (Array.isArray(visibleInterfaceArr)) {
                      for (let k = 0; k < visibleInterfaceArr.length; k++) {
                        if (visibleInterfaceArr[k] == id) {
                          tree.push({
                            name: subAccArr[i]["name"],
                            selectedInterfaces: id
                          });
                          if (k == visibleInterfaceArr.length - 1) {
                            selected = tree;
                          }
                        }
                      }
                    }
                  }
                }
                for (let m = 0; m < routerInterfaces.value.length; m++) {
                  if (routerInterfaces.value[m].zoneId == subAccArr[i]["id"]) {
                    if (selected.length != 0) {
                      for (let x = 0; x < selected.length; x++) {
                        if (
                          selected[x].name != undefined &&
                          selected[x].name == routerInterfaces.value[m].zonename
                        ) {
                          if (
                            !routerInterfaces.value[
                              m
                            ].selectedInterface.includes(
                              selected[x].selectedInterfaces
                            )
                          ) {
                            routerInterfaces.value[m].selectedInterface.push(
                              selected[x].selectedInterfaces
                            );
                          }
                        }
                      }
                    }
                    for (let n = 0; n < vueTree.length; n++) {
                      routerInterfaces.value[m].interfaceTree.push({
                        id: vueTree[n].id,
                        label: vueTree[n].label,
                        isDisabled: vueTree[n].isDisabled
                      });
                    }
                  }
                }
              }
            }
            vueTree = [];
          }
        }
        if (
          routerInterfaces.value != undefined &&
          routerInterfaces.value.length != 0
        ) {
          for (let i = 0; i < routerInterfaces.value.length; i++) {
            routerOptions.value[i] = routerInterfaces.value[i].interfaceTree;
            var zone = routerInterfaces.value[i].zoneId;
            selectedInterfaceValues.value["visibleMikrotikRouter-" + zone] = routerInterfaces.value[
              i
            ].selectedInterface;
          }
        }
        for (let i = 0; i < subAccArr.length; i++) {
          opendiv(i);
        }
        ret=subAccountMergeExtraArrGetter.value;
      }
      return ret;
    })

    onMounted(() => {
      getRouterData();
    })
    onBeforeUnmount(() => {
      settingsStore.clearSettingsState();
    })

    watchEffect(() => {
      routerSettingsData.value = routerSettingsGetter.value;
    })

    const getRouterData = () => {
      if (Object.keys(routerSettingsGetter.value).length == 0) {
        // $store.dispatch("fetchRouterData");
        settingsStore.fetchRouterData();
      } else {
        routerSettingsData.value = routerSettingsGetter.value;
      }
      if (Object.keys(subAccountMergeExtraArrGetter.value).length == 0) {
        layoutStore.fetchSubAccountMergeExtraArr();
      }
    }
    const opendiv = (index) => {
      var options = routerOptions.value[index];
      routerInterfaceOptions.value = options;
    }
    const loadOptions = ({ action, searchQuery, callback, instanceId }) => {
      callback(null);
    }
    const saveRouterSettings = (e) => {
      e.preventDefault();
      for (const [key, value] of Object.entries(selectedInterfaceValues.value)) {
        if (value.length == 0) {
          delete selectedInterfaceValues.value[key];
        }
      }
      // $store
      //   .dispatch("routerSettingsSubmit", selectedInterfaceValues.value)
      settingsStore.routerSettingsSubmit(selectedInterfaceValues.value)
        .then(
          response => {
            successtoaster.value = true;
            successMessage.value = response;
            setTimeout(
              function() {
                successtoaster.value = false;
              }.bind(this),
              2000
            );
          },
          error => {
            errortoaster.value = true;
            errorMessage.value = error;
            setTimeout(
              function() {
                errortoaster.value = false;
              }.bind(this),
              15000
            );
            console.log(error);
          }
        );
    }

    return {
      settingsStore,
      routerSettingsGetter: routerData,
      subAccountMergeExtraArrGetter,
      subAccountMergeExtraArrData,
      routerSettingsData,
      successtoaster,
      successMessage,
      errortoaster,
      errorMessage,
      routerInterfaces,
      visibleInterface,
      selectedInterfaceValues,
      routerOptions,
      routerInterfaceOptions,
      interfaceOptions,
      getRouterData,
      opendiv,
      loadOptions,
      saveRouterSettings,
    }
  },
  components: {
    Treeselect,
    SuccessToaster,
    ErrorToaster
  },
};
</script>

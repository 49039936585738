<template>
  <div>
    <fieldset class="appFieldset appFieldset--quiet">
      <div class="unifiSettingsSection">
        <table class="jazeTable jazeTable--hoverable mt-1 is-not-responsive">
          <thead>
            <tr>
              <th>
                <span>#</span>
              </th>
              <th>
                <span>Name</span>
              </th>
              <th>
                <span>Description</span>
              </th>
              <th>
                <span>Status</span>
              </th>
              <th>
                <button @click="addRule" class="jazeButton jazeButton--primary" title="Add Additional Field Rule">
                  <span class="jaze-icon--plus"></span> &nbsp;Add
                </button>
              </th>
            </tr>
          </thead>
          <tbody class="jazeTableBody">
            <tr v-if="additionalFieldRulesToDisplay != undefined && additionalFieldRulesToDisplay.length !=0" v-for="(rule, index) in additionalFieldRulesToDisplay">
              <td>
                <span> {{index + 1}} </span>
              </td>
              <td>
                <span> {{rule.name}} </span>
              </td>
              <td>
                <span>
                  <b>IF </b> [{{rule.label}} {{rule.symbol}} {{rule.value}}]
                  <b>THEN </b> [ <i>{{rule.action}} </i> : {{rule.labelEncoded}}]
                </span>
              </td>
              <td>
                <span> {{rule.status.toUpperCase()}} </span>
              </td>
              <td class="lastUpdated jazeTableCell--withActions jazeTableCell--smash pr-0">
                <div class="jazeTableCell__actions">
                  <div class="jazeButtonGroup">
                    <button title="Edit" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="editAdditionalFieldRule(rule)">
                      <span class="button__icon icon jaze-icon--pencil"></span><span class="button__label">Edit</span>
                    </button>
                    <button title="Duplicate" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="duplicateAdditionalFieldRule(rule)">
                      <span class="button__icon icon  jaze-icon--clipboard"></span><span class="button__label">Duplicate</span>
                    </button>
                    <button title="Delete" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="deleteAdditionalFieldRule(rule)">
                      <span class="button__icon icon jaze-icon--trashcan"></span><span class="button__label">Delete</span>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </fieldset>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="additionalFieldRuleAddEditModal">
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div class="jazeModal jazeModal--medium unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form ref="addPeriodForm" class="appForm appForm--cozy is-validation-hidden" :class="formClass">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title"> {{action}} Rule </div>
                  <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="additionalFieldRuleAddEditModal=false">
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeAlert jazeAlert--danger row col-12" v-show='errorShow'>
                <div class="col-6">
                  <strong>{{errorMessage}}</strong>
                </div>
              </div>
              <div class="jazeModal__main">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                    <div class="row">
                      <form ref="addEditRuleForm" id="addEditRuleForm" class="col--sm12">
                        <div class="col jazeFormGroup">
                          <div class="appRow">
                            <div class="col--sm3">
                              <label class="appLabel appLabel--boxInline">Name</label>
                            </div>
                            <div class="col--sm3 appFormGroup appFormGroup--cozy">
                              <input type="text" class="input--full" name="name" v-model="ruleName" required oninvalid="this.setCustomValidity(' ')" oninput="this.setCustomValidity('')" placeholder="Rule Name" maxlength="255" />
                            </div>
                          </div>
                          <div class="appRow">
                            <div class="col--sm3">
                              <label class="appLabel appLabel--boxInline">IF</label>
                            </div>
                            <div class="col--sm3 appFormGroup appFormGroup--cozy">
                              <select class="input--full lineHeight--normal without-standard-validation" name="field" v-model="ruleField">
                                <option v-if="dropdownAdditionalField !=undefined && dropdownAdditionalField.length!=0" v-for="field in dropdownAdditionalField" :value="field.MetaFieldName.id">
                                  <span v-if="field.MetaFieldName.type == 'drop_down'">
                                    {{field.MetaFieldName.name}}
                                  </span>
                                </option>
                              </select>
                            </div>
                            <div class="col--sm3 appFormGroup appFormGroup--cozy">
                              <select class="input--full lineHeight--normal without-standard-validation" name="symbol" required oninvalid="this.setCustomValidity(' ')" oninput="this.setCustomValidity('')" v-model="ruleSymbol">
                                <option value="==">Equal to</option>
                              </select>
                            </div>
                            <div class="col--sm3 appFormGroup appFormGroup--cozy">
                              <input type="text" class="input--full" name="value" v-model="ruleValue" required oninvalid="this.setCustomValidity(' ')" oninput="this.setCustomValidity('')" placeholder="Enter value" maxlength="64" />
                            </div>
                          </div>
                          <div class="appRow">
                            <div class="col--sm3">
                              <label class="appLabel appLabel--boxInline">Action</label>
                            </div>
                            <div class="col--sm3 appFormGroup appFormGroup--cozy">
                              <select class="input--full lineHeight--normal without-standard-validation" name="action" v-model="ruleAction">
                                <option value="hide">Hide</option>
                                <option value="show">Show</option>
                              </select>
                            </div>
                            <div class="col--sm4 appFormGroup appFormGroup--cozy">
                              <treeselect :multiple="true" name="action_values[]" :options="actionOption" v-model="ruleActionValues" ></treeselect>
                            </div>
                          </div>
                          <div class="appRow">
                            <div class="col--sm3">
                              <label class="appLabel appLabel--boxInline">Status</label>
                            </div>
                            <div class="col--sm3 appFormGroup appFormGroup--cozy">
                              <select class="input--full lineHeight--normal without-standard-validation" name="status" v-model="ruleStatus">
                                <option value="active">Active</option>
                                <option value="inactive">Disable</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--secondary" type="button" @click="additionalFieldRuleAddEditModal=false">Cancel</button>
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="addEditAdditionalFieldRule($event)">Add rule</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="deleteConfirmModal">
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form class="jazeForm jazeModal jazeModal--small unifiAddUser">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title"> Delete rule? </div>
                  <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="deleteConfirmModal=false">
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeModal__main">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                    <div class="row">
                      <div class="col jazeFormGroup jazeFormGroup--flex">
                        <label class="jazeLabel jazeLabel--primary">
                          Are you sure want to delete the rule <b>{{nameToDelete}}</b> ?
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--secondary" type="button" @click="deleteConfirmModal=false">Cancel</button>
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="deletePeriodConfirm">Yes</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
    <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
  </div>
</template>

<script>
import { ref,toRefs,computed,watch,watchEffect,onMounted } from 'vue'
import Treeselect from "jaze-vue3-treeselect";
import { overrideIsSelected } from "@/overrides/vue3-treeselect";
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";

import { storeToRefs } from "pinia";
import { usesettingsStore } from '@/store/settingsstore'
export default {
  setup(props) {
    const { additionalFields } = toRefs(props)
    const checkDbs = ref( true )
    const actionOption = ref( [] )
    // const additionalFieldRulesData = ref( [] )
    const formClass = ref( "" )
    const errorShow = ref( false )
    const errorMessage = ref( "" )
    const successtoaster = ref( false )
    const successMessage = ref( "" )
    const errortoaster = ref( false )
    const additionalFieldRuleAddEditModal = ref( false )
    const deleteConfirmModal = ref( false )
    const idToDelete = ref( "" )
    const nameToDelete = ref( "" )
    const action = ref( "Add" )
    const ruleId = ref( "" )
    const ruleName = ref( "" )
    const ruleField = ref( "" )
    const ruleSymbol = ref( "" )
    const ruleValue = ref( "" )
    const ruleAction = ref( "" )
    const ruleActionValues = ref( [] )
    const ruleStatus = ref( "" )

    const settingsStore = usesettingsStore()
    const { additionalFieldRulesData } = storeToRefs(settingsStore)
    const additionalFieldRulesGetter = computed(() => additionalFieldRulesData.value)

    const dropdownAdditionalField = computed(() => {
      var dropDownAddField = [];
      if (
        additionalFields.value.User != undefined &&
        additionalFields.value.User.length > 0
      ) {
        for (var i = 0; i < additionalFields.value.User.length; i++) {
          if (additionalFields.value.User[i].MetaFieldName.type == "drop_down") {
            dropDownAddField.push(additionalFields.value.User[i]);
          }
        }
        return dropDownAddField;
      }
    })

    const additionalFieldRulesToDisplay = computed(() => {
      if (
        additionalFieldRulesGetter.value != undefined &&
        additionalFieldRulesGetter.value.length != 0
      ) {
        for (let i = 0; i < additionalFieldRulesGetter.value.length; i++) {
          var label = getLabelNameFromId(
            additionalFieldRulesGetter.value[i].RequestAccessRule.field_type
          );
          additionalFieldRulesGetter.value[i].label = label;
          additionalFieldRulesGetter.value[
            i
          ].name = additionalFieldRulesGetter.value[i].RequestAccessRule.name
            .toLowerCase()
            .trim();
          additionalFieldRulesGetter.value[
            i
          ].status = additionalFieldRulesGetter.value[i].RequestAccessRule.status
            .toLowerCase()
            .trim();
          additionalFieldRulesGetter.value[
            i
          ].symbol = additionalFieldRulesGetter.value[
            i
          ].RequestAccessRule.symbol;
          additionalFieldRulesGetter.value[
            i
          ].value = additionalFieldRulesGetter.value[i].RequestAccessRule.value;
          additionalFieldRulesGetter.value[
            i
          ].action = additionalFieldRulesGetter.value[
            i
          ].RequestAccessRule.action;
          additionalFieldRulesGetter.value[
            i
          ].field = additionalFieldRulesGetter.value[i].RequestAccessRule.field;
          var labelEncoded = getLabelNameFromIdJsonEncoded(
            additionalFieldRulesGetter.value[i].RequestAccessRule.action_values
          );
          additionalFieldRulesGetter.value[i].labelEncoded = labelEncoded;
          var idEncoded = getIdJsonEncoded(
            additionalFieldRulesGetter.value[i].RequestAccessRule.action_values
          );
          additionalFieldRulesGetter.value[i].idEncoded = idEncoded;
        }
      }
      return additionalFieldRulesGetter.value;
    })

    onMounted(() => {
      getAdditionalFieldData();
    })

    const getAdditionalFieldData = () => {
      if (
        additionalFields.value.User != undefined &&
        additionalFields.value.User.length > 0
      ) {
        for (var key in additionalFields.value.User) {
          actionOption.value.push({
            id: additionalFields.value.User[key].MetaFieldName.name,
            label: additionalFields.value.User[key].MetaFieldName.name
          });
        }
      }
    }
    const getLabelNameFromId = (name) => {
      for (let i = 0; i < additionalFields.value.User.length; i++) {
        if (additionalFields.value.User[i].MetaFieldName.name == name) {
          return additionalFields.value.User[i].MetaFieldName.name;
        }
      }
      return name;
    }
    const getLabelNameFromIdJsonEncoded = (ids) => {
      if (ids != "") {
        ids = JSON.parse(ids);
        var data = [];
        for (var key in ids) {
          // data.push(getLabelNameFromId(ids[key]));
           data.push(ids[key]);
          var k = data.join();
        }
        return k;
      } else {
        return "";
      }
    }
    const getIdJsonEncoded = (ids) => {
      if (ids != "") {
        var idsAll = JSON.parse(ids);
        var labelName = [];
        for (let i = 0; i < idsAll.length; i++) {
          labelName.push(idsAll[i]);
        }
        return labelName;
      } else {
        return null;
      }
    }
    const addRule = () => {
      ruleId.value = "";
      ruleName.value = "";
      ruleField.value = "";
      ruleSymbol.value = "";
      ruleValue.value = "";
      ruleAction.value = "";
      ruleActionValues.value = [];
      ruleStatus.value = "";
      additionalFieldRuleAddEditModal.value = true;
    }
    const addEditAdditionalFieldRule = (e) => {
      e.preventDefault();
      successtoaster.value = false;
      errortoaster.value = false;
      var form = document.getElementById('addEditRuleForm');
      const formData = new FormData(form);
      const postData = {};
      var actionValues = [];
      for (let [key, val] of formData.entries()) {
        Object.assign(postData, { [key]: val });
        if (key == "action_values[]") {
          actionValues.push(val);
        }
      }
      postData["action_values"] = actionValues;
      var status = checkForm(postData);
      if (status == "error") {
        formClass.value = "is-form-validation-visible";
        return;
      }
      postData.fieldId = ruleId.value;
      errorShow.value = false;
      // $store.dispatch("addAdditionalFieldRuleSubmit", postData).then(
      settingsStore.addAdditionalFieldRuleSubmit(postData).then(  
        response => {
          additionalFieldRuleAddEditModal.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function() {
              successtoaster.value = false;
            }.bind(this),
            5000
          );
        },
        error => {
          additionalFieldRuleAddEditModal.value = false;
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function() {
              errortoaster.value = false;
            }.bind(this),
            1500
          );
          console.log(error);
        }
      );
    }
    const checkForm = (postData) => {
      if (postData.name == "") {
        errorMessage.value = "Name is Required";
        errorShow.value = true;
        return "error";
      }
      if (postData.value == "") {
        errorMessage.value = "Value is Required";
        errorShow.value = true;
        return "error";
      }
      if (postData.symbol == undefined || postData.symbol == "") {
        errorMessage.value = "Symbol is Required";
        errorShow.value = true;
        return "error";
      }
    }
    const editAdditionalFieldRule = (rule) => {
      action.value = "Edit";
      ruleId.value = rule.RequestAccessRule.id;
      ruleName.value = rule.RequestAccessRule.name;
      ruleField.value = rule.RequestAccessRule.field;
      ruleSymbol.value = rule.RequestAccessRule.symbol;
      ruleValue.value = rule.RequestAccessRule.value;
      ruleAction.value = rule.RequestAccessRule.action;
      ruleActionValues.value = rule.idEncoded;
      ruleStatus.value = rule.RequestAccessRule.status;
      additionalFieldRuleAddEditModal.value = true; 
    }
    const duplicateAdditionalFieldRule = (rule) =>{
      action.value = "Duplicate";
      ruleId.value = "";
      ruleName.value = rule.RequestAccessRule.name;
      ruleField.value = rule.RequestAccessRule.field;
      ruleSymbol.value = rule.RequestAccessRule.symbol;
      ruleValue.value = rule.RequestAccessRule.value;
      ruleAction.value = rule.RequestAccessRule.action;
      ruleActionValues.value = rule.idEncoded;
      ruleStatus.value = rule.RequestAccessRule.status;
      additionalFieldRuleAddEditModal.value = true;
    }
    const deleteAdditionalFieldRule = (rule) => {
      idToDelete.value = rule.RequestAccessRule.id;
      nameToDelete.value = rule.RequestAccessRule.name;
      deleteConfirmModal.value = true;
    }
    const deletePeriodConfirm = () => {
      var data = {
        fieldId: idToDelete.value
      };
      // $store.dispatch("deleteAdditionalFieldRuleSubmit", data).then(
      settingsStore.deleteAdditionalFieldRuleSubmit(data).then(  
        response => {
          deleteConfirmModal.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function() {
              successtoaster.value = false;
            }.bind(this),
            5000
          );
        },
        error => {
          deleteConfirmModal.value = false;
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function() {
              errortoaster.value = false;
            }.bind(this),
            1500
          );
          console.log(error);
        }
      );
    }

    return {
      settingsStore,
      additionalFieldRulesGetter: additionalFieldRulesData,
      additionalFields,
      dropdownAdditionalField,
      additionalFieldRulesToDisplay,
      checkDbs,
      actionOption,
      additionalFieldRulesData,
      formClass,
      errorShow,
      errorMessage,
      successtoaster,
      successMessage,
      errortoaster,
      additionalFieldRuleAddEditModal,
      deleteConfirmModal,
      idToDelete,
      nameToDelete,
      action,
      ruleId,
      ruleName,
      ruleField,
      ruleSymbol,
      ruleValue,
      ruleAction,
      ruleActionValues,
      ruleStatus,
      getAdditionalFieldData,
      getLabelNameFromId,
      getLabelNameFromIdJsonEncoded,
      getIdJsonEncoded,
      addRule,
      addEditAdditionalFieldRule,
      checkForm,
      editAdditionalFieldRule,
      duplicateAdditionalFieldRule,
      deleteAdditionalFieldRule,
      deletePeriodConfirm,
    }
  },
  props: ["additionalFields"],
  components: {
    Treeselect,
    SuccessToaster,
    ErrorToaster
  },
  mixins: [overrideIsSelected()],
};
</script>

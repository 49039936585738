const Full = () => import( '@/container/Full.vue')

// dashboard components
const announcementsIndexRoute = () => import(/* webpackPrefetch: false */ '@/views/announcements/Index.vue')
const triggersIndexRoute = () => import(/* webpackPrefetch: false */ '@/views/announcements/Triggers.vue')
const MessageToSubZone = () => import(/* webpackPrefetch: false */ '@/views/announcements/MessagesToSubZone.vue')
export default {
   path: '/announcements',
   component: Full,
   redirect: '/announcements',
   beforeEnter(to, from, next) {
      if (localStorage.getItem('token')) {
         next()
      } else {
         next('/signin')
      }
   },
   children: [
      {
         path: '/announcements',
         component: announcementsIndexRoute,
         meta:{
            title:"Announcements"
         }
      },
      {
         path: '/announcements/triggers',
         component: triggersIndexRoute,
         meta:{
            title:"Triggers"
         }
      },
      {
         path: '/announcements/messages',
         component: MessageToSubZone,
         meta:{
            title:"Message To SubZones"
         }
      }
   ]
}
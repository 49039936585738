const Full = () => import( '@/container/Full.vue')

const AadhaarkycRoutes = () => import(/* webpackPrefetch: false */ '@/views/externalIntegration/Aadhaarkyc.vue')
const AmazonS3Routes = () => import(/* webpackPrefetch: false */ '@/views/externalIntegration/AmazonS3.vue')
const CustomizeEmailContent = () => import(/* webpackPrefetch: false */ '@/views/externalIntegration/CustomizeEmailContent.vue')
const EmailRoutes = () => import(/* webpackPrefetch: false */ '@/views/externalIntegration/Email.vue')
const EnachRoutes = () => import(/* webpackPrefetch: false */ '@/views/externalIntegration/Enach.vue')
const FtpRoutes = () => import(/* webpackPrefetch: false */ '@/views/externalIntegration/FTP.vue')
const GoogleDriveRoutes = () => import(/* webpackPrefetch: false */ '@/views/externalIntegration/GoogleDrive.vue')
const IptvRoutes = () => import(/* webpackPrefetch: false */ '@/views/externalIntegration/IPTV.vue')
const IvrRoutes = () => import(/* webpackPrefetch: false */ '@/views/externalIntegration/IVR.vue')
const OneSignalRoutes = () => import(/* webpackPrefetch: false */ '@/views/externalIntegration/OneSignal.vue')
const OttRoutes = () => import(/* webpackPrefetch: false */ '@/views/externalIntegration/Ott.vue')
const PayGateRoutes = () => import(/* webpackPrefetch: false */ '@/views/externalIntegration/PaymentGateway.vue')
const PmsRoutes = () => import(/* webpackPrefetch: false */ '@/views/externalIntegration/PMS.vue')
const QuickBookRoutes = () => import(/* webpackPrefetch: false */ '@/views/externalIntegration/QuickBooks.vue')
const SmsRoutes = () => import(/* webpackPrefetch: false */ '@/views/externalIntegration/Sms.vue')
const SocialNetworkRoutes = () => import(/* webpackPrefetch: false */ '@/views/externalIntegration/SocialNetworks.vue')
const IpPhoneRoutes = () => import(/* webpackPrefetch: false */ '@/views/externalIntegration/VoicePhone.vue')
const WebHooksRoutes = () => import(/* webpackPrefetch: false */ '@/views/externalIntegration/WebHooks.vue')
const WhatsappRoutes = () => import(/* webpackPrefetch: false */ '@/views/externalIntegration/Whatsapp.vue')
const XpressConnectRoutes = () => import(/* webpackPrefetch: false */ '@/components/ExternalIntegration/XpressConnect.vue')
const UserStoreRoutes = () => import(/* webpackPrefetch: false */ '@/components/ExternalIntegration/UserStore.vue')
const AcsGatewayRoutes = () => import(/* webpackPrefetch: false */ '@/views/externalIntegration/AcsGateway.vue')
export default {
    path: '/customize_user_request_access/customize_sms_settings',
    component: Full,
    redirect: '/customize_user_request_access/customize_sms_settings',
    beforeEnter(to, from, next) {
        if (localStorage.getItem('token')) {
            next()
        } else {
            next('/signin')
        }
    },
    children: [
        {
            path: '/external_integration/customize_aadhaarkyc',
            component: AadhaarkycRoutes,
            meta:{
                title: "External Integration"
            }
        },
        {
            path: '/external_integration/amazon_s3',
            component: AmazonS3Routes,
            meta: {
                title: "External Integration"
            }
        },
        {
            path: '/customize_user_request_access/customize_email_content',
            component: CustomizeEmailContent,
            meta: {
                title: "External Integration"
            }
        },
        {
            path: '/customize_user_request_access/customize_email_settings',
            component: EmailRoutes,
            meta: {
                title: "External Integration"
            }
        },
        {
            path: '/external_integration/customize_enach',
            component: EnachRoutes,
            meta: {
                title: "External Integration"
            }
        },
        {
            path: '/external_integration/ftp',
            component: FtpRoutes,
            meta: {
                title: "External Integration"
            }
        },
        {
            path: '/external_integration/googledrive',
            component: GoogleDriveRoutes,
            meta: {
                title: "External Integration"
            }
        },
        {
            path: '/external_integration/customize_iptv',
            component: IptvRoutes,
            meta: {
                title: "External Integration"
            }
        },
        {
            path: '/external_integration/customize_ivr',
            component: IvrRoutes,
            meta: {
                title: "External Integration"
            }
        },
        {
            path: '/external_integration/one_signal',
            component: OneSignalRoutes,
            meta: {
                title: "External Integration"
            }
        },
        {
            path: '/external_integration/customize_ott',
            component: OttRoutes,
            meta: {
                title: "External Integration"
            }
        },
        {
            path: '/external_integration/payment_gateway_settings',
            component: PayGateRoutes,
            meta: {
                title: "External Integration"
            }
        },
        {
            path: '/external_integration/pms',
            component: PmsRoutes,
            meta: {
                title: "External Integration"
            }
        },
        {
            path: '/external_integration/quickbooks',
            component: QuickBookRoutes,
            meta: {
                title: "External Integration"
            }
        },
        {
            path: '/customize_user_request_access/customize_sms_settings',
            component: SmsRoutes,
            meta: {
                title: "External Integration"
            }
        },
        {
            path: '/external_integration/index',
            component: SocialNetworkRoutes,
            meta: {
                title: "External Integration"
            }
        },
        {
            path: '/external_integration/customize_ipphone',
            component: IpPhoneRoutes,
            meta: {
                title: "External Integration"
            }
        },
        {
            path: '/external_integration/web_hooks',
            component: WebHooksRoutes,
            meta: {
                title: "External Integration"
            }
        },
        {
            path: '/customize_user_request_access/customize_whatsapp_settings',
            component: WhatsappRoutes,
            meta: {
                title: "External Integration"
            }
        },
        {
            path: '/external_integration/xpress_connect',
            component: XpressConnectRoutes,
            meta: {
                title: "External Integration"
            }
        },
        {
            path: '/external_integration/user_store',
            component: UserStoreRoutes,
            meta: {
                title: "External Integration"
            }
        },
        {
            path: '/customize_user_request_access/customize_acs_gateway',
            component: AcsGatewayRoutes,
            meta: {
                title: "External Integration"
            }
        },
    ],
}
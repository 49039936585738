import { defineStore } from 'pinia'
import router from '@/router/index'
import axios from '@/api/index'
import qs from 'qs'
import Nprogress from 'nprogress';
import  '../../node_modules/js-loading-overlay/dist/js-loading-overlay.min.js'
export const usecpconfigurationStore = defineStore('cpconfigurationstore', {
    state: () => {
        return {
            cpConfigurationData: [],
            blockedMacs: []
        }
    },
    getters: {
        cpConfigurationDataState: (state) => state.cpConfigurationData,
        cpConfigurationGetter() {
            return this.cpConfigurationDataState
        },
        blockedMacsState: (state) => state.blockedMacs,
        blockedMacGetters() {
            return this.blockedMacsState;
        }
    },
    actions: {
        applyToAllSubZone(postData) {
            return new Promise((resolve, reject) => {
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('CpConfiguration/add_all_cpconfiguration_to_subaccounts', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if(response.data.status=='redirect')
                  {
                    router.push("/dashboard");
                  }
                  if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
        },
        fetchCpConfigurationDatas() {
            Nprogress.start();
            axios.get('/CpConfiguration/cpConfig_list')
              .then(res => {
                Nprogress.done()
                if (res.data.status == "redirect") {
                  router.push("/dashboard");
                }
                else{
                this.cpConfigurationData = JSON.parse(res.data.message);
                }
              })
              .catch(error => {
                if (error.response) {
                  if (error.response.status == 403) {
                    window.location = "/dashboard";
                  }
                }
              })
        },
        saveBlockedMacs(postData) {
            return new Promise((resolve, reject) => {
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/captive_portal_settings/save_blocked_macs', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if(response.data.status=='redirect')
                  {
                    router.push("/dashboard");
                  }
                  else if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                  }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
        },
        socialLogOnLike(postData) {
            return new Promise((resolve, reject) => {
              JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
              axios.post('/CpConfiguration/social_logon_like', qs.stringify(postData, {
                parseArrays: false
              }))
                .then(response => {
                  JsLoadingOverlay.hide();
                  if (response.data.status == "redirect") {
                    router.push("/dashboard");
                }
                else if (response.data.status != 'success') {
                    reject(response.data.message);
                    return;
                }
                  resolve(response.data);
                })
                .catch(error => console.log(error));
            });
        },
        fetchBlockedMacsDatas() {
          Nprogress.start();
          axios.get('/captive_portal_settings/blocked_macs_vue')
            .then(res => {
              Nprogress.done()
              if (res.data.status == "redirect") {
                router.push("/dashboard");
              }
              else{
              this.blockedMacs = JSON.parse(res.data.message)
              }
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 403) {
                  window.location = "/dashboard";
                }
              }
            })
      },
    }
})
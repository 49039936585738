
const Full = () => import('@/container/Full.vue')

const EventLogsRoutes = () => import(/* webpackPrefetch: false */ '../views/log/EventLogs.vue')
const EmailLogsRoutes = () => import(/* webpackPrefetch: false */ '../views/log/EmailLogs.vue')
const ReleaseNotesRoutes = () => import(/* webpackPrefetch: false */ '../views/log/ReleaseNotes.vue')
const UserLogsRoutes = () => import(/* webpackPrefetch: false */ '../views/log/UserLogs.vue')
const WebHookLogsRoutes = () => import(/* webpackPrefetch: false */ '../views/log/WebHookLogs.vue')
const Natlog = () => import(/* webpackPrefetch: false */ '@/views/log/Natlog.vue')
const Dhcplogs = () => import(/* webpackPrefetch: false */ '@/views/log/Dhcplogs.vue')
const SmsLogsRoutes = () => import(/* webpackPrefetch: false */ '@/views/log/SmsLogs.vue')
const WhatsappLogsRoutes = () => import(/* webpackPrefetch: false */ '@/views/log/WhatsappLogs.vue')
const PaymentLogsRoutes = () => import(/* webpackPrefetch: false */ '../views/log/PaymentLogs.vue')
const PmsLogsRoutes = () => import(/* webpackPrefetch: false */ '../views/log/PmsLogs.vue')
const FranchisePaymentLogsRoutes = () => import(/* webpackPrefetch: false */ '../views/log/FranchisePaymentLogs.vue')
const Cplogs = () => import(/* webpackPrefetch: false */ '@/views/log/CaptivePortalLogs.vue')


export default {
  path: "/logs",
  component: Full,
  redirect: "/logs/event_logs",
  beforeEnter(to, from, next) {
    if (localStorage.getItem("token")) {
      next();
    } else {
      next("/signin");
    }
  },
  children: [
    {
      path: "event_logs",
      component: EventLogsRoutes,
      meta: {
        title: "Event Logs",
      },
    },
    {
      path: '/logs/email_logs',
      component: EmailLogsRoutes,
      meta: {
          title: "Email Logs"
      }
    },
    {
      path: '/logs/release_notes',
      component: ReleaseNotesRoutes,
      meta: {
          title: "Release Notes"
      }
    },
    {
      path: '/logs/user_logs',
      component: UserLogsRoutes,
      meta: {
          title: "User Logs"
      }
    },
    {
      path: '/logs/webhook_logs',
      component: WebHookLogsRoutes,
      meta: {
          title: "Webhook Logs"
      }
    },
    {
      path: '/logs/nat_logs',
      component: Natlog,
      meta: {
          title: "Nat Logs"
      }
    },
    {
      path: '/logs/dhcp_leases',
      component: Dhcplogs,
      meta: {
          title: "DHCP Leases"
      }
    },
    {
      path: '/logs/sms_logs',
      component: SmsLogsRoutes,
      meta: {
          title: "Sms Logs"
      }
    },
    {
        path: '/logs/whatsapp_logs',
        component: WhatsappLogsRoutes,
        meta: {
            title: "Whatsapp Logs"
        }
    },
    {
        path: '/logs/payment_logs',
        component: PaymentLogsRoutes,
        meta: {
            title: "Payment Logs"
        }
    },
    {
        path: '/logs/pms_logs',
        component: PmsLogsRoutes,
        meta: {
            title: "PMS Logs"
        }
    },
    {
      path: '/logs/franchise_payment_logs',
      component: FranchisePaymentLogsRoutes,
      meta: {
          title: "Franchise Payment Logs"
      }
  },
  {
    path: '/logs/captive_portal_logs',
    component: Cplogs,
    meta: {
        title: "Captive Portal Logs"
    }
},
  ],
};

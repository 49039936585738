const Full = () => import( '@/container/Full.vue')

// dashboard components
const bandwidthRoutes = () => import(/* webpackPrefetch: false */ '@/views/profiles/Bandwidth.vue')
const fuptemplateRoutes = () => import(/* webpackPrefetch: false */ '@/views/profiles/FupTemplate.vue')
const DynamicVendorAttributesRoutes = () => import(/* webpackPrefetch: false */ '@/views/profiles/DynamicVendorAttributes.vue')
const DynamicVendorVlanPoolNameRoutes = () => import(/* webpackPrefetch: false */ '@/views/profiles/DynamicVendorVlanPoolName.vue')
const ProfileRoutes = () => import(/* webpackPrefetch: false */ '@/views/profiles/Profile.vue')
const voucherAccessTemplate = () => import(/* webpackPrefetch: false */ '@/views/profiles/VoucherAccessTemplate.vue')
const AddProfileRoutes = () => import(/* webpackPrefetch: false */ '@/views/profiles/AddProfile.vue')
const AddOttRoutes = () => import(/* webpackPrefetch: false */ '@/views/ott/AddOtt.vue')
const OttRoutes = () => import(/* webpackPrefetch: false */ '@/views/ott/Ott.vue')
const AddIptvRoutes = () => import(/* webpackPrefetch: false */ '@/views/iptvpackages/AddIptv.vue')
const IptvRoutes = () => import(/* webpackPrefetch: false */ '@/views/iptvpackages/Iptv.vue')
const VoiceRoutes = () => import(/* webpackPrefetch: false */ '@/views/profiles/Voice.vue')
const AddVoiceProfileRoutes = () => import(/* webpackPrefetch: false */ '@/views/profiles/AddVoiceProfile.vue')

export default {
   path: '/profiles',
   component: Full,
   redirect: '/profiles',
   beforeEnter(to, from, next) {
      if (localStorage.getItem('token')) {
         next()
      } else {
         next('/signin')
      }
   },
   children: [
      {
         path: '/profiles/bandwidth',
         component: bandwidthRoutes,
         meta: {
            title: "Bandwidth"
         }
      },
      {
         path: '/profiles/fup_template',
         component: fuptemplateRoutes,
         meta: {
            title: "Fup Template"
         }
      },
      {
         path: '/profiles/dynamic_vendor_attributes',
         component: DynamicVendorAttributesRoutes,
         meta: {
            title: "Dynamic Vendor Attributes"
         }
      },
      {
         path: '/profiles/dynamic_vendor_vlan_pool_names/:accountId/:id',
         component: DynamicVendorVlanPoolNameRoutes,
         meta: {
            title: "Dynamic Vendor Attributes"
         }
      },
      {
         path: '/profiles',
         component: ProfileRoutes,
         meta: {
            title: "Usage Packages"
         }
      },
      {
         path: '/profiles/ott',
         component: OttRoutes,
         meta: {
            title: "OTT Packages"
         }
      },
      {
         path: '/profiles/iptv_profiles',
         component: IptvRoutes,
         meta: {
            title: "IPTV Packages"
         }
      },
      {
         path: '/profiles/voucher_access_template',
         component: voucherAccessTemplate,
         meta: {
            title: "Voucher Access Template"
         }
      },
      {
         path: '/profiles/add_profile',
         component: AddProfileRoutes,
         meta: {
            title: "Usage Packages"
         }
      },
      {
         path: '/profiles/add_ott_profile',
         component: AddOttRoutes,
         meta: {
            title: "OTT Packages"
         }
      },
      {
         path: '/profiles/add_iptv_profile',
         component: AddIptvRoutes,
         meta: {
            title: "IPTV Packages"
         }
      },
      {
         path: '/profiles/edit_profiles/:profileId',
         component: AddProfileRoutes,
         meta: {
            title: "Usage Packages"
         }
      },
      {
         path: '/profiles/edit_ott_profiles/:profileId',
         component: AddOttRoutes,
         meta: {
            title: "OTT Packages"
         }
      },
      {
         path: '/profiles/edit_iptv_profiles/:profileId',
         component: AddIptvRoutes,
         meta: {
            title: "IPTV Packages"
         }
      },
      {
         path: '/profiles/edit_ott_profiles/:profileId/:duplicate',
         component: AddOttRoutes,
         meta: {
            title: "OTT Packages"
         }
      },
      {
         path: '/profiles/edit_iptv_profiles/:profileId/:duplicate',
         component: AddIptvRoutes,
         meta: {
            title: "IPTV Packages"
         }
      },
      {
         path: '/profiles/voices',
         component: VoiceRoutes,
         meta: {
            title: "Voice Packages"
         }
      },
      {
         path: '/profiles/add_voice_profile',
         component: AddVoiceProfileRoutes,
         meta: {
            title: "Voice Packages"
         }
      },
      {
         path: '/profiles/edit_voice_profile/:profileId',
         component: AddVoiceProfileRoutes,
         meta: {
            title: "Voice Packages"
         }
      },
      {
         path: '/profiles/edit_voice_profile/:profileId/:duplicate',
         component: AddVoiceProfileRoutes,
         meta: {
            title: "Voice Packages"
         }
      },
      {
         path: '/profiles/edit_profiles/:profileId/:duplicate',
         component: AddProfileRoutes,
         meta: {
            title: "Usage Packages"
         }
      }
   ]
}

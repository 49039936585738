const Full = () => import('@/container/Full.vue')

const AccountBillingDetails = () => import(/* webpackPrefetch: false */ '@/views/Payment/AccountBillingDetails.vue')
const Index = () => import(/* webpackPrefetch: false */ '@/views/Payment/Index.vue')
const Success = () => import(/* webpackPrefetch: false */ '@/views/Payment/Success.vue')

export default {
   path: '/payment',
   component: Full,
   redirect: '/payment',
   beforeEnter(to, from, next) {
      if (localStorage.getItem('token')) {
         next()
      } else {
         next('/signin')
      }
   },
   children: [
      {
         path: '/payment/account_billing_details',
         component: AccountBillingDetails,
         meta: {
            title: "Billing Detail"
         }
      },
      {
         path: '/payment',
         component: Index,
         meta: {
            title: "Payment Information"
         }
      },
      {
         path: '/payment/success/:accountId/:txnid/:amount/:balance',
         component: Success,
         meta: {
            title: "Payment Information"
         }
      },
   ]
}
const Full = () => import( '@/container/Full.vue')

// dashboard components
const pinBillingRoute = () => import(/* webpackPrefetch: false */ '@/views/billing/PinBilling.vue')
const allPinsRoutes = () => import(/* webpackPrefetch: false */ '@/views/billing/AllPins.vue')
const viewPinsRoutes = () => import(/* webpackPrefetch: false */ '@/views/users/UsersViewPins.vue')
const pinBunchGroupRoutes = () => import(/* webpackPrefetch: false */'@/views/billing/PinBunchGroup.vue')
const pinBunchViewRoutes = () => import(/* webpackPrefetch: false */'@/views/billing/PinBunchView.vue')

export default {
   path: '/pin_billing',
   component: Full,
   redirect: '/pin_billing',
   beforeEnter(to, from, next) {
      if (localStorage.getItem('token')) {
         next()
      } else {
         next('/signin')
      }
   },
   children: [
      {
         path: '/pin_billing',
         component: pinBillingRoute,
         meta: {
            title: "Pin Billing"
         }
      },
      {
         path: '/pin_billing/all_pins',
         component: allPinsRoutes,
         meta: {
            title: "Pin Billing"
         }
      },
      {
         path: '/pin_billing/view_pins/:pinGroupId',
         component: viewPinsRoutes,
         meta: {
            title: "Pin Billing"
         }
      },
      {
         path: '/pin_billing/pin_bunch_group',
         component: pinBunchGroupRoutes,
         meta: {
            title: "Pin Billing"
         }
      },
      {
         path: '/pin_billing/pin_bunch_view/:bunchId',
         component: pinBunchViewRoutes,
         meta: {
            title: "Pin Billing"
         }
      }

   ]
}
const Full = () => import( '@/container/Full.vue')

// dashboard components
const acsRoutes = () => import(/* webpackPrefetch: false */ '@/views/acs/Index.vue')
const acsDevicesRoutes = () => import(/* webpackPrefetch: false */ '@/views/acs/AcsDevices.vue')
const acsConfigRoutes = () => import(/* webpackPrefetch: false */ '../views/acs/AcsConfig.vue')
export default {
  path: '/routers',
  component: Full,
  redirect: '/routers',
  beforeEnter(to, from, next) {
    if (localStorage.getItem('token')) {
      next()
    } else {
      next('/signin')
    }
  },
  children: [
    {
      path: '/acs',
      component: acsRoutes,
      meta: {
        title: "ACS"
      }
    },
    {
      path: '/acs/acs_devices',
      component: acsDevicesRoutes,
      meta: {
        title: "Devices"
      }
    },
    {
      path: '/acs/acs_device_detail/:macDeviceId',
      component: acsConfigRoutes,
      meta: {
        title: "TR069 Config"
      }
    },
  ]
}

import { defineStore } from 'pinia'

import router from '@/router/index'
import axios from '@/api/index'
import qs from 'qs'
import Nprogress from 'nprogress';
import  '../../node_modules/js-loading-overlay/dist/js-loading-overlay.min.js'
export const useleadStore = defineStore('leadstore', {
    state: () => {
      return {
        leadManagement: [],
        addLeadData: [],
        dashBoardData: [],
        leadRecentData: [],
        leadClassification: [],
        assignData: [],
        completedData: [],
        droppedData: [],
        leadSLA: []
      }
    },
    getters: {
        leadManagementDataState: (state) => state.leadManagement,
        leadManagementGetters() {
            return this.leadManagementDataState
        },
        addLeadDataState: (state) => state.addLeadData,
        addLeadGetter() {
            return this.addLeadDataState
        },
        dashBoardDataState: (state) => state.dashBoardData,
        leadDashboardListGetters() {
            return this.dashBoardDataState
        },
        leadRecentDataState: (state) => state.leadRecentData,
        leadRecentDataListGetters() {
            return this.leadRecentDataState
        },
        leadClassificationState: (state) => state.leadClassification,
        leadClassificationGetters() {
            return this.leadClassificationState;
        },
        completedDataState: (state) => state.completedData,
        leadCompletedGetters() {
            return this.completedDataState;
        },
        droppedDataState: (state) => state.droppedData,
        leadDroppedGetters() {
            return this.droppedDataState;
        },
        assignDataState: (state) => state.assignData,
        assignListGetters() {
            return this.assignDataState;
        },
        leadSLAState: (state) => state.leadSLA,
        leadSLAListGetters() {
            return this.leadSLAState;
        }
    },
    actions: {
        clearLeadState() {
            this.leadManagement = [];
            this.addLeadData = [];
            this.completedData = [];
            this.leadSLA = [];
        },
        fetchLeadManagementData(authData) {
            return new Promise((resolve) => {
                axios.post('/lead_management/get_all_leads/', qs.stringify(authData, {
                    parseArrays: false
                }))
                .then(res => {
                    Nprogress.done()
                    console.log(res.data)
                    if (res.data.status == "redirect") {
                        router.push("/dashboard");
                    } else {
                        this.leadManagement = res.data
                    }
                    resolve(res.data);
                })
                .catch(error => console.log(error))
            });
        },
        saveLeadManagementStatus(authData) {
            return new Promise((resolve) => {
                Nprogress.start();
                axios.post('/lead_management/change_lead_status', qs.stringify(authData, {
                    parseArrays: false
                }))
                    .then(res => {
                    Nprogress.done()
                    var index = this.leadManagement.allData.findIndex(lead => lead.LeadUsers.id == authData.pk && lead.LeadUsers.account_id == authData.name);
                    this.leadManagement.allData[index].LeadUsers.status = authData.value;
                })
                .catch(error => console.log(error))
            })
        },
        checkAssignAdmin(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/check_reassign_admin_permission', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
    
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        collectInventoryItems(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/collect_inventory_items', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        rejectLead(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/reject_lead', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        unclaimLead(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/unclaim_lead', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        claimLead(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/claim_lead', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        reassignAdmin(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/reassign_to_another_admin', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        collectLeadInventoryItems(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/collect_return_inventory_submit', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        rejectAllLeadFromLead(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/reject_leads_all', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }    
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        importcsvleads(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/import_leaduser_file', postData)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        if (response.data.status == 'success') {
                            var newData = JSON.parse(response.data.leadData);
                            this.importLeadData(newData);               
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        importCopyPasteLeads(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/import_leaduser_file_copy', postData)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        if (response.data.status == 'success') {
                            var newData = JSON.parse(response.data.leadData);
                            this.importLeadData(newData);                            
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        importLeadData(newData) {
            if (newData.length > 0) {
                for (var i = 0; i < newData.length; i++) {
                    var index = this.leadManagement.allData.findIndex(e => e.LeadUsers.id == newData[i].id && e.LeadUsers.account_id == newData[i].account_id);
                    if (index != -1) {
                        this.leadManagement.allData.splice(index, 1)
                    }
                    var data = {};
                    data.LeadUsers = newData[i].LeadUser;
                    data.LeadAddress = newData[i].LeadAddress;
                    this.leadManagement.allData.unshift(data)
                }
            }            
        },
        fetchLeadHistory(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/get_task_admins', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        fetchAddLeadData(getData) {
            Nprogress.start()
            axios.get('/lead_management/add_lead/' + getData.leadId + '/' + getData.accountId + '/true')
                .then(res => {
                    Nprogress.done()
                    if (res.data.status == "redirect") {
                        router.push("/dashboard");
                    } else {
                        this.addLeadData = res.data.message;
                    }
                    if(getData.leadId != 'null'){
                        var endArr = window.location.href.split('/');
                        if (endArr.at(-1) != getData.accountId) {
                            window.location.href = '/lead_management/add_lead/' + getData.leadId + '/' + getData.accountId;
                        }
                    }
    
                })
                .catch(error => console.log(error))
        },
        getCityNameInLeads(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/users/get_city_and_state', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        addOrUpdateOrRejectNewLeadFn(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                var url = '';
                if(postData.dispatchFn !=  undefined) {
                    if(postData.dispatchFn == "rejectNewLead") {
                        url = '/lead_management/reject_lead'
                    } else if(postData.dispatchFn == "updateNewLead") {
                        url = '/lead_management/approve_lead.json'
                    } else if(postData.dispatchFn == "addNewLead") {
                        url = '/lead_management/create_lead'
                    }
                }
                if(url != '') {
                    axios.post(url, postData.bodyFormData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }    
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
                }
            });
        },    
        saveSingleFieldLead(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/save_single_field_lead', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
    
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        this.addLeadData = response.data.message;
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },  
        submitLeadTransfer(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/transfer_lead', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }    
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },  
        sendLeadPaymentLink(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/send_lead_payment_link', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }    
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        submitCAFCollection(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/caf_collection', postData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }    
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        initialPaymentSetting(postData) {
            return new Promise((resolve, reject) => {
                axios.post('/lead_management/save_lead_inital_payment_settings', postData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                    .then(response => {
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }    
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        getPlanRate(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/users/getPlanRateBilling', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }    
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        updateLeadUserDetails(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/lead_management/update_lead_user_data", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        submitCafApproval(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/caf_approval', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }    
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        mailDocuments(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/users/mailDocuments", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        submitCommissioning(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/cabling_and_commissioning', postData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }    
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        splicingStage(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/splicing', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }    
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        fetchLeadDashboardDatas() {
            Nprogress.start();
            return new Promise((resolve, reject) => {
                axios.get('/lead_management/lead_dashboard')
                    .then(res => {
                        Nprogress.done();
                        if (res.data.status == "redirect") {
                            router.push(res.data.message);
                        } else {
                            this.dashBoardData = res.data;
                            resolve(res.data)
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            if (error.response.status == 403) {
                                window.location = "/dashboard";
                            }
                        }
                    })
            })
        },
        getRecentLeadLogs(postData) {
            Nprogress.start();
            return new Promise((resolve, reject) => {
                const vueData = true;
                axios
                    .post("/lead_management/lead_event_logs/" + vueData, qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        Nprogress.done();
                        this.leadRecentData = response.data
                    })
                    .catch(error => console.log(error));
            });
        },
        fetchLeadClassification() {
            Nprogress.start();
            axios.get('/lead_management/lead_classification_list')
                .then(res => {
                    Nprogress.done();
                    if (res.data.status == "redirect") {
                        router.push("/dashboard");
                    } else {
                        this.leadClassification = JSON.parse(res.data);
                    }
                })
                .catch(error => console.log(error))
        },
        savemappingField(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/save_lead_additional_map', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        saveHiddenField(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/save_lead_user_hide_field', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();    
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        saveEnableData(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/edit_lead_field/enable', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        saveSmsData(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/edit_lead_field/Sms', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();    
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        saveEmailData(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/edit_lead_field/Email', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();    
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        addAdminClassification(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/add_adminclassifications_to_group', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();    
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        deleteAdminClassification(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/del_adminclassifications_from_group', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();    
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        restrictLeadClassification(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/settings/save_sub_accounts_cannot_change_settings', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();    
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        if (postData.attribute == 'subAccountsCanNotChangeAssignLeads') {
                            if (postData.switchCheckBoxValue == 'on') {
                                this.assignData['subAccountsCanNotChangeAssignLeads'] ='Yes';
                             //   Vue.set(this.assignData, 'subAccountsCanNotChangeAssignLeads', 'Yes');
                            } else {
                                this.assignData['subAccountsCanNotChangeAssignLeads'] ='No';
                               // Vue.set(this.assignData, 'subAccountsCanNotChangeAssignLeads', 'No');
                            }
                        }
                        if (postData.attribute == 'subAccountsCanNotChangeLeadClassification') {
                            if (postData.switchCheckBoxValue == 'on') {
                                this.leadClassification['subAccountsCanNotChangeLeadClassification']='Yes';
                               // Vue.set(this.leadClassification, 'subAccountsCanNotChangeLeadClassification', 'Yes');
                            } else {
                                this.leadClassification['subAccountsCanNotChangeLeadClassification']='No';
                                //Vue.set(this.leadClassification, 'subAccountsCanNotChangeLeadClassification', 'No');
                            }
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        applyToAllSubZoneLeadClassification(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/add_lead_classification_to_subAcc', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();    
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        fetchCompletedLeadData(authData) {
            return new Promise((resolve, reject) => {
            Nprogress.start();
            axios.post('/lead_management/get_all_completed_leads/', qs.stringify(authData, {
                parseArrays: false
            }))
                .then(res => {
                    Nprogress.done()
                    if (res.data.status == "redirect") {
                        router.push("/dashboard");
                    } else {
                        this.completedData = res.data
                    }
                    resolve(res.data);
                })
                .catch(error => console.log(error))    
            });
        },
        fetchDroppedData(authData) {
            return new Promise((resolve, reject) => {
            Nprogress.start();
            axios.post('/lead_management/get_all_dropped_leads/', qs.stringify(authData, {
                parseArrays: false
            }))
                .then(res => {
                    Nprogress.done()
                    if (res.data.status == "redirect") {
                        router.push("/dashboard");
                    } else {
                        this.droppedData = res.data
                    }
                resolve(res.data);
                })
                .catch(error => console.log(error))   
            });
        },
        activeDroppedLead(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/reapprove_lead', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();    
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        activeDroppedLeadAll(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/reapprove_lead_all', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();    
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        DeleteDroppedLeadAll(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/delete_all_leads', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();    
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        fetchAssignLeadDatas() {
            Nprogress.start();
            axios.get('/lead_management/assign_leads_list')
                .then(res => {
                    Nprogress.done();
                    if (res.data.status == "redirect") {
                        router.push("/dashboard");
                    } else {
                        this.assignData = res.data;
                    }
                })
                .catch(error => console.log(error))
        },
        addAdminClassificationAd(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/assign_admin_to_other_settings', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();    
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        this.assignData.adminToLevels.push(response.data.newData);
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        addUnassignedClassification(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/admin_view_unassigned_settings', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();    
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        this.assignData.adminToUnassignedLevels.push(response.data.newData);
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        viewAllClassification(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/admin_view_assignedall_leads_settings', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();    
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        this.assignData.adminToassignedAllLevels.push(response.data.newData);
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        deleteAdminClassificationUnassigned(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/delete_admin_view_unassigned_settings', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
    
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        if (response.data.status == 'success') {
                            var index = this.assignData.adminToUnassignedLevels.findIndex(e => e.id == postData.adminClassificationId);
                            if (index != -1) {
                                this.assignData.adminToUnassignedLevels.splice(index, 1)
                            }
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        deleteAdminClassificationAd(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/delete_admin_to_other_settings', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();    
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        if (response.data.status == 'success') {
                            var index = this.assignData.adminToLevels.findIndex(e => e.id == postData.adminClassificationId);
                            if (index != -1) {
                                this.assignData.adminToLevels.splice(index, 1)
                            }
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        viewAllClassificationDelete(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/delete_admin_view_assignedall_lead_settings', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();    
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        if (response.data.status == 'success') {
                            var index = this.assignData.adminToassignedAllLevels.findIndex(e => e.id == postData.adminClassificationId);
                            if (index != -1) {
                                this.assignData.adminToassignedAllLevels.splice(index, 1)
                            }                            
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        saveAllAssignedLeadData(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/save_auto_assign_values', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();    
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        fetchLeadSLAData() {
            Nprogress.start();
            axios.get('/lead_management/lead_sla')
                .then(res => {
                    Nprogress.done();
                    this.leadSLA = res.data
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 403) {
                            window.location = "/dashboard";
                        }
                    }
                })
        },
        saveLeadSLAEmailSms(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/lead_management/lead_edit_field", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        saveAllDataLeadSla(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/lead_management/store_lead_sla_values", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        addSubAccountLeadSla(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/lead_management/add_lead_sla_to_all_accounts", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        applyToAllSubZoneLeadAssign(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/lead_management/add_assign_leads_to_subAcc', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();    
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
        restrictAssignLead(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/settings/save_sub_accounts_cannot_change_settings', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
    
                        if (response.data.status != 'success') {
                            reject(response.data.message);
                            return;
                        }
                        if (postData.attribute == 'subAccountsCanNotChangeAssignLeads') {
                            if (postData.switchCheckBoxValue == 'on') {
                                this.assignData['subAccountsCanNotChangeAssignLeads']='Yes';
                               // Vue.set(state.assignData, 'subAccountsCanNotChangeAssignLeads', 'Yes');
                            } else {
                                this.assignData['subAccountsCanNotChangeAssignLeads']='No';
                               // Vue.set(state.assignData, 'subAccountsCanNotChangeAssignLeads', 'No');
                            }
                        }
                        if (postData.attribute == 'subAccountsCanNotChangeLeadClassification') {
                            if (postData.switchCheckBoxValue == 'on') {
                                this.leadClassification['subAccountsCanNotChangeLeadClassification']='Yes';
                               // Vue.set(state.leadClassification, 'subAccountsCanNotChangeLeadClassification', 'Yes');
                            } else {
                                this.leadClassification['subAccountsCanNotChangeLeadClassification']='No';
                               // Vue.set(state.leadClassification, 'subAccountsCanNotChangeLeadClassification', 'No');
                            }
                        }
                        resolve(response.data);
                    })
                    .catch(error => console.log(error))
            });
        },
    }
})
<template>
  <div class="jazeMainPanel">
    <div class="jazePanelContent jazePanelContent--scrollable">
    <div class="unifiSettingsContainer">
      <div class="unifiSettingsHeader unifiSettingsHeader--withTabs noBorder" tabs="settingsSubMenuRoutingAndFirewallCtrl.tabs">
        <ul class="appTabs appTabs--cozy appTabs--flexible" pageutils-nav-active-class="is-tab-selected">
          <li @click="selectedTab='linkedIn'" class="tabs__item" :class="{'is-tab-selected':selectedTab == 'linkedIn'}">
            <span>LinkedIn</span>
          </li>
          <li @click="selectedTab='twitter'" class="tabs__item" :class="{'is-tab-selected':selectedTab == 'twitter'}">
            <span>Twitter</span>
          </li>
          <li @click="selectedTab='facebook'" class="tabs__item" :class="{'is-tab-selected':selectedTab == 'facebook'}">
            <span>Facebook</span>
          </li>
          <li @click="selectedTab='instagram'" class="tabs__item" :class="{'is-tab-selected':selectedTab == 'instagram'}">
            <span>Instagram</span>
          </li>
          <li @click="selectedTab='googlePlus'" class="tabs__item" :class="{'is-tab-selected':selectedTab == 'googlePlus'}">
            <span>Google Plus</span>
          </li>
        </ul>
      </div>
      <div v-if="selectedTab == 'linkedIn'">
          <form ref="linkedInForm" id="linkedInForm" class="appForm appForm--cozy is-validation-hidden">
            <fieldset class="appFieldset appFieldset--quiet">
                <div class="unifiSettingsSection">
                    <div class="appRow">
                        <div class="col--sm3">
                            <label class="appLabel appLabel--primary appLabel--boxAlign">API Key</label>
                        </div>
                        <div class="col--sm5 appFormGroup appFormGroup--cozy">
                            <input class="input--full " type="text" name="api" autofocus="true" v-model="apiKeyLinkedin">
                        </div>
                        <div class="col--sm4 appFormGroup appFormGroup--cozy">
                            <unifi-tooltip unifi-tooltip-class="'jazeTooltip--small'">
                              <a class="jazeTooltipContainer">
                                <a @mouseover="linkedKey = true" @mouseleave="linkedKey = false" class="popovers" style="color: #666666">
                                  <div class="unifiAuraIcon" unifi-aura-icon="'info'" unifi-aura-icon-class="['jazeIcon', 'color-primary']">
                                    <svg ng-switch-when="info" ng-class="{&quot;jazeIcon&quot;:true,&quot;color-primary&quot;:true}" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="jazeIcon color-primary">
                                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                          <circle stroke="currentColor" cx="8" cy="8" r="7.5"></circle>
                                          <rect fill="currentColor" x="7.5" y="7" width="1" height="5"></rect>
                                          <rect fill="currentColor" x="7.5" y="5" width="1" height="1"></rect>
                                      </g>
                                    </svg>
                                  </div>
                                </a>
                                <div class="jazeTooltip jazeTooltip--topRight jazeTooltip--small type-reset unifiDashboardTooltip" v-show='linkedKey'>
                                  <div> Leave empty to use Default API Key. </div>
                                </div>
                              </a>
                            </unifi-tooltip>                            
                        </div>
                    </div>
                    <div class="appRow">
                        <div class="col--sm3">
                            <label class="appLabel appLabel--primary appLabel--boxAlign">API Secret</label>
                        </div>
                        <div class="col--sm5 appFormGroup appFormGroup--cozy">
                            <input class="input--full " type="text" name="secret" autofocus="true" v-model="apiSecretLinkedin">
                        </div>
                        <div class="col--sm4 appFormGroup appFormGroup--cozy">
                            <unifi-tooltip unifi-tooltip-class="'jazeTooltip--small'">
                              <a class="jazeTooltipContainer">
                                <a @mouseover="linkedSecret = true" @mouseleave="linkedSecret = false" class="popovers" style="color: #666666">
                                  <div class="unifiAuraIcon" unifi-aura-icon="'info'" unifi-aura-icon-class="['jazeIcon', 'color-primary']">
                                    <svg ng-switch-when="info" ng-class="{&quot;jazeIcon&quot;:true,&quot;color-primary&quot;:true}" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="jazeIcon color-primary">
                                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                          <circle stroke="currentColor" cx="8" cy="8" r="7.5"></circle>
                                          <rect fill="currentColor" x="7.5" y="7" width="1" height="5"></rect>
                                          <rect fill="currentColor" x="7.5" y="5" width="1" height="1"></rect>
                                      </g>
                                    </svg>
                                  </div>
                                </a>
                                <div class="jazeTooltip jazeTooltip--topRight jazeTooltip--small type-reset unifiDashboardTooltip" v-show='linkedSecret'>
                                  <div> Leave empty to use Default API Secret. </div>
                                </div>
                              </a>
                            </unifi-tooltip>                               
                        </div>
                    </div>
                    <div class="appRow">
                        <div class="col--sm3">
                            <label class="appLabel appLabel--primary appLabel--boxAlign">Redirect URI</label>
                        </div>
                        <div class="col--sm5 appFormGroup appFormGroup--cozy">
                            <input class="input--full " type="text" name="redirectUri" autofocus="true" v-model="apiUriLinkedin">
                        </div>
                        <div class="col--sm4 appFormGroup appFormGroup--cozy">
                            <unifi-tooltip unifi-tooltip-class="'jazeTooltip--small'">
                              <a class="jazeTooltipContainer">
                                <a @mouseover="linkedUri = true" @mouseleave="linkedUri = false" class="popovers" style="color: #666666">
                                  <div class="unifiAuraIcon" unifi-aura-icon="'info'" unifi-aura-icon-class="['jazeIcon', 'color-primary']">
                                    <svg ng-switch-when="info" ng-class="{&quot;jazeIcon&quot;:true,&quot;color-primary&quot;:true}" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="jazeIcon color-primary">
                                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                          <circle stroke="currentColor" cx="8" cy="8" r="7.5"></circle>
                                          <rect fill="currentColor" x="7.5" y="7" width="1" height="5"></rect>
                                          <rect fill="currentColor" x="7.5" y="5" width="1" height="1"></rect>
                                      </g>
                                    </svg>
                                  </div>
                                </a>
                                <div class="jazeTooltip jazeTooltip--topRight jazeTooltip--small type-reset unifiDashboardTooltip" v-show='linkedUri'>
                                  <div> Note: Leave empty to use Default Redirect URI. </div>
                                </div>
                              </a>
                            </unifi-tooltip>                               
                        </div>
                    </div>
                </div>
            </fieldset>
          </form>
      </div>
      <div v-if="selectedTab == 'twitter'">
          <form ref="twitterForm" id="twitterForm" class="appForm appForm--cozy is-validation-hidden">
            <fieldset class="appFieldset appFieldset--quiet">
                <div class="unifiSettingsSection">
                    <div class="appRow">
                        <div class="col--sm3">
                            <label class="appLabel appLabel--primary appLabel--boxAlign">API Key</label>
                        </div>
                        <div class="col--sm5 appFormGroup appFormGroup--cozy">
                            <input class="input--full " type="text" name="api" autofocus="true" v-model="apiKeyTwitter">
                        </div>
                        <div class="col--sm4 appFormGroup appFormGroup--cozy">
                            <unifi-tooltip unifi-tooltip-class="'jazeTooltip--small'">
                              <a class="jazeTooltipContainer">
                                <a @mouseover="twitterKey = true" @mouseleave="twitterKey = false" class="popovers" style="color: #666666">
                                  <div class="unifiAuraIcon" unifi-aura-icon="'info'" unifi-aura-icon-class="['jazeIcon', 'color-primary']">
                                    <svg ng-switch-when="info" ng-class="{&quot;jazeIcon&quot;:true,&quot;color-primary&quot;:true}" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="jazeIcon color-primary">
                                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                          <circle stroke="currentColor" cx="8" cy="8" r="7.5"></circle>
                                          <rect fill="currentColor" x="7.5" y="7" width="1" height="5"></rect>
                                          <rect fill="currentColor" x="7.5" y="5" width="1" height="1"></rect>
                                      </g>
                                    </svg>
                                  </div>
                                </a>
                                <div class="jazeTooltip jazeTooltip--topRight jazeTooltip--small type-reset unifiDashboardTooltip" v-show='twitterKey'>
                                  <div> Leave empty to use Default API Key. </div>
                                </div>
                              </a>
                            </unifi-tooltip>                              
                        </div>
                    </div>
                    <div class="appRow">
                        <div class="col--sm3">
                            <label class="appLabel appLabel--primary appLabel--boxAlign">API Secret</label>
                        </div>
                        <div class="col--sm5 appFormGroup appFormGroup--cozy">
                            <input class="input--full " type="text" name="secret" autofocus="true" v-model="apiSecretTwitter">
                        </div>
                        <div class="col--sm4 appFormGroup appFormGroup--cozy">
                            <unifi-tooltip unifi-tooltip-class="'jazeTooltip--small'">
                              <a class="jazeTooltipContainer">
                                <a @mouseover="twitterSecret = true" @mouseleave="twitterSecret = false" class="popovers" style="color: #666666">
                                  <div class="unifiAuraIcon" unifi-aura-icon="'info'" unifi-aura-icon-class="['jazeIcon', 'color-primary']">
                                    <svg ng-switch-when="info" ng-class="{&quot;jazeIcon&quot;:true,&quot;color-primary&quot;:true}" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="jazeIcon color-primary">
                                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                          <circle stroke="currentColor" cx="8" cy="8" r="7.5"></circle>
                                          <rect fill="currentColor" x="7.5" y="7" width="1" height="5"></rect>
                                          <rect fill="currentColor" x="7.5" y="5" width="1" height="1"></rect>
                                      </g>
                                    </svg>
                                  </div>
                                </a>
                                <div class="jazeTooltip jazeTooltip--topRight jazeTooltip--small type-reset unifiDashboardTooltip" v-show='twitterSecret'>
                                  <div> Leave empty to use Default API Secret. </div>
                                </div>
                              </a>
                            </unifi-tooltip>  
                        </div>
                    </div>
                    <div class="appRow">
                        <div class="col--sm3">
                            <label class="appLabel appLabel--primary appLabel--boxAlign">Redirect URI</label>
                        </div>
                        <div class="col--sm5 appFormGroup appFormGroup--cozy">
                            <input class="input--full " type="text" name="redirectUri" autofocus="true" v-model="apiUriTwitter">
                        </div>
                        <div class="col--sm4 appFormGroup appFormGroup--cozy">
                            <unifi-tooltip unifi-tooltip-class="'jazeTooltip--small'">
                              <a class="jazeTooltipContainer">
                                <a @mouseover="twitterUri = true" @mouseleave="twitterUri = false" class="popovers" style="color: #666666">
                                  <div class="unifiAuraIcon" unifi-aura-icon="'info'" unifi-aura-icon-class="['jazeIcon', 'color-primary']">
                                    <svg ng-switch-when="info" ng-class="{&quot;jazeIcon&quot;:true,&quot;color-primary&quot;:true}" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="jazeIcon color-primary">
                                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                          <circle stroke="currentColor" cx="8" cy="8" r="7.5"></circle>
                                          <rect fill="currentColor" x="7.5" y="7" width="1" height="5"></rect>
                                          <rect fill="currentColor" x="7.5" y="5" width="1" height="1"></rect>
                                      </g>
                                    </svg>
                                  </div>
                                </a>
                                <div class="jazeTooltip jazeTooltip--topRight jazeTooltip--small type-reset unifiDashboardTooltip" v-show='twitterUri'>
                                  <div> Note: Leave empty to use Default Redirect URI. </div>
                                </div>
                              </a>
                            </unifi-tooltip> 
                        </div>
                    </div>
                </div>
            </fieldset>
          </form>
      </div>    
      <div v-if="selectedTab == 'facebook'">
          <form ref="facebookForm" id="facebookForm" class="appForm appForm--cozy is-validation-hidden">
            <fieldset class="appFieldset appFieldset--quiet">
                <div class="unifiSettingsSection">
                    <div class="appRow">
                        <div class="col--sm3">
                            <label class="appLabel appLabel--primary appLabel--boxAlign">API Key</label>
                        </div>
                        <div class="col--sm5 appFormGroup appFormGroup--cozy">
                            <input class="input--full " type="text" name="api" autofocus="true" v-model="apiKeyFB">
                        </div>
                        <div class="col--sm4 appFormGroup appFormGroup--cozy">
                            <unifi-tooltip unifi-tooltip-class="'jazeTooltip--small'">
                              <a class="jazeTooltipContainer">
                                <a @mouseover="facebookKey = true" @mouseleave="facebookKey = false" class="popovers" style="color: #666666">
                                  <div class="unifiAuraIcon" unifi-aura-icon="'info'" unifi-aura-icon-class="['jazeIcon', 'color-primary']">
                                    <svg ng-switch-when="info" ng-class="{&quot;jazeIcon&quot;:true,&quot;color-primary&quot;:true}" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="jazeIcon color-primary">
                                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                          <circle stroke="currentColor" cx="8" cy="8" r="7.5"></circle>
                                          <rect fill="currentColor" x="7.5" y="7" width="1" height="5"></rect>
                                          <rect fill="currentColor" x="7.5" y="5" width="1" height="1"></rect>
                                      </g>
                                    </svg>
                                  </div>
                                </a>
                                <div class="jazeTooltip jazeTooltip--topRight jazeTooltip--small type-reset unifiDashboardTooltip" v-show='facebookKey'>
                                  <div> Leave empty to use Default API Key. </div>
                                </div>
                              </a>
                            </unifi-tooltip>  
                        </div>
                    </div>
                    <div class="appRow">
                        <div class="col--sm3">
                            <label class="appLabel appLabel--primary appLabel--boxAlign">API Secret</label>
                        </div>
                        <div class="col--sm5 appFormGroup appFormGroup--cozy">
                            <input class="input--full " type="text" name="secret" autofocus="true" v-model="apiSecretFB">
                        </div>
                        <div class="col--sm4 appFormGroup appFormGroup--cozy">
                            <unifi-tooltip unifi-tooltip-class="'jazeTooltip--small'">
                              <a class="jazeTooltipContainer">
                                <a @mouseover="facebookSecret = true" @mouseleave="facebookSecret = false" class="popovers" style="color: #666666">
                                  <div class="unifiAuraIcon" unifi-aura-icon="'info'" unifi-aura-icon-class="['jazeIcon', 'color-primary']">
                                    <svg ng-switch-when="info" ng-class="{&quot;jazeIcon&quot;:true,&quot;color-primary&quot;:true}" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="jazeIcon color-primary">
                                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                          <circle stroke="currentColor" cx="8" cy="8" r="7.5"></circle>
                                          <rect fill="currentColor" x="7.5" y="7" width="1" height="5"></rect>
                                          <rect fill="currentColor" x="7.5" y="5" width="1" height="1"></rect>
                                      </g>
                                    </svg>
                                  </div>
                                </a>
                                <div class="jazeTooltip jazeTooltip--topRight jazeTooltip--small type-reset unifiDashboardTooltip" v-show='facebookSecret'>
                                  <div> Leave empty to use Default API Secret. </div>
                                </div>
                              </a>
                            </unifi-tooltip>  
                        </div>
                    </div>
                    <div class="appRow">
                        <div class="col--sm3">
                            <label class="appLabel appLabel--primary appLabel--boxAlign">Redirect URI</label>
                        </div>
                        <div class="col--sm5 appFormGroup appFormGroup--cozy">
                            <input class="input--full " type="text" name="redirectUri" autofocus="true" v-model="apiUriFB">
                        </div>
                        <div class="col--sm4 appFormGroup appFormGroup--cozy">
                            <unifi-tooltip unifi-tooltip-class="'jazeTooltip--small'">
                              <a class="jazeTooltipContainer">
                                <a @mouseover="facebookUri = true" @mouseleave="facebookUri = false" class="popovers" style="color: #666666">
                                  <div class="unifiAuraIcon" unifi-aura-icon="'info'" unifi-aura-icon-class="['jazeIcon', 'color-primary']">
                                    <svg ng-switch-when="info" ng-class="{&quot;jazeIcon&quot;:true,&quot;color-primary&quot;:true}" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="jazeIcon color-primary">
                                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                          <circle stroke="currentColor" cx="8" cy="8" r="7.5"></circle>
                                          <rect fill="currentColor" x="7.5" y="7" width="1" height="5"></rect>
                                          <rect fill="currentColor" x="7.5" y="5" width="1" height="1"></rect>
                                      </g>
                                    </svg>
                                  </div>
                                </a>
                                <div class="jazeTooltip jazeTooltip--topRight jazeTooltip--small type-reset unifiDashboardTooltip" v-show='facebookUri'>
                                  <div> Note: Leave empty to use Default Redirect URI. </div>
                                </div>
                              </a>
                            </unifi-tooltip> 
                        </div>
                    </div>
                </div>
            </fieldset>
          </form>
      </div> 
      <div v-if="selectedTab == 'instagram'">
          <form ref="instagramForm" id="instagramForm" class="appForm appForm--cozy is-validation-hidden">
            <fieldset class="appFieldset appFieldset--quiet">
                <div class="unifiSettingsSection">
                    <div class="appRow">
                        <div class="col--sm3">
                            <label class="appLabel appLabel--primary appLabel--boxAlign">API Key</label>
                        </div>
                        <div class="col--sm5 appFormGroup appFormGroup--cozy">
                            <input class="input--full " type="text" name="api" autofocus="true" v-model="apiKeyInsta">
                        </div>
                        <div class="col--sm4 appFormGroup appFormGroup--cozy">
                            <unifi-tooltip unifi-tooltip-class="'jazeTooltip--small'">
                              <a class="jazeTooltipContainer">
                                <a @mouseover="instaKey = true" @mouseleave="instaKey = false" class="popovers" style="color: #666666">
                                  <div class="unifiAuraIcon" unifi-aura-icon="'info'" unifi-aura-icon-class="['jazeIcon', 'color-primary']">
                                    <svg ng-switch-when="info" ng-class="{&quot;jazeIcon&quot;:true,&quot;color-primary&quot;:true}" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="jazeIcon color-primary">
                                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                          <circle stroke="currentColor" cx="8" cy="8" r="7.5"></circle>
                                          <rect fill="currentColor" x="7.5" y="7" width="1" height="5"></rect>
                                          <rect fill="currentColor" x="7.5" y="5" width="1" height="1"></rect>
                                      </g>
                                    </svg>
                                  </div>
                                </a>
                                <div class="jazeTooltip jazeTooltip--topRight jazeTooltip--small type-reset unifiDashboardTooltip" v-show='instaKey'>
                                  <div> Leave empty to use Default API Key. </div>
                                </div>
                              </a>
                            </unifi-tooltip>  
                        </div>
                    </div>
                    <div class="appRow">
                        <div class="col--sm3">
                            <label class="appLabel appLabel--primary appLabel--boxAlign">API Secret</label>
                        </div>
                        <div class="col--sm5 appFormGroup appFormGroup--cozy">
                            <input class="input--full " type="text" name="secret" autofocus="true" v-model="apiSecretInsta">
                        </div>
                        <div class="col--sm4 appFormGroup appFormGroup--cozy">
                            <unifi-tooltip unifi-tooltip-class="'jazeTooltip--small'">
                              <a class="jazeTooltipContainer">
                                <a @mouseover="instaSecret = true" @mouseleave="instaSecret = false" class="popovers" style="color: #666666">
                                  <div class="unifiAuraIcon" unifi-aura-icon="'info'" unifi-aura-icon-class="['jazeIcon', 'color-primary']">
                                    <svg ng-switch-when="info" ng-class="{&quot;jazeIcon&quot;:true,&quot;color-primary&quot;:true}" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="jazeIcon color-primary">
                                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                          <circle stroke="currentColor" cx="8" cy="8" r="7.5"></circle>
                                          <rect fill="currentColor" x="7.5" y="7" width="1" height="5"></rect>
                                          <rect fill="currentColor" x="7.5" y="5" width="1" height="1"></rect>
                                      </g>
                                    </svg>
                                  </div>
                                </a>
                                <div class="jazeTooltip jazeTooltip--topRight jazeTooltip--small type-reset unifiDashboardTooltip" v-show='instaSecret'>
                                  <div> Leave empty to use Default API Secret. </div>
                                </div>
                              </a>
                            </unifi-tooltip>  
                        </div>
                    </div>
                    <div class="appRow">
                        <div class="col--sm3">
                            <label class="appLabel appLabel--primary appLabel--boxAlign">Redirect URI</label>
                        </div>
                        <div class="col--sm5 appFormGroup appFormGroup--cozy">
                            <input class="input--full " type="text" name="redirectUri" autofocus="true" v-model="apiUriInsta">
                        </div>
                        <div class="col--sm4 appFormGroup appFormGroup--cozy">
                            <unifi-tooltip unifi-tooltip-class="'jazeTooltip--small'">
                              <a class="jazeTooltipContainer">
                                <a @mouseover="instaUri = true" @mouseleave="instaUri = false" class="popovers" style="color: #666666">
                                  <div class="unifiAuraIcon" unifi-aura-icon="'info'" unifi-aura-icon-class="['jazeIcon', 'color-primary']">
                                    <svg ng-switch-when="info" ng-class="{&quot;jazeIcon&quot;:true,&quot;color-primary&quot;:true}" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="jazeIcon color-primary">
                                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                          <circle stroke="currentColor" cx="8" cy="8" r="7.5"></circle>
                                          <rect fill="currentColor" x="7.5" y="7" width="1" height="5"></rect>
                                          <rect fill="currentColor" x="7.5" y="5" width="1" height="1"></rect>
                                      </g>
                                    </svg>
                                  </div>
                                </a>
                                <div class="jazeTooltip jazeTooltip--topRight jazeTooltip--small type-reset unifiDashboardTooltip" v-show='instaUri'>
                                  <div> Note: Leave empty to use Default Redirect URI. </div>
                                </div>
                              </a>
                            </unifi-tooltip> 
                        </div>
                    </div>
                </div>
            </fieldset>
          </form>
      </div>  
      <div v-if="selectedTab == 'googlePlus'">
          <form ref="googlePlusForm" id="googlePlusForm" class="appForm appForm--cozy is-validation-hidden">
            <fieldset class="appFieldset appFieldset--quiet">
                <div class="unifiSettingsSection">
                    <div class="appRow">
                        <div class="col--sm3">
                            <label class="appLabel appLabel--primary appLabel--boxAlign">API Key</label>
                        </div>
                        <div class="col--sm5 appFormGroup appFormGroup--cozy">
                            <input class="input--full " type="text" name="api" autofocus="true" v-model="apiKeyGoogle">
                        </div>
                        <div class="col--sm4 appFormGroup appFormGroup--cozy">
                            <unifi-tooltip unifi-tooltip-class="'jazeTooltip--small'">
                              <a class="jazeTooltipContainer">
                                <a @mouseover="googleKey = true" @mouseleave="googleKey = false" class="popovers" style="color: #666666">
                                  <div class="unifiAuraIcon" unifi-aura-icon="'info'" unifi-aura-icon-class="['jazeIcon', 'color-primary']">
                                    <svg ng-switch-when="info" ng-class="{&quot;jazeIcon&quot;:true,&quot;color-primary&quot;:true}" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="jazeIcon color-primary">
                                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                          <circle stroke="currentColor" cx="8" cy="8" r="7.5"></circle>
                                          <rect fill="currentColor" x="7.5" y="7" width="1" height="5"></rect>
                                          <rect fill="currentColor" x="7.5" y="5" width="1" height="1"></rect>
                                      </g>
                                    </svg>
                                  </div>
                                </a>
                                <div class="jazeTooltip jazeTooltip--topRight jazeTooltip--small type-reset unifiDashboardTooltip" v-show='googleKey'>
                                  <div> Leave empty to use Default API Key. </div>
                                </div>
                              </a>
                            </unifi-tooltip>  
                        </div>
                    </div>
                    <div class="appRow">
                        <div class="col--sm3">
                            <label class="appLabel appLabel--primary appLabel--boxAlign">API Secret</label>
                        </div>
                        <div class="col--sm5 appFormGroup appFormGroup--cozy">
                            <input class="input--full " type="text" name="secret" autofocus="true" v-model="apiSecretGoogle">
                        </div>
                        <div class="col--sm4 appFormGroup appFormGroup--cozy">
                            <unifi-tooltip unifi-tooltip-class="'jazeTooltip--small'">
                              <a class="jazeTooltipContainer">
                                <a @mouseover="googleSecret = true" @mouseleave="googleSecret = false" class="popovers" style="color: #666666">
                                  <div class="unifiAuraIcon" unifi-aura-icon="'info'" unifi-aura-icon-class="['jazeIcon', 'color-primary']">
                                    <svg ng-switch-when="info" ng-class="{&quot;jazeIcon&quot;:true,&quot;color-primary&quot;:true}" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="jazeIcon color-primary">
                                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                          <circle stroke="currentColor" cx="8" cy="8" r="7.5"></circle>
                                          <rect fill="currentColor" x="7.5" y="7" width="1" height="5"></rect>
                                          <rect fill="currentColor" x="7.5" y="5" width="1" height="1"></rect>
                                      </g>
                                    </svg>
                                  </div>
                                </a>
                                <div class="jazeTooltip jazeTooltip--topRight jazeTooltip--small type-reset unifiDashboardTooltip" v-show='googleSecret'>
                                  <div> Leave empty to use Default API Secret. </div>
                                </div>
                              </a>
                            </unifi-tooltip>
                        </div>
                    </div>
                    <div class="appRow">
                        <div class="col--sm3">
                            <label class="appLabel appLabel--primary appLabel--boxAlign">Redirect URI</label>
                        </div>
                        <div class="col--sm5 appFormGroup appFormGroup--cozy">
                            <input class="input--full " type="text" name="redirectUri" autofocus="true" v-model="apiUriGoogle">
                        </div>
                        <div class="col--sm4 appFormGroup appFormGroup--cozy">
                            <unifi-tooltip unifi-tooltip-class="'jazeTooltip--small'">
                              <a class="jazeTooltipContainer">
                                <a @mouseover="googleUri = true" @mouseleave="googleUri = false" class="popovers" style="color: #666666">
                                  <div class="unifiAuraIcon" unifi-aura-icon="'info'" unifi-aura-icon-class="['jazeIcon', 'color-primary']">
                                    <svg ng-switch-when="info" ng-class="{&quot;jazeIcon&quot;:true,&quot;color-primary&quot;:true}" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="jazeIcon color-primary">
                                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                          <circle stroke="currentColor" cx="8" cy="8" r="7.5"></circle>
                                          <rect fill="currentColor" x="7.5" y="7" width="1" height="5"></rect>
                                          <rect fill="currentColor" x="7.5" y="5" width="1" height="1"></rect>
                                      </g>
                                    </svg>
                                  </div>
                                </a>
                                <div class="jazeTooltip jazeTooltip--topRight jazeTooltip--small type-reset unifiDashboardTooltip" v-show='googleUri'>
                                  <div> Note: Leave empty to use Default Redirect URI. </div>
                                </div>
                              </a>
                            </unifi-tooltip> 
                        </div>
                    </div>
                </div>
            </fieldset>
          </form>
      </div>        
      <div class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark">
        <div class="appFormFooter__right">
          <button class="appMainButton appMainButton--primary busyToggle" type="submit" @click="saveSocialSettings(selectedTab)"> Save</button>
        </div>
      </div>
    </div>
    </div>
    <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
    <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
  </div>
</template>

<script>
import { ref,computed,watch,watchEffect,onMounted,onBeforeUnmount } from 'vue'
import Treeselect from "jaze-vue3-treeselect";
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";

import { storeToRefs } from "pinia";
import { usesettingsStore } from '@/store/settingsstore'
export default {
  setup() {
    // const linkedInData = ref( [] )
    // const twitterData = ref( [] )
    // const facebookData = ref( [] )
    // const instagramData = ref( [] )
    const googleData = ref( [] )
    const errorMessage = ref( "" )
    const successtoaster = ref( false )
    const successMessage = ref( "" )
    const errortoaster = ref( false )
    const selectedTab = ref( "linkedIn" )
    const apiKeyLinkedin = ref( "" )
    const apiSecretLinkedin = ref( "" )
    const apiUriLinkedin = ref( "" )
    const apiKeyTwitter = ref( "" )
    const apiSecretTwitter = ref( "" )
    const apiUriTwitter = ref( "" )
    const apiKeyFB = ref( "" )
    const apiSecretFB = ref( "" )
    const apiUriFB = ref( "" )
    const apiKeyInsta = ref( "" )
    const apiSecretInsta = ref( "" )
    const apiUriInsta = ref( "" )
    const apiKeyGoogle = ref( "" )
    const apiSecretGoogle = ref( "" )
    const apiUriGoogle = ref( "" )
    const linkedKey = ref( false )
    const linkedSecret = ref( false )
    const linkedUri = ref( false )
    const twitterKey = ref( false )
    const twitterSecret = ref( false )
    const twitterUri = ref( false )
    const facebookKey = ref( false )
    const facebookSecret = ref( false )
    const facebookUri = ref( false )
    const instaKey = ref( false )
    const instaSecret = ref( false )
    const instaUri = ref( false )
    const googleKey = ref( false )
    const googleSecret = ref( false )
    const googleUri = ref( false )

    const settingsStore = usesettingsStore()
    const { linkedInData, twitterData, facebookData, instagramData, googleplusData } = storeToRefs(settingsStore)
    const linkedInGetter = computed(() => linkedInData.value); 
    const twitterDataGetter = computed(() => twitterData.value); 
    const facebookDataGetter = computed(() => facebookData.value); 
    const instagramDataGetter = computed(() => instagramData.value); 
    const googlePlusGetter = computed(() => googleplusData.value);

    onMounted(() => {
      getSocialData();
    })
    onBeforeUnmount(() => {
      settingsStore.clearSettingsState();
    })

    watchEffect(() => {
      linkedInData.value = linkedInGetter.value;
      if(linkedInData.value.api != linkedInData.value.linkedInAppId) {
        apiKeyLinkedin.value = linkedInData.value.api;
      }
      if(linkedInData.value.secret != linkedInData.value.linkedInAppSecret) {
        apiSecretLinkedin.value = linkedInData.value.secret;
      }
      if(linkedInData.value.redirectUri != linkedInData.value.linkedInredirectUrl) {
        apiUriLinkedin.value = linkedInData.value.redirectUri;
      }
    })
    watchEffect(() => {
      twitterData.value = twitterDataGetter.value;
      if(twitterData.value.api != twitterData.value.twitterAppId) {
        apiKeyTwitter.value = twitterData.value.api;
      }
      if(twitterData.value.secret != twitterData.value.twitterAppSecret) {
        apiSecretTwitter.value = twitterData.value.secret;
      }
      if(twitterData.value.redirectUri != twitterData.value.twitterRedirectUrl) {
        apiUriTwitter.value = twitterData.value.redirectUri;
      }
    })
    watchEffect(() => {
      facebookData.value = facebookDataGetter.value;
      if(facebookData.value.api != facebookData.value.facebookAppId) {
        apiKeyFB.value = facebookData.value.api;
      }
      if(facebookData.value.secret != facebookData.value.facebookAppSecret) {
        apiSecretFB.value = facebookData.value.secret;
      }
      if(facebookData.value.redirectUri != facebookData.value.facebookredirectUrl) {
        apiUriFB.value = facebookData.value.redirectUri;
      }
    })
    watchEffect(() =>  {
      instagramData.value = instagramDataGetter.value;
      if(instagramData.value.api != instagramData.value.instaAppId) {
        apiKeyInsta.value = instagramData.value.api;
      }
      if(instagramData.value.secret != instagramData.value.instaAppSecret) {
        apiSecretInsta.value = instagramData.value.secret;
      }
      if(instagramData.value.redirectUri != instagramData.value.instaredirectUrl) {
        apiUriInsta.value = instagramData.value.redirectUri;
      }
    })
    watchEffect(() => {
      googleData.value = googlePlusGetter.value;
      if(googleData.value.api != googleData.value.googleAppId) {
        apiKeyGoogle.value = googleData.value.api;
      }
      if(googleData.value.secret != googleData.value.googleAppSecret) {
        apiSecretGoogle.value = googleData.value.secret;
      }
      if(googleData.value.redirectUri != googleData.value.googleredirectUrl) {
        apiUriGoogle.value = googleData.value.redirectUri;
      }
    })

    const getSocialData = () => {
      // $store.dispatch("fetchLinkedInData");
      // $store.dispatch("fetchTwitterData");
      // $store.dispatch("fetchFacebookData"); 
      // $store.dispatch("fetchInstgaramData");
      // $store.dispatch("fetchGoogleData"); 
      settingsStore.fetchLinkedInData();
      settingsStore.fetchTwitterData();
      settingsStore.fetchFacebookData();
      settingsStore.fetchInstgaramData();
      settingsStore.fetchGoogleData();
    }
    const saveSocialSettings = (selected) => {
        successtoaster.value = false;
        errortoaster.value = false;
        var form = "";
        var formData = "";
        var funcName = ""; 
        if(selected == 'linkedIn') {
            form = document.getElementById('linkedInForm');
            formData = new FormData(form);
            funcName = "linkedInSettingsSubmit";
        } else if(selected == 'twitter') {
            form = document.getElementById('twitterForm');
            formData = new FormData(form);
            funcName = "twitterSettingsSubmit";
        } else if(selected == 'facebook') {
            form = document.getElementById('facebookForm');
            formData = new FormData(form);
            funcName = "facebookSettingsSubmit";
        } else if(selected == 'instagram') {
            form = document.getElementById('instagramForm');
            formData = new FormData(form);
            funcName = "instaSettingsSubmit";
        } else if(selected == 'googlePlus') {
            form = document.getElementById('googlePlusForm');
            formData = new FormData(form);
            funcName = "googleSettingsSubmit";
        }
        const postData = {};
        for (let [key, val] of formData.entries()) {
          Object.assign(postData, { [key]: val });
        }
        postData.funcName = funcName
        // $store.dispatch(funcName, postData).then(
        settingsStore.saveSocialSettings(postData).then(  
          response => {
            successtoaster.value = true;
            successMessage.value = response;
            setTimeout(
              function() {
                successtoaster.value = false;
              }.bind(this),
              2000
            );
          },
          error => {
            errortoaster.value = true;
            errorMessage.value = error;
            setTimeout(
              function() {
                errortoaster.value = false;
              }.bind(this),
              5000
            );
            console.log(error);
          }
        );
    }
    
    return {
      settingsStore,
      linkedInGetter: linkedInData, 
      twitterDataGetter: twitterData, 
      facebookDataGetter: facebookData, 
      instagramDataGetter: instagramData, 
      googlePlusGetter: googleplusData,
      linkedInData,
      twitterData,
      facebookData,
      instagramData,
      googleData,
      errorMessage,
      successtoaster,
      successMessage,
      errortoaster,
      selectedTab,
      apiKeyLinkedin,
      apiSecretLinkedin,
      apiUriLinkedin,
      apiKeyTwitter,
      apiSecretTwitter,
      apiUriTwitter,
      apiKeyFB,
      apiSecretFB,
      apiUriFB,
      apiKeyInsta,
      apiSecretInsta,
      apiUriInsta,
      apiKeyGoogle,
      apiSecretGoogle,
      apiUriGoogle,
      linkedKey,
      linkedSecret,
      linkedUri,
      twitterKey,
      twitterSecret,
      twitterUri,
      facebookKey,
      facebookSecret,
      facebookUri,
      instaKey,
      instaSecret,
      instaUri,
      googleKey,
      googleSecret,
      googleUri,
      getSocialData,
      saveSocialSettings,
    }
  },
  components: {
    Treeselect,
    SuccessToaster,
    ErrorToaster
  },
};
</script>

const Full = () => import( '@/container/Full.vue')

const STBoxRoutes = () => import(/* webpackPrefetch: false */ '@/views/iptv/SetTopBox.vue')
export default {
   path: '/iptv',
   component: Full,
   redirect: '/iptv/set_top_box',
   beforeEnter(to, from, next) {
      if (localStorage.getItem('token')) {
         next()
      } else {
         next('/signin')
      }
   },
   children: [
      {
         path: '/iptv/set_top_box',
         component: STBoxRoutes,
         meta: {
            title: "IPTV"
        }
      },
   ]
}
const Full = () => import( '@/container/Full.vue')
// dashboard components
const cpIndexRoute = () => import(/* webpackPrefetch: false */ '@/views/CpConfiguration/Index.vue')
const cpWirelessRoute = () => import(/* webpackPrefetch: false */ '@/views/CpConfiguration/WirelessInstruction.vue')
const enableCpRoute = () => import(/* webpackPrefetch: false */ '@/views/CpConfiguration/EnableCapitivePort.vue')
export default {
   path: '/captive_portal_settings',
   component: Full,
   redirect: '/captive_portal_settings',
   beforeEnter(to, from, next) {
      if (localStorage.getItem('token')) {
         next()
      } else {
         next('/signin')
      }
   },
   children: [
      {
         path: '/captive_portal_settings/user_portal',
         component: cpIndexRoute,
         meta: {
            title: "Captive Portal Settings"
         }
      },
      {
         path: '/captive_portal_settings/blocked_macs',
         component: cpIndexRoute,
         meta: {
            title: "Captive Portal Settings"
         }
      },
      {
         path: '/captive_portal_settings/targeted_ads',
         component: cpIndexRoute,
         meta: {
            title: "Captive Portal Settings"
         }
      },
      {
         path: '/customize_user_request_access',
         component: cpIndexRoute,
         meta: {
            title: "Captive Portal Settings"
         }
      },
      {
         path: '/captive_portal_settings',
         component: cpIndexRoute,
         meta: {
            title: "Captive Portal Settings"
         }
      },
      {
         path: '/captive_portal_settings/wireless_instruction',
         component: cpWirelessRoute,
         meta: {
            title: "Captive Portal Settings"
         }
      },
      {
         path: '/captive_portal_settings/enable_captive_portal',
         component: enableCpRoute,
         meta: {
            title: "Captive Portal Settings"
         }
      },
   ]
}
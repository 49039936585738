<template>
  <div class="jazeMainPanel" style="height: 100%;">
    <div class="jazePanelContent jazePanelContent--scrollable">
      <div class="unifiSettingsContainer">
        <form ref="tableReportForm" id="tableReportForm" class="appForm appForm--cozy is-validation-hidden">
          <div class="unifiSettingsHeader">
            <div class="unifiSettingsHeader__title">Report Visible Column</div>
          </div>
          <fieldset class="appFieldset appFieldset--quiet">
            <div class="unifiSettingsSection">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('viewAllReportColumnVisible')">
                <span>Users Details</span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "viewAllReportColumnVisible")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "viewAllReportColumnVisible")? "":"display:none"'>
                <div class="appRow" v-if="reportColumnVisibleData.UserDetailsReportVisibleColumn != undefined && reportColumnVisibleData.UserDetailsReportVisibleColumn.length!=0" v-for="viewUserHeader in reportColumnVisibleData.UserDetailsReportVisibleColumn">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{ capitalize(viewUserHeader.name) }} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="viewUserHeader.name+'-auView'" :checked="viewUserHeader.visible == 'true' ? true : false">
                        <label> Visible </label>
                        </div>
                    </div>
                </div>
              </fieldset>
            </div>
          </fieldset>
        </form>
        <div class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark">
          <div class="appFormFooter__left" v-if="reportColumnVisibleData.visible == 'visible'">
            <button class="appMainButton busyToggle" type="button" @click="setDefaultsModal=true"> Set Defaults </button>

            <button class="appMainButton busyToggle" type="button" @click="setDefaultsSubzonesModal=true" v-if="reportColumnVisibleData.edition == 'isp'"> Set Defaults to All SubZones </button>
            <button class="appMainButton busyToggle" type="button" @click="setDefaultsSubzonesModal=true" v-else> Set Defaults to All SubAccounts </button>
          </div>
          <div class="appFormFooter__right" v-if="reportColumnVisibleData.visible == 'visible'">
            <button class="appMainButton busyToggle" type="button" @click="subZoneModalShow=true" v-if="reportColumnVisibleData.edition == 'isp'"> SubZones Restrictions</button>
            <button class="appMainButton busyToggle" type="button" @click="subZoneModalShow=true" v-else> SubAccounts Restrictions</button>

            <button class="appMainButton busyToggle" type="button" @click="pushZonefn()" v-if="reportColumnVisibleData.edition == 'isp'"> Add to All SubZones</button>
            <button class="appMainButton busyToggle" type="button" @click="pushZonefn()" v-else> Add to All SubAccounts</button>

            <button class="appMainButton appMainButton--primary busyToggle" type="submit" @click="saveReportColumnConfig"> Save</button>
          </div>
        </div>
      </div>
    </div>
    <br><br>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="subZoneModalShow">
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form class="jazeForm jazeModal jazeModal--small unifiAddUser">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title"> Save </div>
                  <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="subZoneModalShow=false">
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeModal__main">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                    <div class="row">
                      <div class="col jazeFormGroup jazeFormGroup--flex">
                        <label class="jazeLabel jazeLabel--primary" v-if="reportColumnVisibleData.edition =='isp'">Allow/Deny sub-zones to change Report Column Visible Configuration</label>
                        <label class="jazeLabel jazeLabel--primary" v-else>Allow/Deny sub-accounts to change Report Column Visible Configuration</label>&nbsp;
                        <div class="toggle appToggle appToggle--light" data-on="On" data-off="Off">
                          <input type="checkbox" :checked="reportColumnVisibleData.subAccountsCanNotChangeReportColumnVisibleConfiguration == 'Yes' ? true : false" v-model="switchcheckboxTableHeader" id="switchcheckboxTableHeader" >
                          <label for="switchcheckboxTableHeader"></label>
                        </div>
                      </div>
                    </div>
                    <div class="appType--micro appType--quiet italicized" v-if="reportColumnVisibleData.edition =='isp'">
                      Note: If it is 'ON', sub-zones can not change Report Column Visible Configuration.
                    </div>
                    <div class="appType--micro appType--quiet italicized" v-else>
                      Note: If it is 'ON', sub-accounts can not change Report Column Visible Configuration.
                    </div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--secondary" type="button" @click="subZoneModalShow=false">Cancel</button>
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="allowSubZones">Yes</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="setDefaultsModal">
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form class="jazeForm jazeModal jazeModal--small unifiAddUser">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title"> Set Defaults </div>
                  <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="setDefaultsModal=false">
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeModal__main">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                      <div class="col jazeFormGroup jazeFormGroup--flex">
                        <label class="jazeLabel jazeLabel--primary"> Do you want to reset Report Column Visible to default ? </label>
                      </div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--secondary" type="button" @click="setDefaultsModal=false">Cancel</button>
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="setDefaultsSave">Yes</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="setDefaultsSubzonesModal">
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form class="jazeForm jazeModal jazeModal--small unifiAddUser">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title"> Set Defaults </div>
                  <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="setDefaultsSubzonesModal=false">
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeModal__main">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                      <div class="col jazeFormGroup jazeFormGroup--flex">
                        <label class="jazeLabel jazeLabel--primary" v-if="reportColumnVisibleData.edition =='isp'"> Are You sure want to use this default settings to all sub zones ? </label>
                        <label class="jazeLabel jazeLabel--primary" v-else> Are You sure want to use this default settings to all sub accounts ? </label>
                      </div><br><br>
                      <div class="appType--micro appType--quiet italicized" v-if="reportColumnVisibleData.edition =='isp'">
                        Note: Once you use these settings in all sub-zones, you cannot undo it. If you want to undo it then it has to be done manually .
                      </div>
                      <div class="appType--micro appType--quiet italicized" v-else>
                        Note: Once you use these settings in all sub-accounts, you cannot undo it. If you want to undo it then it has to be done manually .
                      </div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--secondary" type="button" @click="setDefaultsSubzonesModal=false">Cancel</button>
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="setDefaultsAllZonesSave">Yes</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
    <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
    <PushToSubZone v-if="pushModal" :selected='selected' :edition="reportColumnVisibleData.edition" @extend-clicked-push-detail="onclickclose" :subAccounts="subaccDetailsGetter" :from="'reportVisibleColumnSetting'" :modalType="'selectedType'" :dataSetting="dataSetting"></PushToSubZone>

  </div>
</template>

<script>
import { ref,computed,watch,watchEffect,onMounted,onBeforeUnmount } from 'vue'
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import PushToSubZone from "@/components/Profiles/PushToSubZoneModal.vue";
import Treeselect from "jaze-vue3-treeselect";

import { storeToRefs } from "pinia";
import { usesettingsStore } from '@/store/settingsstore'
import { uselayoutStore } from '@/store/layoutstore'
export default {
  setup() {
    const layoutStore = uselayoutStore()
    const { subaccDetails } = storeToRefs(layoutStore)
    const subaccDetailsGetter= computed(() => subaccDetails.value);
    const reportColumnVisibleData = ref( [] )
    const viewToggleMenuData  = ref( "" )
    const subZoneModalShow = ref( false )
    const setDefaultsModal = ref( false )
    const setDefaultsSubzonesModal = ref( false )
    const selectedAccVal = ref( null )
    const subAccOptions = ref( [
            {
              id: "selectAll",
              label: "Select All",
              children: []
            }
          ] )
    const successtoaster = ref( false )
    const successMessage = ref( "" )
    const errortoaster = ref( false )
    const errorMessage = ref( "" )
    const switchcheckboxTableHeader = ref( false )
    const selectedDatetime = ref( '' )
    const selectedDate = ref( '' )
    const defaultColumn  = ref( 'username' )
    const defaultSorting  = ref( 'asc' )
    const optionaAllSelect = ref( "all" )
    const dataSetting = ref( [{
              id: "selectAll",
              label: "Select All",
              children: []
            }] )
    const pushModal = ref( false )
    const selected = ref( [] )
    // const subAccounts = ref( [] )

    const settingsStore = usesettingsStore()
    const { reportVisibleColumnData } = storeToRefs(settingsStore)
    const reportVisibleColumnConfigGetter = computed(() => reportVisibleColumnData.value);

    onMounted(() => {
      getUserFieldsData();
    })
    onBeforeUnmount(() => {
      settingsStore.clearSettingsState();
    })

    watchEffect(() => {
      reportColumnVisibleData.value = reportVisibleColumnConfigGetter.value;
      if(reportColumnVisibleData.value.accountsArr!=undefined && reportColumnVisibleData.value.accountsArr.length !=0) {
          for(let i=0; i<reportColumnVisibleData.value.accountsArr.length; i++) {
            var deactivated = reportColumnVisibleData.value.accountsArr[i].deactivated == 0 ? " (Deactivated)" : "";
              subAccOptions.value[0].children.push({id:reportColumnVisibleData.value.accountsArr[i].id, label:reportColumnVisibleData.value.accountsArr[i].name + deactivated})
          }
      }
      if (
        reportColumnVisibleData.value.dataSetting != undefined &&
        reportColumnVisibleData.value.dataSetting.length != 0
      ) {
        for (let i = 0; i < reportColumnVisibleData.value.dataSetting.length; i++) {
          dataSetting.value[0].children.push({
            id: reportColumnVisibleData.value.dataSetting[i].id,
            label: reportColumnVisibleData.value.dataSetting[i].label,
          });
        }
      }
      if(reportColumnVisibleData.value.subAccountsCanNotChangeReportColumnVisibleConfiguration == 'Yes') {
        switchcheckboxTableHeader.value = true;
      }
      if(reportColumnVisibleData.value.UserDetailsReportVisibleColumn != undefined){
        for(let i=0; i<reportColumnVisibleData.value.UserDetailsReportVisibleColumn.length; i++){
          if(reportColumnVisibleData.value.UserDetailsReportVisibleColumn[i].name=="defalut_view"){
            defaultColumn.value = reportColumnVisibleData.value.UserDetailsReportVisibleColumn[i].visible=="staticIPAndMAC"?"username":reportColumnVisibleData.value.UserDetailsReportVisibleColumn[i].visible;
            
          }
        }
      }
    })

    const getUserFieldsData = () => {
      if (Object.keys(reportVisibleColumnConfigGetter.value).length == 0) {
        // $store.dispatch("fetchTableHeaderConfig");
        settingsStore.fetchReportColumnVisibleConfig();
      } else {
        reportColumnVisibleData.value = reportVisibleColumnConfigGetter.value;
        if(reportColumnVisibleData.value.UserDetailsReportVisibleColumn != undefined){
          for(let i=0; i<reportColumnVisibleData.value.UserDetailsReportVisibleColumn.length; i++){
          if(reportColumnVisibleData.value.UserDetailsReportVisibleColumn[i].name=="defalut_view"){
            // defaultSorting.value = reportColumnVisibleData.value.UserDetailsReportVisibleColumn[i].sortable;
            defaultColumn.value = reportColumnVisibleData.value.UserDetailsReportVisibleColumn[i].visible;
          }}
        }
      }

    }
    const allowSubZones = () => {
        successtoaster.value = false;
        errortoaster.value = false;
        if (switchcheckboxTableHeader.value == true) {
          var check = "on";
        } else {
          var check = "off";
        }
        var data = {
          switchCheckBoxValue: check,
          attribute: "subAccountsCanNotChangeReportColumnVisibleConfiguration",
          type: 'reportColumn'
        };
        // $store.dispatch("saveSubAccountPermissionSettings", data).then(
        settingsStore.saveSubAccountPermissionSettings(data).then(  
          response => {
            subZoneModalShow.value = false;
            successtoaster.value = true;
            successMessage.value = response;
            setTimeout(
              function() {
                successtoaster.value = false;
              }.bind(this),
              2000
            );
          },
          error => {
            errortoaster.value = true;
            errorMessage.value = error;
            setTimeout(
              function() {
                errortoaster.value = false;
              }.bind(this),
              15000
            );
            console.log(error);
          }
        );
    }
    const setDefaultsSave = () =>  {
        // $store.dispatch("tableConfigSetDefaults").then(
        settingsStore.reportColumnVisibleSetDefaults().then(  
          response => {
            setDefaultsModal.value = false;
            successtoaster.value = true;
            successMessage.value = response;
            setTimeout(
              function() {
                successtoaster.value = false;
              }.bind(this),
              2000
            );
          },
          error => {
            errortoaster.value = true;
            errorMessage.value = error;
            setTimeout(
              function() {
                errortoaster.value = false;
              }.bind(this),
              15000
            );
            console.log(error);
          }
        );
    }
    const setDefaultsAllZonesSave = () => {
        // $store.dispatch("tableConfigSetDefaultsToSubZones").then(
        settingsStore.reportColumnVisibleSetDefaultsToSubZones().then(  
          response => {
            setDefaultsSubzonesModal.value = false;
            successtoaster.value = true;
            successMessage.value = response;
            setTimeout(
              function() {
                successtoaster.value = false;
              }.bind(this),
              2000
            );
          },
          error => {
            errortoaster.value = true;
            errorMessage.value = error;
            setTimeout(
              function() {
                errortoaster.value = false;
              }.bind(this),
              15000
            );
            console.log(error);
          }
        );
    }
    const setDateTimeFn = (event,name) => {
      if(event.target.checked == true) {
        selectedDatetime.value = name;      
      }
      
    }
    const setDateFn = (event,name) => {
      if(event.target.checked == true) {
        selectedDate.value = name;      
      }
    }
    const saveReportColumnConfig = (e) => {
        e.preventDefault();
        var form = document.getElementById('tableReportForm');
        const formData = new FormData(form);
        const postData = {};
        for (let [key, val] of formData.entries()) {
          Object.assign(postData, { [key]: val });
        }
        var submitData = {};
        submitData['auViewIds'] = [];
        submitData['auNonViewIds'] = [];
        // submitData['auEditIds'] = [];
        // submitData['auNonEditIds'] = [];
        for(var i=0; i<reportColumnVisibleData.value.UserDetailsReportVisibleColumn.length; i++) {
            var name = reportColumnVisibleData.value.UserDetailsReportVisibleColumn[i].name;
            var editname = name+ '-auView'; //username-auView
            if(postData[editname] != undefined && postData[editname] == 'on') {
              submitData.auViewIds.push(name);
            } else {
              submitData.auNonViewIds.push(name);
            }
            
        }
        submitData['defaultReportColumn'] = defaultColumn.value;
        // submitData['defaultUserTableSorting'] = defaultSorting.value;
        // $store.dispatch("tableHeaderConfigsubmit", submitData).then(
        settingsStore.reportColumnVisibleConfigsubmit(submitData).then(  
          response => {
            successtoaster.value = true;
            successMessage.value = response;
            setTimeout(
              function() {
                successtoaster.value = false;
              }.bind(this),
              2000
            );
          },
          error => {
            errortoaster.value = true;
            errorMessage.value = error;
            setTimeout(
              function() {
                errortoaster.value = false;
              }.bind(this),
              15000
            );
            console.log(error);
          }
        );
    }
    const viewToggleMenuDatafn = (value) =>{
      if (viewToggleMenuData.value == value) {
        viewToggleMenuData.value = "";
      } else {
        viewToggleMenuData.value = value;
      }
    }
    const onclickclose = (value) => {
      pushModal.value = value;
    }
    const pushZonefn = () => {
      pushModal.value = !pushModal.value;
    }
    const capitalize = (value) => {
        if (!value) return '';
        value = value.toString();
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    return {
      settingsStore,
      reportVisibleColumnConfigGetter: reportVisibleColumnData,
      subaccDetailsGetter,
      reportColumnVisibleData,
      viewToggleMenuData,
      subZoneModalShow,
      setDefaultsModal,
      setDefaultsSubzonesModal,
      selectedAccVal,
      subAccOptions,
      successtoaster,
      successMessage,
      errortoaster,
      errorMessage,
      switchcheckboxTableHeader,
      selectedDatetime,
      selectedDate,
      defaultColumn ,
      defaultSorting ,
      optionaAllSelect,
      dataSetting,
      pushModal,
      selected,
      // subAccounts,
      getUserFieldsData,
      allowSubZones,
      setDefaultsSave,
      setDefaultsAllZonesSave,
      setDateTimeFn,
      setDateFn,
      saveReportColumnConfig,
      viewToggleMenuDatafn,
      onclickclose,
      pushZonefn,
      capitalize,
    }
  },
  components: {
    SuccessToaster,
    ErrorToaster,
    PushToSubZone,
    Treeselect
  },
};
</script>

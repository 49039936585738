const Full = () => import(/* webpackChunkName: "fullcontainer-chunk" */ '@/container/Full.vue')
const SysSetRoutes = () => import(/* webpackPrefetch: false */ '@/views/systemtools/SystemSettings.vue')
const FactoryResetRoutes = () => import(/* webpackPrefetch: false */ '@/views/systemtools/FactoryDefaults.vue')
const RebootRoutes = () => import(/* webpackPrefetch: false */ '@/views/systemtools/Reboot.vue')
const ShutDownRoutes = () => import(/* webpackPrefetch: false */ '@/views/systemtools/ShutDown.vue')
const UpdateRoutes = () => import(/* webpackPrefetch: false */ '@/views/systemtools/Update.vue')
const CertificatesRoutes = () => import(/* webpackPrefetch: false */ '@/views/systemtools/Certificates.vue')
const BackupRoutes = () => import(/* webpackPrefetch: false */ '@/views/systemtools/BackupAndRestore.vue')
export default {
    path: '/system_tools',
    component: Full,
    redirect: '/system_tools',
    beforeEnter(to, from, next) {
        if (localStorage.getItem('token')) {
            next()
        } else {
            next('/signin')
        }
    },
    children: [
        {
            path: '/system_tools/index',
            component: SysSetRoutes,
            meta: {
                title: "System Tools"
            }
        },
        {
            path: '/system_tools/factory_defaults',
            component: FactoryResetRoutes,
            meta: {
                title: "System Tools"
            }
        },
        {
            path: '/system_tools/reboot',
            component: RebootRoutes,
            meta: {
                title: "System Tools"
            }
        },
        {
            path: '/system_tools/halt',
            component: ShutDownRoutes,
            meta: {
                title: "System Tools"
            }
        },
        {
            path: '/system_tools/update',
            component: UpdateRoutes,
            meta: {
                title: "System Tools"
            }
        },
        {
            path: '/system_tools/certificates',
            component: CertificatesRoutes,
            meta: {
                title: "System Tools"
            }
        },
        {
            path: '/system_tools/backup',
            component: BackupRoutes,
            meta: {
                title: "System Tools"
            }
        },
    ]
}
const Full = () => import('@/container/Full.vue')

// dashboard components
const userStatsRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UserStats.vue')
const userDetailsRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UserDetails.vue')
const customizeVouchersRoutes = () => import(/* webpackPrefetch: false */ '../views/users/CustomizeVouchers.vue')
const addUserRoutes = () => import(/* webpackPrefetch: false */ '../views/users/Add.vue')
const editUserRoutes = () => import(/* webpackPrefetch: false */ '../views/users/Edit.vue')
const userOrdersRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UsersOrders.vue')
const userInvoicesRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UsersInvoices.vue')
const userInvoicesAssociatedWithPaymentsRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UsersInvoicesAssociatedWithPay.vue')
const userProformaInvoicesRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UsersProformaInvoices.vue')
const userCreditNotesRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UsersCreditNotes.vue')
const userPaymentsRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UsersPayments.vue')
const userTypeRoutes = () => import(/* webpackPrefetch: false */ '../views/users/Type.vue')
const userStaticIpBillingRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UsersStaticIp.vue')
const subUsersRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UsersSub.vue')
const usersLogsRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UsersLogs.vue')
const eventLogsRoutes = () => import(/* webpackPrefetch: false */ '../views/users/EventsLog.vue')
const smsLogsRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UserSmsLogs.vue')
const emailLogsRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UserEmailLogs.vue')
const whatsappLogsRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UserWhatsappLogs.vue')
const paymentLogsRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UserPaymentLogs.vue')
const renewalLogsRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UserRenewalLogs.vue')
const userPlanHistoryRoutes = () => import(/* webpackPrefetch: false */ '../views/users/PlanHistory.vue')
const userFupHistoryRoutes = () => import(/* webpackPrefetch: false */ '../views/users/FupHistory.vue')
const userFupFilterRoutes = () => import(/* webpackPrefetch: false */ '../views/users/FupFilter.vue')
const userCompletedSessionsRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UsersCompletedSessions.vue')
const sessionsDayWiseRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UsersSessionsDayWise.vue')
const viewAllUsersRoutes = () => import(/* webpackPrefetch: false */ '../views/users/ViewAllUsers.vue')
const viewAllUsersElasticRoutes = () => import(/* webpackPrefetch: false */ '../views/users/ViewAllUsersElastic.vue')
const userTrafficUsageRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UsersTrafficUsage.vue')
const userFupRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UsersFup.vue')
const userPhoneNumbersRoutes = () => import(/* webpackPrefetch: false */ '../components/UserMenuElements/PhoneNumbers.vue')
const userDocumentsRoutes = () => import(/* webpackPrefetch: false */ '../views/users/DocumentManagement.vue')
const UserTickets = () => import(/* webpackPrefetch: false */ '../views/users/UserTicketsMenu.vue')
const SearchUser = () => import(/* webpackPrefetch: false */ '../views/users/SearchUser.vue')
const userInventoryDetailsRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UsersInventoryDetails.vue')
const userSTBRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UserSetTopBox.vue')
const userLedgerRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UsersLedger.vue')
const userIPPRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UserIPPhone.vue')
const userFranchiseStatementPRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UsersFranchiseStatement.vue')
const userPinsRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UsersPins.vue')
const CAFGenerationRoutes = () => import(/* webpackPrefetch: false */ '../views/users/CAFGeneration.vue')
const userIptvBillingRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UsersIptvBilling.vue')
const userVoicePhoneBillingRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UsersVoicePhoneBilling.vue')
const userOttBillingRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UsersOttBilling.vue')
const overrideProfileRoutes = () => import(/* webpackPrefetch: false */ '../views/users/OverrideProfile.vue')
const testPackageRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UserTestPack.vue')
const commentsRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UsersComments.vue')
const websiteLogsRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UsersWebsiteLogs.vue')
const contactsRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UsersContacts.vue')
const setLocationRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UsersSetLocation.vue')
const userDevicesRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UsersDevices.vue')
const userOverrideBillingCycleRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UsersOverrideBillingCycle.vue')
const viewUserReferralCodeRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UsersViewReferralCode.vue')
const viewUserOnucpeDevicesRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UsersViewOnuCpeDevices.vue')
const overrideNotificationsRoutes = () => import(/* webpackPrefetch: false */ '../views/users/OverrideNotifications.vue')
const addBulkRoutes = () => import(/* webpackPrefetch: false */ '../components/Users/AddBulk.vue')
const rejectedUsersRoutes = () => import(/* webpackPrefetch: false */ '../components/Users/RejectedUsers.vue')
const presentSessionsDataRoutes = () => import(/* webpackPrefetch: false */ '../views/users/UsersPresentSessionsData.vue')
const pendingUsersDataRoutes = () => import(/* webpackPrefetch: false */ '../components/Users/PendingUsers.vue')
const importUsersCSVRoutes = () => import(/* webpackPrefetch: false */ '@/views/users/ImportUsersCSV.vue')
const importUsersCopyPasteRoutes = () => import(/* webpackPrefetch: false */ '@/views/users/ImportUsersCopyPaste.vue')
const importUsersIpHistoryRoutes = () => import(/* webpackPrefetch: false */ '@/views/users/IpHistory.vue')
const UserAdminCommentRoutes = () => import(/* webpackPrefetch: false */ '@/views/users/UserAdminComment.vue')
const viewUserTopologyRoutes=() => import(/* webpackPrefetch: false */ '../views/users/UserTopology.vue')
const userIndexRoutes = () => import(/* webpackPrefetch: false */ '../views/users/Index.vue')
const viewUsersRoutes = () => import(/* webpackPrefetch: false */ '../views/users/ViewUsers.vue')
export default {
   path: '/users',
   component: Full,
   redirect: '/users',
   beforeEnter(to, from, next) {
      if (localStorage.getItem('token')) {
         next()
      } else {
         next('/signin')
      }
   },
   children: [
      {
         path: '/users/user_stats/:id/:accountId',
         component: userStatsRoutes,
         meta: {
            title: "User Stats"
         }
      }, {
         path: '/users/details/:id/:accountId',
         component: userDetailsRoutes,
         meta: {
            title: "User Details"
         }
      },
      {
         path: '/vouchers/customize_vouchers',
         component: customizeVouchersRoutes,
         meta: {
            title: "Vouchers"
         }
      },
      {
         path: '/users/add/:userGroupId',
         component: addUserRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/vouchers/add/:userGroupId',
         component: addUserRoutes,
         meta: {
            title: "Vochers"
         }
      },
      {
         path: '/users/edit/:userId/:accountId',
         component: editUserRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/vouchers/edit/:userId/:accountId',
         component: editUserRoutes,
         meta: {
            title: "Vouchers"
         }
      },
      {
         path: '/users/users_orders/:userId/:accountId',
         component: userOrdersRoutes,
         meta: {
            title: "User Orders"
         }
      },
      {
         path: '/users/user_invoices/:userId/:accountId',
         component: userInvoicesRoutes,
         meta: {
            title: "User Invoices"
         }
      },
      {
         path: '/users/user_invoices_associated_with_payments/:userId/:accountId',
         component: userInvoicesAssociatedWithPaymentsRoutes,
         meta: {
            title: "User Invoices Associated With Payments"
         }
      },
      {
         path: '/users/user_proforma_invoices/:userId/:accountId',
         component: userProformaInvoicesRoutes,
         meta: {
            title: "User Proforma Invoices"
         }
      },
      {
         path: '/users/user_credit_notes/:userId/:accountId',
         component: userCreditNotesRoutes,
         meta: {
            title: "User Credit Notes"
         }
      },
      {
         path: '/users/user_payments/:userId/:accountId',
         component: userPaymentsRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/type/:userId/:accountId',
         component: userTypeRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/static_ip_billing/:userId/:accountId',
         component: userStaticIpBillingRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/sub_users/:userId/:accountId',
         component: subUsersRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/users_logs/:userId/:accountId',
         component: usersLogsRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/vouchers/users_logs/:userId/:accountId',
         component: usersLogsRoutes,
         meta: {
            title: "Vouchers"
         }
      },
      {
         path: '/users/event_logs/:userId/:accountId',
         component: eventLogsRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/user_sms_logs/:userId/:accountId',
         component: smsLogsRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/user_email_logs/:userId/:accountId',
         component: emailLogsRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/user_whatsapp_logs/:userId/:accountId',
         component: whatsappLogsRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/user_payment_logs/:userId/:accountId',
         component: paymentLogsRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/user_renewal_logs/:userId/:accountId',
         component: renewalLogsRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/user_plans/:userId/:accountId',
         component: userPlanHistoryRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/fup_history/:userId/:accountId',
         component: userFupHistoryRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/fup_filter/:userId/:accountId',
         component: userFupFilterRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/sessions/:userId/:accountId',
         component: userCompletedSessionsRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/sessions_day_wise/:userId/:accountId',
         component: sessionsDayWiseRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/all',
         component: viewAllUsersRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/all/:account/:status',
         component: viewAllUsersRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/all/:account/:status/:from/:to',
         component: viewAllUsersRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/allusers/:account/:status',
         component: viewAllUsersElasticRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/vouchers/allusers/:account/:status',
         component: viewAllUsersElasticRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/user_traffic_usage/:userId/:accountId',
         component: userTrafficUsageRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/allusers',
         component: viewAllUsersElasticRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/allusers/:account/:status/:from/:to',
         component: viewAllUsersElasticRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/fup/:userId/:accountId',
         component: userFupRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/phone_numbers',
         component: userPhoneNumbersRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/document_management',
         component: userDocumentsRoutes,
         meta: {
            title: "Document Management"
         }
      },
      {
         path: '/helpdesk/user_tickets/:id/:account',
         component: UserTickets,
         meta: {
            title: "User Tickets"
         }
      },
      {
         path: '/users/search_users',
         component: SearchUser,
         meta: {
            title: "Search User"
         }
      },
      {
         path: '/users/inventory_details/:userId/:accountId',
         component: userInventoryDetailsRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/user_set_top_boxs/:userId/:accountId',
         component: userSTBRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/user_ledger/:userId/:accountId',
         component: userLedgerRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/user_set_top_phones/:userId/:accountId',
         component: userIPPRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/user_franchise_statement/:userId/:accountId',
         component: userFranchiseStatementPRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/user_pins/:userId/:accountId',
         component: userPinsRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/caf_generation',
         component: CAFGenerationRoutes,
         meta: {
            title: "CAF Generation"
         }
      },

      {
         path: '/users/iptv_billing/:userId/:accountId',
         component: userIptvBillingRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/ipphone_billing/:userId/:accountId',
         component: userVoicePhoneBillingRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/ott_billing/:userId/:accountId',
         component: userOttBillingRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/override_profile/:userId/:profilId/:accountId',
         component: overrideProfileRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/override_profile/:userId/:profilId/:accountId/true',
         component: testPackageRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/comments/:userId/:accountId',
         component: commentsRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/website_logs/:userId/:accountId',
         component: websiteLogsRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/user_contacts/:userId/:accountId',
         component: contactsRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/set_location/:userId/:accountId',
         component: setLocationRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/user_devices/:userId/:accountId',
         component: userDevicesRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/override_billing_cycle/:userId/:accountId',
         component: userOverrideBillingCycleRoutes,
         meta: {
            title: "Users"
         }
      },
      {

         path: '/users/view_user_referral_code/:userId/:accountId',
         component: viewUserReferralCodeRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/view_user_onucpe_devices/:userId/:accountId',
         component: viewUserOnucpeDevicesRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/view_user_topology/:userId/:accountId',
         component: viewUserTopologyRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/override_notifications/:userId/:accountId',
         component: overrideNotificationsRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/add_bulk/:userId',
         component: addBulkRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/vouchers/add_bulk/:userId',
         component: addBulkRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/rejected_users',
         component: rejectedUsersRoutes,
         meta: {
            title: "Rejecting Users"
         }
      },
      {
         path: '/users/present_sessions_date/:userId/:accountId',
         component: presentSessionsDataRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/users/pending_users',
         component: pendingUsersDataRoutes,
         meta: {
            title: "Pending Users"
         }
      },
      {
         path: '/users/pending_users/:user_id',
         component: pendingUsersDataRoutes,
         meta: {
            title: "Pending Users"
         }
      },
      {
         path: '/vouchers/import/file/:groupid',
         component: importUsersCSVRoutes,
         meta: {
            title: "Import Users"
         }
      },
      {
         path: '/vouchers/import/text/:groupid',
         component: importUsersCopyPasteRoutes,
         meta: {
            title: "Import Users"
         }
      },
      {
         path: '/users/userIpDetails/:userId/:accountId',
         component: importUsersIpHistoryRoutes,
         meta: {
            title: "Users IP"
         }
      },
      {
         path: '/user/admin_comment/:userId/:accountId',
         component: UserAdminCommentRoutes,
         meta: {
            title: "Admin Comment"
         }
      },
      {
         path: '/users',
         component: userIndexRoutes,
         meta: {
            title: "Users"
         }
      },
      {
         path: '/vouchers',
         component: userIndexRoutes,
         meta: {
            title: "Vouchers"
         }
      },
      {
         path: '/users/view/:userGroupId',
         component: viewUsersRoutes,
         meta: {
            title: "Users"
         }
      },
    ]
}

<template>
  <div class="jazeMainPanel" style="height: 100%;">
    <div class="jazePanelContent jazePanelContent--scrollable">
      <div class="unifiSettingsContainer">
        <form ref="tableHeaderForm" id="tableHeaderForm" class="appForm appForm--cozy is-validation-hidden">
          <div class="unifiSettingsHeader">
            <div class="unifiSettingsHeader__title">Table Header configuration</div>
          </div>
          <fieldset class="appFieldset appFieldset--quiet">
            <div class="unifiSettingsSection">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('viewAllUser')">
                <span>View All Users</span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "viewAllUser")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "viewAllUser")? "":"display:none"'>
                <div class="appRow" v-if="tableHeaderData.viewAllUserTableHeaders != undefined && tableHeaderData.viewAllUserTableHeaders.length!=0">
                  <div class="col--sm5 col--md4 col--lg3">
                      <label class="appLabel appLabel--primary"> Default view </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                      <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <select class="width-full" name='defaultColumn'  v-model="defaultColumn">
                          <template v-for="(viewUserHeader,index) in tableHeaderData.viewAllUserTableHeaders">
                          <option v-if="(viewUserHeader.type == tableHeaderData.type || viewUserHeader.type =='ultimate') && (viewUserHeader.name !='staticIPAndMAC')" :key="index" :value="viewUserHeader.name" >
                            {{ capitalize(viewUserHeader.name) }}
                          </option>
                          </template>
                        </select>
                      </div>
                      <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <select class="width-full" name='defaultSorting'  v-model="defaultSorting">
                          <option value="asc">Asc</option>
                          <option value="desc">Desc</option>
                        </select>
                      </div>
                  </div>
                </div>
                <div class="appRow" v-if="tableHeaderData.viewAllUserTableHeaders != undefined && tableHeaderData.viewAllUserTableHeaders.length!=0" v-for="viewUserHeader in tableHeaderData.viewAllUserTableHeaders">
                  <div v-if="viewUserHeader.type == tableHeaderData.type || viewUserHeader.type =='ultimate'">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{ capitalize(viewUserHeader.name) }} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="viewUserHeader.name+'-auView'" :checked="viewUserHeader.visible == 'true' ? true : false">
                        <label> Visible </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="viewUserHeader.name+'-auEdit'" :checked="viewUserHeader.sortable == 'true' ? true : false">
                        <label> Sortable </label>
                        </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('viewActiveSession')">
                <span>View Active Sessions</span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "viewActiveSession")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "viewActiveSession")? "":"display:none"'>
                <div class="appRow" v-if="tableHeaderData.viewActiveSessionsTableHeaders != undefined && tableHeaderData.viewActiveSessionsTableHeaders.length!=0" v-for="activeSessionsHeader in tableHeaderData.viewActiveSessionsTableHeaders">
                  <div v-if="activeSessionsHeader.type == tableHeaderData.type || activeSessionsHeader.type =='ultimate'">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{ capitalize(activeSessionsHeader.name) }} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="activeSessionsHeader.name+'-asView'" :checked="activeSessionsHeader.visible == 'true' ? true : false">
                        <label> Visible </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="activeSessionsHeader.name+'-asEdit'" :checked="activeSessionsHeader.sortable == 'true' ? true : false">
                        <label> Sortable </label>
                        </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('viewCompletedSession')">
                <span>Completed Sessions</span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "viewCompletedSession")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "viewCompletedSession")? "":"display:none"'>
                <div class="appRow" v-if="tableHeaderData.viewCompletedSessionsTableHeaders != undefined && tableHeaderData.viewCompletedSessionsTableHeaders.length!=0" v-for="completedSessionsHeader in tableHeaderData.viewCompletedSessionsTableHeaders">
                  <div v-if="(completedSessionsHeader.type == tableHeaderData.type || completedSessionsHeader.type =='ultimate') && !((completedSessionsHeader.name == 'username' || completedSessionsHeader.name == 'firstname' || completedSessionsHeader.name == 'lastname' || completedSessionsHeader.name == 'email') && tableHeaderData.jazePlatform !='hardware') && !(completedSessionsHeader.name == 'userId' && tableHeaderData.jazePlatform =='hardware')">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{ capitalize(completedSessionsHeader.name) }} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="completedSessionsHeader.name+'-csView'" :checked="completedSessionsHeader.visible == 'true' ? true : false">
                        <label> Visible </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="completedSessionsHeader.name+'-csEdit'" :checked="completedSessionsHeader.sortable == 'true' ? true : false">
                        <label> Sortable </label>
                        </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('groupUser')">
                <span> Group Users </span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "groupUser")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "groupUser")? "":"display:none"'>
                <div class="appRow" v-if="tableHeaderData.viewGroupUsersTableHeaders != undefined && tableHeaderData.viewGroupUsersTableHeaders.length!=0" v-for="groupUsersHeader in tableHeaderData.viewGroupUsersTableHeaders">
                  <div v-if="groupUsersHeader.type == tableHeaderData.type || groupUsersHeader.type =='ultimate'">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{ capitalize(groupUsersHeader.name) }} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="groupUsersHeader.name+'-guView'" :checked="groupUsersHeader.visible == 'true' ? true : false">
                        <label> Visible </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="groupUsersHeader.name+'-guEdit'" :checked="groupUsersHeader.sortable == 'true' ? true : false">
                        <label> Sortable </label>
                        </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('userLog')">
                <span> User Logs </span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "userLog")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "userLog")? "":"display:none"'>
                <div class="appRow" v-if="tableHeaderData.viewUserLogsTableHeaders != undefined && tableHeaderData.viewUserLogsTableHeaders.length!=0" v-for="userLogsHeader in tableHeaderData.viewUserLogsTableHeaders">
                  <div v-if="userLogsHeader.type == tableHeaderData.type || userLogsHeader.type =='ultimate'">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{ capitalize(userLogsHeader.name) }} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="userLogsHeader.name+'-ulView'" :checked="userLogsHeader.visible == 'true' ? true : false">
                        <label> Visible </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="userLogsHeader.name+'-ulEdit'" :checked="userLogsHeader.sortable == 'true' ? true : false">
                        <label> Sortable </label>
                        </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('userSession')">
                <span> User Sessions </span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "userSession")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "userSession")? "":"display:none"'>
                <div class="appRow" v-if="tableHeaderData.viewUserSessionsTableHeaders != undefined && tableHeaderData.viewUserSessionsTableHeaders.length!=0" v-for="userSessionsHeader in tableHeaderData.viewUserSessionsTableHeaders">
                  <div v-if="userSessionsHeader.type == tableHeaderData.type || userSessionsHeader.type =='ultimate'">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{ capitalize(userSessionsHeader.name) }} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="userSessionsHeader.name+'-usView'" :checked="userSessionsHeader.visible == 'true' ? true : false">
                        <label> Visible </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="userSessionsHeader.name+'-usEdit'" :checked="userSessionsHeader.sortable == 'true' ? true : false">
                        <label> Sortable </label>
                        </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('logUser')">
                <span> Logs for Users </span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "logUser")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "logUser")? "":"display:none"'>
                <div class="appRow" v-if="tableHeaderData.viewLogsUserLogsTableHeaders != undefined && tableHeaderData.viewLogsUserLogsTableHeaders.length!=0" v-for="logUsersHeader in tableHeaderData.viewLogsUserLogsTableHeaders">
                  <div v-if="logUsersHeader.type == tableHeaderData.type || logUsersHeader.type =='ultimate'">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{ capitalize(logUsersHeader.name) }} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="logUsersHeader.name+'-lulView'" :checked="logUsersHeader.visible == 'true' ? true : false">
                        <label> Visible </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="logUsersHeader.name+'-lulEdit'" :checked="logUsersHeader.sortable == 'true' ? true : false">
                        <label> Sortable </label>
                        </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('logSms')">
                <span> Logs for SMS </span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "logSms")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "logSms")? "":"display:none"'>
                <div class="appRow" v-if="tableHeaderData.viewLogsSMSLogsTableHeaders != undefined && tableHeaderData.viewLogsSMSLogsTableHeaders.length!=0" v-for="logsSmsHeader in tableHeaderData.viewLogsSMSLogsTableHeaders">
                  <div v-if="logsSmsHeader.type == tableHeaderData.type || logsSmsHeader.type =='ultimate'">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{ capitalize(logsSmsHeader.name) }} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="logsSmsHeader.name+'-lsmslView'" :checked="logsSmsHeader.visible == 'true' ? true : false">
                        <label> Visible </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="logsSmsHeader.name+'-lsmslEdit'" :checked="logsSmsHeader.sortable == 'true' ? true : false">
                        <label> Sortable </label>
                        </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('logEmail')">
                <span> Logs for Email </span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "logEmail")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "logEmail")? "":"display:none"'>
                <div class="appRow" v-if="tableHeaderData.viewLogsEmailLogsTableHeaders != undefined && tableHeaderData.viewLogsEmailLogsTableHeaders.length!=0" v-for="logsEmailHeader in tableHeaderData.viewLogsEmailLogsTableHeaders">
                  <div v-if="logsEmailHeader.type == tableHeaderData.type || logsEmailHeader.type =='ultimate'">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{ capitalize(logsEmailHeader.name) }} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="logsEmailHeader.name+'-lemaillView'" :checked="logsEmailHeader.visible == 'true' ? true : false">
                        <label> Visible </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="logsEmailHeader.name+'-lemaillEdit'" :checked="logsEmailHeader.sortable == 'true' ? true : false">
                        <label> Sortable </label>
                        </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('viewAllPins')">
                <span> View all PINs </span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "viewAllPins")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "viewAllPins")? "":"display:none"'>
                <div class="appRow" v-if="tableHeaderData.viewAllPinsTableHeaders != undefined && tableHeaderData.viewAllPinsTableHeaders.length!=0" v-for="viewPinsHeader in tableHeaderData.viewAllPinsTableHeaders">
                  <div v-if="viewPinsHeader.type == tableHeaderData.type || viewPinsHeader.type =='ultimate'">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{ capitalize(viewPinsHeader.name) }} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="viewPinsHeader.name+'-lpinlView'" :checked="viewPinsHeader.visible == 'true' ? true : false">
                        <label> Visible </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="viewPinsHeader.name+'-lpinlEdit'" :checked="viewPinsHeader.sortable == 'true' ? true : false">
                        <label> Sortable </label>
                        </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('payAsYouGo')">
                <span> Pay As You Go Users </span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "payAsYouGo")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "payAsYouGo")? "":"display:none"'>
                <div class="appRow" v-if="tableHeaderData.viewPayAsYouGoTableHeaders != undefined && tableHeaderData.viewPayAsYouGoTableHeaders.length!=0" v-for="payasyougoHeader in tableHeaderData.viewPayAsYouGoTableHeaders">
                  <div v-if="payasyougoHeader.type == tableHeaderData.type || payasyougoHeader.type =='ultimate'">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{ capitalize(payasyougoHeader.name) }} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="payasyougoHeader.name+'-lpaylView'" :checked="payasyougoHeader.visible == 'true' ? true : false">
                        <label> Visible </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="payasyougoHeader.name+'-lpaylEdit'" :checked="payasyougoHeader.sortable == 'true' ? true : false">
                        <label> Sortable </label>
                        </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('viewInvoice')">
                <span> View Invoice </span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "viewInvoice")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "viewInvoice")? "":"display:none"'>
                <div class="appRow" v-if="tableHeaderData.viewInvoiceTableHeaders != undefined && tableHeaderData.viewInvoiceTableHeaders.length!=0" v-for="viewInvoiceHeader in tableHeaderData.viewInvoiceTableHeaders">
                  <div v-if="(viewInvoiceHeader.type == tableHeaderData.type || viewInvoiceHeader.type =='ultimate') && !(viewInvoiceHeader.name =='balance' || viewInvoiceHeader.name =='total')">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{ capitalize(viewInvoiceHeader.name) }} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="viewInvoiceHeader.name+'-linvoicelView'" :checked="viewInvoiceHeader.visible == 'true' ? true : false">
                        <label> Visible </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="viewInvoiceHeader.name+'-linvoicelEdit'" :checked="viewInvoiceHeader.sortable == 'true' ? true : false">
                        <label> Sortable </label>
                        </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('viewPayment')">
                <span> View Payments </span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "viewPayment")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "viewPayment")? "":"display:none"'>
                <div class="appRow" v-if="tableHeaderData.viewPaymentsTableHeaders != undefined && tableHeaderData.viewPaymentsTableHeaders.length!=0" v-for="viewpaymentsHeader in tableHeaderData.viewPaymentsTableHeaders">
                  <div v-if="viewpaymentsHeader.type == tableHeaderData.type || viewpaymentsHeader.type =='ultimate'">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{ capitalize(viewpaymentsHeader.name) }} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="viewpaymentsHeader.name+'-lpaymentslView'" :checked="viewpaymentsHeader.visible == 'true' ? true : false">
                        <label> Visible </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="viewpaymentsHeader.name+'-lpaymentslEdit'" :checked="viewpaymentsHeader.sortable == 'true' ? true : false">
                        <label> Sortable </label>
                        </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('viewOrders')">
                <span> View Orders </span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "viewOrders")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "viewOrders")? "":"display:none"'>
                <div class="appRow" v-if="tableHeaderData.viewOrdersTableHeaders != undefined && tableHeaderData.viewOrdersTableHeaders.length!=0" v-for="viewOrdersHeader in tableHeaderData.viewOrdersTableHeaders">
                  <div v-if="viewOrdersHeader.type == tableHeaderData.type || viewOrdersHeader.type =='ultimate'">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{ capitalize(viewOrdersHeader.name) }} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="viewOrdersHeader.name+'-lOrderslView'" :checked="viewOrdersHeader.visible == 'true' ? true : false">
                        <label> Visible </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="viewOrdersHeader.name+'-lOrderslEdit'" :checked="viewOrdersHeader.sortable == 'true' ? true : false">
                        <label> Sortable </label>
                        </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('paymentCollection')">
                <span> Payment Collection </span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "paymentCollection")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "paymentCollection")? "":"display:none"'>
                <div class="appRow" v-if="tableHeaderData.viewLogsUserPaymentTableHeader != undefined && tableHeaderData.viewLogsUserPaymentTableHeader.length!=0" v-for="paymentCollectionHeader in tableHeaderData.viewLogsUserPaymentTableHeader">
                  <div v-if="paymentCollectionHeader.type == tableHeaderData.type || paymentCollectionHeader.type =='ultimate'">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{ capitalize(paymentCollectionHeader.name) }} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="paymentCollectionHeader.name+'-llogsuserpaymentlView'" :checked="paymentCollectionHeader.visible == 'true' ? true : false">
                        <label> Visible </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="paymentCollectionHeader.name+'-llogsuserpaymentlEdit'" :checked="paymentCollectionHeader.sortable == 'true' ? true : false">
                        <label> Sortable </label>
                        </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('billingConfigure')">
                <span> Billing Configuration </span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "billingConfigure")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "billingConfigure")? "":"display:none"'>
                <div class="appRow" v-if="tableHeaderData.viewBillingConfigurationTableHeader != undefined && tableHeaderData.viewBillingConfigurationTableHeader.length!=0" v-for="billingConfigHeader in tableHeaderData.viewBillingConfigurationTableHeader">
                  <div v-if="billingConfigHeader.type == tableHeaderData.type || billingConfigHeader.type =='ultimate'">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{ capitalize(billingConfigHeader.name) }} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="billingConfigHeader.name+'-lbillingconfigurationlView'" :checked="billingConfigHeader.visible == 'true' ? true : false">
                        <label> Visible </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="billingConfigHeader.name+'-lbillingconfigurationlEdit'" :checked="billingConfigHeader.sortable == 'true' ? true : false">
                        <label> Sortable </label>
                        </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('logEvent')">
                <span> Logs for Events </span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "logEvent")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "logEvent")? "":"display:none"'>
                <div class="appRow" v-if="tableHeaderData.viewLogsEventLogsTableHeaders != undefined && tableHeaderData.viewLogsEventLogsTableHeaders.length!=0" v-for="logsEventHeader in tableHeaderData.viewLogsEventLogsTableHeaders">
                  <div v-if="logsEventHeader.type == tableHeaderData.type || logsEventHeader.type =='ultimate'">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{ capitalize(logsEventHeader.name) }} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="logsEventHeader.name+'-lelView'" :checked="logsEventHeader.visible == 'true' ? true : false">
                        <label> Visible </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="logsEventHeader.name+'-lelEdit'" :checked="logsEventHeader.sortable == 'true' ? true : false">
                        <label> Sortable </label>
                        </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('logPayment')">
                <span> Logs for Payments </span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "logPayment")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "logPayment")? "":"display:none"'>
                <div class="appRow" v-if="tableHeaderData.viewLogsPaymentLogsTableHeaders != undefined && tableHeaderData.viewLogsPaymentLogsTableHeaders.length!=0" v-for="logsPaymentHeader in tableHeaderData.viewLogsPaymentLogsTableHeaders">
                  <div v-if="logsPaymentHeader.type == tableHeaderData.type || logsPaymentHeader.type =='ultimate'">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{ capitalize(logsPaymentHeader.name) }} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="logsPaymentHeader.name+'-lpaymentlView'" :checked="logsPaymentHeader.visible == 'true' ? true : false">
                        <label> Visible </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="logsPaymentHeader.name+'-lpaymentlEdit'" :checked="logsPaymentHeader.sortable == 'true' ? true : false">
                        <label> Sortable </label>
                        </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection" v-if="tableHeaderData.jazePlatform == 'hardware' && tableHeaderData.edition != 'isp'">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('logPms')">
                <span> Logs for PMS </span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "logPms")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "logPms")? "":"display:none"'>
                <div class="appRow" v-if="tableHeaderData.viewLogsPmsLogsTableHeaders != undefined && tableHeaderData.viewLogsPmsLogsTableHeaders.length!=0" v-for="logsPmsHeader in tableHeaderData.viewLogsPmsLogsTableHeaders">
                  <div v-if="logsPmsHeader.type == tableHeaderData.type || logsPmsHeader.type =='ultimate'">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{ capitalize(logsPmsHeader.name) }} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="logsPmsHeader.name+'-lpmlView'" :checked="logsPmsHeader.visible == 'true' ? true : false">
                        <label> Visible </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="logsPmsHeader.name+'-lpmlEdit'" :checked="logsPmsHeader.sortable == 'true' ? true : false">
                        <label> Sortable </label>
                        </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection" v-if="tableHeaderData.parentFranchiseHead == 'yes' || tableHeaderData.franchiseHead == 'yes'">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('franchisePayment')">
                <span> Franchise Payment Statement </span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "franchisePayment")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "franchisePayment")? "":"display:none"'>
                <div class="appRow" v-if="tableHeaderData.viewFranchisePaymentTableHeaders != undefined && tableHeaderData.viewFranchisePaymentTableHeaders.length!=0" v-for="franchisePaymentStatementHeader in tableHeaderData.viewFranchisePaymentTableHeaders">
                  <div v-if="(franchisePaymentStatementHeader.type == tableHeaderData.type || franchisePaymentStatementHeader.type =='ultimate') && !(franchisePaymentStatementHeader.name =='closingBalanace' || franchisePaymentStatementHeader.name =='created' || franchisePaymentStatementHeader.name =='deposit' || franchisePaymentStatementHeader.name =='myShare' || franchisePaymentStatementHeader.name =='withdrawal')">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{ capitalize(franchisePaymentStatementHeader.name) }} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="franchisePaymentStatementHeader.name+'-lfranchisepaymentlView'" :checked="franchisePaymentStatementHeader.visible == 'true' ? true : false">
                        <label> Visible </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="franchisePaymentStatementHeader.name+'-lfranchisepaymentlEdit'" :checked="franchisePaymentStatementHeader.sortable == 'true' ? true : false">
                        <label> Sortable </label>
                        </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection" v-if="tableHeaderData.parentFranchiseHead == 'yes' || tableHeaderData.franchiseHead == 'yes'">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('franchiseStatement')">
                <span> Franchise  Statement </span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "franchiseStatement")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "franchiseStatement")? "":"display:none"'>
                <div class="appRow" v-if="tableHeaderData.viewFranchiseStatementTableHeaders != undefined && tableHeaderData.viewFranchiseStatementTableHeaders.length!=0" v-for="franchiseStatementHeader in tableHeaderData.viewFranchiseStatementTableHeaders">
                  <div v-if="(franchiseStatementHeader.type == tableHeaderData.type || franchiseStatementHeader.type =='ultimate') && !(franchiseStatementHeader.name =='closingBalanace' || franchiseStatementHeader.name =='created' || franchiseStatementHeader.name =='deposit' || franchiseStatementHeader.name =='withdrawal')">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{ capitalize(franchiseStatementHeader.name) }} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="franchiseStatementHeader.name+'-lfranchisestatementlView'" :checked="franchiseStatementHeader.visible == 'true' ? true : false">
                        <label> Visible </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="franchiseStatementHeader.name+'-lfranchisestatementlEdit'" :checked="franchiseStatementHeader.sortable == 'true' ? true : false">
                        <label> Sortable </label>
                        </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('invoiceAsso')">
                <span> Invoice Associated with Payments </span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "invoiceAsso")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "invoiceAsso")? "":"display:none"'>
                <div class="appRow" v-if="tableHeaderData.viewInvoicesAssociatedTableHeaders != undefined && tableHeaderData.viewInvoicesAssociatedTableHeaders.length!=0" v-for="invoicesAssociatedHeader in tableHeaderData.viewInvoicesAssociatedTableHeaders">
                  <div v-if="(invoicesAssociatedHeader.type == tableHeaderData.type || invoicesAssociatedHeader.type =='ultimate') && !(invoicesAssociatedHeader.name =='balance' || invoicesAssociatedHeader.name =='total')">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{ capitalize(invoicesAssociatedHeader.name) }} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="invoicesAssociatedHeader.name+'-linvoicesAssociatedlView'" :checked="invoicesAssociatedHeader.visible == 'true' ? true : false">
                        <label> Visible </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="invoicesAssociatedHeader.name+'-linvoicesAssociatedlEdit'" :checked="invoicesAssociatedHeader.sortable == 'true' ? true : false">
                        <label> Sortable </label>
                        </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('setDateTime')">
                <span> Set Datetime Format </span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "setDateTime")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "setDateTime")? "":"display:none"'>
                <div class="appRow" v-if="tableHeaderData.setDateTimeFormatTableHeaders != undefined && tableHeaderData.setDateTimeFormatTableHeaders.length!=0" v-for="datetimeHeader in tableHeaderData.setDateTimeFormatTableHeaders">
                  <div v-if="datetimeHeader.type == tableHeaderData.type || datetimeHeader.type =='ultimate'">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{datetimeHeader.format}} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--cozy">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock">
                          <input type="radio" name="setDateTime" :checked="datetimeHeader.visible == 'true' ? true : false" @click="setDateTimeFn($event,datetimeHeader.name)">
                          <label for="setDateTime"> DateTime </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock">
                          <input type="radio" name="setDate" :checked="datetimeHeader.sortable == 'true' ? true : false" @click="setDateFn($event,datetimeHeader.name)">
                          <label for="setDate"> Date </label>
                        </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('allTickets')">
                <span> All Tickets </span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "allTickets")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "allTickets")? "":"display:none"'>
                <div class="appRow" v-if="tableHeaderData.viewAllTicketsTableHeaders != undefined && tableHeaderData.viewAllTicketsTableHeaders.length!=0" v-for="allTicketsHeader in tableHeaderData.viewAllTicketsTableHeaders">
                  <div v-if="allTicketsHeader.type == tableHeaderData.type || allTicketsHeader.type =='ultimate'">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{ capitalize(allTicketsHeader.name) }} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="allTicketsHeader.name+'-lTicketslView'" :checked="allTicketsHeader.visible == 'true' ? true : false">
                        <label> Visible </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="allTicketsHeader.name+'-lTicketslEdit'" :checked="allTicketsHeader.sortable == 'true' ? true : false">
                        <label> Sortable </label>
                        </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('completedTickets')">
                <span> Completed Tickets </span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "completedTickets")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "completedTickets")? "":"display:none"'>
                <div class="appRow" v-if="tableHeaderData.viewAllCompletedTicketsTableHeaders != undefined && tableHeaderData.viewAllCompletedTicketsTableHeaders.length!=0" v-for="completedTicketsHeader in tableHeaderData.viewAllCompletedTicketsTableHeaders">
                  <div v-if="completedTicketsHeader.type == tableHeaderData.type || completedTicketsHeader.type =='ultimate'">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{ capitalize(completedTicketsHeader.name) }} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="completedTicketsHeader.name+'-lcompletedlView'" :checked="completedTicketsHeader.visible == 'true' ? true : false">
                        <label> Visible </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="completedTicketsHeader.name+'-lcompletedlEdit'" :checked="completedTicketsHeader.sortable == 'true' ? true : false">
                        <label> Sortable </label>
                        </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection" v-if="tableHeaderData.edition == 'isp'">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('myZoneDetail')">
                <span> My Zone Detail </span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "myZoneDetail")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "myZoneDetail")? "":"display:none"'>
                <div class="appRow" v-if="tableHeaderData.viewMyZoneTableHeaders != undefined && tableHeaderData.viewMyZoneTableHeaders.length!=0" v-for="zoneDetailHeader in tableHeaderData.viewMyZoneTableHeaders">
                  <div v-if="zoneDetailHeader.type == tableHeaderData.type || zoneDetailHeader.type =='ultimate'">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{ capitalize(zoneDetailHeader.name) }} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="zoneDetailHeader.name+'-lmyZonelView'" :checked="zoneDetailHeader.visible == 'true' ? true : false">
                        <label> Visible </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="zoneDetailHeader.name+'-lmyZonelEdit'" :checked="zoneDetailHeader.sortable == 'true' ? true : false">
                        <label> Sortable </label>
                        </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('userDocument')">
                <span> User Document Management </span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "userDocument")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "userDocument")? "":"display:none"'>
                <div class="appRow" v-if="tableHeaderData.viewUserDocumentsTableHeaders != undefined && tableHeaderData.viewUserDocumentsTableHeaders.length!=0" v-for="userDocHeader in tableHeaderData.viewUserDocumentsTableHeaders">
                  <div v-if="(userDocHeader.type == tableHeaderData.type || userDocHeader.type =='ultimate') && !(userDocHeader.name =='balance' || userDocHeader.name =='total')">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{ capitalize(userDocHeader.name) }} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="userDocHeader.name+'-documentView'" :checked="userDocHeader.visible == 'true' ? true : false">
                        <label> Visible </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="userDocHeader.name+'-documentEdit'" :checked="userDocHeader.sortable == 'true' ? true : false">
                        <label> Sortable </label>
                        </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection" v-if="tableHeaderData.edition == 'isp'">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('leadManagement')">
                <span> Lead Management </span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "leadManagement")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "leadManagement")? "":"display:none"'>
                <div class="appRow" v-if="tableHeaderData.viewLeadManagementTableHeaders != undefined && tableHeaderData.viewLeadManagementTableHeaders.length!=0" v-for="leadMgtHeader in tableHeaderData.viewLeadManagementTableHeaders">
                  <div v-if="leadMgtHeader.type == tableHeaderData.type || leadMgtHeader.type =='ultimate'">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{ capitalize(leadMgtHeader.name) }} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="leadMgtHeader.name+'-leadManagementView'" :checked="leadMgtHeader.visible == 'true' ? true : false">
                        <label> Visible </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="leadMgtHeader.name+'-leadManagementEdit'" :checked="leadMgtHeader.sortable == 'true' ? true : false">
                        <label> Sortable </label>
                        </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection" v-if="tableHeaderData.edition == 'isp'">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('completedLeads')">
                <span> Completed Leads </span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "completedLeads")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "completedLeads")? "":"display:none"'>
                <div class="appRow" v-if="tableHeaderData.viewCompletedLeadsTableHeaders != undefined && tableHeaderData.viewCompletedLeadsTableHeaders.length!=0" v-for="completedLeadsHeader in tableHeaderData.viewCompletedLeadsTableHeaders">
                  <div v-if="completedLeadsHeader.type == tableHeaderData.type || completedLeadsHeader.type =='ultimate'">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{ capitalize(completedLeadsHeader.name) }} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="completedLeadsHeader.name+'-completedLeadsView'" :checked="completedLeadsHeader.visible == 'true' ? true : false">
                        <label> Visible </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="completedLeadsHeader.name+'-completedLeadsEdit'" :checked="completedLeadsHeader.sortable == 'true' ? true : false">
                        <label> Sortable </label>
                        </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection" v-if="tableHeaderData.edition == 'isp'">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('droppedLeads')">
                <span> Dropped Leads </span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "droppedLeads")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "droppedLeads")? "":"display:none"'>
                <div class="appRow" v-if="tableHeaderData.viewDroppedLeadsTableHeaders != undefined && tableHeaderData.viewDroppedLeadsTableHeaders.length!=0" v-for="droppedLeadsHeader in tableHeaderData.viewDroppedLeadsTableHeaders">
                  <div v-if="droppedLeadsHeader.type == tableHeaderData.type || droppedLeadsHeader.type =='ultimate'">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{ capitalize(droppedLeadsHeader.name) }} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="droppedLeadsHeader.name+'-droppedLeadsView'" :checked="droppedLeadsHeader.visible == 'true' ? true : false">
                        <label> Visible </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="droppedLeadsHeader.name+'-droppedLeadsEdit'" :checked="droppedLeadsHeader.sortable == 'true' ? true : false">
                        <label> Sortable </label>
                        </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection" v-if="tableHeaderData.edition == 'isp'">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('logWebhook')">
                <span> Logs for Webhook </span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "logWebhook")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "logWebhook")? "":"display:none"'>
                <div class="appRow" v-if="tableHeaderData.viewLogsWebhookLogsTableHeaders != undefined && tableHeaderData.viewLogsWebhookLogsTableHeaders.length!=0" v-for="webhookLogsHeader in tableHeaderData.viewLogsWebhookLogsTableHeaders">
                  <div v-if="webhookLogsHeader.type == tableHeaderData.type || webhookLogsHeader.type =='ultimate'">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{ capitalize(webhookLogsHeader.name) }} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="webhookLogsHeader.name+'-lwebhooklView'" :checked="webhookLogsHeader.visible == 'true' ? true : false">
                        <label> Visible </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="webhookLogsHeader.name+'-lwebhooklEdit'" :checked="webhookLogsHeader.sortable == 'true' ? true : false">
                        <label> Sortable </label>
                        </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection" v-if="tableHeaderData.edition == 'isp'">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('userRenewal')">
                <span> User Renewal Logs </span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "userRenewal")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "userRenewal")? "":"display:none"'>
                <div class="appRow" v-if="tableHeaderData.viewUserRenewalLogsTableHeaders != undefined && tableHeaderData.viewUserRenewalLogsTableHeaders.length!=0" v-for="userRenewLogsHeader in tableHeaderData.viewUserRenewalLogsTableHeaders">
                  <div v-if="userRenewLogsHeader.type == tableHeaderData.type || userRenewLogsHeader.type =='ultimate'">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{ capitalize(userRenewLogsHeader.name) }} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="userRenewLogsHeader.name+'-lrenewallView'" :checked="userRenewLogsHeader.visible == 'true' ? true : false">
                        <label> Visible </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="userRenewLogsHeader.name+'-lrenewallEdit'" :checked="userRenewLogsHeader.sortable == 'true' ? true : false">
                        <label> Sortable </label>
                        </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection" v-if="tableHeaderData.edition == 'isp'">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('gracePeriod')">
                <span> Grace Period Users </span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "gracePeriod")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "gracePeriod")? "":"display:none"'>
                <div class="appRow" v-if="tableHeaderData.viewgracePeriodTableHeaders != undefined && tableHeaderData.viewgracePeriodTableHeaders.length!=0" v-for="graceUsersHeader in tableHeaderData.viewgracePeriodTableHeaders">
                  <div v-if="graceUsersHeader.type == tableHeaderData.type || graceUsersHeader.type =='ultimate'">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{ capitalize(graceUsersHeader.name) }} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="graceUsersHeader.name+'-lgracelView'" :checked="graceUsersHeader.visible == 'true' ? true : false">
                        <label> Visible </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="graceUsersHeader.name+'-lgracelEdit'" :checked="graceUsersHeader.sortable == 'true' ? true : false">
                        <label> Sortable </label>
                        </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('stockOverview')">
                <span> Stock Overview </span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "stockOverview")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "stockOverview")? "":"display:none"'>
                <div class="appRow" v-if="tableHeaderData.viewStockOverviewTableHeaders != undefined && tableHeaderData.viewStockOverviewTableHeaders.length!=0" v-for="stockOverviewHeader in tableHeaderData.viewStockOverviewTableHeaders">
                  <div v-if="stockOverviewHeader.type == tableHeaderData.type || stockOverviewHeader.type =='ultimate'">
                    <div class="col--sm5 col--md4 col--lg3">
                        <label class="appLabel appLabel--primary"> {{ capitalize(stockOverviewHeader.name) }} </label>
                    </div>
                    <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="stockOverviewHeader.name+'-stockOverviewView'" :checked="stockOverviewHeader.visible == 'true' ? true : false">
                        <label> Visible </label>
                        </div>
                        <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                        <input type="checkbox" :name="stockOverviewHeader.name+'-stockOverviewEdit'" :checked="stockOverviewHeader.sortable == 'true' ? true : false">
                        <label> Sortable </label>
                        </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </fieldset>
        </form>
        <div class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark">
          <div class="appFormFooter__left" v-if="tableHeaderData.visible == 'visible'">
            <button class="appMainButton busyToggle" type="button" @click="setDefaultsModal=true"> Set Defaults </button>

            <button class="appMainButton busyToggle" type="button" @click="setDefaultsSubzonesModal=true" v-if="tableHeaderData.edition == 'isp'"> Set Defaults to All SubZones </button>
            <button class="appMainButton busyToggle" type="button" @click="setDefaultsSubzonesModal=true" v-else> Set Defaults to All SubAccounts </button>
          </div>
          <div class="appFormFooter__right" v-if="tableHeaderData.visible == 'visible'">
            <button class="appMainButton busyToggle" type="button" @click="subZoneModalShow=true" v-if="tableHeaderData.edition == 'isp'"> SubZones Restrictions</button>
            <button class="appMainButton busyToggle" type="button" @click="subZoneModalShow=true" v-else> SubAccounts Restrictions</button>

            <button class="appMainButton busyToggle" type="button" @click="pushZonefn()" v-if="tableHeaderData.edition == 'isp'"> Add to All SubZones</button>
            <button class="appMainButton busyToggle" type="button" @click="pushZonefn()" v-else> Add to All SubAccounts</button>

            <button class="appMainButton appMainButton--primary busyToggle" type="submit" @click="savetableHeaderConfig"> Save</button>
          </div>
        </div>
      </div>
    </div>
    <br><br>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="subZoneModalShow">
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form class="jazeForm jazeModal jazeModal--small unifiAddUser">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title"> Save </div>
                  <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="subZoneModalShow=false">
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeModal__main">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                    <div class="row">
                      <div class="col jazeFormGroup jazeFormGroup--flex">
                        <label class="jazeLabel jazeLabel--primary" v-if="tableHeaderData.edition =='isp'">Allow/Deny sub-zones to change Table Header Configuration</label>
                        <label class="jazeLabel jazeLabel--primary" v-else>Allow/Deny sub-accounts to change Table Header Configuration</label>&nbsp;
                        <div class="toggle appToggle appToggle--light" data-on="On" data-off="Off">
                          <input type="checkbox" :checked="tableHeaderData.subAccountsCanNotChangeTableHeaderConfiguration == 'Yes' ? true : false" v-model="switchcheckboxTableHeader" id="switchcheckboxTableHeader" >
                          <label for="switchcheckboxTableHeader"></label>
                        </div>
                      </div>
                    </div>
                    <div class="appType--micro appType--quiet italicized" v-if="tableHeaderData.edition =='isp'">
                      Note: If it is 'ON', sub-zones can not change Table Header Configuration.
                    </div>
                    <div class="appType--micro appType--quiet italicized" v-else>
                      Note: If it is 'ON', sub-accounts can not change Table Header Configuration.
                    </div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--secondary" type="button" @click="subZoneModalShow=false">Cancel</button>
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="allowSubZones">Yes</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="setDefaultsModal">
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form class="jazeForm jazeModal jazeModal--small unifiAddUser">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title"> Set Defaults </div>
                  <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="setDefaultsModal=false">
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeModal__main">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                      <div class="col jazeFormGroup jazeFormGroup--flex">
                        <label class="jazeLabel jazeLabel--primary"> Do you want to reset table headers to default ? </label>
                      </div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--secondary" type="button" @click="setDefaultsModal=false">Cancel</button>
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="setDefaultsSave">Yes</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="setDefaultsSubzonesModal">
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form class="jazeForm jazeModal jazeModal--small unifiAddUser">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title"> Set Defaults </div>
                  <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="setDefaultsSubzonesModal=false">
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeModal__main">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                      <div class="col jazeFormGroup jazeFormGroup--flex">
                        <label class="jazeLabel jazeLabel--primary" v-if="tableHeaderData.edition =='isp'"> Are You sure want to use this default settings to all sub zones ? </label>
                        <label class="jazeLabel jazeLabel--primary" v-else> Are You sure want to use this default settings to all sub accounts ? </label>
                      </div><br><br>
                      <div class="appType--micro appType--quiet italicized" v-if="tableHeaderData.edition =='isp'">
                        Note: Once you use these settings in all sub-zones, you cannot undo it. If you want to undo it then it has to be done manually .
                      </div>
                      <div class="appType--micro appType--quiet italicized" v-else>
                        Note: Once you use these settings in all sub-accounts, you cannot undo it. If you want to undo it then it has to be done manually .
                      </div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--secondary" type="button" @click="setDefaultsSubzonesModal=false">Cancel</button>
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="setDefaultsAllZonesSave">Yes</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
    <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
    <PushToSubZone v-if="pushModal" :selected='selected' :edition="tableHeaderData.edition" @extend-clicked-push-detail="onclickclose" :subAccounts="subaccDetailsGetter" :from="'tableHeaderConfig'" :modalType="'selectedType'" :dataSetting="dataSetting"></PushToSubZone>

  </div>
</template>

<script>
import { ref,computed,watch,watchEffect,onMounted,onBeforeUnmount } from 'vue';
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import PushToSubZone from "@/components/Profiles/PushToSubZoneModal.vue";
import Treeselect from "jaze-vue3-treeselect";

import { storeToRefs } from "pinia";
import { usesettingsStore } from '@/store/settingsstore'
import { uselayoutStore } from '@/store/layoutstore'
export default {
  setup() {
    const layoutStore = uselayoutStore()
    const { subaccDetails } = storeToRefs(layoutStore)
    const subaccDetailsGetter= computed(() => subaccDetails.value);
    const tableHeaderData = ref( [] )
    const viewToggleMenuData  = ref( "" )
    const subZoneModalShow = ref( false )
    const setDefaultsModal = ref( false )
    const setDefaultsSubzonesModal = ref( false )
    const selectedAccVal = ref( null )
    const subAccOptions = ref( [
            {
              id: "selectAll",
              label: "Select All",
              children: []
            }
          ] )
    const successtoaster = ref( false )
    const successMessage = ref( "" )
    const errortoaster = ref( false )
    const errorMessage = ref( "" )
    const switchcheckboxTableHeader = ref( false )
    const selectedDatetime = ref( '' )
    const selectedDate = ref( '' )
    const defaultColumn  = ref( 'username' )
    const defaultSorting  = ref( 'asc' )
    const optionaAllSelect = ref( "all" )
    const dataSetting = ref( [{
              id: "selectAll",
              label: "Select All",
              children: []
            }] )
    const pushModal = ref( false )
    const selected = ref( [] )
    // const subAccounts = ref( [] )

    const settingsStore = usesettingsStore()
    const { tableHeaderConfigData } = storeToRefs(settingsStore)
    const tableHeaderConfigGetter = computed(() => tableHeaderConfigData.value);

    onMounted(() => {
      getUserFieldsData();
    })
    onBeforeUnmount(() => {
      settingsStore.clearSettingsState();
    })

    watchEffect(() => {
      tableHeaderData.value = tableHeaderConfigGetter.value;
      if(tableHeaderData.value.accountsArr!=undefined && tableHeaderData.value.accountsArr.length !=0) {
          for(let i=0; i<tableHeaderData.value.accountsArr.length; i++) {
            var deactivated = tableHeaderData.value.accountsArr[i].deactivated == 0 ? " (Deactivated)" : "";
              subAccOptions.value[0].children.push({id:tableHeaderData.value.accountsArr[i].id, label:tableHeaderData.value.accountsArr[i].name + deactivated})
          }
      }
      if (
        tableHeaderData.value.dataSetting != undefined &&
        tableHeaderData.value.dataSetting.length != 0
      ) {
        for (let i = 0; i < tableHeaderData.value.dataSetting.length; i++) {
          dataSetting.value[0].children.push({
            id: tableHeaderData.value.dataSetting[i].id,
            label: tableHeaderData.value.dataSetting[i].label,
          });
        }
      }
      if(tableHeaderData.value.subAccountsCanNotChangeTableHeaderConfiguration == 'Yes') {
        switchcheckboxTableHeader.value = true;
      }
      if(tableHeaderData.value.viewAllUserTableHeaders != undefined){
        for(let i=0; i<tableHeaderData.value.viewAllUserTableHeaders.length; i++){
          if(tableHeaderData.value.viewAllUserTableHeaders[i].name=="defalut_view"){
            defaultSorting.value = tableHeaderData.value.viewAllUserTableHeaders[i].sortable;
            defaultColumn.value = tableHeaderData.value.viewAllUserTableHeaders[i].visible=="staticIPAndMAC"?"username":tableHeaderData.value.viewAllUserTableHeaders[i].visible;
            
          }
        }
      
      }
      
    })
    const getUserFieldsData = () => {
      if (Object.keys(tableHeaderConfigGetter.value).length == 0) {
        // $store.dispatch("fetchTableHeaderConfig");
        settingsStore.fetchTableHeaderConfig();
      } else {
        tableHeaderData.value = tableHeaderConfigGetter.value;
        if(tableHeaderData.value.viewAllUserTableHeaders != undefined){
          for(let i=0; i<tableHeaderData.value.viewAllUserTableHeaders.length; i++){
          if(tableHeaderData.value.viewAllUserTableHeaders[i].name=="defalut_view"){
            defaultSorting.value = tableHeaderData.value.viewAllUserTableHeaders[i].sortable;
            defaultColumn.value = tableHeaderData.value.viewAllUserTableHeaders[i].visible;
          }}
        }
      }
    }
    const allowSubZones = () => {
        successtoaster.value = false;
        errortoaster.value = false;
        if (switchcheckboxTableHeader.value == true) {
          var check = "on";
        } else {
          var check = "off";
        }
        var data = {
          switchCheckBoxValue: check,
          attribute: "subAccountsCanNotChangeTableHeaderConfiguration",
          type: 'tableHeader'
        };
        // $store.dispatch("saveSubAccountPermissionSettings", data).then(
        settingsStore.saveSubAccountPermissionSettings(data).then(  
          response => {
            subZoneModalShow.value = false;
            successtoaster.value = true;
            successMessage.value = response;
            setTimeout(
              function() {
                successtoaster.value = false;
              }.bind(this),
              2000
            );
          },
          error => {
            errortoaster.value = true;
            errorMessage.value = error;
            setTimeout(
              function() {
                errortoaster.value = false;
              }.bind(this),
              15000
            );
            console.log(error);
          }
        );
    }
    const setDefaultsSave = () => {
        // $store.dispatch("tableConfigSetDefaults").then(
        settingsStore.tableConfigSetDefaults().then(  
          response => {
            setDefaultsModal.value = false;
            successtoaster.value = true;
            successMessage.value = response;
            setTimeout(
              function() {
                successtoaster.value = false;
              }.bind(this),
              2000
            );
          },
          error => {
            errortoaster.value = true;
            errorMessage.value = error;
            setTimeout(
              function() {
                errortoaster.value = false;
              }.bind(this),
              15000
            );
            console.log(error);
          }
        );
    }
    const setDefaultsAllZonesSave = () => {
        // $store.dispatch("tableConfigSetDefaultsToSubZones").then(
        settingsStore.tableConfigSetDefaultsToSubZones().then(  
          response => {
            setDefaultsSubzonesModal.value = false;
            successtoaster.value = true;
            successMessage.value = response;
            setTimeout(
              function() {
                successtoaster.value = false;
              }.bind(this),
              2000
            );
          },
          error => {
            errortoaster.value = true;
            errorMessage.value = error;
            setTimeout(
              function() {
                errortoaster.value = false;
              }.bind(this),
              15000
            );
            console.log(error);
          }
        );
    }
    const setDateTimeFn = (event, name) => {
      if(event.target.checked == true) {
        selectedDatetime.value = name;      
      }
    }
    const setDateFn = (event, name) => {
      if(event.target.checked == true) {
        selectedDate.value = name;      
      }
    } 
    const savetableHeaderConfig = (e) => {
        successtoaster.value = false;
        errortoaster.value = false;
        e.preventDefault();
        var form = document.getElementById('tableHeaderForm');
        const formData = new FormData(form);
        const postData = {};
        for (let [key, val] of formData.entries()) {
          Object.assign(postData, { [key]: val });
        }
        var submitData = {};
        //view all users
        submitData['auViewIds'] = [];
        submitData['auNonViewIds'] = [];
        submitData['auEditIds'] = [];
        submitData['auNonEditIds'] = [];
        for(var i=0; i<tableHeaderData.value.viewAllUserTableHeaders.length; i++) {
          if(tableHeaderData.value.viewAllUserTableHeaders[i].type == tableHeaderData.value.type || tableHeaderData.value.viewAllUserTableHeaders[i].type == 'ultimate') {
            var name = tableHeaderData.value.viewAllUserTableHeaders[i].name;
            var editname = name+ '-auView';
            var sortname = name+ '-auEdit';
            if(postData[editname] != undefined && postData[editname] == 'on') {
              submitData.auViewIds.push(name);
            } else {
              submitData.auNonViewIds.push(name);
            }
            if(postData[sortname] != undefined && postData[sortname] == 'on') {
              submitData.auEditIds.push(name);
            } else {
              submitData.auNonEditIds.push(name);
            }
          }
        }

        //view active sessions
        submitData['asViewIds'] = [];
        submitData['asNonViewIds'] = [];
        submitData['asEditIds'] = [];
        submitData['asNonEditIds'] = [];
        for(var i=0; i<tableHeaderData.value.viewActiveSessionsTableHeaders.length; i++) {
          if(tableHeaderData.value.viewActiveSessionsTableHeaders[i].type == tableHeaderData.value.type || tableHeaderData.value.viewActiveSessionsTableHeaders[i].type == 'ultimate') {
            var name = tableHeaderData.value.viewActiveSessionsTableHeaders[i].name;
            var editname = name+ '-asView';
            var sortname = name+ '-asEdit';
            if(postData[editname] != undefined && postData[editname] == 'on') {
              submitData.asViewIds.push(name);
            } else {
              submitData.asNonViewIds.push(name);
            }
            if(postData[sortname] != undefined && postData[sortname] == 'on') {
              submitData.asEditIds.push(name);
            } else {
              submitData.asNonEditIds.push(name);
            }
          }
        }

        //view completed sessions
        submitData['csViewIds'] = [];
        submitData['csNonViewIds'] = [];
        submitData['csEditIds'] = [];
        submitData['csNonEditIds'] = [];
        for(var i=0; i<tableHeaderData.value.viewCompletedSessionsTableHeaders.length; i++) {
          if(tableHeaderData.value.viewCompletedSessionsTableHeaders[i].type == tableHeaderData.value.type || tableHeaderData.value.viewCompletedSessionsTableHeaders[i].type == 'ultimate') {
            var name = tableHeaderData.value.viewCompletedSessionsTableHeaders[i].name;
            var editname = name+ '-csView';
            var sortname = name+ '-csEdit';
            if(postData[editname] != undefined && postData[editname] == 'on') {
              submitData.csViewIds.push(name);
            } else {
              submitData.csNonViewIds.push(name);
            }
            if(postData[sortname] != undefined && postData[sortname] == 'on') {
              submitData.csEditIds.push(name);
            } else {
              submitData.csNonEditIds.push(name);
            }
          }
        }

        //view group users
        submitData['guViewIds'] = [];
        submitData['guNonViewIds'] = [];
        submitData['guEditIds'] = [];
        submitData['guNonEditIds'] = [];
        for(var i=0; i<tableHeaderData.value.viewGroupUsersTableHeaders.length; i++) {
          if(tableHeaderData.value.viewGroupUsersTableHeaders[i].type == tableHeaderData.value.type || tableHeaderData.value.viewGroupUsersTableHeaders[i].type == 'ultimate') {
            var name = tableHeaderData.value.viewGroupUsersTableHeaders[i].name;
            var editname = name+ '-guView';
            var sortname = name+ '-guEdit';
            if(postData[editname] != undefined && postData[editname] == 'on') {
              submitData.guViewIds.push(name);
            } else {
              submitData.guNonViewIds.push(name);
            }
            if(postData[sortname] != undefined && postData[sortname] == 'on') {
              submitData.guEditIds.push(name);
            } else {
              submitData.guNonEditIds.push(name);
            }
          }
        }

        //view user logs
        submitData['ulViewIds'] = [];
        submitData['ulNonViewIds'] = [];
        submitData['ulEditIds'] = [];
        submitData['ulNonEditIds'] = [];
        for(var i=0; i<tableHeaderData.value.viewUserLogsTableHeaders.length; i++) {
          if(tableHeaderData.value.viewUserLogsTableHeaders[i].type == tableHeaderData.value.type || tableHeaderData.value.viewUserLogsTableHeaders[i].type == 'ultimate') {
            var name = tableHeaderData.value.viewUserLogsTableHeaders[i].name;
            var editname = name+ '-ulView';
            var sortname = name+ '-ulEdit';
            if(postData[editname] != undefined && postData[editname] == 'on') {
              submitData.ulViewIds.push(name);
            } else {
              submitData.ulNonViewIds.push(name);
            }
            if(postData[sortname] != undefined && postData[sortname] == 'on') {
              submitData.ulEditIds.push(name);
            } else {
              submitData.ulNonEditIds.push(name);
            }
          }
        }

        //view user sessions
        submitData['usViewIds'] = [];
        submitData['usNonViewIds'] = [];
        submitData['usEditIds'] = [];
        submitData['usNonEditIds'] = [];
        for(var i=0; i<tableHeaderData.value.viewUserSessionsTableHeaders.length; i++) {
          if(tableHeaderData.value.viewUserSessionsTableHeaders[i].type == tableHeaderData.value.type || tableHeaderData.value.viewUserSessionsTableHeaders[i].type == 'ultimate') {
            var name = tableHeaderData.value.viewUserSessionsTableHeaders[i].name;
            var editname = name+ '-usView';
            var sortname = name+ '-usEdit';
            if(postData[editname] != undefined && postData[editname] == 'on') {
              submitData.usViewIds.push(name);
            } else {
              submitData.usNonViewIds.push(name);
            }
            if(postData[sortname] != undefined && postData[sortname] == 'on') {
              submitData.usEditIds.push(name);
            } else {
              submitData.usNonEditIds.push(name);
            }
          }
        }

        //view logs user logs
        submitData['lulViewIds'] = [];
        submitData['lulNonViewIds'] = [];
        submitData['lulEditIds'] = [];
        submitData['lulNonEditIds'] = [];
        for(var i=0; i<tableHeaderData.value.viewLogsUserLogsTableHeaders.length; i++) {
          if(tableHeaderData.value.viewLogsUserLogsTableHeaders[i].type == tableHeaderData.value.type || tableHeaderData.value.viewLogsUserLogsTableHeaders[i].type == 'ultimate') {
            var name = tableHeaderData.value.viewLogsUserLogsTableHeaders[i].name;
            var editname = name+ '-lulView';
            var sortname = name+ '-lulEdit';
            if(postData[editname] != undefined && postData[editname] == 'on') {
              submitData.lulViewIds.push(name);
            } else {
              submitData.lulNonViewIds.push(name);
            }
            if(postData[sortname] != undefined && postData[sortname] == 'on') {
              submitData.lulEditIds.push(name);
            } else {
              submitData.lulNonEditIds.push(name);
            }
          }
        }

        //view logs sms logs
        submitData['lsmslViewIds'] = [];
        submitData['lsmslNonViewIds'] = [];
        submitData['lsmslEditIds'] = [];
        submitData['lsmslNonEditIds'] = [];
        for(var i=0; i<tableHeaderData.value.viewLogsSMSLogsTableHeaders.length; i++) {
          if(tableHeaderData.value.viewLogsSMSLogsTableHeaders[i].type == tableHeaderData.value.type || tableHeaderData.value.viewLogsSMSLogsTableHeaders[i].type == 'ultimate') {
            var name = tableHeaderData.value.viewLogsSMSLogsTableHeaders[i].name;
            var editname = name+ '-lsmslView';
            var sortname = name+ '-lsmslEdit';
            if(postData[editname] != undefined && postData[editname] == 'on') {
              submitData.lsmslViewIds.push(name);
            } else {
              submitData.lsmslNonViewIds.push(name);
            }
            if(postData[sortname] != undefined && postData[sortname] == 'on') {
              submitData.lsmslEditIds.push(name);
            } else {
              submitData.lsmslNonEditIds.push(name);
            }
          }
        }

        //view logs webhook logs
        submitData['lwebhooklViewIds'] = [];
        submitData['lwebhooklNonViewIds'] = [];
        submitData['lwebhooklEditIds'] = [];
        submitData['lwebhooklNonEditIds'] = [];
        for(var i=0; i<tableHeaderData.value.viewLogsWebhookLogsTableHeaders.length; i++) {
          if(tableHeaderData.value.viewLogsWebhookLogsTableHeaders[i].type == tableHeaderData.value.type || tableHeaderData.value.viewLogsWebhookLogsTableHeaders[i].type == 'ultimate') {
            var name = tableHeaderData.value.viewLogsWebhookLogsTableHeaders[i].name;
            var editname = name+ '-lwebhooklView';
            var sortname = name+ '-lwebhooklEdit';
            if(postData[editname] != undefined && postData[editname] == 'on') {
              submitData.lwebhooklViewIds.push(name);
            } else {
              submitData.lwebhooklNonViewIds.push(name);
            }
            if(postData[sortname] != undefined && postData[sortname] == 'on') {
              submitData.lwebhooklEditIds.push(name);
            } else {
              submitData.lwebhooklNonEditIds.push(name);
            }
          }
        }

        //view logs email logs
        submitData['lemaillViewIds'] = [];
        submitData['lemaillNonViewIds'] = [];
        submitData['lemaillEditIds'] = [];
        submitData['lemaillNonEditIds'] = [];
        for(var i=0; i<tableHeaderData.value.viewLogsEmailLogsTableHeaders.length; i++) {
          if(tableHeaderData.value.viewLogsEmailLogsTableHeaders[i].type == tableHeaderData.value.type || tableHeaderData.value.viewLogsEmailLogsTableHeaders[i].type == 'ultimate') {
            var name = tableHeaderData.value.viewLogsEmailLogsTableHeaders[i].name;
            var editname = name+ '-lemaillView';
            var sortname = name+ '-lemaillEdit';
            if(postData[editname] != undefined && postData[editname] == 'on') {
              submitData.lemaillViewIds.push(name);
            } else {
              submitData.lemaillNonViewIds.push(name);
            }
            if(postData[sortname] != undefined && postData[sortname] == 'on') {
              submitData.lemaillEditIds.push(name);
            } else {
              submitData.lemaillNonEditIds.push(name);
            }
          }
        }

        //view all pins
        submitData['lpinlViewIds'] = [];
        submitData['lpinlNonViewIds'] = [];
        submitData['lpinlEditIds'] = [];
        submitData['lpinlNonEditIds'] = [];
        for(var i=0; i<tableHeaderData.value.viewAllPinsTableHeaders.length; i++) {
          if(tableHeaderData.value.viewAllPinsTableHeaders[i].type == tableHeaderData.value.type || tableHeaderData.value.viewAllPinsTableHeaders[i].type == 'ultimate') {
            var name = tableHeaderData.value.viewAllPinsTableHeaders[i].name;
            var editname = name+ '-lpinlView';
            var sortname = name+ '-lpinlEdit';
            if(postData[editname] != undefined && postData[editname] == 'on') {
              submitData.lpinlViewIds.push(name);
            } else {
              submitData.lpinlNonViewIds.push(name);
            }
            if(postData[sortname] != undefined && postData[sortname] == 'on') {
              submitData.lpinlEditIds.push(name);
            } else {
              submitData.lpinlNonEditIds.push(name);
            }
          }
        }

        //view Pay As You Go Users
        submitData['lpaylViewIds'] = [];
        submitData['lpaylNonViewIds'] = [];
        submitData['lpaylEditIds'] = [];
        submitData['lpaylNonEditIds'] = [];
        for(var i=0; i<tableHeaderData.value.viewPayAsYouGoTableHeaders.length; i++) {
          if(tableHeaderData.value.viewPayAsYouGoTableHeaders[i].type == tableHeaderData.value.type || tableHeaderData.value.viewPayAsYouGoTableHeaders[i].type == 'ultimate') {
            var name = tableHeaderData.value.viewPayAsYouGoTableHeaders[i].name;
            var editname = name+ '-lpaylView';
            var sortname = name+ '-lpaylEdit';
            if(postData[editname] != undefined && postData[editname] == 'on') {
              submitData.lpaylViewIds.push(name);
            } else {
              submitData.lpaylNonViewIds.push(name);
            }
            if(postData[sortname] != undefined && postData[sortname] == 'on') {
              submitData.lpaylEditIds.push(name);
            } else {
              submitData.lpaylNonEditIds.push(name);
            }
          }
        }

        //view Invoice Users
        submitData['linvoicelViewIds'] = [];
        submitData['linvoicelNonViewIds'] = [];
        submitData['linvoicelEditIds'] = [];
        submitData['linvoicelNonEditIds'] = [];
        for(var i=0; i<tableHeaderData.value.viewInvoiceTableHeaders.length; i++) {
          if(tableHeaderData.value.viewInvoiceTableHeaders[i].type == tableHeaderData.value.type || tableHeaderData.value.viewInvoiceTableHeaders[i].type == 'ultimate') {
            var name = tableHeaderData.value.viewInvoiceTableHeaders[i].name;
            var editname = name+ '-linvoicelView';
            var sortname = name+ '-linvoicelEdit';
            if(postData[editname] != undefined && postData[editname] == 'on') {
              submitData.linvoicelViewIds.push(name);
            } else {
              submitData.linvoicelNonViewIds.push(name);
            }
            if(postData[sortname] != undefined && postData[sortname] == 'on') {
              submitData.linvoicelEditIds.push(name);
            } else {
              submitData.linvoicelNonEditIds.push(name);
            }
          }
        }

        //view Invoices Associated Users
        submitData['linvoicesAssociatedlViewIds'] = [];
        submitData['linvoicesAssociatedlNonViewIds'] = [];
        submitData['linvoicesAssociatedlEditIds'] = [];
        submitData['linvoicesAssociatedlNonEditIds'] = [];
        for(var i=0; i<tableHeaderData.value.viewInvoicesAssociatedTableHeaders.length; i++) {
          if(tableHeaderData.value.viewInvoicesAssociatedTableHeaders[i].type == tableHeaderData.value.type || tableHeaderData.value.viewInvoicesAssociatedTableHeaders[i].type == 'ultimate') {
            var name = tableHeaderData.value.viewInvoicesAssociatedTableHeaders[i].name;
            var editname = name+ '-linvoicesAssociatedlView';
            var sortname = name+ '-linvoicesAssociatedlEdit';
            if(postData[editname] != undefined && postData[editname] == 'on') {
              submitData.linvoicesAssociatedlViewIds.push(name);
            } else {
              submitData.linvoicesAssociatedlNonViewIds.push(name);
            }
            if(postData[sortname] != undefined && postData[sortname] == 'on') {
              submitData.linvoicesAssociatedlEditIds.push(name);
            } else {
              submitData.linvoicesAssociatedlNonEditIds.push(name);
            }
          }
        }

        //view date time format
        submitData['setDateTimeVar'] = [];
        submitData['setDateTimeNonVar'] = [];
        submitData['setDateVar'] = [];
        submitData['setDateNonVar'] = [];
        for(var i=0; i<tableHeaderData.value.setDateTimeFormatTableHeaders.length; i++) {
          if(tableHeaderData.value.setDateTimeFormatTableHeaders[i].type == tableHeaderData.value.type || tableHeaderData.value.setDateTimeFormatTableHeaders[i].type == 'ultimate') {
            var name = tableHeaderData.value.setDateTimeFormatTableHeaders[i].name;
            if(name == selectedDatetime.value) {
              submitData.setDateTimeVar.push(name);
            } else {
              submitData.setDateTimeNonVar.push(name);
            }
            if(name == selectedDate.value) {
              submitData.setDateVar.push(name);
            } else {
              submitData.setDateNonVar.push(name);
            }
          }
        }
        //view Payments Users
        submitData['lpaymentslViewIds'] = [];
        submitData['lpaymentslNonViewIds'] = [];
        submitData['lpaymentslEditIds'] = [];
        submitData['lpaymentslNonEditIds'] = [];
        for(var i=0; i<tableHeaderData.value.viewPaymentsTableHeaders.length; i++) {
          if(tableHeaderData.value.viewPaymentsTableHeaders[i].type == tableHeaderData.value.type || tableHeaderData.value.viewPaymentsTableHeaders[i].type == 'ultimate') {
            var name = tableHeaderData.value.viewPaymentsTableHeaders[i].name;
            var editname = name+ '-lpaymentslView';
            var sortname = name+ '-lpaymentslEdit';
            if(postData[editname] != undefined && postData[editname] == 'on') {
              submitData.lpaymentslViewIds.push(name);
            } else {
              submitData.lpaymentslNonViewIds.push(name);
            }
            if(postData[sortname] != undefined && postData[sortname] == 'on') {
              submitData.lpaymentslEditIds.push(name);
            } else {
              submitData.lpaymentslNonEditIds.push(name);
            }
          }
        }

        //view Orders
        submitData['lorderslViewIds'] = [];
        submitData['lorderslNonViewIds'] = [];
        submitData['lorderslEditIds'] = [];
        submitData['lorderslNonEditIds'] = [];
        for(var i=0; i<tableHeaderData.value.viewOrdersTableHeaders.length; i++) {
          if(tableHeaderData.value.viewOrdersTableHeaders[i].type == tableHeaderData.value.type || tableHeaderData.value.viewOrdersTableHeaders[i].type == 'ultimate') {
            var name = tableHeaderData.value.viewOrdersTableHeaders[i].name;
            var editname = name+ '-lOrderslView';
            var sortname = name+ '-lOrderslEdit';
            if(postData[editname] != undefined && postData[editname] == 'on') {
              submitData.lorderslViewIds.push(name);
            } else {
              submitData.lorderslNonViewIds.push(name);
            }
            if(postData[sortname] != undefined && postData[sortname] == 'on') {
              submitData.lorderslEditIds.push(name);
            } else {
              submitData.lorderslNonEditIds.push(name);
            }
          }
        }

        //view LogsUserPayment
        submitData['llogsuserpaymentlViewIds'] = [];
        submitData['llogsuserpaymentlNonViewIds'] = [];
        submitData['llogsuserpaymentlEditIds'] = [];
        submitData['llogsuserpaymentlNonEditIds'] = [];
        for(var i=0; i<tableHeaderData.value.viewLogsUserPaymentTableHeader.length; i++) {
          if(tableHeaderData.value.viewLogsUserPaymentTableHeader[i].type == tableHeaderData.value.type || tableHeaderData.value.viewLogsUserPaymentTableHeader[i].type == 'ultimate') {
            var name = tableHeaderData.value.viewLogsUserPaymentTableHeader[i].name;
            var editname = name+ '-llogsuserpaymentlView';
            var sortname = name+ '-llogsuserpaymentlEdit';
            if(postData[editname] != undefined && postData[editname] == 'on') {
              submitData.llogsuserpaymentlViewIds.push(name);
            } else {
              submitData.llogsuserpaymentlNonViewIds.push(name);
            }
            if(postData[sortname] != undefined && postData[sortname] == 'on') {
              submitData.llogsuserpaymentlEditIds.push(name);
            } else {
              submitData.llogsuserpaymentlNonEditIds.push(name);
            }
          }
        }

        //view billingconfiguration
        submitData['lbillingconfigurationlViewIds'] = [];
        submitData['lbillingconfigurationlNonViewIds'] = [];
        submitData['lbillingconfigurationlEditIds'] = [];
        submitData['lbillingconfigurationlNonEditIds'] = [];
        for(var i=0; i<tableHeaderData.value.viewBillingConfigurationTableHeader.length; i++) {
          if(tableHeaderData.value.viewBillingConfigurationTableHeader[i].type == tableHeaderData.value.type || tableHeaderData.value.viewBillingConfigurationTableHeader[i].type == 'ultimate') {
            var name = tableHeaderData.value.viewBillingConfigurationTableHeader[i].name;
            var editname = name+ '-lbillingconfigurationlView';
            var sortname = name+ '-lbillingconfigurationlEdit';
            if(postData[editname] != undefined && postData[editname] == 'on') {
              submitData.lbillingconfigurationlViewIds.push(name);
            } else {
              submitData.lbillingconfigurationlNonViewIds.push(name);
            }
            if(postData[sortname] != undefined && postData[sortname] == 'on') {
              submitData.lbillingconfigurationlEditIds.push(name);
            } else {
              submitData.lbillingconfigurationlNonEditIds.push(name);
            }
          }
        }

        //view Event logs
        submitData['lelViewIds'] = [];
        submitData['lelNonViewIds'] = [];
        submitData['lelEditIds'] = [];
        submitData['lelNonEditIds'] = [];
        for(var i=0; i<tableHeaderData.value.viewLogsEventLogsTableHeaders.length; i++) {
          if(tableHeaderData.value.viewLogsEventLogsTableHeaders[i].type == tableHeaderData.value.type || tableHeaderData.value.viewLogsEventLogsTableHeaders[i].type == 'ultimate') {
            var name = tableHeaderData.value.viewLogsEventLogsTableHeaders[i].name;
            var editname = name+ '-lelView';
            var sortname = name+ '-lelEdit';
            if(postData[editname] != undefined && postData[editname] == 'on') {
              submitData.lelViewIds.push(name);
            } else {
              submitData.lelNonViewIds.push(name);
            }
            if(postData[sortname] != undefined && postData[sortname] == 'on') {
              submitData.lelEditIds.push(name);
            } else {
              submitData.lelNonEditIds.push(name);
            }
          }
        }

        //view logs Payment logs
        submitData['lpaymentlViewIds'] = [];
        submitData['lpaymentlNonViewIds'] = [];
        submitData['lpaymentlEditIds'] = [];
        submitData['lpaymentlNonEditIds'] = [];
        for(var i=0; i<tableHeaderData.value.viewLogsPaymentLogsTableHeaders.length; i++) {
          if(tableHeaderData.value.viewLogsPaymentLogsTableHeaders[i].type == tableHeaderData.value.type || tableHeaderData.value.viewLogsPaymentLogsTableHeaders[i].type == 'ultimate') {
            var name = tableHeaderData.value.viewLogsPaymentLogsTableHeaders[i].name;
            var editname = name+ '-lpaymentlView';
            var sortname = name+ '-lpaymentlEdit';
            if(postData[editname] != undefined && postData[editname] == 'on') {
              submitData.lpaymentlViewIds.push(name);
            } else {
              submitData.lpaymentlNonViewIds.push(name);
            }
            if(postData[sortname] != undefined && postData[sortname] == 'on') {
              submitData.lpaymentlEditIds.push(name);
            } else {
              submitData.lpaymentlNonEditIds.push(name);
            }
          }
        }

        //view logs Pms logs
        submitData['lpmlViewIds'] = [];
        submitData['lpmlNonViewIds'] = [];
        submitData['lpmlEditIds'] = [];
        submitData['lpmlNonEditIds'] = [];
        for(var i=0; i<tableHeaderData.value.viewLogsPmsLogsTableHeaders.length; i++) {
          if(tableHeaderData.value.viewLogsPmsLogsTableHeaders[i].type == tableHeaderData.value.type || tableHeaderData.value.viewLogsPmsLogsTableHeaders[i].type == 'ultimate') {
            var name = tableHeaderData.value.viewLogsPmsLogsTableHeaders[i].name;
            var editname = name+ '-lpmlView';
            var sortname = name+ '-lpmlEdit';
            if(postData[editname] != undefined && postData[editname] == 'on') {
              submitData.lpmlViewIds.push(name);
            } else {
              submitData.lpmlNonViewIds.push(name);
            }
            if(postData[sortname] != undefined && postData[sortname] == 'on') {
              submitData.lpmlEditIds.push(name);
            } else {
              submitData.lpmlNonEditIds.push(name);
            }
          }
        }

        //view franchisepayment
        submitData['lfranchisepaymentlViewIds'] = [];
        submitData['lfranchisepaymentlNonViewIds'] = [];
        submitData['lfranchisepaymentlEditIds'] = [];
        submitData['lfranchisepaymentlNonEditIds'] = [];
        for(var i=0; i<tableHeaderData.value.viewFranchisePaymentTableHeaders.length; i++) {
          if(tableHeaderData.value.viewFranchisePaymentTableHeaders[i].type == tableHeaderData.value.type || tableHeaderData.value.viewFranchisePaymentTableHeaders[i].type == 'ultimate') {
            if(tableHeaderData.value.parentFranchiseHead == 'yes' || tableHeaderData.value.franchiseHead == 'yes') {
              var name = tableHeaderData.value.viewFranchisePaymentTableHeaders[i].name;
              var editname = name+ '-lfranchisepaymentlView';
              var sortname = name+ '-lfranchisepaymentlEdit';
              if(postData[editname] != undefined && postData[editname] == 'on') {
                submitData.lfranchisepaymentlViewIds.push(name);
              } else {
                submitData.lfranchisepaymentlNonViewIds.push(name);
              }
              if(postData[sortname] != undefined && postData[sortname] == 'on') {
                submitData.lfranchisepaymentlEditIds.push(name);
              } else {
                submitData.lfranchisepaymentlNonEditIds.push(name);
              }
          }
          }
        }

        //view franchisestatement
        submitData['lfranchisestatementlViewIds'] = [];
        submitData['lfranchisestatementlNonViewIds'] = [];
        submitData['lfranchisestatementlEditIds'] = [];
        submitData['lfranchisestatementlNonEditIds'] = [];
        for(var i=0; i<tableHeaderData.value.viewFranchiseStatementTableHeaders.length; i++) {
          if(tableHeaderData.value.parentFranchiseHead == 'yes' || tableHeaderData.value.franchiseHead == 'yes') {
            if(tableHeaderData.value.viewFranchiseStatementTableHeaders[i].type == tableHeaderData.value.type || tableHeaderData.value.viewFranchiseStatementTableHeaders[i].type == 'ultimate') {
              var name = tableHeaderData.value.viewFranchiseStatementTableHeaders[i].name;
              var editname = name+ '-lfranchisestatementlView';
              var sortname = name+ '-lfranchisestatementlView';
              if(postData[editname] != undefined && postData[editname] == 'on') {
                submitData.lfranchisestatementlViewIds.push(name);
              } else {
                submitData.lfranchisestatementlNonViewIds.push(name);
              }
              if(postData[sortname] != undefined && postData[sortname] == 'on') {
                submitData.lfranchisestatementlEditIds.push(name);
              } else {
                submitData.lfranchisestatementlNonEditIds.push(name);
              }
            }
          }
        }

        // All Tickets
        submitData['lTicketslViewIds'] = [];
        submitData['lTicketslNonViewIds'] = [];
        submitData['lTicketslEditIds'] = [];
        submitData['lTicketslNonEditIds'] = [];
        for(var i=0; i<tableHeaderData.value.viewAllTicketsTableHeaders.length; i++) {
          if(tableHeaderData.value.viewAllTicketsTableHeaders[i].type == tableHeaderData.value.type || tableHeaderData.value.viewAllTicketsTableHeaders[i].type == 'ultimate') {
            var name = tableHeaderData.value.viewAllTicketsTableHeaders[i].name;
            var editname = name+ '-lTicketslView';
            var sortname = name+ '-lTicketslEdit';
            if(postData[editname] != undefined && postData[editname] == 'on') {
              submitData.lTicketslViewIds.push(name);
            } else {
              submitData.lTicketslNonViewIds.push(name);
            }
            if(postData[sortname] != undefined && postData[sortname] == 'on') {
              submitData.lTicketslEditIds.push(name);
            } else {
              submitData.lTicketslNonEditIds.push(name);
            }
          }
        }

        // All Completed Tickets
        submitData['lcompletedlViewIds'] = [];
        submitData['lcompletedlNonViewIds'] = [];
        submitData['lcompletedlEditIds'] = [];
        submitData['lcompletedlNonEditIds'] = [];
        for(var i=0; i<tableHeaderData.value.viewAllCompletedTicketsTableHeaders.length; i++) {
          if(tableHeaderData.value.viewAllCompletedTicketsTableHeaders[i].type == tableHeaderData.value.type || tableHeaderData.value.viewAllCompletedTicketsTableHeaders[i].type == 'ultimate') {
            var name = tableHeaderData.value.viewAllCompletedTicketsTableHeaders[i].name;
            var editname = name+ '-lcompletedlView';
            var sortname = name+ '-lcompletedlEdit';
            if(postData[editname] != undefined && postData[editname] == 'on') {
              submitData.lcompletedlViewIds.push(name);
            } else {
              submitData.lcompletedlNonViewIds.push(name);
            }
            if(postData[sortname] != undefined && postData[sortname] == 'on') {
              submitData.lcompletedlEditIds.push(name);
            } else {
              submitData.lcompletedlNonEditIds.push(name);
            }
          }
        }

        // view My zone detail
        submitData['lmyZonelViewIds'] = [];
        submitData['lmyZonelNonViewIds'] = [];
        submitData['lmyZonelEditIds'] = [];
        submitData['lmyZonelNonEditIds'] = [];
        for(var i=0; i<tableHeaderData.value.viewMyZoneTableHeaders.length; i++) {
          if(tableHeaderData.value.viewMyZoneTableHeaders[i].type == tableHeaderData.value.type || tableHeaderData.value.viewMyZoneTableHeaders[i].type == 'ultimate') {
            var name = tableHeaderData.value.viewMyZoneTableHeaders[i].name;
            var editname = name+ '-lmyZonelView';
            var sortname = name+ '-lmyZonelEdit';
            if(postData[editname] != undefined && postData[editname] == 'on') {
              submitData.lmyZonelViewIds.push(name);
            } else {
              submitData.lmyZonelNonViewIds.push(name);
            }
            if(postData[sortname] != undefined && postData[sortname] == 'on') {
              submitData.lmyZonelEditIds.push(name);
            } else {
              submitData.lmyZonelNonEditIds.push(name);
            }
          }
        }

        // view Users document
        submitData['documentViewIds'] = [];
        submitData['documentNonViewIds'] = [];
        submitData['documentEditIds'] = [];
        submitData['documentNonEditIds'] = [];
        for(var i=0; i<tableHeaderData.value.viewUserDocumentsTableHeaders.length; i++) {
          if(tableHeaderData.value.viewUserDocumentsTableHeaders[i].type == tableHeaderData.value.type || tableHeaderData.value.viewUserDocumentsTableHeaders[i].type == 'ultimate') {
            var name = tableHeaderData.value.viewUserDocumentsTableHeaders[i].name;
            var editname = name+ '-documentView';
            var sortname = name+ '-documentEdit';
            if(postData[editname] != undefined && postData[editname] == 'on') {
              submitData.documentViewIds.push(name);
            } else {
              submitData.documentNonViewIds.push(name);
            }
            if(postData[sortname] != undefined && postData[sortname] == 'on') {
              submitData.documentEditIds.push(name);
            } else {
              submitData.documentNonEditIds.push(name);
            }
          }
        }

        // view Lead Management
        submitData['leadManagementViewIds'] = [];
        submitData['leadManagementNonViewIds'] = [];
        submitData['leadManagementEditIds'] = [];
        submitData['leadManagementNonEditIds'] = [];
        for(var i=0; i<tableHeaderData.value.viewLeadManagementTableHeaders.length; i++) {
          if(tableHeaderData.value.viewLeadManagementTableHeaders[i].type == tableHeaderData.value.type || tableHeaderData.value.viewLeadManagementTableHeaders[i].type == 'ultimate') {
            var name = tableHeaderData.value.viewLeadManagementTableHeaders[i].name;
            var editname = name+ '-leadManagementView';
            var sortname = name+ '-leadManagementEdit';
            if(postData[editname] != undefined && postData[editname] == 'on') {
              submitData.leadManagementViewIds.push(name);
            } else {
              submitData.leadManagementNonViewIds.push(name);
            }
            if(postData[sortname] != undefined && postData[sortname] == 'on') {
              submitData.leadManagementEditIds.push(name);
            } else {
              submitData.leadManagementNonEditIds.push(name);
            }
          }
        }

        // Completed Leads
        submitData['completedLeadsViewIds'] = [];
        submitData['completedLeadsNonViewIds'] = [];
        submitData['completedLeadsEditIds'] = [];
        submitData['completedLeadsNonEditIds'] = [];
        for(var i=0; i<tableHeaderData.value.viewCompletedLeadsTableHeaders.length; i++) {
          if(tableHeaderData.value.viewCompletedLeadsTableHeaders[i].type == tableHeaderData.value.type || tableHeaderData.value.viewCompletedLeadsTableHeaders[i].type == 'ultimate') {
            var name = tableHeaderData.value.viewCompletedLeadsTableHeaders[i].name;
            var editname = name+ '-completedLeadsView';
            var sortname = name+ '-completedLeadsEdit';
            if(postData[editname] != undefined && postData[editname] == 'on') {
              submitData.completedLeadsViewIds.push(name);
            } else {
              submitData.completedLeadsNonViewIds.push(name);
            }
            if(postData[sortname] != undefined && postData[sortname] == 'on') {
              submitData.completedLeadsEditIds.push(name);
            } else {
              submitData.completedLeadsNonEditIds.push(name);
            }
          }
        }

        // Dropped Leads
        submitData['droppedLeadsViewIds'] = [];
        submitData['droppedLeadsNonViewIds'] = [];
        submitData['droppedLeadsEditIds'] = [];
        submitData['droppedLeadsNonEditIds'] = [];
        for(var i=0; i<tableHeaderData.value.viewDroppedLeadsTableHeaders.length; i++) {
          if(tableHeaderData.value.viewDroppedLeadsTableHeaders[i].type == tableHeaderData.value.type || tableHeaderData.value.viewDroppedLeadsTableHeaders[i].type == 'ultimate') {
            var name = tableHeaderData.value.viewDroppedLeadsTableHeaders[i].name;
            var editname = name+ '-droppedLeadsView';
            var sortname = name+ '-droppedLeadsEdit';
            if(postData[editname] != undefined && postData[editname] == 'on') {
              submitData.droppedLeadsViewIds.push(name);
            } else {
              submitData.droppedLeadsNonViewIds.push(name);
            }
            if(postData[sortname] != undefined && postData[sortname] == 'on') {
              submitData.droppedLeadsEditIds.push(name);
            } else {
              submitData.droppedLeadsNonEditIds.push(name);
            }
          }
        }

        // User Renewal Logs
        submitData['lrenewallViewIds'] = [];
        submitData['lrenewallNonViewIds'] = [];
        submitData['lrenewallEditIds'] = [];
        submitData['lrenewallNonEditIds'] = [];
        for(var i=0; i<tableHeaderData.value.viewUserRenewalLogsTableHeaders.length; i++) {
          if(tableHeaderData.value.viewUserRenewalLogsTableHeaders[i].type == tableHeaderData.value.type || tableHeaderData.value.viewUserRenewalLogsTableHeaders[i].type == 'ultimate') {
            var name = tableHeaderData.value.viewUserRenewalLogsTableHeaders[i].name;
            var editname = name+ '-lrenewallView';
            var sortname = name+ '-lrenewallEdit';
            if(postData[editname] != undefined && postData[editname] == 'on') {
              submitData.lrenewallViewIds.push(name);
            } else {
              submitData.lrenewallNonViewIds.push(name);
            }
            if(postData[sortname] != undefined && postData[sortname] == 'on') {
              submitData.lrenewallEditIds.push(name);
            } else {
              submitData.lrenewallNonEditIds.push(name);
            }
          }
        }

        // view Grace Period Users
        submitData['lgracelViewIds'] = [];
        submitData['lgracelNonViewIds'] = [];
        submitData['lgracelEditIds'] = [];
        submitData['lgracelNonEditIds'] = [];
        for(var i=0; i<tableHeaderData.value.viewgracePeriodTableHeaders.length; i++) {
          if(tableHeaderData.value.viewgracePeriodTableHeaders[i].type == tableHeaderData.value.type || tableHeaderData.value.viewgracePeriodTableHeaders[i].type == 'ultimate') {
            var name = tableHeaderData.value.viewgracePeriodTableHeaders[i].name;
            var editname = name+ '-lgracelView';
            var sortname = name+ '-lgracelEdit';
            if(postData[editname] != undefined && postData[editname] == 'on') {
              submitData.lgracelViewIds.push(name);
            } else {
              submitData.lgracelNonViewIds.push(name);
            }
            if(postData[sortname] != undefined && postData[sortname] == 'on') {
              submitData.lgracelEditIds.push(name);
            } else {
              submitData.lgracelNonEditIds.push(name);
            }
          }
        }

        // Stock Overview
        submitData['stockOverviewViewIds'] = [];
        submitData['stockOverviewNonViewIds'] = [];
        submitData['stockOverviewEditIds'] = [];
        submitData['stockOverviewNonEditIds'] = [];
        for(var i=0; i<tableHeaderData.value.viewStockOverviewTableHeaders.length; i++) {
          if(tableHeaderData.value.viewStockOverviewTableHeaders[i].type == tableHeaderData.value.type || tableHeaderData.value.viewStockOverviewTableHeaders[i].type == 'ultimate') {
            var name = tableHeaderData.value.viewStockOverviewTableHeaders[i].name;
            var editname = name+ '-stockOverviewView';
            var sortname = name+ '-stockOverviewEdit';
            if(postData[editname] != undefined && postData[editname] == 'on') {
              submitData.stockOverviewViewIds.push(name);
            } else {
              submitData.stockOverviewNonViewIds.push(name);
            }
            if(postData[sortname] != undefined && postData[sortname] == 'on') {
              submitData.stockOverviewEditIds.push(name);
            } else {
              submitData.stockOverviewNonEditIds.push(name);
            }
          }
        }

        submitData['defaultUserTableColumn'] = defaultColumn.value;
        submitData['defaultUserTableSorting'] = defaultSorting.value;
        // $store.dispatch("tableHeaderConfigsubmit", submitData).then(
        settingsStore.tableHeaderConfigsubmit(submitData).then(  
          response => {
            successtoaster.value = true;
            successMessage.value = response;
            setTimeout(
              function() {
                successtoaster.value = false;
              }.bind(this),
              2000
            );
          },
          error => {
            errortoaster.value = true;
            errorMessage.value = error;
            setTimeout(
              function() {
                errortoaster.value = false;
              }.bind(this),
              15000
            );
            console.log(error);
          }
        );
    }
    const viewToggleMenuDatafn = (value) => {
      if (viewToggleMenuData.value == value) {
        viewToggleMenuData.value = "";
      } else {
        viewToggleMenuData.value = value;
      }
    }
    const onclickclose = (value) => {
      pushModal.value = value;
    }
    const pushZonefn = () => {
      pushModal.value = !pushModal.value;
    }
    const capitalize = (value) => {
        if (!value) return '';
        value = value.toString();
        return value.charAt(0).toUpperCase() + value.slice(1);
    }
    
    return {
      settingsStore,
      tableHeaderConfigGetter: tableHeaderConfigData,
      subaccDetailsGetter,
      tableHeaderData,
      viewToggleMenuData ,
      subZoneModalShow,
      setDefaultsModal,
      setDefaultsSubzonesModal,
      selectedAccVal,
      subAccOptions,
      successtoaster,
      successMessage,
      errortoaster,
      errorMessage,
      switchcheckboxTableHeader,
      selectedDatetime,
      selectedDate,
      defaultColumn ,
      defaultSorting ,
      optionaAllSelect,
      dataSetting,
      pushModal,
      selected,
      // subAccounts,
      getUserFieldsData,
      allowSubZones,
      setDefaultsSave,
      setDefaultsAllZonesSave,
      setDateTimeFn,
      setDateFn,
      savetableHeaderConfig,
      viewToggleMenuDatafn,
      onclickclose,
      pushZonefn,
      capitalize,
    }
  },
  // filters: {
  //   capitalize: function (value) {
  //       if (!value) return '';
  //       value = value.toString();
  //       return value.charAt(0).toUpperCase() + value.slice(1);
  //   }
  // },
  components: {
    SuccessToaster,
    ErrorToaster,
    PushToSubZone,
    Treeselect
  },
};
</script>

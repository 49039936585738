<template>
  <div class="jazeMainPanel" style="height: 100%;" v-if="isDatabaseAccessible">
    <div class="jazePanelContent jazePanelContent--scrollable">
      <div class="unifiSettingsContainer">
        <form ref="adminForm" class="appForm appForm--cozy is-validation-hidden">
          <div class="unifiSettingsHeader">
            <div class="unifiSettingsHeader__title">Admin User Group Control</div>
          </div>
          <fieldset class="appFieldset appFieldset--quiet">
            <div class="unifiSettingsSection" v-if="Object.keys(adminUserGroupsData).length != 0">
              <div v-if="adminUserGroupsData.adminSettings!= undefined && adminUserGroupsData.adminSettings.length!=0">
                <div v-for="(admin,index) in adminUserGroupsData.adminSettings" :key=admin.id>
                  <div class="appRow">
                    <div class="col--sm3">
                      <label class="appLabel appLabel--primary appLabel--boxAlign"> {{admin.admin_name}} </label>
                    </div>
                    <div class="col--sm5 appFormGroup appFormGroup--cozy">
                      <!-- <treeselect @open='opendiv(index)' :load-options="loadOptions" :options="adminGroupOptions" v-model="selectedGroupsArr['groups_'+admin.id]" :multiple="true" :searchable="true" :clearable="false" placeholder="Select the Group"></treeselect> -->
                      <treeselect @open='opendiv(index)' :load-options="loadOptions" :options="adminGroupOptions" v-model="selectedGroupsArr['groups_'+admin.id]" :multiple="true" :searchable="true" :clearable="false" placeholder="Select the Group" :disable-branch-nodes="true"></treeselect>
                    </div>
                  </div>
                </div>  
              </div>
              <div v-else>
                <div class="appRow col--sm10">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">There are currently no admins other than Super Admin!</label>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
        <div class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark">
          <div class="appFormFooter__right" v-if="adminUserGroupsData.adminSettings!= undefined && adminUserGroupsData.adminSettings.length!=0">
            <button class="appMainButton appMainButton--primary busyToggle" type="submit" @click="saveAdminSettings"> Save</button>
          </div>
        </div>
      </div>
    </div>

    <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
    <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
  </div>
  <div class="jazeMainPanel" style="height: 100%;" v-else>
    <div class="jazePanelContent jazePanelContent--scrollable">
      <div class="unifiSettingsContainer">
          <div class="unifiSettingsHeader">
            <div class="unifiSettingsHeader__left">
              <div class="unifiSettingsHeader__title">Admin User Group Control</div>
            </div>
          </div>
      </div>
    </div>
    <ErrorPage></ErrorPage>
  </div>
</template>

<script>
import { ref,toRefs,computed,watch,watchEffect,onMounted,onBeforeUnmount } from 'vue'
import Treeselect from "jaze-vue3-treeselect";
import { overrideIsSelected } from "@/overrides/vue3-treeselect";
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import ErrorPage from "@/components/Status/ErrorPage.vue";
import { storeToRefs } from "pinia";
import { uselayoutStore } from '@/store/layoutstore';
;
import { usesettingsStore } from '@/store/settingsstore'
export default {
  mixins: [overrideIsSelected()],
  setup() {
    const adminUserGroupsData = ref( [] )
    const successtoaster = ref( false )
    const successMessage = ref( "" )
    const errortoaster = ref( false )
    const errorMessage = ref( "" )
    const adminGroupOptions = ref( null )
    const adminGroupArr = ref( [] )
    const selectedGroupsArr = ref( [] )

    const layoutStore = uselayoutStore()
    const { headerDetails } = storeToRefs(layoutStore)
    const headerDetailsGetter = computed(() => headerDetails.value)
    const settingsStore = usesettingsStore()
    const { adminSettingsData } = storeToRefs(settingsStore)
    const adminSettingsGetter = computed(() => adminSettingsData.value)

    const isDatabaseAccessible = computed(() => {
      var isDatabaseAccessible = true;
      if (Object.keys(headerDetailsGetter.value).length > 0) {
        return headerDetailsGetter.value.isDatabaseAccessible;
      }else{
        layoutStore.fetchHeaderDetails();
        isDatabaseAccessible = headerDetailsGetter.value.isDatabaseAccessible;
      }
      return isDatabaseAccessible
    })

    onMounted(() => {
      adminUserGroups();
    })
    onBeforeUnmount(() => {
      settingsStore.clearSettingsState();
    })

    watchEffect(() => {
      adminUserGroupsData.value = adminSettingsGetter.value;
      if (
        adminUserGroupsData.value.adminSettings != undefined &&
        adminUserGroupsData.value.adminSettings.length != 0
      ) {
        var adminArr = adminUserGroupsData.value.adminSettings;
        var groupArr = adminUserGroupsData.value.groups;
        for (var i = 0; i < adminArr.length; i++) {
          var groups = [];
          var selectedData = [];
          for (var j = 0; j < groupArr.length; j++) {
            groups.push({
              id: groupArr[j].UserGroup.id,
              label: groupArr[j].UserGroup.name
            });
          }
          adminGroupArr.value[i] = groups;
          if (
            adminArr[i].groups != undefined &&
            adminArr[i].groups.length != 0
          ) {
            for (var k = 0; k < adminArr[i].groups.length; k++) {
              selectedData.push(adminArr[i].groups[k]);
            }
          }
          selectedGroupsArr.value["groups_" + adminArr[i].id] = selectedData;
          opendiv(i);
        }
      }
    })

    const adminUserGroups = () => {
      if(!isDatabaseAccessible.value){
        return;
      }
      if (Object.keys(adminSettingsGetter.value).length == 0) {
        // $store.dispatch("fetchAdminUserGroups");
        settingsStore.fetchAdminUserGroups();
      } else {
        adminUserGroupsData.value = adminSettingsGetter.value;
      }
    }
    const opendiv = (index) => {
      var options = adminGroupArr.value[index];
      adminGroupOptions.value = options;
    }
    const loadOptions = ({ action, searchQuery, callback, instanceId }) => {
      callback(null);
    }
    const saveAdminSettings = (e) => {
      e.preventDefault();
      for (const [key, value] of Object.entries(selectedGroupsArr.value)) {
        if (value.length == 0) {
          delete selectedGroupsArr.value[key];
        }
      }
      // $store.dispatch("adminSettingsSubmit", selectedGroupsArr).then(
      settingsStore.adminSettingsSubmit(selectedGroupsArr.value).then(  
        response => {
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function() {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        error => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function() {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
    }

    return {
      layoutStore,
      headerDetailsGetter: headerDetails,
      settingsStore,
      adminSettingsGetter: adminSettingsData,
      adminUserGroupsData,
      successtoaster,
      successMessage,
      errortoaster,
      errorMessage,
      adminGroupOptions,
      adminGroupArr,
      selectedGroupsArr,
      isDatabaseAccessible,
      adminUserGroups,
      opendiv,
      loadOptions,
      saveAdminSettings,
    }
  },
  components: {
    Treeselect,
    SuccessToaster,
    ErrorToaster,
    ErrorPage,
  },
};
</script>

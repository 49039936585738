<template>
  <div class="jazeMainPanel" style="height: 100%;">
    <div class="jazePanelContent jazePanelContent--scrollable">
      <div class="unifiSettingsContainer">
        <form ref="customerPortalURLForm" id="customerPortalURLForm" class="appForm appForm--cozy is-validation-hidden">
          <div class="unifiSettingsHeader">
            <div class="unifiSettingsHeader__title">Customer Portal URL</div>
          </div>
          <fieldset class="appFieldset appFieldset--quiet">
            <div class="unifiSettingsSection">
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">URL</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input
                    class="input--full"
                    type="text"
                    name="customerPortalURL"
                    v-model="customerPortalURL"
                  />
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label
                    class="appLabel appLabel--primary appLabel--boxAlign"
                  >Redirect Users to Customer Portal Directly</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input
                    type="checkbox"
                    name="redirectUsersDirectly"
                    :checked="custPortURLConfigDetails!=undefined && custPortURLConfigDetails.redirectUsersDirectly!=undefined && custPortURLConfigDetails.redirectUsersDirectly =='yes' ? true : false"
                  /> &emsp;
                </div>
              </div>
            </div>
          </fieldset>
        </form>
        <div
          class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark"
        >
          <div class="appFormFooter__right">
            <button
              class="appMainButton appMainButton--primary busyToggle"
              type="submit"
              @click="saveCustPortURLConfig"
            >Save</button>
          </div>
        </div>
      </div>
    </div>
    <SuccessToaster
      :successMessage="successMessage"
      :sucesstoasterShow="successtoaster"
      v-if="successtoaster"
    ></SuccessToaster>
    <ErrorToaster :errorMessage="errorMessage" :errortoasterShow="errortoaster" v-if="errortoaster"></ErrorToaster>
  </div>
</template>

<script>
import { ref,computed,watch,watchEffect,onMounted,onBeforeUnmount } from 'vue'
import Treeselect from "jaze-vue3-treeselect";
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";

import { storeToRefs } from "pinia";
import { usesettingsStore } from '@/store/settingsstore'
export default {
  setup() {
    // const custPortURLConfigDetails = ref( [] )
    const errortoaster = ref( false )
    const errorMessage = ref( "" )
    const successtoaster = ref( false )
    const successMessage = ref( "" )
    const customerPortalURL = ref( "" )

    const settingsStore = usesettingsStore()
    const { custPortURLConfigDetails } = storeToRefs(settingsStore)
    const custPortURLConfigGetter = computed(() => custPortURLConfigDetails.value)

    onMounted(() => {
      getConfigDetails();
    })
    onBeforeUnmount(() => {
      settingsStore.clearSettingsState();
    })

    watchEffect(() => {
      custPortURLConfigDetails.value = custPortURLConfigGetter.value;
      customerPortalURL.value = custPortURLConfigDetails.value.customerPortalURL;
    })

    const getConfigDetails = () => {
      if (Object.keys(custPortURLConfigGetter.value).length == 0) {
        // $store.dispatch("fetchCustPortURLDetails");
        settingsStore.fetchCustPortURLDetails();
      } else {
        custPortURLConfigDetails.value = custPortURLConfigGetter.value;
      }
    }
    const saveCustPortURLConfig = (e) => {
      successtoaster.value = false;
      errortoaster.value = false;
      e.preventDefault();
      var form = document.getElementById('customerPortalURLForm');
      const formData = new FormData(form);
      const postData = {};
      for (let [key, val] of formData.entries()) {
        Object.assign(postData, { [key]: val });
      }
      if(postData['redirectUsersDirectly'] == 'on') {
        postData['redirectUsersDirectly'] = 'yes'
      } else {
         postData['redirectUsersDirectly'] = 'no'
      }
      // $store.dispatch("customerPortalURLSubmit", postData).then(
      settingsStore.customerPortalURLSubmit(postData).then(  
        (response) => {
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
    }

    return { 
      settingsStore,
      custPortURLConfigGetter: custPortURLConfigDetails,
      custPortURLConfigDetails,
      errortoaster,
      errorMessage,
      successtoaster,
      successMessage,
      customerPortalURL,
      getConfigDetails,
      saveCustPortURLConfig,
    }
  },
  components: {
    Treeselect,
    SuccessToaster,
    ErrorToaster,
  },
};
</script>

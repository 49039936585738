<template>
  <div class="jazeMainPanel" style="height: 100%;">
    <div class="jazePanelContent jazePanelContent--scrollable">
      <div class="unifiSettingsContainer">
        <form
          ref="paymentCollectionSettingsForm"
          id="paymentCollectionSettingsForm"
          class="appForm appForm--cozy is-validation-hidden"
        >
          <div class="unifiSettingsHeader">
            <div class="unifiSettingsHeader__title">Payment Collection Settings</div>
          </div>
          <fieldset class="appFieldset appFieldset--quiet">
            <div class="unifiSettingsSection">
              <div class="appRow">
                <div class="col--sm4">
                  <label
                    class="appLabel appLabel--primary appLabel--boxAlign"
                  >Select Payment Collection</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <div class="appInputGroup verticalRhythmTop--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input
                        type="radio"
                        id="paymentCollectionAdminOrAreawise"
                        name="paymentCollectionAdminOrAreawise"
                        value="admin"
                        :checked="payConfigDetails!=undefined && payConfigDetails.paymentCollectionAdminOrAreawise!=undefined && payConfigDetails.paymentCollectionAdminOrAreawise =='admin' ? true :false"
                      />
                      <label for="paymentCollectionAdminOrAreawise">Admin-wise</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input
                        type="radio"
                        id="paymentCollectionAdminOrAreawise"
                        name="paymentCollectionAdminOrAreawise"
                        value="area"
                        :checked="payConfigDetails!=undefined && payConfigDetails.paymentCollectionAdminOrAreawise!=undefined && payConfigDetails.paymentCollectionAdminOrAreawise =='area' ? true :false "
                      />
                      <label for="paymentCollectionAdminOrAreawise">Area-wise</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input
                        type="radio"
                        id="paymentCollectionAdminOrAreawise"
                        name="paymentCollectionAdminOrAreawise"
                        value="showAll"
                        :checked="payConfigDetails!=undefined && (payConfigDetails.paymentCollectionAdminOrAreawise==undefined || (payConfigDetails.paymentCollectionAdminOrAreawise!=undefined && payConfigDetails.paymentCollectionAdminOrAreawise =='showAll')) ? true : false "
                      />
                      <label for="paymentCollectionAdminOrAreawise">Show All</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label
                    class="appLabel appLabel--primary appLabel--boxAlign"
                  >Payment collection auto approval</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input
                    type="checkbox"
                    id="paymentCollectionAutoApproval"
                    name="paymentCollectionAutoApproval"
                    :checked="payConfigDetails!=undefined && payConfigDetails.paymentCollectionAutoApproval!=undefined && payConfigDetails.paymentCollectionAutoApproval =='yes' ? true : false"
                  /> &emsp;
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label
                    class="appLabel appLabel--primary appLabel--boxAlign"
                  >After Collection don`t delete the assigned admin in admin collection</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input
                    type="checkbox"
                    id="paymentCollectionAutoAdminAssign"
                    name="paymentCollectionAutoAdminAssign"
                    :checked="payConfigDetails!=undefined && payConfigDetails.paymentCollectionAutoAdminAssign!=undefined && payConfigDetails.paymentCollectionAutoAdminAssign =='yes' ? true : false"
                  /> &emsp;
                </div>
              </div>
            </div>
          </fieldset>
        </form>
        <div
          class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark"
        >
          <div class="appFormFooter__right">
            <button
              class="appMainButton busyToggle"
              type="button"
              @click="subZoneModalShow=true"
              :title="subzoneType"
              v-if="payConfigDetails.edition == 'isp'"
            >SubZones Restrictions</button>
            <button
              class="appMainButton busyToggle"
              type="button"
              @click="subZoneModalShow=true"
              :title="subzoneType"
              v-else
            >SubAccounts Restrictions</button>

            <button
              class="appMainButton busyToggle"
              type="button"
              @click="pushZonefn()"
              v-if="payConfigDetails.edition == 'isp'"
            >Add to All SubZones</button>
            <button
              class="appMainButton busyToggle"
              type="button"
              @click="pushZonefn()"
              v-else
            >Add to All SubAccounts</button>

            <button
              class="appMainButton appMainButton--primary busyToggle"
              type="submit"
              @click="savePaymentConfig"
            >Save</button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered"
      v-if="subZoneModalShow"
    >
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div
            class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress"
          >
            <form class="jazeForm jazeModal jazeModal--small unifiAddUser">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title">Save</div>
                  <svg
                    viewBox="0 0 14 14"
                    class="jazeIcon jazeIcon--actionable"
                    @click="subZoneModalShow=false"
                  >
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeModal__main">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                    <div class="row">
                      <div class="col jazeFormGroup jazeFormGroup--flex">
                        <label
                          class="jazeLabel jazeLabel--primary"
                          v-if="payConfigDetails.edition =='isp'"
                        >Allow/Deny sub-zones to change payment collection settings</label>
                        <label
                          class="jazeLabel jazeLabel--primary"
                          v-else
                        >Allow/Deny sub-accounts to change payment collection settings</label>
                        <div class="toggle appToggle appToggle--light" data-on="On" data-off="Off">
                          <input
                            type="checkbox"
                            name="switchcheckbox"
                            id="switchcheckbox"
                            :checked="payConfigDetails.subAccountsCanNotChangePaymentCollection == 'Yes' ? true : false"
                          />
                          <label for="switchcheckbox"></label>
                        </div>
                      </div>
                    </div>
                    <div
                      class="appType--micro appType--quiet italicized"
                      v-if="payConfigDetails.edition =='isp'"
                    >Note: If it is 'ON', sub-zones can not change the payment collection settings.</div>
                    <div
                      class="appType--micro appType--quiet italicized"
                      v-else
                    >Note: If it is 'ON', sub-accounts can not change the payment collection settings.</div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button
                    class="jazeButton jazeButton--secondary"
                    type="button"
                    @click="subZoneModalShow=false"
                  >Cancel</button>
                  <button
                    class="jazeButton jazeButton--primary submitForm"
                    type="button"
                    @click="allowSubZones"
                  >Yes</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <SuccessToaster
      :successMessage="successMessage"
      :sucesstoasterShow="successtoaster"
      v-if="successtoaster"
    ></SuccessToaster>
    <ErrorToaster :errorMessage="errorMessage" :errortoasterShow="errortoaster" v-if="errortoaster"></ErrorToaster>
    <PushToSubZone v-if="pushModal" :selected='selected' :edition="payConfigDetails.edition" @extend-clicked-push-detail="onclickclose" :subAccounts="subAccOptions" :from="'paymentollection'" :modalType="'selectedType'" :dataSetting="dataSetting"></PushToSubZone>
  </div>
</template>

<script>
import { ref,toRefs,computed,watch,watchEffect,onMounted,onBeforeUnmount } from 'vue'
import Treeselect from "@riophae/vue-treeselect";
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import PushToSubZone from "@/components/Profiles/PushToSubZoneModal.vue";

import { storeToRefs } from "pinia";
import { usesettingsStore } from '@/store/settingsstore'
import { uselayoutStore } from '@/store/layoutstore'
export default {
  setup() {
    const layoutStore = uselayoutStore()
    const { subaccDetails } = storeToRefs(layoutStore)
    const subaccDetailsGetter= computed(() => subaccDetails.value);
    // const payConfigDetails = ref( [] )
    const errortoaster = ref( false )
    const errorMessage = ref( "" )
    const successtoaster = ref( false )
    const successMessage = ref( "" )
    const subZoneModalShow = ref( false )
    const subzoneType = ref( "" )
    const selectedAccVal = ref( null )
    const subAccOptions = ref( [
            {
              id: "selectAll",
              label: "Select All",
              children: [],
            },
          ] )
    const optionaAllSelect = ref( "all" )
    const dataSetting = ref( [{
              id: "selectAll",
              label: "Select All",
              children: []
            }] )
    const dataSettingValue = ref( null )
    const pushModal = ref( false )
    const selected = ref( [] )
    // const subAccounts = ref( [] )

    const settingsStore = usesettingsStore()
    const { payConfigDetails } = storeToRefs(settingsStore)
    const paymentConfigGetter = computed(() => payConfigDetails.value)

    onMounted(() => {
      getConfigDetails();
    })
    onBeforeUnmount(() => {
      settingsStore.clearSettingsState();
    })

    watchEffect(() => {
      payConfigDetails.value = paymentConfigGetter.value;
      if (
        payConfigDetails.value.subAccountsCanNotChangePaymentCollection == "Yes"
      ) {
        subzoneType.value = "Enabled";
      } else {
        subzoneType.value = "Disabled";
      }
      if (
        payConfigDetails.value.dataSetting != undefined &&
        payConfigDetails.value.dataSetting.length != 0
      ) {
        for (let i = 0; i < payConfigDetails.value.dataSetting.length; i++) {
          // var deactivated = payConfigDetails.value.dataSetting[i].deactivated == 0 ? " (Deactivated)" : "";
          dataSetting.value[0].children.push({
            id: payConfigDetails.value.dataSetting[i].id,
            label: payConfigDetails.value.dataSetting[i].label,
          });
        }
      }
      if (
        subaccDetailsGetter.value != undefined &&
        subaccDetailsGetter.value.length != 0
      ) {
        for (let i = 0; i < subaccDetailsGetter.value.length-1; i++) {
          var deactivated = subaccDetailsGetter.value[i].deactivated == 0 ? " (Deactivated)" : "";
          subAccOptions.value[0].children.push({
            id: subaccDetailsGetter.value[i].id,
            label: subaccDetailsGetter.value[i].name + deactivated,
          });
        }
      }
    })

    const getConfigDetails = () => {
      if (Object.keys(paymentConfigGetter.value).length == 0) {
        // $store.dispatch("fetchPaymentCollectDetails");
        settingsStore.fetchPaymentCollectDetails();
      } else {
        payConfigDetails.value = paymentConfigGetter.value;
      }
    }
    const allowSubZones = () => {
      successtoaster.value = false;
      errortoaster.value = false;
      if (document.getElementById("switchcheckbox").checked == true) {
        var check = "on";
      } else {
        var check = "off";
      }
      var data = {
        switchCheckBoxValue: check,
        attribute: "subAccountsCanNotChangePaymentCollection",
        type: "paymentcollect",
      };
      // $store.dispatch("saveSubAccountPermissionSettings", data).then(
      settingsStore.saveSubAccountPermissionSettings(data).then(  
        (response) => {
          subZoneModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
    }
    const savePaymentConfig = (e) => {
      successtoaster.value = false;
      errortoaster.value = false;
      e.preventDefault();
      var form = document.getElementById('paymentCollectionSettingsForm');
      const formData = new FormData(form);
      const postData = {};
      for (let [key, val] of formData.entries()) {
        Object.assign(postData, { [key]: val });
      }
      // $store.dispatch("paymentcollectConfigurationSubmit", postData).then(
      settingsStore.paymentcollectConfigurationSubmit(postData).then(  
        (response) => {
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
    }
    const onclickclose = (value) => {
      pushModal.value = value;
    }
    const pushZonefn = () => {
      pushModal.value = !pushModal.value;
    }

    return {
      settingsStore,
      paymentConfigGetter: payConfigDetails,
      subaccDetailsGetter,
      payConfigDetails,
      errortoaster,
      errorMessage,
      successtoaster,
      successMessage,
      subZoneModalShow,
      subzoneType,
      selectedAccVal,
      subAccOptions,
      optionaAllSelect,
      dataSetting,
      dataSettingValue,
      pushModal,
      selected,
      // subAccounts,
      getConfigDetails,
      allowSubZones,
      savePaymentConfig,
      onclickclose,
      pushZonefn,
    }
  },
  components: {
    Treeselect,
    SuccessToaster,
    ErrorToaster,
    PushToSubZone,
  },
};
</script>

const Full = () => import( '@/container/Full.vue')

const InterfaceRoutes = () => import(/* webpackPrefetch: false */ '@/views/networksettings/Index.vue')
const DhcpRoutes = () => import(/* webpackPrefetch: false */ '@/views/networksettings/DHCP.vue')
const VlanRoutes = () => import(/* webpackPrefetch: false */ '@/views/networksettings/VLAN.vue')
const RouteAndNATRoutes = () => import(/* webpackPrefetch: false */ '@/views/networksettings/RoutingAndNAT.vue')
const SnmpRoutes = () => import(/* webpackPrefetch: false */ '@/views/networksettings/SNMP.vue')

export default {
   path: '/network_settings',
   component: Full,
   redirect: '/network_settings',
   beforeEnter(to, from, next) {
      if (localStorage.getItem('token')) {
         next()
      } else {
         next('/signin')
      }
   },
   children: [
      {
         path: '/network_settings/index',
         component: InterfaceRoutes,
         meta: {
            title: "Network Settings"
         }
      },
      {
         path: '/network_settings/dhcp',
         component: DhcpRoutes,
         meta: {
            title: "DHCP"
         }
      },
      {
         path: '/network_settings/vlan',
         component: VlanRoutes,
         meta: {
            title: "VLAN"
         }
      },
      {
         path: '/network_settings/routing',
         component: RouteAndNATRoutes,
         meta: {
            title: "Routing"
         }
      },
      {
         path: '/network_settings/snmp',
         component: SnmpRoutes,
         meta: {
            title: "SNMP"
         }
      },
   ]
}
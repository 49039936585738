<template>
    <div class="jazeMainPanel" style="height: 100%;">
        <div class="jazePanelContent jazePanelContent--scrollable">
        <div class="unifiSettingsContainer">
                <form ref="consolidatedTemplateForm" class="appForm appForm--cozy is-validation-hidden " :class="formClass">
                    <div class="unifiSettingsHeader ">
                        <div class="unifiSettingsHeader__title">User Notifications</div>
                    </div>
                    <fieldset class="appFieldset appFieldset--quiet">
                        <div class="unifiSettingsSection">
                            <table class="jazeTable jazeTable--hoverable jazeTable--noWrap mt-1 is-not-responsive">
                                <thead>
                                </thead>
                                <tbody class="jazeTableBody">
                                    <tr class="appRow" v-for='(template,index) in computedConsolidatedTemplateLists' :key='index'>
                                    <td v-if="template != undefined && template != ''">
                                        <div class="col--sm6">
                                        <label class="appLabel appLabel--primary appLabel--boxAlign"> {{template.name}} </label>
                                        </div>
                                        <div class="col--sm2 appFormGroup appFormGroup--cozy">
                                        Email <input :disabled="((template.smsWpOnly != undefined && template.smsWpOnly == 'yes') || (visible != 'visible')) ? true : false" type="checkbox" @click="userNotify(template.name, template.id, 'email', template.email)" v-model="template.email" />
                                        </div>
                                        <div class="col--sm2 appFormGroup appFormGroup--cozy">
                                        SMS <input :disabled="((template.emailOnly != undefined && template.emailOnly == 'yes') || (visible != 'visible')) ? true : false" type="checkbox" @click="userNotify(template.name, template.id, 'sms', template.sms)" v-model="template.sms"/>
                                        </div>
                                        <div class="col--sm2 appFormGroup appFormGroup--cozy">
                                        Whatsapp <input :disabled="((template.emailOnly != undefined && template.emailOnly == 'yes') || (visible != 'visible')) ? true : false" type="checkbox" @click="userNotify(template.name, template.id, 'whatsapp', template.whatsapp)" v-model="template.whatsapp"/>
                                        </div>
                                    </td>
                                    </tr>  
                                </tbody>
                            </table>
                        </div>
                    </fieldset>
                </form>
                <div class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark" v-if="visible == 'visible'">
                    <div class="appFormFooter__right">
                    <button class="appMainButton busyToggle" type="button" @click="subZoneModalShow=true" v-if="edition == 'isp'"> SubZones Restrictions</button>
                    <button class="appMainButton busyToggle" type="button" @click="subZoneModalShow=true" v-else> SubAccounts Restrictions</button>

                    <button class="appMainButton appMainButton--primary busyToggle" type="button" @click="pushZonefn()" v-if="edition == 'isp'"> Add to All SubZones</button>
                    <button class="appMainButton appMainButton--primary busyToggle" type="button" @click="pushZonefn()" v-else> Add to All SubAccounts</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="subZoneModalShow">
        <div class="pos-relative" dialog-window>
            <div dialog-content>
            <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
                <form class="jazeForm jazeModal jazeModal--small unifiAddUser">
                <div class="jazeModal__header pb-0">
                    <div class="jazeHeader jazeHeader--centered">
                    <div class="jazeHeader__title"> Save </div>
                    <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="subZoneModalShow=false">
                        <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                        <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                        </g>
                    </svg>
                    </div>
                </div>
                <div class="jazeModal__main">
                    <div class="jazeModalContent pb-1">
                    <div class="container container--horizontal-cozy container--vertical-comfy">
                        <div class="row">
                        <div class="col jazeFormGroup jazeFormGroup--flex">
                            <label class="jazeLabel jazeLabel--primary" v-if="edition =='isp'">Allow/Deny sub-zones to change User Notifications Templates</label>
                            <label class="jazeLabel jazeLabel--primary" v-else>Allow/Deny sub-accounts to change User Notifications Templates</label>
                            <div class="toggle appToggle appToggle--light" data-on="On" data-off="Off">
                            <input type="checkbox" v-model="switchcheckbox" id="switchcheckbox" :checked="subAccountsCanNotChangeConsolidatedTemplate == 'Yes' ? true : false">
                            <label for="switchcheckbox"></label>
                            </div>
                        </div>
                        </div>
                        <div class="appType--micro appType--quiet italicized" v-if="edition =='isp'">
                        Note: If it is 'ON', sub-zones can not change the User Notifications.
                        </div>
                        <div class="appType--micro appType--quiet italicized" v-else>
                        Note: If it is 'ON', sub-accounts can not change the User Notifications.
                        </div>
                    </div>
                    </div>
                </div>
                <div class="jazeModal__footer">
                    <div class="jazeButtonGroup jazeButtonGroup--cozy">
                    <button class="jazeButton jazeButton--secondary" type="button" @click="subZoneModalShow=false">Cancel</button>
                    <button class="jazeButton jazeButton--primary submitForm" type="button" @click="allowSubZones">Yes</button>
                    </div>
                </div>
                </form>
            </div>
            </div>
        </div>
        </div>
    <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
    <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
    <PushToSubZone v-if="pushModal" :selected='selected' :edition="edition" @extend-clicked-push-detail="onclickclose" :subAccounts="subaccDetailsGetter" :from="'userNotification'" :modalType="'selectedType'" :dataSetting="templatesArr"></PushToSubZone>
  </div>
</template>
<script>
import { ref,computed,watch,watchEffect,onMounted,onBeforeUnmount } from 'vue'
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import PushToSubZone from "@/components/Profiles/PushToSubZoneModal.vue";
import Treeselect from "@riophae/vue-treeselect";

import { uselayoutStore } from '@/store/layoutstore'
import {storeToRefs} from "pinia";
import { usesettingsStore } from '@/store/settingsstore'
export default {
  setup() {
    const errorShow = ref( false )
    const successtoaster = ref( false )
    const successMessage = ref( "" )
    const errortoaster = ref( false )
    const errorMessage = ref( "" )
    const htmlContent = ref( false )
    const sendVerificationCodeEmail = ref( null )
    const sendSms = ref( "off" )
    const sendWhatsapp = ref( "off" )
    const formClass = ref( "" )
    const consolidatedTemplateLists = ref( [] )
    const valueData = ref( null )
    const accountIdset = ref( null )
    const edition = ref( 'isp' )
    const subZoneModalShow = ref( false )
    const switchcheckbox = ref( false )
    const subAccountsCanNotChangeConsolidatedTemplate = ref( "No" )
    const subAccOptions = ref( [
            {
              id: "selectAll",
              label: "Select All",
              children: [],
            },
          ] )
    const selectedAccVal = ref( null )
    const visible = ref( "" )
    const optionaAllSelect = ref( "all" )
    const templatesArr = ref( [{
              id: "selectAll",
              label: "Select All",
              children: []
            }] )
    const dataSettingValue = ref( null )
    const pushModal = ref( false )
    const selected = ref( [] )
    // const subAccounts = ref( [] )

    const layoutStore = uselayoutStore()
    const { headerDetails,subaccDetails } = storeToRefs(layoutStore)
    const subaccDetailsGetter= computed(() => subaccDetails.value);
    const headerDetailsGetter = computed(() => headerDetails.value);
    const settingsStore = usesettingsStore()
    const { consolidatedTemplateList } = storeToRefs(settingsStore)
    const getConsolidatedTemplateDetailsGetters = computed(() => consolidatedTemplateList.value)

    const computedConsolidatedTemplateLists = computed(() => {
      if (
          getConsolidatedTemplateDetailsGetters.value[valueData.value + "-consolidatedTemplateList"] != undefined &&
          getConsolidatedTemplateDetailsGetters.value[valueData.value + "-consolidatedTemplateList"] != []
        ) {
            consolidatedTemplateLists.value = getConsolidatedTemplateDetailsGetters.value[valueData.value + "-consolidatedTemplateList"].templatesArr;
            visible.value = getConsolidatedTemplateDetailsGetters.value[valueData.value + "-consolidatedTemplateList"].visible;
            return getConsolidatedTemplateDetailsGetters.value[valueData.value + "-consolidatedTemplateList"].templatesArr;
        }
    })

    onMounted(() => {
      getConsolidatedTemplateDetails();
    })

    const accIdSet = () => {
      valueData.value = accountIdset.value;
    }
    const getConsolidatedTemplateDetails = () => {
      // $store.dispatch("fetchConsolidatedTemplateDetails");
      settingsStore.fetchConsolidatedTemplateDetails();
    }

    watchEffect(() => {
      if (Object.keys(headerDetailsGetter.value).length > 0) {
        accountIdset.value = headerDetailsGetter.value.Auth.User.Account.id;
        accIdSet();
       // getConsolidatedTemplateDetails();
      }
    })

    watch(getConsolidatedTemplateDetailsGetters,() => {
      if (Object.keys(headerDetailsGetter.value).length > 0) {
        valueData.value = headerDetailsGetter.value.Auth.User.Account.id;
      }
      if (Object.keys(getConsolidatedTemplateDetailsGetters.value).length > 0) {
        var obj = Object.keys(getConsolidatedTemplateDetailsGetters.value);
        var dataobj = valueData.value + "-consolidatedTemplateList";
        if (obj.includes(dataobj) == true) {
          edition.value = getConsolidatedTemplateDetailsGetters.value[dataobj].edition;
          visible.value = getConsolidatedTemplateDetailsGetters.value[dataobj].visible;
          // subAccounts.value = getConsolidatedTemplateDetailsGetters.value[dataobj].accountsArr;
        }
      }
      if(getConsolidatedTemplateDetailsGetters.value != undefined && getConsolidatedTemplateDetailsGetters.value[dataobj] != undefined && getConsolidatedTemplateDetailsGetters.value[dataobj].subAccountsCanNotChangeConsolidatedTemplate != undefined && (getConsolidatedTemplateDetailsGetters.value[dataobj].subAccountsCanNotChangeConsolidatedTemplate == "Yes")) {
          switchcheckbox.value = true;
      }
      if (
        getConsolidatedTemplateDetailsGetters.value[dataobj].templatesArr != undefined &&
        getConsolidatedTemplateDetailsGetters.value[dataobj].templatesArr.length != 0
      ) {
        for (let i = 0; i < getConsolidatedTemplateDetailsGetters.value[dataobj].templatesArr.length; i++) {
          templatesArr.value[0].children.push({
            id: getConsolidatedTemplateDetailsGetters.value[dataobj].templatesArr[i].id,
            label: getConsolidatedTemplateDetailsGetters.value[dataobj].templatesArr[i].name,
          });
        }
      }
      if (getConsolidatedTemplateDetailsGetters.value[dataobj] != undefined &&
          getConsolidatedTemplateDetailsGetters.value[dataobj].accountsArr != undefined &&
          getConsolidatedTemplateDetailsGetters.value[dataobj].accountsArr.length != 0) {
        for (
          let i = 0;
          i < getConsolidatedTemplateDetailsGetters.value[dataobj].accountsArr.length;
          i++
        ) {
          var deactivated = getConsolidatedTemplateDetailsGetters.value[dataobj].accountsArr[i].deactivated == 0 ? " (Deactivated)" : "";
          subAccOptions.value[0].children.push({
            id: getConsolidatedTemplateDetailsGetters.value[dataobj].accountsArr[i].id,
            label: getConsolidatedTemplateDetailsGetters.value[dataobj].accountsArr[i].name + deactivated,
          });
        }
      }
    })

    const userNotify = (name, template, type, set) => {
      var data = {
        templateName: name,
        templateId: template,
        type: type,
        set: set == true ? 'true' : 'false'
      }
      // $store.dispatch("userNotificationsSubmit", data).then(
      settingsStore.userNotificationsSubmit(data).then(  
        response => {
          successtoaster.value = true;
          htmlContent.value = true;
          successMessage.value = response.message;
          setTimeout(
            function () {
              successtoaster.value = false;
              htmlContent.value = false;
            }.bind(this),
            3500
          );
        },
        error => {
          errortoaster.value = true;
          htmlContent.value = true;
          errorMessage.value = error;
          setTimeout(() => {
            errortoaster.value = false;
            htmlContent.value = false;
          }, 3500);
        }
      );
    }
    const allowSubZones = () => {
      successtoaster.value = false;
      errortoaster.value = false;
      if (switchcheckbox.value == true) {
        var check = "on";
      } else {
        var check = "off";
      }
      var data = {
        switchCheckBoxValue: check,
        attribute: "subAccountsCanNotChangeConsolidatedTemplate",
      };
      // $store.dispatch("saveSubAccountPermissionSettings", data).then(
      settingsStore.saveSubAccountPermissionSettings(data).then(  
        (response) => {
          subZoneModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            3500
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            3500
          );
          console.log(error);
        }
      );
    }  
    const checkSmsOrWhatsappDisable = (template) => {
      if(template != undefined && template.smswpdisabled != undefined && template.smswpdisabled == 'yes') {

      }
    }
    const onclickclose = (value) => {
      pushModal.value = value;
    }
    const pushZonefn = () => {
      pushModal.value = !pushModal.value;
    }

    return {
      layoutStore,
      headerDetailsGetter: headerDetails,
      settingsStore,
      getConsolidatedTemplateDetailsGetters: consolidatedTemplateList,
      subaccDetailsGetter,
      computedConsolidatedTemplateLists,
      errorShow,
      successtoaster,
      successMessage,
      errortoaster,
      errorMessage,
      htmlContent,
      sendVerificationCodeEmail,
      sendSms,
      sendWhatsapp,
      formClass,
      consolidatedTemplateLists,
      valueData,
      accountIdset,
      edition,
      subZoneModalShow,
      switchcheckbox,
      subAccountsCanNotChangeConsolidatedTemplate,
      subAccOptions,
      selectedAccVal,
      visible,
      optionaAllSelect,
      templatesArr,
      dataSettingValue,
      pushModal,
      selected,
      // subAccounts,
      accIdSet,
      getConsolidatedTemplateDetails,
      userNotify,
      allowSubZones,
      checkSmsOrWhatsappDisable,
      onclickclose,
      pushZonefn,
    }
  },
  components: {
    SuccessToaster,
    ErrorToaster,
    PushToSubZone,
    Treeselect,
  },
};
</script>
const Full = () => import( '@/container/Full.vue')

// dashboard components
const reportRoutes = () => import(/* webpackPrefetch: false */ '@/views/reporting/index.vue')
const scheduleReportRoutes = () => import(/* webpackPrefetch: false */ '@/views/reporting/ScheduleReporting.vue')
const addEditscheduleReportRoutes = () => import(/* webpackPrefetch: false */ '@/components/Reporting/AddEditScheduleReport.vue')
export default {
   path: '/reporting',
   component: Full,
   redirect: '/reporting',
   beforeEnter(to, from, next) {
      if (localStorage.getItem('token')) {
         next()
      } else {
         next('/signin')
      }
   },
   children: [
      {
         path: '/reporting',
         component: reportRoutes,
         meta: {
            title: "Reporting"
         }
      },
      {
         path: '/reporting/schedule_reporting',
         component: scheduleReportRoutes,
         meta: {
            title: "Schedule Reporting"
         }
      },
      {
         path: '/reporting/add_schedule_reporting',
         component: addEditscheduleReportRoutes,
         meta: {
            title: "Schedule Reporting"
         }
      },
      {
         path: '/reporting/add_schedule_reporting/:id',
         component: addEditscheduleReportRoutes,
         meta: {
            title: "Schedule Reporting"
         }
      }
   ]
}
